// helpers para sprints

import equiposEnPartida from "./equipos.js";

import {host} from "./connection.js"

// ${host}

import { verJugadasEquipo } from "./jugadas.js"

// dict hardcodeado de sprint : mazo
const MazosSprint = {
    "Sprint 1": "Trends",
    "Sprint 2": "Sustainability",
    "Sprint 3": "Patterns",
    "Sprint 4": "BlueOcean",
    "Sprint 5": "DigitalDrivers",
    "Sprint 6": "Platform",
    "Sprint 7": "DataDriven",
    "Sprint 8": "Test",
    "Sprint 9": "Metrics",
    "Sprint 10": "Ecosystem"
};

// agregar nuevo sprint a la DB, asociado a la partidaID
async function crearSprint(partidaId, nombre, numSprint) {
    try {
        console.debug(`Creando sprint con partida ${partidaId}, nombre ${nombre}, numSprint ${numSprint}`)
        const apiUrl = `https://${host}/juego/${partidaId}/sprint`;
        console.debug(`Llamando al back por ${apiUrl}`)
        const body = { "duracion_min": null, "numSprint": numSprint, "nombre": nombre }
        const response = await fetch(apiUrl, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) });
        if (!response.ok) {
            const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
            throw new Error(errorMessage);
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Fetch Data Error:', error);
        throw error;
    }
}

// asociar equipo a un sprint
// pide numSprint
async function agregarEquipoAlSprint(partidaId, equipoId, sprint) {
    try {
        console.debug(`Asociando equipo ${equipoId} en partida ${partidaId} a ${sprint}`)
        const apiUrl = `https://${host}/juego/${partidaId}/${equipoId}/sprint`;
        const body = { "numSprint": sprint };
        console.debug(`Llamando al back por ${apiUrl} con body ${JSON.stringify(body)}`)
        const response = await fetch(apiUrl, { method: 'PATCH', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) });
        if (!response.ok) {
            const errorMessage = `agregarEquipoAlSprint Network response was not ok. Status: ${response.status} ${response.statusText}`;
            throw new Error(errorMessage);
        }
        const data = await response.json();
        console.debug(`Resp es ${JSON.stringify(data)}`)
        return data.data;
    } catch (error) {
        console.error('Fetch Data Error:', error);
        throw error;
    }
}

// dentro de la partida, admin selecciona el sprint
function nuevoSprint(partidaId, numSprint) {
    // crear el sprint en la DB y asociar a la partida
    crearSprint(partidaId, numSprint)

    // traer lista de equipos en la partida
    const equipos = equiposEnPartida(partidaId) // array con cada objeto equipo

    // asociar equipos al sprint
    for (var equipo in Object.values(equipos)) {
        agregarEquipoAlSprint(partidaId, equipo, numSprint)
    }

    // return ???
}

// traer numero de sprints en la partida 
async function numeroSprintsEnPartida(partidaId) {
    try {
        const apiUrl = `https://${host}/juego/${partidaId}/sprints`;
        const response = await fetch(apiUrl);
        if (!response.ok) {
            const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
            throw new Error(errorMessage);
        }
        const data = await response.json();
        console.debug("Datos de respuesta de numeroSprintsEnPartida:", data.data);
        return data.data;
    } catch (error) {
        console.error('Fetch Data Error:', error);
        return null;
    }
}

// pide "Trends"
// app.get('/juego/:partida/sprint'
async function getSprintEnPartida(partidaId, nombre) {
    try {
        const apiUrl = `https://${host}/juego/${partidaId}/sprint/${nombre}`;
        const response = await fetch(apiUrl);
        if (!response.ok) {
            const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
            throw new Error(errorMessage);
        }
        const data = await response.json();
        console.debug("Sprint en partida es:", data.data[0]);
        return data.data[0];
    } catch (error) {
        console.error('Fetch Data Error:', error);
        throw error;
    }
}

// patch para asociar duracion al sprint
// pide {"duracion_min": null}
// app.patch('/juego/:partida/sprint/:sprint/tiempo', async (req, res) => {

// determinar cual es el proximo sprint para un equipo, para redirect

// dado una partida y equipo, determina cual sprint les toca jugar
async function traerNombreSprint(partidaID, equipoID) {
    try {
        var nombreSprint
        var sprints = await numeroSprintsEnPartida(partidaID)


        return nombreSprint


    } catch (error) {
        console.error(`Error trayendo el proximo sprint para equipo ${equipoID} en partida ${partidaID}`)
    }
}

function addRevBeforeLastFourChars(inputString) {
    if (inputString.length >= 4) {
        const prefix = inputString.slice(0, -4);
        const extension = inputString.slice(-4);
        return prefix + "_REV" + extension;
    } else {
        // Handle the case where the input string is too short.
        console.debug(inputString);
        return inputString;
    }
}

export default {
    MazosSprint,
    crearSprint,
    nuevoSprint,
    numeroSprintsEnPartida,
    getSprintEnPartida,
    traerNombreSprint,
    agregarEquipoAlSprint,
    addRevBeforeLastFourChars
};