import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import img from "../assets/img/Inicio.jpg";  // Ruta IMAGEN
import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    Input
} from "reactstrap";
import { host } from "../variables/connection.js"
// ${host}

const LoginAdmin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === "username") setUsername(value);
        if (name === "password") setPassword(value);
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        const URL = `https://${host}/api/login`;
        console.debug(`Intentando conectar al back por ${URL} ...`);

        fetch(URL, {
            method: 'POST',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password, loginType: 'admin', }), // ultimo valor null
        })
            .then(response => {
                console.debug('Respuesta recibida:', response);

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.debug('Data procesada:', data);

                if (data.token) {
                    console.debug('Token recibido, guardando en localStorage...');
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('userType', 'admin'); // Aquí se almacena el tipo de usuario como "admin"
                    setError(null);
                    console.debug('Navegando a /admin/NuevaPartida...');
                    navigate('/admin/NuevaPartida');
                } else {
                    console.debug('No se recibió un token.');
                    setError('Error de inicio de sesión. Verifica tus credenciales.');
                }
            })
            .catch(err => {
                console.error('Error en el proceso:', err);
                setError('Hubo un problema al iniciar sesión.');
            });
    };

    return (
        <div className="content"
            style={{
                backgroundImage: `url(${img})`,
                backgroundSize: 'cover',
                backgroundPosition: '50% 33%',  // 60% mueve la imagen un poco hacia abajo
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                width: '100vw'
            }}>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366' }}>
                        <CardBody>
                            <h2 style={{ color: '#E8E8E8' }}>Iniciar Sesión</h2>
                            <p style={{ color: '#E5E5E5', marginBottom: '20px' }}>
                                Bienvenido Administrador, por favor ingrese su credencial de acceso.
                            </p>
                            <form onSubmit={handleLogin}>
                                <div style={{ marginBottom: '10px' }}>
                                    <Input
                                        style={{
                                            color: '#E8E8E8',
                                            borderColor: '#2C83D5',
                                            fontSize: '18px',
                                            backgroundColor: 'transparent'
                                        }}
                                        type="text"
                                        placeholder="Nombre de usuario"
                                        name="username"
                                        value={username}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div style={{ marginBottom: '10px' }}>
                                    <Input
                                        style={{
                                            color: '#E8E8E8',
                                            borderColor: '#2C83D5',
                                            fontSize: '18px',
                                            backgroundColor: 'transparent'
                                        }}
                                        type="password"
                                        placeholder="Contraseña"
                                        name="password"
                                        value={password}
                                        onChange={handleInputChange}
                                        onKeyPress={event => {
                                            if (event.key === "Enter") {
                                                handleLogin(event);
                                            }
                                        }}
                                    />
                                </div>
                                <Button style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px' }}>Iniciar Sesión</Button>
                                {error && <p style={{ color: '#E8E8E8', marginTop: '10px' }}>{error}</p>}
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default LoginAdmin;