// helpers para manejar equipos

import { host } from "./connection.js"
// ${host}

// equipos en partida
async function equiposEnPartida(partidaId) {
    const apiUrl = `https://${host}/juego/${partidaId}`;
    console.debug(`Trayendo lista de equipos para partida ${partidaId}...`)

    try {
        const response = await fetch(apiUrl);

        if (!response.ok) {
            const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
            throw new Error(errorMessage);
        }

        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Fallo de equiposEnPartida en equipos.js:', error);
        throw error;
    }
}

async function cambiarPosicion(partidaID, equipoID, recuadro) {
    const url = `https://${host}/juego/${partidaID}/${equipoID}/recuadro`;
    const payload = {"recuadro": recuadro}
    console.debug(`Cambiando posicion de equipo ${equipoID} a ${recuadro}...`)

    try {
        const response = await fetch(url, {
            method: 'PATCH', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
          });

        if (!response.ok) {
            const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
            throw new Error(errorMessage);
        }

        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Fallo de cambiarPosicion en equipos.js:', error);
        throw error;
    }

}

export default { equiposEnPartida, cambiarPosicion }