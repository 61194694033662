// baraja.js
// asignar cartas a cada equipo

import cartasHelpers from "./cartas.js"
import equiposHelpers from "./equipos.js"
import sprintsHelpers from "./sprints.js"
import { host } from "./connection.js"

const MazosSprint = sprintsHelpers.MazosSprint
const getSprintEnPartida = sprintsHelpers.getSprintEnPartida
const listaMazos = cartasHelpers.listaMazos
const cartasEnMazo = cartasHelpers.cartasEnMazo
const cartaPorID = cartasHelpers.cartaPorID
const cartaPorNumero = cartasHelpers.cartaPorNumero
const variasCartasPorID = cartasHelpers.variasCartasPorID
const equiposEnPartida = equiposHelpers.equiposEnPartida

//////////////////
//      DB      //
//////////////////

/*
BARAJA:
  `id` BIGINT UNSIGNED NOT NULL AUTO_INCREMENT PRIMARY KEY,
  `partida_id` BIGINT UNSIGNED NOT NULL,
  `equipo_id` BIGINT UNSIGNED NULL,
  `cartas_ids` VARCHAR(255) NOT NULL,
  `sprint` BIGINT UNSIGNED NOT NULL,
  `descarte` TINYINT(1) NOT NULL

EQUIPOS:
  `baraja_trends` BIGINT UNSIGNED NULL,
  `baraja_modelpatterns` BIGINT UNSIGNED NULL,
  `baraja_blueocean` BIGINT UNSIGNED NULL,
  `baraja_digitaldrivers` BIGINT UNSIGNED NULL,
  `baraja_plataforma` BIGINT UNSIGNED NULL,
  `baraja_datadriven` BIGINT UNSIGNED NULL,
  `baraja_modeltest` BIGINT UNSIGNED NULL,
  `baraja_metricas` BIGINT UNSIGNED NULL,
*/

// crear una baraja en la db
// espera un array de dict de arrays [{teamID:1,cartasIDs:[1,2,3]},{teamID:2,cartasIDs:[4,5,6]}]
// barajas/teamCards trae {"3":[46,32,65,47,17,29,6,54,51,40,49,38],"4":[22,64,27,30,11,42,13,58,45,55,4,10]}
async function crearBarajaEnDb(partidaId, sprint, teamCards) {
  console.debug(`Creando baraja en partida ${partidaId}, ${sprint}, con ${JSON.stringify(teamCards)}`)
  let equipos = Object.keys(teamCards) // [3,4]
  let numEquipos = equipos.length // 2

  // Iterate through each team in teamCards
  for (let i = 0; i < numEquipos; i++) {
    let teamID = equipos[i]; // 3
    let cardIDs = teamCards[teamID]; // [46,32,65,47,17,29,6,54,51,40,49,38]
    cardIDs = cardIDs.sort((a, b) => a - b)
    console.debug(`cartas sorteadas para equipo ${teamID} son ${cardIDs}`)

    // Create the URL and payload for the fetch request
    const url = `https://${host}/juego/${partidaId}/${teamID}/sprint/${sprint}/baraja`;
    const payload = { cardIDs };
    console.debug(`Enviando al back por ${url}, con payload ${JSON.stringify(payload)}`)

    // Send the fetch request using the Fetch API
    fetch(url, {
      method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Request failed with status: ${response.status}`);
        }
        return response.json(); // Parse the response if needed
      })
      .then(data => {
        console.debug(`Sprint request for ${teamID} sent successfully.`);
        // Handle the response data if needed
      })
      .catch(error => {
        console.error(`Error sending sprint request for ${teamID}:`, error);
      });
  }
}

// asignar max de movimientos x sprint
async function asignarMaxMovimientos(partidaId, sprintId, movs) {
  // Create the URL and payload for the fetch request
  const url = `https://${host}/juego/${partidaId}/sprint/${sprintId}/movs`;
  const payload = { "movimientos_disp": movs };

  console.debug(`asignarMaxMovimientos enviando al back por ${url}, con payload ${JSON.stringify(payload)}`)

  // Send the fetch request using the Fetch API
  fetch(url, {
    method: 'PATCH', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      return response.json(); // Parse the response if needed
    })
    .then(data => {
      console.debug(`Sprint request for ${sprintId} sent successfully.`);
      // Handle the response data if needed
    })
    .catch(error => {
      console.error(`Error sending sprint request for ${sprintId}:`, error);
    });
}

// todas las cartas en mazo (lista de objs)
// pide "Trends"
async function cartasEnBaraja(partidaID, equipoID, sprintID) {
  console.debug(`trayendo cartasEnBaraja para sprint ${sprintID}...`)

  const apiUrl = `https://${host}/juego/${partidaID}/${equipoID}/sprint/${sprintID}/baraja`;

  try {
    const response = await fetch(apiUrl);

    if (!response.ok) {
      const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
      throw new Error(errorMessage);
    }

    const data = await response.json();

    // validaciones

    // ESTE ERROR SUCEDE CUANDO LA CONEXION ESTA LENTA Y EL SRV NO CREA LA BARAJA... como lo arreglamos? quizas usar el mazo en vez?
    if (!data) { return }
    console.debug(`cartasEnBaraja resp: ${JSON.stringify(data)}`)
    if (!data.data) {
      console.error(`cartasEnBaraja no devuelve data.data! F5...`)
      return;
    } else {
      const cartasIDs = data.data.cartas_ids
      console.debug(`cartasEnBaraja IDs: ${JSON.stringify(cartasIDs)}`)

      const objsCartas = await variasCartasPorID(cartasIDs)
      console.debug(`cartasEnBaraja trajo ${objsCartas.length} objs cartas.`)

      return objsCartas;
    }

  } catch (error) {
    console.error('Fetch Data Error:', error);
    throw error;
  }
};

//////////////////
//  MATEMATICA  //
//////////////////

// num cartas / num equipos
function numCartasRepartibles(totalItems, numberOfTeams) {
  // Check if the inputs are valid
  if (typeof totalItems !== 'number' || typeof numberOfTeams !== 'number' || numberOfTeams <= 0) {
    console.debug(`el input de numCartasRepartibles esta roto`)
    return 'Invalid input';
  }

  // Calculate the maximum number of items per team
  const maxItems = Math.floor(totalItems / numberOfTeams);
  console.debug(`max cartas repartibles son ${maxItems}`)

  return maxItems;
}

// sobrantes
function numSobrantes(m, e, c) { return m - e * c; }                          // <--- esto esta bien ?????

// randomiza el orden de una lista, sin modificar la original
function randomizarLista(lista) {
  // Create a copy of the original array to avoid modifying it directly
  const shuffledArray = [...lista];

  // Start from the end of the array and swap elements with random elements
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)); // Generate a random index between 0 and i
    // Swap the elements at indices i and j
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
}

// randomizar el orden de una lista (Fisher-Yates algorithm)
// randomiza la lista directamente (modifica la original)
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

//////////////////
//   REPARTO    //
//////////////////

// le pasas un array de objetos y un key (cartas, "categoria")
// lo separa por key en un array de arrays de objetos
function separateByProperty(objectsArray, propertyKey) {
  // Create an object to store arrays based on the specified property
  const categorizedArrays = {};

  // Loop through each object in the array
  objectsArray.forEach(obj => {
    // Extract the property value from the object
    const propertyValue = obj[propertyKey];

    // Check if an array for the property value exists, if not, create one
    if (!categorizedArrays[propertyValue]) {
      categorizedArrays[propertyValue] = [];
    }

    // Push the object into the corresponding property value array
    categorizedArrays[propertyValue].push(obj);
  });

  // Convert the object of arrays to an array of arrays
  const resultArrays = Object.values(categorizedArrays);

  return resultArrays;
}

// crea un array de cartas para cada equipo (RANDOM)
function distributeCardsToTeams(teams, cards, cardsPerTeam) {
  if (!Array.isArray(teams) || !Array.isArray(cards) || typeof cardsPerTeam !== 'number') {
    console.error('Invalid input. Please provide valid teams (array), cards (array), and cardsPerTeam (number).');
    return null;
  }

  if (cardsPerTeam <= 0 || cardsPerTeam > cards.length) {
    console.error('Invalid number of cards per team.');
    return null;
  }

  // Shuffle the cards array (optional)
  cards = shuffleArray(cards);

  const teamCards = {};
  teams.forEach(team => {
    teamCards[team] = [];
  });

  for (let i = 0; i < cardsPerTeam; i++) {
    teams.forEach(team => {
      if (cards.length > 0) {
        const card = cards.pop();
        teamCards[team].push(card);
      }
    });
  }

  const descarte = cards; // Move the remaining cards to the discard pile

  return teamCards
}

// mazo entero a cada equipo
function giveMazoToTeams(teams, cards) {
  var barajas = {}
  for (let i = 0; i < teams.length; i++) {
    const teamID = teams[i]
    barajas[teamID] = cards
  }
  console.debug(`barajas mazo entero son ${JSON.stringify(barajas)}`)
  return barajas
}

// REPARTIR RANDOM
// equipos y cartas son arrays de dict
function repartirRandom(partida, sprintID, equipos, cartas, movimientos) {

  // asegurar que se pueda repartir esas cartas entre esos equipos
  const sobrantes = numSobrantes(cartas.length, equipos.length, movimientos);
  console.debug(`sobrantes para sprint ${sprintID}: ${cartas.length} - ${equipos.length} * ${movimientos} = ${sobrantes}`)

  if (sobrantes >= 0) {
    console.debug('Repartiendo cartas...');

    // randomizar array de cartas
    const cartasDisponibles = randomizarLista(cartas)
    console.debug(`reparto: cartas disponibles son ${JSON.stringify(cartasDisponibles)}`)

    // determinar cantidad de cartas para repartir
    const numRepartibles = numCartasRepartibles(cartasDisponibles.length, equipos.length)
    console.debug(`num repartibles son ${JSON.stringify(numRepartibles)}`)

    // crear lista para cada baraja para cada equipo
    var barajas = distributeCardsToTeams(equipos, cartasDisponibles, numRepartibles)
    console.debug(`barajas son ${JSON.stringify(barajas)}`)

    // crear una baraja para cada elemento en teamCards
    crearBarajaEnDb(partida, sprintID, barajas)

  } else {
    console.error('No hay suficientes cartas para repartir a todos los equipos.');
  }
};
/*
// repartir random, pero respetando las categorias
function repartirRandomCategorico(partida, sprintID, equipos, cartas, movimientos) {

  // asegurar que se pueda repartir esas cartas entre esos equipos
  const sobrantes = numSobrantes(cartas.length, equipos.length, movimientos);

  if (sobrantes >= 0) {
    console.debug('Repartiendo cartas por categoria...');

    // separar en categorias
    const cartasPorPalo = separateByProperty(cartas, "categoria")

    // contar la cantidad de categorias (asumiendo que cada categoria tiene la misma cantidad de cartas)

    // determinar la cantidad de cartas 

    // para cada categoria, randomizar etc

    // unificar baraja de vuelta

    // SORTEAR POR ID ASI NO ROMPE !!!

    // crear una baraja para cada elemento en teamCards
    crearBarajaEnDb(partida, sprintID, barajas)

  } else {
    console.debug('No hay suficientes cartas para repartir a todos los equipos.');
  }*
};*/

// le da el mazo entero a cada equipo
// equipos y cartas son arrays de dict
function repartirEntero(partida, sprintID, equipos, cartas) {

  console.debug(`Repartiendo ${cartas.length} cartas para equipos ${equipos}, mazo entero...`);

  // crear lista para cada baraja para cada equipo
  var barajas = giveMazoToTeams(equipos, cartas, cartas.length)
  console.debug(`barajas son ${JSON.stringify(barajas)}`)

  // crear una baraja para cada elemento en teamCards
  crearBarajaEnDb(partida, sprintID, barajas)

};

// asignar cartas por CATEGORIA
async function asignarCartasBarajaCategoria(partida, arrayCartas, arrayMovimientos) {
  console.debug(`asignarCartasBarajaCategoria recibe ${partida}, ${JSON.stringify(arrayCartas)}, ${JSON.stringify(arrayMovimientos)}...`)

}

// AsignarCartas
// trae 2 arrays:
// la cantidad de cartas disponibles por equipo por sprint ["Sprint 0": 10, "Sprint 1": 15]
// la cantidad de movimientos ["Sprint 0": 3, "Sprint 1": 5]
async function asignarCartasBaraja(partida, arrayCartas, arrayMovimientos, tipo) {
  console.debug(`asignarCartasBaraja de tipo ${tipo} recibe ${partida}, cartas ${JSON.stringify(arrayCartas)}, movs ${JSON.stringify(arrayMovimientos)}...`)

  // traer lista de equipos con partidaID
  const equiposTodo = await equiposEnPartida(partida);
  let equipos = []
  for (let i = 0; i < equiposTodo.length; i++) {
    equipos.push(equiposTodo[i].id)
  }
  console.debug(`equipos son: ${JSON.stringify(equipos)}...`)

  // desempacar un array y empacar otro
  const sprints = arrayCartas.map(x => Object.keys(x)[0]);
  const arrCartas = arrayCartas.map(x => Object.values(x)[0]);
  const arrMovs = arrayMovimientos.map(x => Object.values(x)[0]);

  console.debug(`sprints son: ${JSON.stringify(sprints)}`);

  for (let i = 0; i < sprints.length; i++) {
    let sprint = sprints[i]
    console.debug(`armando asignarCartasBaraja para ${sprint}`)
    // traer cartas para cada mazo
    var sprintNombre = MazosSprint[sprint]
    console.debug(`nombre del sprint es ${sprintNombre}`)
    var sprintData = await getSprintEnPartida(partida, sprintNombre)
    var sprintID = sprintData.id
    console.debug(`sprintID es ${sprintID}`)
    var mazo = await cartasEnMazo(sprintNombre) // esto pide nombre
    console.debug(`el mazo tiene ${mazo.length} cartas: ${JSON.stringify(mazo)}`)
    var movimientos = arrMovs[i]
    console.debug(`movimientos son ${movimientos}`)
    var cartas = [] // IDs

    // sacar valores random del mazo hasta que quede en cierto length
    const length = Math.min(arrCartas[i] * equipos.length, mazo.length);
    console.debug(`len es min(${arrCartas[i] * equipos.length},${mazo.length}) = ${length}`)

    console.debug(`shuffling mazo...`)
    mazo = shuffleArray(mazo);
    for (let i = 0; i < length; i++) {
      cartas.push(mazo[i].id);
    };
    console.debug(`cartas son ${JSON.stringify(cartas)}`)

    // mandar todo a repartir
    console.debug(`enviando a repartir partida ${partida}, sprint ${sprintID}, equipos ${JSON.stringify(equipos)}, ${cartas.length} cartas ${JSON.stringify(cartas)}, movs ${movimientos}`)

    if (sprintNombre === "Platform" || sprintNombre === "Ecosystem" ) { repartirEntero(partida, sprintID, equipos, cartas) }
    else {
      switch (tipo) {
        case "entero":
          repartirEntero(partida, sprintID, equipos, cartas); break;
        case "random":
          repartirRandom(partida, sprintID, equipos, cartas, movimientos); break;
        default:
          console.error(`no se pudo repartir, falta tipo!`)
          break;
      }
    }

    // asignar max movs al sprint
    console.debug(`asignando max movimientos, partida ${partida}, sprint ${sprintID}, movs ${movimientos}`)
    asignarMaxMovimientos(partida, sprintID, movimientos)

  };
}

// esto crea barajas "default" que contienen el mazo entero
// la cantidad de movimientos disponibles se limita aca excepto para ciertos sprints
// cuando se crea una partida presencial, se llama esto con el array de objs de sprints y accion "guardar"
// cuando se crea una partida virtual, se llama con accion "devolver", para usarlo para setear a los sprints
async function determinarCartasBarajaDefault(partida, sprints, movs, accion) {
  console.debug(`determinarCartasBarajaDefault recibe partida ${partida}, sprints ${JSON.stringify(sprints)}, movs ${movs}, accion ${accion}...`)

  var arrayCartas = []
  var arrayMovimientos = []

  // traer lista de equipos con partidaID
  const equipos = await equiposEnPartida(partida);

  for (let i = 0; i < sprints.length; i++) {

    const nombreSprint = sprints[i].nombre;
    const numSprint = sprints[i].numSprint;
    console.debug(`determinarCartasBarajaDefault en sprint ${nombreSprint}...`)

    // traer la lista de IDs de cartas para ese mazo
    const cartasObjs = await cartasEnMazo(nombreSprint) // array de objs
    const cartas = cartasObjs.map(x => x.id) // array de IDs
    console.debug(`mazo ${nombreSprint} tiene ${cartas.length} cartas...`)

    // determinar la cantidad maxima de cartas que se puede dar a cada equipo
    const repartibles = cartas.length;
    // si quisieramos de hecho repartir:
    // repartibles = numCartasRepartibles(cartas.length, equipos.length);

    // determinar los movs por default o default a 5
    var movsDefault
    switch (nombreSprint) {
      case "Platform":
        movsDefault = repartibles; break; // se pueden jugar todas
      case "Ecosystem":
        movsDefault = repartibles; break;
      case "DataDriven":
        movsDefault = 10; break; // dos por categoria
      default:
        movsDefault = movs; break;
    }

    // armar objetos
    var objCarta = {};
    objCarta[numSprint] = repartibles;

    var objMov = {};
    objMov[numSprint] = movsDefault;

    console.debug(`determinarCartasBarajaDefault en sprint ${nombreSprint} tiene ${JSON.stringify(objMov)} movs y ${JSON.stringify(objCarta)} repartibles.`)

    // pushear al array
    arrayCartas.push(objCarta);
    arrayMovimientos.push(objMov);
  }

  console.debug(`determinarCartasBarajaDefault en partida ${partida} tiene: cartas ${JSON.stringify(arrayCartas)}, movs ${JSON.stringify(arrayMovimientos)}.`)

  if (accion === "guardar") {
    asignarCartasBaraja(partida, arrayCartas, arrayMovimientos, "entero")
  } else if (accion === "devolver") {
    return [arrayCartas, arrayMovimientos]
  }
}

/* por las dudas
// REPARTIR A MANO
// recibe las cartas para asignarle a un equipo individual
function repartirManual (partida, sprint, equipo, cartas){

    // (PARA V2) activar/desactivar cartas disponibles para el mazo
    var descarte = []
    var cartasDescartadasPorAdmin = []
    var cartasDisponibles = descartarCartas(cartasDescartadasPorAdmin, ------)

    // elegir partida / equipo / sprint

    // array cartasEnUso

    // array cartasDisponibles = mazo - cartasEnUso

}
*/

export default {
  numCartasRepartibles,
  distributeCardsToTeams,
  crearBarajaEnDb,
  repartirRandom,
  asignarCartasBaraja,
  determinarCartasBarajaDefault,
  cartasEnBaraja
};