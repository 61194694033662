import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// assets y estilos
import img from "../assets/img/fondo3.png";
import {
	Button,
	ButtonGroup,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	Label,
	FormGroup,
	Input,
	Table,
	Row,
	Col,
	UncontrolledTooltip,
	CustomInput,
} from "reactstrap";

// helpers
import { host } from "../variables/connection.js"
import barajaHelpers from "../variables/baraja.js"

const GestorEquipos = () => {
	const [partidas, setPartidas] = useState([]);
	const [selectedPartida, setSelectedPartida] = useState(null);
	const [presencial, setPresencial] = useState(null)

	const [nombreEquipo, setNombreEquipo] = useState('');
	const [claveEquipo, setClave] = useState('');

	const [message, setMessage] = useState('');

	const [equipos, setEquipos] = useState([]);
	const [selectedEquipo, setSelectedEquipo] = useState(null);

	const [usuario, setUsuario] = useState("");
	const [nombreJugador, setNombreJugador] = useState('');
	const [esCapitan, setEsCapitan] = useState('no');
	const [jugadores, setJugadores] = useState([]);

	// modal
	const [listaPasteada, setListaPasteada] = useState([])
	const [modalOpen, setModalOpen] = useState(false); // solapa

	//		PARTIDA		//

	const findPartidaName = () => {
		const partida = partidas.find(p => p.id === selectedPartida);
		return partida ? partida.nombre : "Selecciona una Partida";
	};

	const findEquipoName = () => {
		const equipo = equipos.find(e => e.id === selectedEquipo);
		return equipo ? equipo.nombre : "Selecciona un Equipo";
	};

	const fetchPartidas = async () => {
		try {
			const response = await fetch(`https://${host}/api/partidas`);

			if (!response.ok) {
				throw new Error('Respuesta del servidor no es OK');
			}

			const data = await response.json();

			if (data && Array.isArray(data.data)) {
				setPartidas(data.data);
				console.debug(`partidas son ${JSON.stringify(data.data)}`)
				if (data.data.length > 0) {
					setSelectedPartida(data.data[0].id);
					setPresencial(data.data[0].presencial)
					console.debug(`presencial es ${data.data[0].presencial}`)
				} else {
					setSelectedPartida(null);
				}
			} else {
				console.error("Formato de datos no esperado:", data);
			}
		} catch (error) {
			console.error("Error al obtener los datos:", error);
		}
	};

	const fetchEquiposPorPartida = async (partidaId) => {
		try {
			const response = await fetch(`https://${host}/juego/${partidaId}`);

			if (!response.ok) {
				throw new Error(`Error ${response.status}: ${response.statusText}`);
			}

			// Primero, verificamos si la respuesta tiene contenido
			const contentType = response.headers.get("content-type");
			if (contentType && contentType.includes("application/json")) {
				const data = await response.json();

				if (Array.isArray(data.data)) {
					setEquipos(data.data);

					// Si aún no hay un equipo seleccionado, o el equipo seleccionado actual no está en la nueva lista, selecciona el primer equipo de la lista.
					if (!selectedEquipo || !data.data.some(equipo => equipo.id === selectedEquipo)) {
						setSelectedEquipo(data.data[0]?.id);
					}
				} else {
					setEquipos([]);
					console.warn(data.message);
				}
			} else {
				// Si no hay contenido (o no es JSON), limpiamos los equipos
				setEquipos([]);
			}
		} catch (error) {
			setEquipos([]); // En caso de un error, resetea los equipos.
			console.error("Error al obtener los equipos:", error);
		}
	};

	useEffect(() => {
		fetchPartidas();
	}, []);

	// Este useEffect se encargará de cargar los equipos cuando cambie la partida seleccionada
	useEffect(() => {
		if (selectedPartida) {
			fetchEquiposPorPartida(selectedPartida);
		}
	}, [selectedPartida]);

	const handlePartidaChange = (e) => {
		const partidaId = Number(e.target.value);

		setSelectedPartida(partidaId);
		fetchEquiposPorPartida(partidaId);

		const pres = partidas.find(x => x.id === partidaId).presencial
		console.debug(`cambiando a partida ${partidaId}, presencial es ${pres}`)
		setPresencial(pres)

		setSelectedEquipo(null);  // Resetea el equipo seleccionado
		setJugadores([]);  // Resetea la lista de jugadores
		setEquipos([]);  // Resetea los equipos después
	};

	//		EQUIPOS		//

	const handleCrearEquipo = async () => {
		try {
			const response = await fetch(`https://${host}/juego/${selectedPartida}/equipos`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					activo: 1,
					nombre: nombreEquipo,
					clave: claveEquipo
				})
			});

			if (response.status >= 400 && response.status < 600) {
				throw new Error('Error en el servidor');
			}

			const result = await response.json();
			console.debug("Respuesta del servidor:", result);
			if (response.status === 201) {
				setMessage(result.message);
				setNombreEquipo(''); // Limpiar campos
				setClave('');

				await fetchEquiposPorPartida(selectedPartida);  // <-- Aquí actualizas la lista de equipos

				// Establecer el equipo recién creado como el equipo seleccionado
				const newEquipo = result.data;  // Asumiendo que el equipo recién creado se devuelve en "data". Si no es así, ajusta según la respuesta del servidor.
				console.debug("Nuevo equipo:", newEquipo.id);  // Aquí
				setSelectedEquipo(result.data);
				console.debug("Equipo seleccionado:", selectedEquipo);  // Y aquí
			} else {
				setMessage('Error al crear equipo: ' + result.message);
			}
		} catch (error) {
			setMessage('Hubo un error al crear el equipo: ' + error.message);
		}
	};

	const fetchJugadoresPorEquipo = async (equipoId) => {
		const query = `https://${host}/juego/${selectedPartida}/${equipoId}/jugadores`
		console.debug("pidiendo al back con:", query)
		try {
			const response = await fetch(query);

			if (!response.ok) {
				throw new Error('Respuesta del servidor no es OK');
			}

			if (response.status == 204) {
				console.debug("Este equipo aun no tiene jugadores");
				setJugadores([]);
			} else {
				var data = await response.json();
				if (data && Array.isArray(data.data)) {
					setJugadores(data.data);
				} else {
					setJugadores([]);
					console.warn(data.message || "Error al obtener los jugadores");
				}
			}

		} catch (error) {
			setJugadores([]); // En caso de un error, resetea la lista de jugadores.
			console.error("Error al obtener los jugadores:", error);
		}
	};

	useEffect(() => {
		if (selectedEquipo) {
			fetchJugadoresPorEquipo(selectedEquipo);
		}
	}, [selectedEquipo]);

	//		JUGADORES	//

	const handleCrearJugador = async () => {
		if (!selectedEquipo || !selectedPartida) {
			setMessage("Por favor, selecciona una partida y un equipo antes de agregar un jugador.");
			return;
		}

		const partidaSeleccionada = partidas.find(p => p.id === Number(selectedPartida));

		if (!partidaSeleccionada) {
			console.debug(typeof selectedPartida);
			console.debug(partidas.map(p => typeof p.id));
			console.debug("Selected Partida:", selectedPartida);
			console.debug("Partidas:", partidas);
			setMessage("Error al obtener información de la partida seleccionada.");
			return;
		}

		const institucion = partidaSeleccionada.institucion;

		const esPresencial = partidaSeleccionada.presencial;
		var usuarioLower = usuario.toLowerCase();

		const payload = {
			activo: 1,
			presencial: esPresencial ? 1 : 0,
			nombre: nombreJugador,
			capitan: esCapitan === 'si' ? 1 : 0,
			usuario: usuarioLower,
			institucion: institucion,
		};

		console.debug("Probando crear jugador con datos:", payload)
		creacionJugador(payload)
	};

	async function creacionJugador(payload) {
		const query = `https://${host}/juego/${selectedPartida}/${selectedEquipo}/unjugador`;
		console.debug("creacionJugador en", query, ", y datos:", payload)
		try {
			const response = await fetch(query, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(payload)
			});

			const result = await response.json();

			// determinar si la respuesta fue correcta o no
			switch (response.status) {
				case 409:
					setMessage('Error al crear jugador, el usuario ya existe.');
					break;
				case 201:
					// si esta todo bien, te permite recrear
					setMessage(result.message);
					setUsuario('');
					setNombreJugador('');
					setEsCapitan('no');
					fetchJugadoresPorEquipo(selectedEquipo);  // Actualiza la lista de jugadores
					break;
				default:
					setMessage('Error al crear jugador.');
					break;
			}

		} catch (error) {
			setMessage('Hubo un error al crear el jugador.');
		}
	}

	// 		MODAL		//

	async function handleCrearVariosJugadores(lista) {

		if (!selectedEquipo || !selectedPartida) {
			setMessage("Por favor, selecciona una partida y un equipo antes de agregar jugadores.");
			return;
		}

		const partidaSeleccionada = partidas.find(p => p.id === Number(selectedPartida));
		const esPresencial = partidaSeleccionada.presencial;
		console.debug(`handleCrearVariosJugadores con ${lista} en partida ${partidaSeleccionada}`)

		for (let i = 0; i < lista.length; i++) {

			if (lista[i] === null || lista[i] === undefined) { continue }

			var nombreLower = lista[i].toLowerCase();

			var payload
			if (i === 0) {
				console.debug(`creando capitan ${lista[i]}`)

				payload = {
					activo: 1,
					presencial: esPresencial ? 1 : 0,
					nombre: nombreLower,
					capitan: 1,
					usuario: nombreLower
				};
			} else {
				console.debug(`creando jugador ${lista[i]}`)

				payload = {
					activo: 1,
					presencial: esPresencial ? 1 : 0,
					nombre: nombreLower,
					capitan: 0,
					usuario: nombreLower
				};
			}

			console.debug(`payload para ${i} es ${JSON.stringify(payload)}`)
			creacionJugador(payload)

		}
	}

	const handleModalConfirm = (optionsRutas) => {
		console.debug('Modal confirmado:', optionsRutas);
	};

	function abrirModal() {
		setModalOpen(true)
	}

	function Modal({ isOpen, onClose, onConfirm, }) {
		const [pastedText, setPastedText] = useState('');

		const modalStyle = {
			display: isOpen ? 'block' : 'none',
			position: 'fixed',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			padding: '20px',
			backgroundColor: 'orange',
			zIndex: 1000
		};

		const overlayStyle = {
			display: isOpen ? 'block' : 'none',
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			zIndex: 999
		};

		var listaPasteadaModal
		const handleSubmit = () => {
			listaPasteadaModal = pastedText.split('\n');
			console.debug(`submitting con ${listaPasteadaModal}`)
			onConfirm(handleCrearVariosJugadores(listaPasteadaModal));
			onClose();
		};

		return (
			<>

				<div style={overlayStyle} onClick={onClose}></div>
				<div style={modalStyle}>

					<h2>Pega una lista de jugadores para el equipo seleccionado</h2>
					<p>
						Pegar una columna de nombres o emails unicos.<br />
						La primera fila será el capitán.
					</p>

					<textarea
						value={pastedText}
						onChange={(e) => setPastedText(e.target.value)}
						placeholder="Pega una columna de emails de jugadores..."
						rows={5}
					/><br />

					<button onClick={handleSubmit}>Confirmar (no se puede editar)</button>

				</div>
			</>
		);
	}

	//		RENDER		//

	// Estilo en línea para el radio activo
	const activeRadioStyle = {
		backgroundColor: '#003366',
		borderColor: '#003366',
	};

	return (

		<div className="content"
			style={{
                backgroundImage: `url(${img})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                width: '100vw',
			}}>
			<Row>
				<Col xs="12">
					<Card style={{ backgroundColor: '#003366' }}>
						<CardBody>
							<h2 style={{ color: '#E8E8E8' }}>Crear Equipo</h2>

							<FormGroup style={{ marginBottom: '10px', maxWidth: '50%' }}>
								<Label style={{ color: '#E8E8E8' }}>Seleccione una Partida</Label>
								<Input
									style={{
										color: '#E8E8E8',
										borderColor: '#2C83D5',
										fontSize: '18px',
										backgroundColor: 'transparent',
										padding: '10px 15px', // Añadido padding
										height: 'auto'       // Establecer la altura en auto para ajustarse al contenido
									}}
									type="select"
									value={selectedPartida || ''}
									onChange={handlePartidaChange}
								>
									{partidas.map(partida => (
										<option key={partida.id} value={partida.id}>
											{partida.nombre}
										</option>
									))}
								</Input>
							</FormGroup>

							{selectedPartida && equipos.map(equipo => (
								<Row key={equipo.id} style={{ marginBottom: '10px', maxWidth: '50%' }}>
									<Col md="6">
										<FormGroup>
											<Label style={{ color: '#E8E8E8' }}>Nombre del Equipo</Label>
											<Input
												style={{
													color: '#E8E8E8',
													borderColor: '#2C83D5',
													fontSize: '18px',
													backgroundColor: 'transparent'
												}}
												value={equipo.nombre}
												readOnly
											/>
										</FormGroup>
									</Col>
									<Col md="6">
										<FormGroup>
											<Label style={{ color: '#E8E8E8' }}>Clave del Equipo</Label>
											<Input
												style={{
													color: '#E8E8E8',
													borderColor: '#2C83D5',
													fontSize: '18px',
													backgroundColor: 'transparent'
												}}
												value={equipo.clavePlain}
												readOnly
											/>
										</FormGroup>
									</Col>
								</Row>
							))}

							<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', maxWidth: '50%', width: '100%' }}>

								<div style={{ flex: 1, marginRight: '10px' }}>
									<FormGroup>
										<Label style={{ color: '#E8E8E8' }}>Nombre del Equipo</Label>
										<Input
											style={{
												color: '#E8E8E8',
												borderColor: '#2C83D5',
												fontSize: '18px',
												backgroundColor: 'transparent'
											}}
											value={nombreEquipo}
											onChange={(e) => setNombreEquipo(e.target.value)}
										/>
									</FormGroup>
								</div>

								<div style={{ flex: 1, marginLeft: '10px' }}>
									<FormGroup>
										<Label style={{ color: '#E8E8E8' }}>Clave del Equipo</Label>
										<Input
											style={{
												color: '#E8E8E8',
												borderColor: '#2C83D5',
												fontSize: '18px',
												backgroundColor: 'transparent'
											}}
											value={claveEquipo}
											onChange={(e) => setClave(e.target.value)}
										/>
									</FormGroup>
								</div>

							</div>


							<Button onClick={handleCrearEquipo} style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px' }}>
								Crear
							</Button>
							{message && (
								<p style={{ color: '#FFA07A', marginTop: '10px' }}>{message}</p>
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>

			<Row>
				<Col xs="12">
					<Card style={{ backgroundColor: '#003366' }}>
						<CardBody>
							<h2 style={{ color: '#E8E8E8' }}>Agregar jugadores</h2>
							<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', maxWidth: '50%', width: '100%' }}>
								{/* Partidas */}
								<FormGroup style={{ flex: 1, marginRight: '10px' }}>
									<Label style={{ color: '#E8E8E8' }}>Seleccione una Partida</Label>
									<Input
										style={{
											color: '#E8E8E8',
											borderColor: '#2C83D5',
											fontSize: '18px',
											backgroundColor: 'transparent',
											padding: '10px 15px',
											height: 'auto'
										}}
										type="select"
										value={selectedPartida || ''}
										onChange={handlePartidaChange}
									>
										{partidas.map(partida => (
											<option key={partida.id} value={partida.id}>
												{partida.nombre}
											</option>
										))}
									</Input>
								</FormGroup>

								{/* Equipos */}
								<FormGroup style={{ flex: 1, marginLeft: '10px' }}>
									<Label style={{ color: '#E8E8E8' }}>Seleccione un Equipo</Label>
									<Input
										style={{
											color: '#E8E8E8',
											borderColor: '#2C83D5',
											fontSize: '18px',
											backgroundColor: 'transparent',
											padding: '10px 15px',
											height: 'auto'
										}}
										type="select"
										value={selectedEquipo || ''}
										onChange={(e) => setSelectedEquipo(e.target.value)}
									>
										{equipos.map(equipo => (
											<option key={equipo.id} value={equipo.id}>
												{equipo.nombre}
											</option>
										))}
									</Input>
								</FormGroup>
							</div >

							<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', maxWidth: '50%', width: '100%' }}>
								{/* Nombre del Jugador */}
								<FormGroup style={{ flex: 1, marginRight: '10px' }}>
									<Label style={{ color: '#E8E8E8' }}>Usuario para logueo -Email-</Label>
									<Input
										style={{
											color: '#E8E8E8',
											borderColor: '#2C83D5',
											fontSize: '18px',
											backgroundColor: 'transparent'
										}}
										value={usuario}
										onChange={(e) => setUsuario(e.target.value)}
									/>
								</FormGroup>

								{/* Nombre del Jugador */}
								<FormGroup style={{ flex: 1, marginRight: '10px' }}>
									<Label style={{ color: '#E8E8E8' }}>Nombre y apellido para certificado</Label>
									<Input
										style={{
											color: '#E8E8E8',
											borderColor: '#2C83D5',
											fontSize: '18px',
											backgroundColor: 'transparent'
										}}
										value={nombreJugador}
										onChange={(e) => setNombreJugador(e.target.value)}
									/>
								</FormGroup>

								{/* Capitán */}
								<FormGroup style={{ flex: 1, marginLeft: '10px' }}>
									<Label style={{ color: '#E8E8E8' }}>Capitán</Label>
									<div>
										<CustomInput
											type="radio"
											id="capitanNo"
											name="esCapitan"
											label="No"
											value="no"
											checked={esCapitan === 'no'}
											onChange={() => setEsCapitan('no')}
											inline // para que se muestre en línea
											style={esCapitan === 'no' ? activeRadioStyle : {}}  // Aplica el estilo si esCapitan es 'no'
										/>
										<CustomInput
											type="radio"
											id="capitanSi"
											name="esCapitan"
											label="Sí"
											value="si"
											checked={esCapitan === 'si'}
											onChange={() => setEsCapitan('si')}
											inline // para que se muestre en línea
											style={esCapitan === 'si' ? activeRadioStyle : {}}  // Aplica el estilo si esCapitan es 'si'
										/>
									</div>
								</FormGroup>
							</div>

							<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', maxWidth: '50%', width: '100%' }}>
								<Button onClick={handleCrearJugador} style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px' }}>
									Agregar Jugador
								</Button>

								<Button onClick={abrirModal} style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px' }}>
									Agregar lista de jugadores en equipo seleccionado
								</Button>
							</div>

							{
								jugadores.map(jugador => (
									<Row key={jugador.id} style={{ marginBottom: '10px', maxWidth: '50%' }}>
										<Col md="6">
											<FormGroup>
												<Label style={{ color: '#E8E8E8' }}>Nombre del Jugador</Label>
												<Input style={{ color: '#E8E8E8' }} value={jugador.nombre} readOnly />
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<Label style={{ color: '#E8E8E8' }}>Capitán</Label>
												<Input style={{ color: '#E8E8E8' }} value={jugador.capitan ? 'Sí' : 'No'} readOnly />
											</FormGroup>
										</Col>
									</Row>
								))
							}

							< Modal
								isOpen={modalOpen}
								onClose={() => setModalOpen(false)}
								onConfirm={handleModalConfirm}
							/>

						</CardBody >
					</Card >
				</Col >
			</Row >

		</div >

	);
}

export default GestorEquipos;