import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// variables
import sprints from "../variables/sprints.js";
import { host } from "../variables/connection.js"

// assets y estilos
import img from "../assets/img/fondo3.png";
import framework1 from "../assets/img/C1.jpg";
import framework2 from "../assets/img/C2.png";
import framework3 from "../assets/img/C3.png";
import framework4 from "../assets/img/C4.png";
import {
	Button,
	Card,
	CardBody,
	Row,
	Col,
	Input,
	FormGroup,
	Label
} from "reactstrap";

const NuevoFormulario = () => {

	// conf de la partida
	const [nombrePartida, setNombrePartida] = useState('');
	const [nombreUniversidad, setNombreUniversidad] = useState('');
	const [fechaInicio, setFechaInicio] = useState('');
	const [fechaFinal, setFechaFinal] = useState('');
	const [tipo, setTipo] = useState('presencial');
	const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
	const [estado, setEstado] = useState('Activo');
	const [idioma, setIdioma] = useState('es');
	const [subpreguntas, setSubpreguntas] = useState('Si');
	const [sprintsSeleccionados, setSprintsSeleccionados] = useState({});

	// exito/error
	const [mensaje, setMensaje] = useState(null);

	// HANDLERS

	const handleSprintChange = (event) => {
		const name = event.target.name;
		const isChecked = event.target.checked;
		setSprintsSeleccionados(prevState => ({
			...prevState,
			[name]: isChecked ? event.target.value : null
		}));
	};

	// CRUD

	const enviarDatos = async () => {
		try {

			// CREAR PARTIDA BASE

			const response = await fetch(`https://${host}/api/partidas`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					activo: estado === 'Activo' ? 1 : 0,
					startDate: fechaInicio,
					endDate: fechaFinal,
					presencial: tipo === 'presencial' ? 1 : 0,
					nombre: nombrePartida,
					institucion: nombreUniversidad,
					idioma: idioma,
					subpreguntas: subpreguntas === 'Si' ? 1 : 0,
				})
			});
			const { data } = await response.json();
			if (!response.ok) { throw new Error(data.message || 'Error al crear partida.'); }
			const partidaId = data;
			console.debug(`partida creada con id ${data}`)

			// VIRTUAL - asociar canvas

			if (tipo === "virtual" && imagenSeleccionada) {
				console.debug(`armando partida virtual...`)

				const frameworkResponse = await fetch(`https://${host}/juego/${partidaId}/framework/${imagenSeleccionada}`, {
					method: 'PATCH'
				});
				console.debug(`framework response es ${JSON.stringify(frameworkResponse)}`)
				if (!frameworkResponse.ok) { throw new Error("Fallo al establecer framework") }

				const frameworkData = await frameworkResponse.json();
				console.debug(`partida virtual armada con resp ${JSON.stringify(frameworkData)}`)
			}

			// ARMAR SPRINTS

			console.debug(`armando sprints...`)
			// Convert the selected sprints into an array of objects
			const selectedSprintsArray = Object.entries(sprintsSeleccionados)
				.map(([sprintName, sprintValue]) => ({ name: sprintName, value: sprintValue }))
				.filter(sprint => sprint.value !== null); // Remove null values

			// Sort the selected sprints by their 'value' attribute in ascending order
			selectedSprintsArray.sort((a, b) => a.value - b.value);

			console.debug(`armando sprints con ${JSON.stringify(selectedSprintsArray)}`)
			// Lugar donde se llama a la función
			for (const sprint of selectedSprintsArray) {
				const sprintName = sprint.name;
				const sprintValue = sprint.value;
				console.debug(`Creando sprint ${sprintName}`);
				console.debug(`enviando a crearSprint(${partidaId},${sprintName},Sprint ${sprintValue})`);
				await sprints.crearSprint(partidaId, sprintName, `Sprint ${sprintValue}`);
			}
			console.debug(`todos los sprints fueron creados exitosamente.`)

			setMensaje('Partida creada con éxito.');
		} catch (error) {
			setMensaje(`Error: ${error.message}`);
		}
	};

	// RENDER

	return (
		<div className="content"
			style={{
				backgroundImage: `url(${img})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				height: '100vh',
				width: '100vw',
			}}>
			<Row>
				<Col xs="12">
					<Card style={{ backgroundColor: '#003366' }}>
						<CardBody>
							<h2 style={{ color: '#E8E8E8' }}>Crear una nueva partida</h2>

							<FormGroup style={{ marginBottom: '10px', maxWidth: '50%' }}>
								<Input
									style={{
										color: '#E8E8E8',
										borderColor: '#2C83D5',
										fontSize: '18px',
										backgroundColor: 'transparent'
									}}
									type="text"
									placeholder="Nombre partida"
									value={nombrePartida}
									onChange={e => setNombrePartida(e.target.value)}
								/>
							</FormGroup>

							<FormGroup style={{ marginBottom: '10px', maxWidth: '50%' }}>
								<Input
									style={{
										color: '#E8E8E8',
										borderColor: '#2C83D5',
										fontSize: '18px',
										backgroundColor: 'transparent'
									}}
									type="text"
									placeholder="Nombre universidad"
									value={nombreUniversidad}
									onChange={e => setNombreUniversidad(e.target.value)}
								/>
							</FormGroup>

							<h3 style={{ color: '#E8E8E8', marginBottom: '2px' }}>Fecha de inicio</h3>
							<FormGroup style={{ marginBottom: '10px', maxWidth: '25%' }}>
								<Input
									style={{
										color: '#E8E8E8',
										borderColor: '#2C83D5',
										fontSize: '18px',
										backgroundColor: 'transparent'
									}}
									type="date"
									placeholder="Fecha inicio"
									value={fechaInicio}
									onChange={e => setFechaInicio(e.target.value)}
								/>
							</FormGroup>

							<h3 style={{ color: '#E8E8E8', marginBottom: '2px' }}>Fecha de fin</h3>
							<FormGroup style={{ marginBottom: '10px', maxWidth: '25%' }}>
								<Input
									style={{
										color: '#E8E8E8',
										borderColor: '#2C83D5',
										fontSize: '18px',
										backgroundColor: 'transparent'
									}}
									type="date"
									placeholder="Fecha final"
									value={fechaFinal}
									onChange={e => setFechaFinal(e.target.value)}
								/>
							</FormGroup>

							<FormGroup tag="fieldset" style={{ marginTop: '40px', marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
								<Label style={{ color: '#E8E8E8', fontSize: '20px' }}>Tipo de partida</Label>
								<div style={{ display: 'flex', gap: '10px' }}>
									<FormGroup check>
										<Label check style={{ color: '#E8E8E8', fontSize: '15px' }}>
											<Input type="radio" name="tipo" value="presencial"
												onChange={e => setTipo(e.target.value)} />
											Presencial
										</Label>
									</FormGroup>
									<FormGroup check>
										<Label check style={{ color: '#E8E8E8', fontSize: '15px' }}>
											<Input type="radio" name="tipo" value="virtual"
												onChange={e => setTipo(e.target.value)} />
											Virtual
										</Label>
									</FormGroup>
								</div>
							</FormGroup>

							{tipo === "virtual" && (
								<FormGroup style={{ marginTop: '10px', marginBottom: '10px' }}>
									<Label style={{ color: '#E8E8E8', marginBottom: '10px' }}>Selecciona un framework</Label>

									<div style={{ display: 'flex', gap: '100px', paddingLeft: '17px' }}>
										<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
											<Input type="radio" name="img" onChange={() => setImagenSeleccionada(1)} />
											<img src={framework1} alt="Framework 1" style={{ width: '100px', height: '100px' }} />
										</div>
										<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
											<Input type="radio" name="img" onChange={() => setImagenSeleccionada(2)} />
											<img src={framework2} alt="Framework 2" style={{ width: '100px', height: '100px' }} />
										</div>
										<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
											<Input type="radio" name="img" onChange={() => setImagenSeleccionada(3)} />
											<img src={framework3} alt="Framework 3" style={{ width: '100px', height: '100px' }} />
										</div>
										<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
											<Input type="radio" name="img" onChange={() => setImagenSeleccionada(4)} />
											<img src={framework4} alt="Framework 4" style={{ width: '100px', height: '100px' }} />
										</div>
									</div>
								</FormGroup>
							)}

							<div style={{ marginTop: '15px', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>

								<FormGroup style={{ marginBottom: '10px', flex: '1', marginRight: '20px' }}>
									<Label style={{ color: '#E8E8E8', marginBottom: '5px', fontSize: '20px' }}>Estado de Partida</Label>
									<Input
										type="select"
										name="estado"
										onChange={e => setEstado(e.target.value)}
										style={{ borderColor: '#2C83D5' }}
										onFocus={(e) => e.target.style.borderColor = '#FFFFFF'}
										onBlur={(e) => e.target.style.borderColor = '#2C83D5'}
									>
										<option value="Activo">Activo</option>
										<option value="Inactivo">Inactivo</option>
									</Input>
								</FormGroup>

								<FormGroup style={{ marginBottom: '10px', flex: '1' }}>
									<Label style={{ color: '#E8E8E8', marginBottom: '5px', fontSize: '20px' }}>Idioma</Label>
									<Input
										type="select"
										name="idioma"
										onChange={e => setIdioma(e.target.value)}
										style={{ borderColor: '#2C83D5' }}
										onFocus={(e) => e.target.style.borderColor = '#FFFFFF'}
										onBlur={(e) => e.target.style.borderColor = '#2C83D5'}
									>
										<option value="es">Español</option>
										<option value="en">Inglés</option>
										<option value="pt">Portugués</option>
									</Input>
								</FormGroup>
							</div>

							<FormGroup tag="fieldset" style={{ marginTop: '40px', marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
								<Label style={{ color: '#E8E8E8', fontSize: '20px' }}>Subpreguntas Sprint 0</Label>
								<div style={{ display: 'flex', gap: '10px' }}>
									<FormGroup check>
										<Label check style={{ color: '#E8E8E8', fontSize: '15px' }}>
											<Input type="radio" name="subpreguntas" value="Si"
												onChange={e => setSubpreguntas(e.target.value)} />
											Si
										</Label>
									</FormGroup>
									<FormGroup check>
										<Label check style={{ color: '#E8E8E8', fontSize: '15px' }}>
											<Input type="radio" name="subpreguntas" value="No"
												onChange={e => setSubpreguntas(e.target.value)} />
											No
										</Label>
									</FormGroup>
								</div>
							</FormGroup>

							<div>
								<style>{`
                                .customCheckbox {
                                    appearance: none;
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    width: 30px;
                                    height: 30px;
                                    background-color: #FFFFFF;
                                    border: 2px solid #2C83D5;
                                    border-radius: 4px;
                                    outline: none;
                                    cursor: pointer;
                                    position: relative;
                                }

                                .customCheckbox:checked {
                                    background-color: #FFFFFF;
                                    border-color: #FFFFFF;
                                }

                                .customCheckbox:checked:after {
                                    content: '\\2713'; 
                                    display: block;
                                    position: absolute;
                                    color: #333;  // Color cercano al negro para el marcador
                                    width: 9px;
                                    height: 9px;
                                    text-align: center;
                                    line-height: 9px;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                }

                                .checkboxLabel {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;
                                    color: #E8E8E8;
                                    font-size: 25px;  // Tamaño del texto aumentado
                                }
                            `}</style>

								<FormGroup style={{ marginTop: '20px', marginBottom: '20px' }}>
									<Label style={{ color: '#E8E8E8', marginBottom: '15px', fontSize: '25px' }}>
										Elegir Sprints activos para la partida
									</Label>
									<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
										<label className="checkboxLabel" style={{ fontSize: '25px' }}>
											<input
												type="checkbox"
												className="customCheckbox"
												name="Trends"
												value="1"
												onChange={handleSprintChange}
											/>
											Trends
										</label>
										<label className="checkboxLabel" style={{ fontSize: '25px' }}>
											<input
												type="checkbox"
												className="customCheckbox"
												name="Sustainability"
												value="2"
												onChange={handleSprintChange}
											/>
											Sustainability
										</label>
										<label className="checkboxLabel" style={{ fontSize: '25px' }}>
											<input
												type="checkbox"
												className="customCheckbox"
												name="Patterns"
												value="3"
												onChange={handleSprintChange}
											/>
											Business Model Patterns
										</label>
										<label className="checkboxLabel" style={{ fontSize: '25px' }}>
											<input
												type="checkbox"
												className="customCheckbox"
												name="BlueOcean"
												value="4"
												onChange={handleSprintChange}
											/>
											Blue Ocean
										</label>
										<label className="checkboxLabel" style={{ fontSize: '25px' }}>
											<input
												type="checkbox"
												className="customCheckbox"
												name="DigitalDrivers"
												value="5"
												onChange={handleSprintChange}
											/>
											Digital Drivers
										</label>
										<label className="checkboxLabel" style={{ fontSize: '25px' }}>
											<input
												type="checkbox"
												className="customCheckbox"
												name="Platform"
												value="6"
												onChange={handleSprintChange}
											/>
											Platform
										</label>
										<label className="checkboxLabel" style={{ fontSize: '25px' }}>
											<input
												type="checkbox"
												className="customCheckbox"
												name="DataDriven"
												value="7"
												onChange={handleSprintChange}
											/>
											Data Driven
										</label>
										<label className="checkboxLabel" style={{ fontSize: '25px' }}>
											<input
												type="checkbox"
												className="customCheckbox"
												name="Test"
												value="8"
												onChange={handleSprintChange}
											/>
											Test
										</label>
										<label className="checkboxLabel" style={{ fontSize: '25px' }}>
											<input
												type="checkbox"
												className="customCheckbox"
												name="Metrics"
												value="9"
												onChange={handleSprintChange}
											/>
											Métricas
										</label>
										<label className="checkboxLabel" style={{ fontSize: '25px' }}>
											<input
												type="checkbox"
												className="customCheckbox"
												name="Ecosystem"
												value="10"
												onChange={handleSprintChange}
											/>
											Ecosystem
										</label>
									</div>
								</FormGroup>
							</div>

							<Button onClick={enviarDatos} style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px' }}>
								Enviar
							</Button>
							{mensaje && <div style={{ marginTop: '20px', color: mensaje.includes("Error") ? "red" : "green" }}>{mensaje}</div>}
						</CardBody>
					</Card>
				</Col>
			</Row>

			<style>{`
                input[type="date"]::-webkit-calendar-picker-indicator {
                    filter: invert(1);
                    opacity: 1;
                }
            `}</style>

		</div>
	);
}

export default NuevoFormulario;