
import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import img from "../assets/img/fondo3.png"; // Ruta IMAGEN

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import { host } from "../variables/connection.js";

import resultados from "../variables/resultados.js"
import tokenHelpers from "../variables/tokens.js"

const decodeToken = tokenHelpers.decodeToken;

function Metricas1(props) {

  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true);

  const [userType, setUserType] = useState("Admin");
  const [partidas, setPartidas] = useState([]);
  const [selectedPartida, setSelectedPartida] = useState(null);
  const [equipos, setEquipos] = useState([]);
  const [selectedEquipo, setSelectedEquipo] = useState(null);
  
  const [TablaMetricas, setTablaMetricas] = useState([]);
  const [datosAñoActual, setdatosAñoActual] = useState([]);
  const [datosAñoActualE, setdatosAñoActualE] = useState([]);
  const [tableData, settableData] = useState([]);
  

  useEffect(() => {
    setearConfDesdeToken(); // Para que se ejecute al montar el componente
  }, []); // Se ejecutará solo una vez al montar el componente gracias a la dependencia vacía []

  function setearConfDesdeToken() {
    // recibir token de local storage
    const token = localStorage.getItem('token')

    if (token) {
      console.debug('Token found:', token);
      const claims = decodeToken(token);
      console.debug('Token claims:', claims);
      
      // armar idioma
			if (claims.idioma) { i18n.changeLanguage(claims.idioma) }

			// configuracion de juego
      if (claims.role === "Player" || claims.role === "Captain") {
        setUserType("Player")
        setSelectedPartida(claims.partida)
        setSelectedEquipo(claims.equipo)
      } else if (claims.role === "Admin") {
        setUserType("Admin")
      } else {
        console.error("y vos quien sos ???")
      }
    }
  }

  const findPartidaName = () => {
    const partida = partidas.find(p => p.id === selectedPartida);
    return partida ? partida.nombre : "Selecciona una Partida";
  };

  const findEquipoName = () => {
    const equipo = equipos.find(e => e.id === selectedEquipo);
    return equipo ? equipo.nombre : "Selecciona un Equipo";
  };

  const fetchPartidas = async () => {
    try {
      const response = await fetch(`https://${host}/api/partidas`);
      if (!response.ok) { throw new Error('Respuesta del servidor no es OK'); }
      const data = await response.json();
      if (Array.isArray(data.data) && data.data.length) {
        setPartidas(data.data);
      } else {
        console.error("Formato de datos no esperado:", data);
      }
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  const fetchEquiposPorPartida = async (partidaId) => {
    try {
      const response = await fetch(`https://${host}/juego/${partidaId}`);
      if (!response.ok) { throw new Error('Respuesta del servidor no es OK'); }
      const data = await response.json();
      if (Array.isArray(data.data)) {
        setEquipos(data.data);
      } else {
        setEquipos([]);
        console.warn(data.message);
      }
    } catch (error) {
      setEquipos([]);
      console.error("Error al obtener los equipos:", error);
    }
  };

  useEffect(() => {
    fetchPartidas();
  }, []);

  useEffect(() => {
    if (selectedPartida) {
      fetchEquiposPorPartida(selectedPartida);
    }
  }, [selectedPartida]);

  const handlePartidaChange = (e) => {
    const partidaId = e.target.value;
    setSelectedPartida(partidaId);
    setEquipos([]);
    setSelectedEquipo(null);
  };

  const handleEquipoChange = (e) => {
    const equipoId = e.target.value;
    setSelectedEquipo(equipoId);
  };

  useEffect(() => {
    resultados.tablaResultados(selectedPartida, selectedEquipo)
      .then((result) => {
        setdatosAñoActual(result.datosAñoActual);
        setdatosAñoActualE(result.datosAñoActualE);
        setTablaMetricas(result.TablaMetricas);
        settableData(result.tableData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error trayendo resultados:", error);
        setLoading(true);
      });
  }, [selectedPartida, selectedEquipo]);

  var trafico = TablaMetricas && TablaMetricas[17] ? (TablaMetricas[17][2] || 0).toFixed(0) : "0";
  var visitors = TablaMetricas && TablaMetricas[8] ? (TablaMetricas[8][2] || 0).toFixed(0) : "0";

  return (
    <>
      <div className="content"
        style={{
          backgroundImage: `url(${img})`, // Aplicar la imagen de fondo importada
          backgroundSize: 'cover', // Opcional, ajusta el tamaño de la imagen de fondo
          backgroundRepeat: 'no-repeat', // Opcional, controla la repetición de la imagen
        }}>
        {userType !== "Player" && userType !== "Captain" ? (
          <Row>
            <Col xs="12">
              <Card style={{ backgroundColor: '#003366' }}>
                <CardBody>
                  <h2 style={{ color: '#E8E8E8' }}>Seleccionar partida & equipo</h2>
                  <FormGroup style={{ marginBottom: '10px', maxWidth: '50%' }}>
                    <Label style={{ color: '#E8E8E8' }}>Seleccione una Partida</Label>
                    <Input
                      style={{
                        color: '#E8E8E8',
                        borderColor: '#2C83D5',
                        fontSize: '18px',
                        backgroundColor: 'transparent',
                        padding: '10px 15px', // Añadido padding
                        height: 'auto'       // Establecer la altura en auto para ajustarse al contenido
                      }}
                      type="select"
                      value={selectedPartida || ''}
                      onChange={handlePartidaChange}
                    >
                      {partidas.map(partida => (
                        <option key={partida.id} value={partida.id}>
                          {partida.nombre}
                        </option>
                      ))}
                    </Input>
                    <Label style={{ color: '#E8E8E8' }}>Seleccione un Equipo</Label>
                    <Input
                      style={{
                        color: '#E8E8E8',
                        borderColor: '#2C83D5',
                        fontSize: '18px',
                        backgroundColor: 'transparent',
                        padding: '10px 15px', // Añadido padding
                        height: 'auto'       // Establecer la altura en auto para ajustarse al contenido
                      }}
                      type="select"
                      value={selectedEquipo || ''}
                      onChange={handleEquipoChange}
                    >
                      {equipos.map(equipo => (
                        <option key={equipo.id} value={equipo.id}>
                          {equipo.nombre}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : null}

        {/* ESPERAR A QUE CARGUEN LOS DATOS ANTES DE RENDER */}
        {loading ? (
          <p>{t('common.cargandoResultados')}...</p>
        ) : (
          // Render your tables and charts when loading is false
          <>
            <Row>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                    {t('metricas.trafico')}...
                    </h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      {trafico}
                    </CardTitle>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      235000
                    </CardTitle>
                  </CardHeader>
                  <CardBody style={{ textAlign: 'center' }}>
                    <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.traficoDesc')}...
                    </h5>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                    {t('metricas.conversion')}...
                    </h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      {parseFloat(TablaMetricas[9][2].replace('%', '')).toFixed(2)}%
                    </CardTitle>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      1.00%
                    </CardTitle>
                  </CardHeader>
                  <CardBody style={{ textAlign: 'center' }}>
                    <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.conversionDesc')}...
                    </h5>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                      {t('metricas.unique')}...
                    </h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      {visitors}
                    </CardTitle>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      100000
                    </CardTitle>
                  </CardHeader>
                  <CardBody style={{ textAlign: 'center' }}>
                    <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.uniqueDesc')}
                    </h5>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                    {t('metricas.revenue')}
                    </h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      {TablaMetricas[12][2]}
                    </CardTitle>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      $ 0
                    </CardTitle>
                  </CardHeader>
                  <CardBody style={{ textAlign: 'center' }}>
                    <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.revenueDesc')}
                    </h5>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                    {t('metricas.lead')}
                    </h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      {TablaMetricas[2][2]}
                    </CardTitle>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      8:15:30
                    </CardTitle>
                  </CardHeader>
                  <CardBody style={{ textAlign: 'center' }}>
                    <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.leadDesc')}
                    </h5>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                    {t('metricas.NPS')}
                    </h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      {TablaMetricas[15][2] }
                    </CardTitle>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      20
                    </CardTitle>
                  </CardHeader>
                  <CardBody style={{ textAlign: 'center' }}>
                    <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.NPSDesc')}
                    </h5>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                    {t('metricas.ticketMedio')}
                    </h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      {TablaMetricas[16][2]}
                    </CardTitle>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      $ 238.89
                    </CardTitle>
                  </CardHeader>
                  <CardBody style={{ textAlign: 'center' }}>
                    <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.ticketMedioDesc')}
                    </h5>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                    {t('metricas.margen')}
                    </h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      {TablaMetricas[18][2]}
                    </CardTitle>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      $ 875.000
                    </CardTitle>
                  </CardHeader>
                  <CardBody style={{ textAlign: 'center' }}>
                    <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.margenDesc')}
                    </h5>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                    {t('metricas.cross')}
                    </h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      {TablaMetricas[6][2]}
                    </CardTitle>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                      1
                    </CardTitle>
                  </CardHeader>
                  <CardBody style={{ textAlign: 'center' }}>
                    <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.crossDesc')}
                    </h5>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
}

export default Metricas1;
