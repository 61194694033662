// Final.js
// Pantalla final

import React, { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

// assets
import { Radar, Bar, Line } from 'react-chartjs-2';
import img from "../assets/img/fondo3.png"; // Ruta IMAGEN
import img1 from "../assets/img/medalla1.svg"; // medallas
import img2 from "../assets/img/medalla2.svg";
import img3 from "../assets/img/medalla3.svg";

// fondos de canvas
import canvasPostit from "../assets/img/canvas1.png";
import canvasEnBlanco from "../assets/img/canvas2.png";
import canvasB2B from "../assets/img/canvas3.png";
import canvasNavigator from "../assets/img/canvas4.png";
import canvasPlatform from "../assets/img/canvasPlataformas.png";
import canvasDataDriven from "../assets/img/canvasDataDriven.png";
import EcosystemCanvas from 'components/Sprints/EcosystemCanvas';

// recuadros canvas 1 postits
import recuadro1ActividadesClaves from "../assets/recuadrosCanvas/canvas1/ActividadesClaves.png"
import recuadro1Canales from "../assets/recuadrosCanvas/canvas1/Canales.png"
import recuadro1EstructuraDeCostos from "../assets/recuadrosCanvas/canvas1/EstructuraDeCostos.png"
import recuadro1FlujosDeIngreso from "../assets/recuadrosCanvas/canvas1/FlujosDeIngreso.png"
import recuadro1PropuestaDeValor from "../assets/recuadrosCanvas/canvas1/PropuestaDeValor.png"
import recuadro1RecursosClaves from "../assets/recuadrosCanvas/canvas1/RecursosClaves.png"
import recuadro1RelacionesConClientes from "../assets/recuadrosCanvas/canvas1/RelacionesConClientes.png"
import recuadro1SegmentosDeCliente from "../assets/recuadrosCanvas/canvas1/SegmentosDeCliente.png"
import recuadro1SociosClaves from "../assets/recuadrosCanvas/canvas1/SociosClaves.png"

// recuadros canvas 2 en blanco
import recuadro2ActividadesClaves from "../assets/recuadrosCanvas/canvas2/ActividadesClaves.png"
import recuadro2Canales from "../assets/recuadrosCanvas/canvas2/Canales.png"
import recuadro2EstructuraDeCostos from "../assets/recuadrosCanvas/canvas2/EstructuraDeCostos.png"
import recuadro2FlujosDeIngreso from "../assets/recuadrosCanvas/canvas2/FlujosDeIngreso.png"
import recuadro2PropuestaDeValor from "../assets/recuadrosCanvas/canvas2/PropuestaDeValor.png"
import recuadro2RecursosClaves from "../assets/recuadrosCanvas/canvas2/RecursosClaves.png"
import recuadro2RelacionesConClientes from "../assets/recuadrosCanvas/canvas2/RelacionesConClientes.png"
import recuadro2SegmentosDeCliente from "../assets/recuadrosCanvas/canvas2/SegmentosDeCliente.png"
import recuadro2SociosClaves from "../assets/recuadrosCanvas/canvas2/SociosClaves.png"

// recuadros canvas 3 b2b
import recuadro3ActividadesClaves from "../assets/recuadrosCanvas/canvas3/ActividadesClaves.png"
import recuadro3Canales from "../assets/recuadrosCanvas/canvas3/Canales.png"
import recuadro3EstructuraDeCostos from "../assets/recuadrosCanvas/canvas3/EstructuraDeCostos.png"
import recuadro3FlujosDeIngreso from "../assets/recuadrosCanvas/canvas3/FlujosDeIngreso.png"
import recuadro3PropuestaDeValor from "../assets/recuadrosCanvas/canvas3/PropuestaDeValor.png"
import recuadro3RecursosClaves from "../assets/recuadrosCanvas/canvas3/RecursosClaves.png"
import recuadro3RelacionesConClientes from "../assets/recuadrosCanvas/canvas3/RelacionesConClientes.png"
import recuadro3SegmentosDeCliente from "../assets/recuadrosCanvas/canvas3/SegmentosDeCliente.png"
import recuadro3SociosClaves from "../assets/recuadrosCanvas/canvas3/SociosClaves.png"
import recuadro3Consumidores from "../assets/recuadrosCanvas/canvas3/Consumidores.png"
import recuadro3Influencer from "../assets/recuadrosCanvas/canvas3/Influencer.png"
import recuadro3Intermediarios from "../assets/recuadrosCanvas/canvas3/Intermediarios.png"

// recuadros canvas 4 navigator
import recuadro4Que from "../assets/recuadrosCanvas/canvas4/Que.png"
import recuadro4Porque from "../assets/recuadrosCanvas/canvas4/Porque.png"
import recuadro4Quien from "../assets/recuadrosCanvas/canvas4/Quien.png"
import recuadro4Como from "../assets/recuadrosCanvas/canvas4/Como.png"
import recuadro4Propuesta from "../assets/recuadrosCanvas/canvas4/Propuesta.png"
import recuadro4Captura from "../assets/recuadrosCanvas/canvas4/Captura.png"
import recuadro4Creacion from "../assets/recuadrosCanvas/canvas4/Creacion.png"

// recuadros canvas 5 data driven
import recuadro5DataSources from "../assets/recuadrosCanvas/canvas5/DataSources.png"
import recuadro5Analytics from "../assets/recuadrosCanvas/canvas5/Analytics.png"
import recuadro5ProductOrService from "../assets/recuadrosCanvas/canvas5/ProductOrService.png"
import recuadro5Benefit from "../assets/recuadrosCanvas/canvas5/Benefit.png"
import recuadro5ImplicationsMonetization from "../assets/recuadrosCanvas/canvas5/ImplicationsMonetization.png"
import recuadro5ImplicationsCosts from "../assets/recuadrosCanvas/canvas5/ImplicationsCosts.png"

// recuadros canvas 6 platform NO SE USA
/*import recuadro6DataSources from "../assets/recuadrosCanvas/canvas6/DataSources.png"
import recuadro6Analytics from "../assets/recuadrosCanvas/canvas6/Analytics.png"
import recuadro6ProductOrService from "../assets/recuadrosCanvas/canvas6/ProductOrService.png"
import recuadro6Benefit from "../assets/recuadrosCanvas/canvas6/Benefit.png"
import recuadro6ImplicationsMonetization from "../assets/recuadrosCanvas/canvas6/ImplicationsMonetization.png"
import recuadro6ImplicationsCosts from "../assets/recuadrosCanvas/canvas6/ImplicationsCosts.png"*/

// PDF y certificado
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import DownloadCertificate from "../components/PDF/certificateGenerator.js";

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// componentes
import Velocimetro from 'components/Puntos/Velocimetro';
import ChartDIM from 'components/Charts/PieDIM'; // digital innovation
import BarChartComponent from "../components/Charts/barsusten.jsx";
import ConcentricClickableSectors from 'components/Sprints/EcosystemCircle'; // ecosystem

// internos
import puntosHelpers from "../variables/puntos.js"
import tokenHelpers from "../variables/tokens.js"
import equipoHelpers from "../variables/equipos.js"
import cartasHelpers from "../variables/cartas.js";
import jugadasHelpers from "../variables/jugadas.js"
import sprintHelpers from "../variables/sprints.js"
import canvasHelpers from "../variables/canvas.js"
import resultados from "../variables/resultados.js"
import { host } from "../variables/connection.js"

const decodeToken = tokenHelpers.decodeToken;
const equiposEnPartida = equipoHelpers.equiposEnPartida;
const fetchData = puntosHelpers.fetchData;
const fetchModifier = puntosHelpers.fetchModifier;
const numeroSprintsEnPartida = sprintHelpers.numeroSprintsEnPartida;
const cartasEnMazo = cartasHelpers.cartasEnMazo;
const cartaPorNumero = cartasHelpers.cartaPorNumero;
const cartaPorID = cartasHelpers.cartaPorID;
const variasCartasPorID = cartasHelpers.variasCartasPorID;
const listaMazos = cartasHelpers.listaMazos;
const verJugadasEquipo = jugadasHelpers.verJugadasEquipo;

// canvas
const DropZone = canvasHelpers.DropZone;
const DraggableCard = canvasHelpers.DraggableCard;
const traerFramework = canvasHelpers.traerFramework;
const traerRecuadros = canvasHelpers.traerRecuadros;

// Estilos CSS
const styles = {
  slidersContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Alinea los sliders verticalmente en el centro
  },
  sliderItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px', // Espacio entre cada slider
  },
  label: {
    width: '120px', // Establece un ancho fijo para las etiquetas
    textAlign: 'right', // Alinea el texto a la derecha
    paddingRight: '10px', // Espacio a la derecha del texto
  },
};

function sortByKeyAscendente(array, key) {
  return array.sort(function (a, b) {
    var x = a[key]; var y = b[key];
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}

function sortByKeyDescendente(array, key) {
  return array.sort(function (a, b) {
    var x = a[key]; var y = b[key];
    return ((x > y) ? -1 : ((x < y) ? 1 : 0));
  });
}

const Final = () => {
  const f = "CPT Final"

  // conf
  const { t, i18n } = useTranslation();
  const [idioma, setIdioma] = useState("es")
  const [path, setPath] = useState("../assets/es/cartas/")
  const [loading, setLoading] = useState(true) // hacer visible los elementos al empezar
  const navigate = useNavigate();

  // datos usuario
  const [nombre, setNombre] = useState("");
  const [institucion, setInstitucion] = useState("");
  const [fecha, setFecha] = useState("")

  // general
  const [selectedPartida, setSelectedPartida] = useState(null);
  const [partidaVirtual, setPartidaVirtual] = useState(false);
  const [selectedEquipo, setSelectedEquipo] = useState(null);
  const [sprintsJugados, setSprintsJugados] = useState([])
  const [selectedCanvas, setSelectedCanvas] = useState("canvasPostit")
  const arranqueListo = false

  // cucardones
  const [topTres, setTopTres] = useState(null)
  const [topTresMejora, setTopTresMejora] = useState(null)
  const [topTresPorter, setTopTresPorter] = useState(null)
  const [topTresAmit, setTopTresAmit] = useState(null)
  const [puntosGanados, setPuntosGanados] = useState(null)
  const [mejoraGanada, setMejoraGanada] = useState(null)
  const [cucardonesPuntos, setCucardonesPuntos] = useState(null)
  const [cucardonesQuizzes, setCucardonesQuizzes] = useState(null)
  const [cucardonesMejora, setCucardonesMejora] = useState(null)

  // sprint 0
  const [sliderValues, setSliderValues] = useState([5, 5, 5, 5]); // AMIT
  const [sliderValues2, setSliderValues2] = useState([5, 5, 5, 5, 5]); // PORTER
  const [sliderValuesData, setsliderValuesData] = useState([5, 5, 5, 5, 5, 5, 5, 5, 5]); // DATA MATURITY
  const [valWheel, setValWheel] = useState([5, 5, 5, 5]); // valwheel

  // metricas
  const [TablaMetricas, setTablaMetricas] = useState([]);
  const [datosAñoActual, setdatosAñoActual] = useState([]);
  const [datosAñoActualE, setdatosAñoActualE] = useState([]);
  const [tableData, settableData] = useState([]);

  // cartas y jugadas
  const [jugadas, setJugadas] = useState(null);
  const [cartas, setCartas] = useState([]);
  const [images, setImages] = useState([]);
  const [dimData, setDimData] = useState([]); // para chart digital innovation measurement
  const [ecosystem_val, setEcoSystemVal] = useState({}) // ECOSYSTEM
  const [sustainData, setSustainData] = useState({}) // SUSTAINABILITY

  // canvas
  const correctorGlobal = 95 // modificar aca si las banderas se salen de los recuadros
  const [tableroWidth, setTableroWidth] = useState(0);
  const [tableroHeight, setTableroHeight] = useState(0);
  const [tableroWidthZero, setTableroWidthZero] = useState(0);
  const [tableroHeightZero, setTableroHeightZero] = useState(0);
  const [droppedCards, setDroppedCards] = useState({
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: []
  });

  console.debug(`jugadas init ${JSON.stringify(jugadas)} tipo ${typeof jugadas}`)

  //////////////
  /*  CONFIG  */
  //////////////

  // devuelve true si se jugo el sprint y falso si no
  function jugamosEsteSprint(keyword, data) {
    return data.some(item => item.nombre === keyword);
  }

  async function setearConfDesdeToken() {
    // recibir token de local storage
    const token = localStorage.getItem('token')
    console.debug(`jugadas arranque setearConf ${JSON.stringify(jugadas)} tipo ${typeof jugadas}`)

    if (!token) { console.error(`no hay token!`); return null }
    console.debug('Token found:', token);
    const claims = decodeToken(token);
    console.debug('Token claims:', claims);

    // armar fecha
    const date = new Date();
    var now = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    setFecha(now)
    console.debug(`fecha es ${now}`)

    // armar idioma
    if (claims.idioma) {
      setIdioma(claims.idioma)
      console.debug(`seteando path a ../assets/${claims.idioma}/cartas/...`)
      setPath(`../assets/${claims.idioma}/cartas/`)
      i18n.changeLanguage(claims.idioma)

      // setear fecha como string i18n
      now = date.toLocaleDateString(claims.idioma, {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      });
    }

    // setear datos usuario
    if (claims.nombre && claims.institucion) {
      setNombre(claims.nombre)
      setInstitucion(claims.institucion)
      console.debug(`Generando final para ${claims.nombre}, ${claims.institucion}, el dia ${fecha}`)
    }

    // configuracion de juego
    if (claims.role === "Player" || claims.role === "Captain") {

      // setear partida y equipo
      setSelectedPartida(claims.partida)
      setSelectedEquipo(claims.equipo)
      console.debug(`Seteando pagina final para partida ${claims.partida}, equipo ${claims.equipo}...`)

      // datos asociados al equipo
      var datosEquipos, datosEquipo, datosSprints, datosJugadas, datosMejora
      try {
        datosEquipos = await equiposEnPartida(claims.partida)
        datosEquipo = await fetchData(claims.partida, claims.equipo)
        datosSprints = await numeroSprintsEnPartida(claims.partida)
        datosJugadas = await verJugadasEquipo(claims.partida, claims.equipo)
        datosMejora = await getMejora(datosEquipo[0])

        const mejora = {
          total: Math.round((datosMejora.mejora_total - 1) * 1000, 0),
          porter: Math.round((datosMejora.mejora_porter) * 1000, 0), // agregamos un 0 hasta determinar pq es distinto del grafico
          amit: Math.round((datosMejora.mejora_amit) * 1000, 0)
        }
        setMejoraGanada(mejora)

        //console.debug(`datos equipo son ${JSON.stringify(datosEquipo)}`)
        console.debug(`Equipo esta en sprint id ${datosEquipo[0]["sprint_id"]}`)
        console.debug(`datos de todos los sprints en p son ${JSON.stringify(datosSprints)}`)
        console.debug(`datos de todos las jugadas son ${JSON.stringify(datosJugadas)}`)
        console.debug(`datos mejora son ${JSON.stringify(mejora)}`)

        // armar un array de nombres de sprints jugados
        const datosSprintsJugados = [...new Set(datosSprints.map(item => item.nombre))];
        setSprintsJugados(datosSprintsJugados);

        // ir alimentando a cada seccion
        console.debug(`enviando a top3: ${JSON.stringify(datosEquipos)}`)
        top3(datosEquipos) // top 3 
        top3mejora(datosEquipos)
        repartirMedallas(datosEquipo[0]) // cucardones
        traerPuntosEquipo(datosEquipo[0]) // sprint 0
        // metricas se arma solo
        // blue ocean? lo encuentra

        // ecosystem
        var ecosystem
        const ecosystemResp = await armarEcosystem(datosEquipo[0])
        if (!ecosystemResp || !ecosystemResp.success) { console.error(`no hubo respuesta de ecosystemResp`) }
        else { ecosystem = ecosystemResp.data }
        setEcoSystemVal(ecosystem)

        // empezar a armar jugadas, DIM chart, Sustainability
        setJugadas(datosJugadas) // traer cartas jugadas
        traerDatosDeCartas(datosJugadas)
        console.debug(`jugadas seteado ${JSON.stringify(jugadas)} tipo ${typeof jugadas}`)

        if (claims.presencial === 1) {
          // JUEGO PRESENCIAL


        } else {
          // JUEGO VIRTUAL
          setPartidaVirtual(true)

          // traer el framework basado en partida
          const frameworkID = await traerFramework(claims.partida)
          console.debug(`frameworkID es ${frameworkID}...`)

          // setear img framework
          var selecciondeCanvas
          switch (frameworkID) {
            case 1: selecciondeCanvas = "canvasPostit"; break; // Talleres 
            case 2: selecciondeCanvas = "canvasEnBlanco"; break;
            case 3: selecciondeCanvas = "canvasB2B"; break;
            case 4: selecciondeCanvas = "canvasNavigator"; break;
            case 5: selecciondeCanvas = "canvasDataDriven"; break;
            case 6: selecciondeCanvas = "canvasPlatform"; break;
            default: selecciondeCanvas = null; break;
          }
          console.debug(`canvas seleccionado es ${selecciondeCanvas}`)
          setSelectedCanvas(selecciondeCanvas)

          // traer recuadros canvas del framework
          var selecciondeRecuadros = await traerRecuadros(frameworkID);
          console.debug(`recuadros seleccionados son ${JSON.stringify(selecciondeRecuadros)}`)

          // convierte a los recuadros en un array de IDs
          var recuadrosViejosArr = selecciondeRecuadros.map(x => x.id)
          console.debug(`recuadrosViejosArr es ${JSON.stringify(recuadrosViejosArr)}`)
          var recuadrosViejos = {}

          // si se jugo data driven virt, agregar recuadros de ese
          if (datosSprintsJugados.includes("DataDriven")) {
            // traer recuadros DD
            var selecciondeRecuadrosDD = await traerRecuadros(5);
            console.debug(`recuadros seleccionados son ${JSON.stringify(selecciondeRecuadrosDD)}`)

            // 
            var recuadrosViejosArrDD = selecciondeRecuadrosDD.map(x => x.id)
            console.debug(`recuadrosViejosArr data driven es ${JSON.stringify(recuadrosViejosArrDD)}`)

            // mergearlo al array
            recuadrosViejosArr = recuadrosViejosArr.concat(recuadrosViejosArr, recuadrosViejosArrDD)
            console.debug(`recuadrosViejosArr con data driven es ${JSON.stringify(recuadrosViejosArrDD)}`)
          }

          // filtrar jugadas para los que tengan recuadro
          if (datosJugadas) {
            datosJugadas.filter(x => x.recuadro)
            console.debug(`jugadas anteriores con recuadro son ${JSON.stringify(datosJugadas)}`)

            // traer todas las imagenes para las cartas jugadas previamente
            const arrayCartasViejas = datosJugadas.map(x => x.carta_id)
            console.debug(`arrayCartasViejas es ${arrayCartasViejas}`)
            const cartasViejasData = await variasCartasPorID(arrayCartasViejas)
            console.debug(`cartasViejasData es ${JSON.stringify(cartasViejasData)}`)
            var imgsCartasViejas = await fetchImagesDerecho(cartasViejasData)
            console.debug(`imgsCartasViejas son:`, imgsCartasViejas)

            // armar las imagenes de los sprints anteriores y ponerlos en el tablero
            // mapear los recuadros, sin que se puedan deseleccionar
            try {
              for (let i = 0; i < recuadrosViejosArr.length; i++) {
                var keyD = recuadrosViejosArr[i];
                var jugD = datosJugadas.filter(x => x.recuadrocanvas_id === keyD);

                // Convertir jugadas a imágenes
                console.debug(`jugD viejo es ${JSON.stringify(jugD)}`);
                console.debug(`cartasviejasdata es array?`, Array.isArray(cartasViejasData)); // Check if it logs true
                console.debug(`imgscartas es array?`, Array.isArray(imgsCartasViejas)); // Check if it logs true

                for (let i = 0; i < jugD.length; i++) {
                  var carta = cartasViejasData.find(x => x && x.id === jugD[i].carta_id);

                  if (carta) {
                    var svgpath = carta.svg_front;
                    var imgInfo = imgsCartasViejas.find(x => x && x.name === svgpath);

                    if (imgInfo) {
                      var name = imgInfo.name;
                      var src = imgInfo.src;
                      jugD[i] = { name: name, src: src };
                    }
                  }
                }

                console.debug(`jugD nuevo es ${JSON.stringify(jugD)}`);
                recuadrosViejos[keyD] = jugD;
              }

              console.debug(`recuadrosViejos son ${JSON.stringify(recuadrosViejos)}`);
              setDroppedCards(recuadrosViejos);
            } catch (error) {
              console.error(error);
            }

          } else {
            // si no hay jugadas anteriores, monta un array de objetos con arrays vacios
            for (let i = 0; i < recuadrosViejosArr.length; i++) {
              var keyD = recuadrosViejosArr[i]
              recuadrosViejos[keyD] = []
            }
            setDroppedCards(recuadrosViejos)
          }

        }

      } catch (error) {
        console.error(`No se pudo traer datos en conf ini:`, error)
      }

    } else if (claims.role === "Admin") {
      //setUserType("Admin")
    } else {
      console.error("y vos quien sos ???")
    }

  }

  useEffect(() => {
    //console.debug(`jugadas antes useEffect ${JSON.stringify(jugadas)} tipo ${typeof jugadas}`)
    setearConfDesdeToken()
  }, [])

  //console.debug(`jugadas desp de useEffect ${JSON.stringify(jugadas)} tipo ${typeof jugadas}`)

  // llamar las funciones dentro de effect

  ///////////////
  /*   CARTAS  */
  ///////////////

  // modificado para remover dups
  useEffect(() => {
    async function fetchImages() {
      const imageData = await Promise.all(cartas.map(fetchImageData));

      /* SOLO FILTRA POR NAME, NO SRC-- anda todavia.
      // Create a Map to store unique items based on the 'name' property
      const uniqueImageMap = new Map();

      imageData.forEach(item => {
        if (item && item.name) {
        uniqueImageMap.set(item.name, item);
        }
      });
  	
      // Convert the Map back to an array
      const uniqueImageData = Array.from(uniqueImageMap.values());

      setImages(uniqueImageData);
      */

      // Create Sets to store unique names and srcs
      const uniqueNames = new Set();
      const uniqueSrcs = new Set();

      const uniqueImageData = imageData.filter(item => {
        if (item && item.name && item.src) {
          const nameExists = uniqueNames.has(item.name);
          const srcExists = uniqueSrcs.has(item.src);

          if (!nameExists && !srcExists) {
            uniqueNames.add(item.name);
            uniqueSrcs.add(item.src);
            return true;
          }
        }
        return false;
      });

      setImages(uniqueImageData);
    }
    if (cartas.length > 0) {
      fetchImages();
    }
  }, [cartas]);

  const fetchImageData = async (carta) => {
    const fullPath = `${path}${carta.svg_front}`;
    const URL = `https://${host}/${fullPath}`;
    const response = await fetch(URL);
    if (!response.ok) {
      return null;
    }
    const blob = await response.blob();
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve({
          src: reader.result,
          name: carta.svg_front,
          i18: carta.nombre_18full
        });
      };
      reader.readAsDataURL(blob);
    });
  };

  async function fetchImagesDerecho(datosCartas) {

    if (!datosCartas || datosCartas.length === 0 || datosCartas === null || datosCartas === undefined) { return }

    // limpieza
    var datosCartasClean = datosCartas.filter(x => x !== null && x !== undefined)

    var imageData = await Promise.all(datosCartasClean.map(fetchImageData));

    console.debug(`se trajeron ${imageData.length} cartas derecho, como ${imageData[0].name}`)

    var listaCartas = []
    for (let i = 0; i < imageData.length; i++) {
      var imagenInd = imageData[i]
      if (!imagenInd) {
        console.debug(`falta carta ${i}/${imageData.length}, borrando...`)
        continue
      }
      if (!imagenInd.src) {
        console.debug(`falta src ${imagenInd}, borrando...`)
        imageData.splice(i)
        continue
      }

      listaCartas.push(imagenInd.name)
      //console.debug(imageData[i].name)
    }

    console.debug(`lista derecho tiene ${listaCartas.length}: ${listaCartas}`)
    console.debug(`fetchImagesDerecho len es ${imageData.length}`)
    console.debug(`fetchImagesDerecho 0 :`, imageData[0])

    // NO SETEAR ESTADO :p
    return imageData
  }

  // modificado para traer cartas unicas
  async function traerDatosDeCartas(jugadasData) {
    // traer lista de carta IDs
    var cartaData = []
    var uniqueSvgFronts = new Set();

    for (let i = 0; i < jugadasData.length; i++) {
      var datos = await cartaPorID(jugadasData[i]["carta_id"]);

      if (datos && datos["svg_front"] && !uniqueSvgFronts.has(datos["svg_front"])) {
        cartaData.push(datos);
        uniqueSvgFronts.add(datos["svg_front"]);
      }
    }
    console.debug(`Traido datos para ${cartaData.length} cartas jugadas...`)

    setCartas(cartaData);

    // armar chart DIM
    const DIM = armarChartDIM(cartaData, jugadasData);
    setDimData(DIM)

    // armar sustentabilidad
    var sustainability
    const sustResp = armarSustentabilidad(cartaData, jugadasData)
    if (!sustResp || !sustResp.success) {
      console.error("resp sustainability rompio!")
    } else {
      sustainability = sustResp.data
    }
    setSustainData(sustainability)

  }

  function jugadasSprint(cartaData, sprint) {

    if (!cartaData) { return null }

    // traer solo Platform
    const jugadas = cartaData.filter(x => x && x.mazo === sprint);
    console.debug(`armando jugadas ${sprint} con ${JSON.stringify(jugadas)}`)

    if (!jugadas || jugadas.length === 0) { return null }
    console.debug(`jugadas ${sprint} son ${JSON.stringify(jugadas)}`)

    const categories = [...new Set(jugadas.map((jugada) => jugada.categoria))];

    return (
      categories.map((categoria) => (
        <div key={categoria}>
          <h3>{categoria}</h3>
          <ul>
            {jugadas.map((jugada) => {
              if (jugada.categoria === categoria) {
                return (
                  <li key={jugada.nombre_es}>{t(jugada.nombre_18full)}</li>
                );
              }
              return null; // saltear esa jugada si no matchea
            })}
          </ul>
        </div>
      ))
    )
  }

  // va por cada jugada en un sprint y suma los puntos de cartas
  function sumarColumnaSprint(datosCartas, datosJugadas, mazo, columna) {
    const fn = `TEST ${f}/sumarPuntosSprint`
    console.debug(`${fn}...`)
    try {
      // filtrar los arrays
      const jugadas = datosJugadas.filter(j => j && j.mazo === mazo)
      const cartas = datosCartas.filter(c => c && c.mazo === mazo)
      if (!jugadas || jugadas.length === 0) { return null }

      // para cada jugada, sumar la columna
      var cumulativo = 0
      for (let i = 0; i < jugadas.length; i++) {
        const jugada = jugadas[i]
        const carta = cartas.find(c => c.id == jugada.carta_id)
        if (!carta) { continue }
        else {
          console.debug(`${fn}: ${mazo}: ${cumulativo} + ${carta[columna]} (#${carta.id})`)
          cumulativo = cumulativo + Number(carta[columna])
        }
      }

      // devolver
      console.debug(`${fn}: ${columna} para ${mazo} son ${cumulativo}`)
      return cumulativo
    } catch (error) {
      const msg = `${fn} error: ${error.message}`
      console.error(msg)
      return null
    }
  }

  //////////////
  /* MEDALLAS */
  //////////////

  function top3valor(equipos, valor) {

    // sortear
    const sorted = sortByKeyDescendente(equipos, valor)
    //console.debug(`top3 sorted por ${valor} es ${JSON.stringify(sorted)}`)

    // agarrar top 3
    const top3puntos = sorted.slice(0, 2)
    //console.debug(`top3 sliced es ${JSON.stringify(top3puntos)}`)

    // para que no rompa si hay menos de 3 equipos
    if (top3puntos.length >= 3) {
      console.debug(`Top 3 equipos por ${valor} son ${top3puntos[0].nombre}, ${top3puntos[1].nombre}, ${top3puntos[2].nombre}.`)
      // ???
    }

    return top3puntos
  }

  async function top3(equiposData) {
    //console.debug(`top3 puntos recibe ${JSON.stringify(equiposData)}`)
    if (!equiposData) { return }
    const top3puntos = top3valor(equiposData, "puntos")
    setTopTres(top3puntos)
  }

  async function getMejora(e) {
    var m = e
    const resp = await fetchModifier(m.partida_id, m.id)
    if (!resp || !resp.success) {
      console.error(`getMejora: error trayendo equipo ${m.id}`)
    } else {
      m.mejora_total = resp.data.total;
      m.mejora_porter = resp.data.porter;
      m.mejora_amit = resp.data.amit;

      console.debug(`top3mejora: equipo ${m.id} tiene mejora total ${JSON.stringify(m.mejora_total)}`)
    }
    return m
  }

  async function top3mejora(equiposData) {
    //console.debug(`top3mejora recibe ${JSON.stringify(equiposData)}`)
    if (!equiposData) { return }

    // para cada equipo, traer la mejora e incorporarla al objeto
    var equiposConMejora = []
    for (let i = 0; i < equiposData.length; i++) {
      const e = equiposData[i]
      const m = await getMejora(e)
      equiposConMejora.push(m)
    }

    const top3total = top3valor(equiposConMejora, "mejora_total")
    const top3porter = top3valor(equiposConMejora, "mejora_porter")
    const top3amit = top3valor(equiposConMejora, "mejora_amit")

    setTopTresMejora(top3total)
    setTopTresPorter(top3porter)
    setTopTresAmit(top3amit)
  }

  async function repartirMedallas(equipoData) {
    console.debug(`Repartiendo medallas para equipo ${equipoData.id}`)

    // determinar si gano alguno de las medallas x puntos
    var puntos = equipoData.puntos;
    console.debug(`equipo ${equipoData.id} tiene puntos: ${puntos}`)
    switch (true) {
      case (puntos > 3000):
        setCucardonesPuntos(<span style={styles.primerPuesto}>🥇
          {t('final.excelente')}</span>)
        break;
      case (puntos > 2000):
        setCucardonesPuntos(<span style={styles.segundoPuesto}>🥈
          {t('final.muyBien')}</span>)
        break;
      case (puntos > 1000):
        setCucardonesPuntos(<span style={styles.tercerPuesto}>🥉
          {t('final.puedenMejorar')}</span>)
        break;
      default:
        setCucardonesPuntos(<span style={styles.tercerPuesto}>🥉
          {t('final.aunNoCalif')} </span>)
        break;
    }

    /*
    <h2>Medallas</h2>
    <h3 style={{ marginTop: "5px" }}>Puntaje:</h3>
    {cucardonesPuntos}
    <img src={img1} alt="Medalla 1" />
    <h3 style={{ marginTop: "5px" }}>Quizzes:</h3>
    {cucardonesQuizzes}
    <img src={img2} alt="Medalla 2" />
    <h3 style={{ marginTop: "5px" }}>Tiempo:</h3>
    <img src={img3} alt="Medalla 3" />
    */

    // determinar si gano alguna de las medallas x quizzes
    var quizzes = (equipoData.quizzesGanados / equipoData.quizzesJugados) * 100;
    console.debug(`equipo ${equipoData.id} tuvo ${equipoData.quizzesGanados} quizzes ganados de ${equipoData.quizzesJugados} jugados, o ${quizzes}%`)

    if (equipoData.quizzesJugados > sprintsJugados.length) {
      console.debug(`alguien se estuvo macheteando...`)
    }

    switch (true) {
      case (quizzes > 75):
        setCucardonesQuizzes(<span style={styles.primerPuesto}>🥇
          {t('final.excelente')}</span>)
        break;
      case (quizzes > 50):
        setCucardonesQuizzes(<span style={styles.segundoPuesto}>🥈
          {t('final.muyBien')}</span>)
        break;
      case (quizzes > 25):
        setCucardonesQuizzes(<span style={styles.tercerPuesto}>🥉
          {t('final.puedenMejorar')}</span>)
        break;
      default:
        setCucardonesQuizzes(<span style={styles.tercerPuesto}>🥉
          {t('final.aunNoCalif')} </span>)
        break;
    }

    switch (true) {
      case (quizzes > 75):
        setCucardonesQuizzes(<span style={styles.primerPuesto}>🥇
          {t('final.excelente')}</span>)
        break;
      case (quizzes > 50):
        setCucardonesQuizzes(<span style={styles.segundoPuesto}>🥈
          {t('final.muyBien')}</span>)
        break;
      case (quizzes > 25):
        setCucardonesQuizzes(<span style={styles.tercerPuesto}>🥉
          {t('final.puedenMejorar')}</span>)
        break;
      default:
        setCucardonesQuizzes(<span style={styles.tercerPuesto}>🥉
          {t('final.aunNoCalif')} </span>)
        break;
    }
  }

  const TeamPointsDisplay = ({ teams = [] }) => {
    const sortedTeams = [...teams].sort((a, b) => b.points - a.points);

    return (
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        zIndex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // fondo ligeramente oscuro
        borderRadius: '10px', // esquinas redondeadas
        padding: '20px', // espacio alrededor
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' // sombra sutil
      }}>
        {sortedTeams.map((team, index) => (
          <div
            key={index}
            style={{
              marginBottom: '10px',
              fontWeight: 'bold',
              fontSize: '2em',
              color: '#FFF',
              animation: 'growText 1.5s ease-in-out',
              transition: 'all 0.3s ease-in-out' // transición suave
            }}
          >
            {getBadge(index)}
            {team.name} : {team.points} Puntos!
          </div>
        ))}
      </div>
    );
  };

  const getBadge = (index) => {
    switch (index) {
      case 0:
        return <span style={styles.primerPuesto}>🥇</span>;
      case 1:
        return <span style={styles.segundoPuesto}>🥈</span>;
      case 2:
        return <span style={styles.tercerPuesto}>🥉</span>;
      default:
        return null;
    }
  }

  const styles = {
    // por ranking de puntos
    primerPuesto: {
      color: 'gold',
      marginRight: '10px',
      fontSize: '3em'
    },
    segundoPuesto: {
      color: 'silver',
      marginRight: '10px',
      fontSize: '3em'
    },
    tercerPuesto: {
      color: '#cd7f32', // Bronze
      marginRight: '10px',
      fontSize: '3em'
    }
  };

  //////////////
  /* SPRINT 0 */
  //////////////

  // buscar puntos del equipo
  async function traerPuntosEquipo(equipo) {
    //console.debug(`datos equipo: ${JSON.stringify(equipo)}, tipo ${typeof equipo}`)

    //console.debug(`Porter antes de cambiar: ${sliderValues2}`)
    //console.debug(`Amit antes de cambiar: ${sliderValues}`)

    //console.debug(`keys equipo: ${Object.keys(equipo)}`)

    //console.debug(`porter buyers orig: ${equipo.porter_buyers_orig}, tipo ${typeof equipo.porter_buyers_orig}`)
    //console.debug(`como numero: ${Number(equipo.porter_buyers_orig)}, tipo ${typeof Number(equipo.porter_buyers_orig)}`)

    // este clamp se asegura que los valores esten dentro del rango aceptable para los charts
    const clampValue = (value, min, max) => {
      return Math.max(min, Math.min(max, value));
    }

    const porter = [
      clampValue(Number(equipo.porter_buyers_mod) || 5, 1, 7),
      clampValue(Number(equipo.porter_suppliers_mod) || 5, 1, 7),
      clampValue(Number(equipo.porter_rivalry_mod) || 5, 1, 7),
      clampValue(Number(equipo.porter_entrants_mod) || 5, 1, 7),
      clampValue(Number(equipo.porter_substitutes_mod) || 5, 1, 7)
    ]

    const amit = [
      clampValue(Number(equipo.driver_novelty_mod) || 5, 1, 7),
      clampValue(Number(equipo.driver_lockin_mod) || 5, 1, 7),
      clampValue(Number(equipo.driver_complementary_mod) || 5, 1, 7),
      clampValue(Number(equipo.driver_efficiency_mod) || 5, 1, 7)
    ]

    const dataMaturity = [
      clampValue(Number(equipo.datamaturity_estrategia_mod) || 5, 1, 7),
      clampValue(Number(equipo.datamaturity_cultura_mod) || 5, 1, 7),
      clampValue(Number(equipo.datamaturity_orgestructura_mod) || 5, 1, 7),
      clampValue(Number(equipo.datamaturity_tecnologia_mod) || 5, 1, 7),
      clampValue(Number(equipo.datamaturity_centralidad_mod) || 5, 1, 7)
    ]

    //	labels: ["What", "How", "Why", "Who"],

    const valWheel = [
      Number(equipo.valwheel_what_mod) || 5,
      Number(equipo.valwheel_how_mod) || 5,
      Number(equipo.valwheel_why_mod) || 5,
      Number(equipo.valwheel_who_mod) || 5,
    ]

    //console.debug(`porter: ${porter}`)
    //console.debug(`amit: ${amit}`)

    // setear sliders
    setSliderValues2(prevValues => porter)
    setSliderValues(prevValues => amit)
    setsliderValuesData(prevValues => dataMaturity)

    const puntosGanados = equipo.puntos;
    setPuntosGanados(prevValues => puntosGanados)
    setValWheel(prevValues => valWheel)
  }

  // porter
  const data = {
    labels: ["Novelty", "Lock-In", "Complementarities", "Efficiency"],
    datasets: [
      {
        label: t('amit.valores'),
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 0.2)",
        borderWidth: 1,
        data: sliderValues,
      },
    ],
  };

  // amit
  const data2 = { // Datos para el segundo gráfico
    labels: ["Buyers", "Suppliers", "Competitors", "New Entry", "Substitutes"],
    datasets: [
      {
        label: t('porter.valores'),
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 0.2)",
        borderWidth: 1,
        data: sliderValues2,
      },
    ],
  };

  // madurez
  const dataDataMaturity = { // Datos para DATA MATURITY
    labels: ["Strategy", "Culture", "Organization structure", "Technology", "Customer Centricity"],
    datasets: [
      {
        label: t('Data_Maturity.valores'),
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 0.2)",
        borderWidth: 1,
        data: sliderValuesData,
      },
    ],
  };

  const radarOptions = {
    scale: {
      pointLabels: {
        fontColor: '#000', // Color de las etiquetas, ajusta según necesites
        font: {
          size: 12, // Tamaño de la fuente, ajusta según necesites
          weight: 'bold' // Etiquetas en negrita
        }
      },
      gridLines: {
        color: 'rgba(0, 0, 0, 0.1)', // Color de las líneas de la cuadrícula, ajusta la opacidad según necesites
      },
      ticks: {
        backdropColor: 'rgba(0, 0, 0, 0)', // Color de fondo de las marcas
      },
      min: 1,
      max: 7,
      stepSize: 1,
    },
  };

  ///////////////
  /*  METRICAS */
  ///////////////

  useEffect(() => {
    resultados.tablaResultados(selectedPartida, selectedEquipo)
      .then((result) => {
        setdatosAñoActual(result.datosAñoActual);
        setdatosAñoActualE(result.datosAñoActualE);
        setTablaMetricas(result.TablaMetricas);
        settableData(result.tableData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error trayendo resultados:", error);
        setLoading(true);
      });
  }, [selectedPartida, selectedEquipo]);

  var trafico = TablaMetricas && TablaMetricas[17] ? (TablaMetricas[17][2] || 0).toFixed(0) : "0";
  var visitors = TablaMetricas && TablaMetricas[8] ? (TablaMetricas[8][2] || 0).toFixed(0) : "0";

  ///////////////
  /*  GRAFICOS */
  ///////////////

  // catch 22
  const data22 = {
    labels: ["What", "How", "Why", "Who"],
    datasets: [
      {
        label: t('valwheel.label'),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        borderWidth: 1,
        data: valWheel,
      },
    ],
  };

  const options22 = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
        ticks: {
          color: 'black', // Cambia el color de los textos en el eje X
        },
      },
      y: {
        ticks: {
          color: 'black', // Cambia el color de los textos en el eje Y
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: 'black', // Cambia el color de las etiquetas de la leyenda
        },
      },
    },
  };

  ////////////////
  /*  DIM Chart */
  ////////////////

  // arma un array de mazos jugados y puntos para c/u
  function puntosPorSprint(datosCartas, datosJugadas) {
    const fn = `TEST ${f}/puntosPorSprint`
    console.debug(`${fn}...`)
    try {
      // armar arr de mazos
      const mazos = []
      for (let i = 0; i < datosJugadas.length; i++) {
        const jugada = datosJugadas[i];
        const mazo = jugada.mazo;

        // si el mazo no existe en arr, lo agrega
        const pos = mazos.find(m => m === mazo)
        if (pos) { continue }
        else { mazos.push(mazo) }
      }

      // iterar sobre arr para traer los puntos
      const puntosSp = {}
      for (let i = 0; i < mazos.length; i++) {
        const mazo = mazos[i]
        const puntos = sumarColumnaSprint(datosCartas, datosJugadas, mazo, "valor_propio")
        if (!puntos) { continue }
        else { puntosSp[mazo] = puntos }
      }

      // devolver
      return puntosSp

    } catch (error) {
      const msg = `${fn} error: ${error.message}`
      console.error(msg)
      return null
    }
  }

  // arma el dataset para DIM 
  function armarChartDIM(datosCartas, datosJugadas) {
    const fn = `TEST ${f}/armarChartDIM`
    console.debug(`${fn}...`)
    try {
      if (!datosCartas || datosCartas.length < 1 || !datosJugadas || datosJugadas.length < 1) { throw new Error('faltan datos!') }

      console.debug(`${fn}: ${datosCartas.length} cartas y ${datosJugadas.length} jugadas...`)

      const puntosSp = puntosPorSprint(datosCartas, datosJugadas);
      if (!puntosSp) { throw new Error('puntosSp volvio vacio!') }

      const data_BMI = {
        title: 'BMI', data: {
          'Trends': puntosSp.Trends || 0,
          'Sustainability': puntosSp.Sustainability || 0,
          'Patterns': puntosSp.Patterns || 0,
          'BlueOcean': puntosSp.BlueOcean || 0
        }
      }

      const data_Customer = {
        title: 'CUSTOMER EXPERIENCE', data: {
          'DigitalDrivers': puntosSp.DigitalDrivers || 0,
          'Test': puntosSp.Test || 0,
          'Platform': puntosSp.Platform || 0,
          'CustomerJourney': puntosSp.CustomerJourney || 0
        }
      }

      const data_digital = {
        title: 'DIGITAL TRANSFORMATION', data: {
          'Ecosystem': puntosSp.Ecosystem || 0,
          'Roadmap': puntosSp.Roadmap || 0,
          'Metrics': puntosSp.Metrics || 0,
          'DataDriven': puntosSp.DataDriven || 0
        }
      }

      const dataSets = [data_BMI, data_Customer, data_digital];

      console.debug(`${fn}: data es ${JSON.stringify(dataSets)}`)
      return dataSets

    } catch (error) {
      const msg = `${fn} error: ${error.message}`
      console.error(msg)
      return null
    }
  }

  ///////////////
  /* BLUEOCEAN */
  ///////////////

  function extractValuesCarta(data) {
    return data.map(({ id, nombre_es, blueocean }) => ({ id, nombre_es, blueocean_viejo: blueocean }));
  }
  function extractValuesJugada(data) {
    return data.map(({ carta_id, blueocean }) => ({ id: carta_id, blueocean_nuevo: blueocean }));
  }

  const sortOrderBlue =
    [
      'Jewelry',
      'Marcas conocidas', //esp
      'Productos diseñador', //esp
      'Velocidad Checkout', //esp
      'Coffee Shop',
      'Playground',
      'Plataforma Digital',  //esp
      'BlockChain',
      'Experencial',  //esp
      'Tienda de concepto', //esp
      'Clickear', //esp
      'Sucripcion', //esp
      'Algoritmos', //esp
      'Pago sin contacto', //esp
      'Tiendas sustentables', //esp
      'Campaña publicitaria por TV', //esp
      'QR code',
      'marketing digital', //esp
      'Campaña de descuentos', //esp
      'Realidad Aumentada', //esp
      'Algoritmos personalizados', //esp
      'Localization',
      'Social Shopping',
      'In-Store Mobile Commerce',
      'Small-Format Stores',
      'Store credit cards',
      'Shop in shop',
      'Fast Fashion',
      'Clothes Share',
      'Pick up in store',
      'Stores as fullfilment centers',
      'Mesa logística', //esp
    ]
  const valoresDefaultBlue = [5, 7, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 5, 0, 0, 2, 3, 0, 2, 0, 3, 0, 0]

  function updateDefaultArray(data, nombres, defaultArray) {
    const nuevoArray = [...defaultArray]; // Create a copy of defaultArray to avoid modifying the original

    data.forEach((item) => {
      const index = nombres.indexOf(item.nombre_es);
      if (index !== -1) {
        nuevoArray[index] = item.blueocean_nuevo;
      } else {
        console.error(`blue ocean updateDefaultArray no encuentra a ${item.nombre_es}`);
      }
    });

    if (nuevoArray.length !== defaultArray.length) {
      console.error(`blue ocean update array no funciono`);
      return defaultArray; // Return the original array in case of an issue
    }

    return nuevoArray;
  }

  function combineArraysBO(cartas, jugadas) {
    return cartas.map((carta) => {
      const matchingJugada = jugadas.find((jugada) => jugada.id === carta.id);
      if (matchingJugada) {
        return {
          ...carta,
          blueocean_nuevo: matchingJugada.blueocean_nuevo,
        };
      }
      return carta;
    });
  }

  function armarBlueOcean(datosCartas, datosJugadas) {

    if (!datosJugadas || !datosCartas) { return null }

    // traer datos de jugadas
    const jugadasBlueOcean = datosJugadas.filter(x => x && x.mazo === "BlueOcean");
    const cartasBlueOcean = datosCartas.filter(x => x && x.mazo === "BlueOcean");
    //console.debug(`armando jugadas blue ocean con ${JSON.stringify(jugadasBlueOcean)}`)

    if (!jugadasBlueOcean || jugadasBlueOcean.length === 0) { return null }
    //console.debug(`jugadas blue ocean son ${JSON.stringify(jugadasBlueOcean)}`)

    // filtrarlo a solo nombre_es y blueocean
    const cartaFilt = extractValuesCarta(cartasBlueOcean)
    const jugadasFilt = extractValuesJugada(jugadasBlueOcean)
    //console.debug(`blue ocean filt, cartas son ${JSON.stringify(cartaFilt)}, jugadas son ${JSON.stringify(jugadasFilt)}`)

    // matchear la carta a la jugada
    var arrayBOfinal = combineArraysBO(cartaFilt, jugadasFilt)

    //console.debug(`jugadas filt blue ocean son ${JSON.stringify(arrayBOfinal)}`)

    // armar valores iniciales que vamos a sobreescribir
    var db = valoresDefaultBlue

    // sorteado y convertido a un array de solo valores blue ocean
    db = updateDefaultArray(arrayBOfinal, sortOrderBlue, db)
    console.debug(`blue ocean: db es ${db}, defaults son ${valoresDefaultBlue}, hubo cambio? ${db !== valoresDefaultBlue}`)


    const dataBlueOcean = {
      labels: [
        'final.LabelsBlueOcean.Jewelry',
        'final.LabelsBlueOcean.Marcas conocidas',
        'final.LabelsBlueOcean.Productos diseñador',
        'final.LabelsBlueOcean.Velocidad Checkout',
        'final.LabelsBlueOcean.Coffee Shop',
        'final.LabelsBlueOcean.Playground',
        'final.LabelsBlueOcean.Plataforma Digital',
        'final.LabelsBlueOcean.BlockChain',
        'final.LabelsBlueOcean.Experencial',
        'final.LabelsBlueOcean.Tienda de concepto',
        'final.LabelsBlueOcean.Clickear',
        'final.LabelsBlueOcean.Sucripcion',
        'final.LabelsBlueOcean.Algoritmos',
        'final.LabelsBlueOcean.Pago sin contacto',
        'final.LabelsBlueOcean.Tiendas sustentables',
        'final.LabelsBlueOcean.Campaña publicitaria por TV',
        'final.LabelsBlueOcean.QR code',
        'final.LabelsBlueOcean.marketing digital',
        'final.LabelsBlueOcean.Campaña de descuentos',
        'final.LabelsBlueOcean.Realidad Aumentada',
        'final.LabelsBlueOcean.Algoritmos personalizados',
        'final.LabelsBlueOcean.Localization',
        'final.LabelsBlueOcean.Social Shopping',
        'final.LabelsBlueOcean.In-Store Mobile Commerce',
        'final.LabelsBlueOcean.Small-Format Stores',
        'final.LabelsBlueOcean.Store credit cards',
        'final.LabelsBlueOcean.Shop in shop',
        'final.LabelsBlueOcean.Fast Fashion',
        'final.LabelsBlueOcean.Clothes Share',
        'final.LabelsBlueOcean.Pick up in store',
        'final.LabelsBlueOcean.Stores as fullfilment centers',
        'final.LabelsBlueOcean.Mesa logística',
      ],
      datasets: [
        {
          label: 'Valor Final',
          data: db, // datos de la DB
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
        },
        {
          label: 'Valor Inicial',
          data: valoresDefaultBlue,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    };

    const optionsBlueOcean = {
      scales: {
        y: {
          beginAtZero: true,
          suggestedMax: 7,
        },
      },
      elements: {
        line: {
          tension: 0.4 // Puedes ajustar este valor entre 0 y 1 para controlar la suavidad
        }
      }
    };

    return (
      <Col xs="12">
        <Card style={{ backgroundColor: '#FAFAFA' }}>
          <CardBody>
            <h2 style={{ color: '#005F73' }}>Blue Ocean</h2>
            <Line
              data={{
                ...dataBlueOcean,
                labels: dataBlueOcean.labels.map(label => t(label))
              }}
              options={optionsBlueOcean}
            />
          </CardBody>
        </Card>
      </Col>
    )

  }

  ///////////////
  /* ECOSYSTEM */
  ///////////////

  // arma los datos para Ecosystem
  async function armarEcosystem(datosEquipo) {
    const fn = `TEST ${f}/armarEcosystem`
    console.debug(`${fn}...`)
    try {
      if (!datosEquipo || !datosEquipo.ecosistema_dinero_orig) { throw new Error('faltan datos!') }

      const orig = Number(datosEquipo.ecosistema_dinero_orig)
      const mod = Math.floor(datosEquipo.ecosistema_dinero_mod) // MAQUETA: Math.floor(orig + cambio) 
      const cambio = Math.floor(mod - orig) // MAQUETA: Math.floor(orig * Math.random()) 
      const oferta = datosEquipo.ecosistema_oferta
      const propuesta = datosEquipo.ecosistema_propuesta_valor

      console.debug(`${fn}: circulo sin parse es ${JSON.stringify(datosEquipo.ecosistema_circulo)}, tipo ${typeof datosEquipo.ecosistema_circulo}`)
      var circulo = datosEquipo.ecosistema_circulo.replace(/'/g, '"').replace(/true/g, 'true') || {};
      circulo = JSON.parse(circulo)
      console.debug(`${fn}: circulo con parse es ${JSON.stringify(circulo)}, tipo ${typeof circulo}`)

      const ecosystem = {
        "orig": orig,
        "mod": mod,
        "cambio": cambio,
        "oferta": oferta,
        "propuesta": propuesta,
        "circulo": circulo
      }

      console.debug(`${fn}: data es ${JSON.stringify(ecosystem)}`)
      return { success: true, data: ecosystem }

    } catch (error) {
      const msg = `${fn} error: ${error.message}`
      console.error(msg)
      return { success: false, error: error.message }
    }
  }

  // maqueta x si hace falta
  //ecosystem_val.cambio = Math.floor(ecosystem_val.orig * Math.random())
  //ecosystem_val.mod = Math.floor(ecosystem_val.orig + ecosystem_val.cambio)

  /////////////////////
  /* SUSTENTABILIDAD */
  /////////////////////

  // arma los datos para Sustentabilidad
  function armarSustentabilidad(datosCartas, datosJugadas) {
    const fn = `TEST ${f}/armarSustentabilidad`
    console.debug(`${fn}...`)
    try {
      if (!datosJugadas || datosJugadas.length < 1) { throw new Error('faltan datos!') }

      const economico = sumarColumnaSprint(datosCartas, datosJugadas, "Sustainability", "sustentabilidad_economico")
      const social = sumarColumnaSprint(datosCartas, datosJugadas, "Sustainability", "sustentabilidad_social")
      const ecologico = sumarColumnaSprint(datosCartas, datosJugadas, "Sustainability", "sustentabilidad_ecologico")

      const sustentabilidad = [economico, social, ecologico]

      console.debug(`${fn}: data es ${JSON.stringify(sustentabilidad)}`)
      return { success: true, data: sustentabilidad }

    } catch (error) {
      const msg = `${fn} error: ${error.message}`
      console.error(msg)
      return { success: false, error: error.message }
    }
  }

  ///////////////
  /*  VIRTUAL  */
  ///////////////

  var droppedNames
  if (droppedCards) {
    droppedNames = Object.values(droppedCards)
      .filter(x => x !== undefined && x !== null)
      .flat()
      .map(card => card.name);
  }

  // canvas
  function CanvasFinal(canvas) {

    // determinar cuales recuadros devolver segun el canvas
    // platform, datadriven y blue ocean tienen sprints propios

    // 1, talleres
    if (canvas === "canvasPostit") {
      console.debug(`armando canvas 1...`)
      return (
        <>
          {arranqueListo ? (
            <p>Presiona para comenzar!</p>
          ) : (
            <>
              <DndProvider backend={HTML5Backend}>
                <Row>
                  <Col xs="9">
                    <div style={{ display: 'flex', flexDirection: 'row', margin: '0' }}>
                      <DropZone recuadro={"1"} droppedCard={droppedCards["1"]} style={{
                        backgroundImage: `url(${recuadro1SociosClaves})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        overflowY: 'auto',
                        maxHeight: '400px'
                      }} />
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '0' }}>
                        <DropZone recuadro={"2"} droppedCard={droppedCards["2"]} style={{
                          height: '50%',
                          margin: '0',
                          backgroundImage: `url(${recuadro1ActividadesClaves})`,
                          backgroundSize: '100% 100%',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          overflowY: 'auto',
                          maxHeight: '200px'
                        }} />
                        <DropZone recuadro={"3"} droppedCard={droppedCards["3"]} style={{
                          height: '50%',
                          backgroundImage: `url(${recuadro1RecursosClaves})`,
                          backgroundSize: '100% 100%',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          overflowY: 'auto',
                          maxHeight: '200px'
                        }} />
                      </div>
                      <DropZone recuadro={"4"} droppedCard={droppedCards["4"]} style={{
                        backgroundImage: `url(${recuadro1PropuestaDeValor})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        overflowY: 'auto',
                        maxHeight: '400px'
                      }} />
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '0' }}>
                        <DropZone recuadro={"5"} droppedCard={droppedCards["5"]} style={{
                          height: '50%',
                          backgroundImage: `url(${recuadro1RelacionesConClientes})`,
                          backgroundSize: '100% 100%',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          overflowY: 'auto',
                          maxHeight: '200px'
                        }} />
                        <DropZone recuadro={"6"} droppedCard={droppedCards["6"]} style={{
                          height: '50%',
                          backgroundImage: `url(${recuadro1Canales})`,
                          backgroundSize: '100% 100%',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          overflowY: 'auto',
                          maxHeight: '200px'
                        }} />
                      </div>
                      <DropZone recuadro={"7"} droppedCard={droppedCards["7"]} style={{
                        backgroundImage: `url(${recuadro1SegmentosDeCliente})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                      }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', height: '35%' }}>
                      <DropZone recuadro={"8"} droppedCard={droppedCards["8"]} style={{
                        backgroundImage: `url(${recuadro1EstructuraDeCostos})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                      }} />
                      <DropZone recuadro={"9"} droppedCard={droppedCards["9"]} style={{
                        backgroundImage: `url(${recuadro1FlujosDeIngreso})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                      }} />
                    </div>
                  </Col>
                </Row>
              </DndProvider>
            </>
          )
          }
        </>
      );
    }

    // 2
    if (canvas === "canvasEnBlanco") {
      console.debug(`armando canvas 2...`)
      return (
        <>
          {arranqueListo ? (
            <p>Presiona para comenzar!</p>
          ) : (
            <>
              <DndProvider backend={HTML5Backend}>
                <Row>
                  <Col xs="9">
                    <div style={{ display: 'flex', flexDirection: 'row', margin: '0' }}>
                      <DropZone recuadro={"10"} droppedCard={droppedCards["10"]} style={{
                        backgroundImage: `url(${recuadro2SociosClaves})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        overflowY: 'auto',
                        maxHeight: '400px'
                      }} />
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '0' }}>
                        <DropZone recuadro={"11"} droppedCard={droppedCards["11"]} style={{
                          height: '50%',
                          margin: '0',
                          backgroundImage: `url(${recuadro2ActividadesClaves})`,
                          backgroundSize: '100% 100%',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          overflowY: 'auto',
                          maxHeight: '200px'
                        }} />
                        <DropZone recuadro={"12"} droppedCard={droppedCards["12"]} style={{
                          height: '50%',
                          backgroundImage: `url(${recuadro2RecursosClaves})`,
                          backgroundSize: '100% 100%',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          overflowY: 'auto',
                          maxHeight: '200px'
                        }} />
                      </div>
                      <DropZone recuadro={"13"} droppedCard={droppedCards["13"]} style={{
                        backgroundImage: `url(${recuadro2PropuestaDeValor})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        overflowY: 'auto',
                        maxHeight: '400px'
                      }} />
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '0' }}>
                        <DropZone recuadro={"14"} droppedCard={droppedCards["14"]} style={{
                          height: '50%',
                          backgroundImage: `url(${recuadro2RelacionesConClientes})`,
                          backgroundSize: '100% 100%',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          overflowY: 'auto',
                          maxHeight: '200px'
                        }} />
                        <DropZone recuadro={"15"} droppedCard={droppedCards["15"]} style={{
                          height: '50%',
                          backgroundImage: `url(${recuadro2Canales})`,
                          backgroundSize: '100% 100%',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          overflowY: 'auto',
                          maxHeight: '200px'
                        }} />
                      </div>
                      <DropZone recuadro={"16"} droppedCard={droppedCards["16"]} style={{
                        backgroundImage: `url(${recuadro2SegmentosDeCliente})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                      }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', height: '35%' }}>
                      <DropZone recuadro={"17"} droppedCard={droppedCards["17"]} style={{
                        backgroundImage: `url(${recuadro2EstructuraDeCostos})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                      }} />
                      <DropZone recuadro={"18"} droppedCard={droppedCards["18"]} style={{
                        backgroundImage: `url(${recuadro2FlujosDeIngreso})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                      }} />
                    </div>
                  </Col>
                </Row>
              </DndProvider>
            </>
          )
          }
        </>
      );
    }

    // 3
    if (canvas === "canvasB2B") {
      console.debug(`armando canvas 3...`)
      return (
        <>
          {arranqueListo ? (
            <p>Presiona para comenzar!</p>
          ) : (
            <>
              <DndProvider backend={HTML5Backend}>
                <Row>
                  <Col xs="9">
                    <div style={{ display: 'flex', height: '100%', margin: '0', width: '100%', maxHeight: '800px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', margin: '0', width: '50%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', margin: '1px', width: '100%' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', width: '33%', margin: '0' }}>
                            <DropZone recuadro={"19"} droppedCard={droppedCards["19"]} style={{
                              backgroundImage: `url(${recuadro3SociosClaves})`,
                              backgroundSize: '100% 100%',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              overflowY: 'auto',
                              maxHeight: '400px',
                            }} />
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', width: '33%', margin: '0' }}>
                            <DropZone recuadro={"20"} droppedCard={droppedCards["20"]} style={{
                              height: '50%',
                              margin: '0',
                              backgroundImage: `url(${recuadro3ActividadesClaves})`,
                              backgroundSize: '100% 100%',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              overflowY: 'auto',
                              maxHeight: '200px'
                            }} />
                            <DropZone recuadro={"21"} droppedCard={droppedCards["21"]} style={{
                              height: '50%',
                              backgroundImage: `url(${recuadro3RecursosClaves})`,
                              backgroundSize: '100% 100%',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              overflowY: 'auto',
                              maxHeight: '200px'
                            }} />
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', width: '33%', margin: '0' }}>
                            <DropZone recuadro={"22"} droppedCard={droppedCards["22"]} style={{
                              backgroundImage: `url(${recuadro3PropuestaDeValor})`,
                              backgroundSize: '100% 100%',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              overflowY: 'auto',
                              maxHeight: '400px',
                            }} />

                          </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', margin: '0', width: '100%' }}>
                          <DropZone recuadro={"26"} droppedCard={droppedCards["26"]} style={{
                            backgroundImage: `url(${recuadro3EstructuraDeCostos})`,
                            backgroundSize: '100% 100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            maxHeight: '200px'
                          }} />
                        </div>
                      </div>

                      <div style={{ display: 'flex', flexDirection: 'row', height: '100%', margin: '0', width: '50%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '66%', margin: '0' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', margin: '0' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '0' }}>
                              <DropZone recuadro={"23"} droppedCard={droppedCards["23"]} style={{
                                height: '50%',
                                backgroundImage: `url(${recuadro3RelacionesConClientes})`,
                                backgroundSize: '100% 100%',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                overflowY: 'auto',
                                maxHeight: '200px'
                              }} />
                              <DropZone recuadro={"24"} droppedCard={droppedCards["24"]} style={{
                                height: '50%',
                                backgroundImage: `url(${recuadro3Canales})`,
                                backgroundSize: '100% 100%',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                overflowY: 'auto',
                                maxHeight: '200px'
                              }} />
                            </div>
                            <DropZone recuadro={"25"} droppedCard={droppedCards["25"]} style={{
                              backgroundImage: `url(${recuadro3SegmentosDeCliente})`,
                              backgroundSize: '100% 100%',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              maxHeight: '400px'
                            }} />
                          </div>

                          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <DropZone recuadro={"27"} droppedCard={droppedCards["27"]} style={{
                              backgroundImage: `url(${recuadro3FlujosDeIngreso})`,
                              backgroundSize: '100% 100%',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                            }} />
                          </div>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '33%', margin: '0' }}>
                          <DropZone recuadro={"28"} droppedCard={droppedCards["28"]} style={{
                            backgroundImage: `url(${recuadro3Consumidores})`,
                            backgroundSize: '100% 100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                          }} />
                          <DropZone recuadro={"29"} droppedCard={droppedCards["29"]} style={{
                            backgroundImage: `url(${recuadro3Influencer})`,
                            backgroundSize: '100% 100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                          }} />
                          <DropZone recuadro={"30"} droppedCard={droppedCards["30"]} style={{
                            backgroundImage: `url(${recuadro3Intermediarios})`,
                            backgroundSize: '100% 100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            height: '120%'
                          }} />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </DndProvider>
            </>
          )
          }
        </>
      );
    }

    // 4
    if (selectedCanvas === "canvasNavigator") {
      console.debug(`armando canvas 4...`)
      return (
        <>
          {arranqueListo ? (
            <p>Presiona para comenzar!</p>
          ) : (
            <>
              <DndProvider backend={HTML5Backend}>
                <Row>
                  <Col xs="9">
                    <div style={{ display: 'flex', flexDirection: 'row', margin: '0' }}>
                      <DropZone recuadro={"31"} droppedCard={droppedCards["31"]} style={{
                        backgroundImage: `url(${recuadro4Que})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        overflowY: 'auto',
                        maxHeight: '400px'
                      }} />
                      <DropZone recuadro={"35"} droppedCard={droppedCards["35"]} style={{
                        height: '50%',
                        backgroundImage: `url(${recuadro4Propuesta})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        overflowY: 'auto',
                        maxHeight: '200px'
                      }} />
                      <DropZone recuadro={"33"} droppedCard={droppedCards["33"]} style={{
                        height: '50%',
                        backgroundImage: `url(${recuadro4Quien})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        overflowY: 'auto',
                        maxHeight: '200px'
                      }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: '0' }}>
                      <DropZone recuadro={"32"} droppedCard={droppedCards["32"]} style={{
                        height: '50%',
                        margin: '0',
                        backgroundImage: `url(${recuadro4Porque})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        overflowY: 'auto',
                        maxHeight: '200px'
                      }} />

                      <DropZone recuadro={"36"} droppedCard={droppedCards["36"]} style={{
                        height: '50%',
                        backgroundImage: `url(${recuadro4Captura})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        overflowY: 'auto',
                        maxHeight: '200px'
                      }} />

                      <DropZone recuadro={"37"} droppedCard={droppedCards["37"]} style={{
                        backgroundImage: `url(${recuadro4Creacion})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                      }} />
                      <DropZone recuadro={"34"} droppedCard={droppedCards["34"]} style={{
                        backgroundImage: `url(${recuadro4Como})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        overflowY: 'auto',
                        maxHeight: '400px'
                      }} />
                    </div>
                  </Col>
                </Row>
              </DndProvider>
            </>
          )
          }
        </>
      );
    }


  }

  // canvas data driven virtual final
  function SprintDataDrivenVirtualFinal() {

    return (
      <>
        {arranqueListo ? (
          <p>Presiona para comenzar!</p>
        ) : (
          <>
            <DndProvider backend={HTML5Backend}>
              <Row>
                <Col xs="9">
                  <div style={{ display: 'flex', flexDirection: 'row', margin: '0', minHeight: '600px' }}>
                    <DropZone recuadro={"38"} droppedCard={droppedCards["38"]} style={{
                      backgroundImage: `url(${recuadro5DataSources})`,
                      backgroundSize: '100% 100%',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      overflowY: 'auto',
                      maxHeight: '800px'
                    }} />
                    <DropZone recuadro={"39"} droppedCard={droppedCards["39"]} style={{
                      backgroundImage: `url(${recuadro5Analytics})`,
                      backgroundSize: '100% 100%',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      overflowY: 'auto',
                      maxHeight: '800px'
                    }} />
                    <DropZone recuadro={"40"} droppedCard={droppedCards["40"]} style={{
                      backgroundImage: `url(${recuadro5ProductOrService})`,
                      backgroundSize: '100% 100%',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      overflowY: 'auto',
                      maxHeight: '800px'
                    }} />
                    <DropZone recuadro={"41"} droppedCard={droppedCards["41"]} style={{
                      backgroundImage: `url(${recuadro5Benefit})`,
                      backgroundSize: '100% 100%',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      overflowY: 'auto',
                      maxHeight: '800px'
                    }} />
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '0', width: '100%' }}>
                      <DropZone recuadro={"42"} droppedCard={droppedCards["42"]} style={{
                        backgroundImage: `url(${recuadro5ImplicationsMonetization})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        overflowY: 'auto',
                        height: '300px',
                      }} />
                      <DropZone recuadro={"43"} droppedCard={droppedCards["43"]} style={{
                        height: '50%',
                        backgroundImage: `url(${recuadro5ImplicationsCosts})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        overflowY: 'auto',
                        height: '300px',
                      }} />
                    </div>
                  </div>
                </Col>
              </Row>
            </DndProvider>
          </>
        )
        }
      </>
    );

  }

  ///////////////////////////////
  /*   certificado y puntajes  */
  ///////////////////////////////

  const generarPDF = async (event) => {
    event.preventDefault()
    console.debug(`generando PDF...`)
    const output = await DownloadCertificate(nombre, institucion, fecha)
    if (!output) { return null }
    else { return output }
  }

  const downloadPdfDocument = () => {
    const input = document.getElementById('contenidoParaPdf');
    if (!input) return;

    const pdf = new jsPDF('p', 'pt', 'a4');
    const margin = 20;
    const marginTop = 30;

    html2canvas(input, {
      scale: 2,
      width: input.scrollWidth,
      height: input.scrollHeight,
      windowWidth: input.scrollWidth,
      windowHeight: input.scrollHeight,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const imgWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
      const imgHeight = canvas.height * imgWidth / canvas.width;

      let heightLeft = imgHeight;
      let position = marginTop;

      pdf.addImage(imgData, 'JPEG', margin, position, imgWidth, imgHeight);
      heightLeft -= pdf.internal.pageSize.getHeight();

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight + marginTop;
        pdf.addPage();
        pdf.addImage(imgData, 'JPEG', margin, position, imgWidth, imgHeight);
        heightLeft -= pdf.internal.pageSize.getHeight();
      }
      pdf.save("Resultados.pdf");
    });
  }

  ///////////////
  /*   RENDER  */
  ///////////////

  // row 0 : premios (superar mil puntos, terminar primero, etc)
  // row 1 : porter, amit and zott
  // row 1a: ecosystem (MOVER)
  // row 2 : blue ocean (si se jugo), value wheel
  // row 3 : las 9 tarjetas de metricas 1
  // row 4 : renderiza cartas que jugo el equipo

  return (
    <div
      id="contenidoParaPdf"
      className="content" style={{
        backgroundImage: `url(${img})`, // Aplicar la imagen de fondo importada
        backgroundSize: 'cover', // Opcional, ajusta el tamaño de la imagen de fondo
        backgroundRepeat: 'repeat', // Opcional, controla la repetición de la imagen
      }}>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <h1>{t('final.titulo')}</h1>
              <h3>
                {nombre ? (nombre) : (null)}<br></br>
                {institucion ? (institucion) : (null)}<br></br>
                {fecha ? (fecha) : (null)}<br></br>
              </h3>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {loading ? (
        <p>{t('final.cargandoRes')}</p>
      ) : (
        <>
          {/* IMPRIMIR RESULTADO Y CERTIFICADO */}
          <Row>
            <Col xs="12">
              <Card style={{ backgroundColor: '#003366' }}>
                <CardBody>
                  <h2 style={{ color: '#E8E8E8' }}>{t('certificado.generar')}</h2>

                  <Button onClick={e => generarPDF(e)} style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px' }}>
                    {t('certificado.generarCertificado')}
                  </Button>
                  <Button onClick={downloadPdfDocument} style={{ backgroundColor: '#28A745', borderColor: '#004080', borderWidth: '2px', marginLeft: '10px' }}>
                    {t('certificado.generarResultados')}
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* MEDALLAS */}
          <Row>
            <Col xs="4">
              <Card>
                <CardBody>
                  <h2 style={{ fontSize: '50px' }}>{t('final.top3')}</h2>
                  <h3 style={{ fontSize: '30px' }}>{t('final.puntosGanados')}</h3>
                  {topTres ? (
                    <div>
                      {topTres[0] && <p style={styles.primerPuesto}>{topTres[0]["nombre"]} 🥇</p>}
                      {topTres[1] && <p style={styles.primerPuesto}>{topTres[1]["nombre"]} 🥈</p>}
                      {topTres[2] && <p style={styles.primerPuesto}>{topTres[2]["nombre"]} 🥉</p>}
                    </div>
                  ) : (
                    <div>
                      <p style={styles.primerPuesto}>Team 2 🥇</p>
                      <p style={styles.segundoPuesto}>Team 1 🥈</p>
                      <p style={styles.tercerPuesto}>Team 3 🥉</p>
                    </div>
                  )
                  }
                  {/*
									<h3 style={{ fontSize: '30px' }}>{t('final.mejora_total')}</h3>
									{topTresMejora ? (
										<div>
											{topTresMejora[0] && <p style={styles.primerPuesto}>{topTresMejora[0]["nombre"]} 🥇</p>}
											{topTresMejora[1] && <p style={styles.primerPuesto}>{topTresMejora[1]["nombre"]} 🥈</p>}
											{topTresMejora[2] && <p style={styles.primerPuesto}>{topTresMejora[2]["nombre"]} 🥉</p>}
										</div>
									) : (
										<div>
											<p style={styles.primerPuesto}>Team 2 🥇</p>
											<p style={styles.segundoPuesto}>Team 1 🥈</p>
											<p style={styles.tercerPuesto}>Team 3 🥉</p>
										</div>
									)*/}
                  <h3 style={{ fontSize: '30px' }}>{t('porter.mejora')}</h3>
                  {topTresPorter ? (
                    <div>
                      {topTresPorter[0] && <p style={styles.primerPuesto}>{topTresPorter[0]["nombre"]} 🥇</p>}
                      {topTresPorter[1] && <p style={styles.primerPuesto}>{topTresPorter[1]["nombre"]} 🥈</p>}
                      {topTresPorter[2] && <p style={styles.primerPuesto}>{topTresPorter[2]["nombre"]} 🥉</p>}
                    </div>
                  ) : (
                    <div>
                      <p style={styles.primerPuesto}>Team 2 🥇</p>
                      <p style={styles.segundoPuesto}>Team 1 🥈</p>
                      <p style={styles.tercerPuesto}>Team 3 🥉</p>
                    </div>
                  )}
                  <h3 style={{ fontSize: '30px' }}>{t('amit.mejora')}</h3>
                  {topTresAmit ? (
                    <div>
                      {topTresAmit[0] && <p style={styles.primerPuesto}>{topTresAmit[0]["nombre"]} 🥇</p>}
                      {topTresAmit[1] && <p style={styles.primerPuesto}>{topTresAmit[1]["nombre"]} 🥈</p>}
                      {topTresAmit[2] && <p style={styles.primerPuesto}>{topTresAmit[2]["nombre"]} 🥉</p>}
                    </div>
                  ) : (
                    <div>
                      <p style={styles.primerPuesto}>Team 2 🥇</p>
                      <p style={styles.segundoPuesto}>Team 1 🥈</p>
                      <p style={styles.tercerPuesto}>Team 3 🥉</p>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xs="4" style={{ fontSize: '22px' }}>
              <Card>
                <CardBody>
                  <h2 style={{ fontSize: '50px' }}>{t('final.puntosGanados')}</h2>

                  {puntosGanados ? (
                    <Velocimetro value={puntosGanados} />
                  ) : (null)
                  }

                  {mejoraGanada ? (
                    <div>
                      <p>{t('porter.mejora')}: {mejoraGanada.porter}%</p>
                      <p>{t('amit.mejora')}: {mejoraGanada.amit}%</p>
                    </div>
                  ) : (null)
                  }

                </CardBody>
              </Card>
            </Col>
            <Col xs="4">
              <Card>
                <CardBody>
                  <h2>{t('final.Medallas')}</h2>
                  <h3 style={{ marginTop: "5px" }}>{t('final.Puntaje')}</h3>
                  {cucardonesPuntos}
                  <h3 style={{ marginTop: "5px" }}>{t('final.Quizzes')}</h3>
                  {cucardonesQuizzes}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* ECOSYSTEM */}
          {sprintsJugados.includes("Ecosystem") && ecosystem_val && ecosystem_val.mod ? (
            <div>
              <Row>
                <Col xs="4">
                  <Card>
                    <CardBody>
                      <h2>{t('ecosystem.resultado')}</h2>
                      <h3 style={{ marginTop: "5px" }}>{t('ecosystem.dinero_orig')}: ${ecosystem_val.orig}</h3>
                      <h3 style={{ marginTop: "5px" }}>{t('ecosystem.dinero_cambio')}: ${ecosystem_val.cambio}</h3>
                      <h3 style={{ marginTop: "5px" }}>{t('ecosystem.dinero_mod')}: ${ecosystem_val.mod}</h3>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="4">
                  <Card>
                    <CardBody>
                      <h2>{t('ecosystem.oferta')}</h2>
                      <h3 style={{ marginTop: "5px" }}>{ecosystem_val.oferta}</h3>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="4">
                  <Card>
                    <CardBody>
                      <h2>{t('ecosystem.propuesta')}</h2>
                      <h3 style={{ marginTop: "5px" }}>{ecosystem_val.propuesta}</h3>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="8">
                  <Card style={{ backgroundColor: '#003366', padding: "50px" }}>
                    <CardBody>
                      <h1>ECOSYSTEM: LIFE AREAS</h1>
                      <ConcentricClickableSectors get={ecosystem_val.circulo} />
                      {
                        //ConcentricClickableSectors (ecosystem_val.circulo)
                      }
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="12" md="4">
                  <Card style={{ padding: "50px" }}>
                    <CardBody>
                      <h3>Círculo Externo: Facilitador</h3>
                      <h3>Círculo Medio: Realizador</h3>
                      <h3>Círculo Interno: Orquestador</h3>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : null}

          {/* PORTER, AMIT, ZOTT */}
          <Row>
            {/* PORTER */}
            <Col xs="6">
              <Card style={{ backgroundColor: '#FAFAFA' }}>
                <CardBody>
                  <h2 style={{ color: '#2c2c2c' }}>{t('porter.label')}</h2>
                  <div id="radar-chart-container" style={{ textAlign: "center" }}>
                    <div style={{ width: "400px", margin: "0 auto" }}>
                      <Radar data={data2} options={radarOptions} />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* AMIT & ZOTT */}
            <Col xs="6">
              <Card style={{ backgroundColor: '#FAFAFA' }}>
                <CardBody>
                  <h2 style={{ color: '#2c2c2c' }}>{t('amit.label')}</h2>
                  <div id="radar-chart-container" style={{ textAlign: "center" }}>
                    <div style={{ width: "400px", margin: "0 auto" }}>
                      <Radar data={data} options={radarOptions} />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* DIGITAL MATURITY */}
          {dataDataMaturity ? (
            <Row>
              <Col xs="12">
                <Card style={{ backgroundColor: '#FAFAFA' }}>
                  <CardBody>
                    <h2 style={{ color: '#005F73' }}>{t('Data_Maturity.label')}</h2>
                    <div id="radar-chart-container" style={{ textAlign: "center" }}>
                      <div style={{ width: "400px", margin: "0 auto" }}>
                        <Radar data={dataDataMaturity} options={radarOptions} />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}

          {/* VALUE WHEEL */}
          <Row>
            {/* VALUE WHEEL */}
            <Col xs="12">
              <Card style={{ backgroundColor: '#FAFAFA' }}>
                <CardBody>
                  <h2 style={{ color: '#005F73' }}>{t('valwheel.label')}</h2>
                  <Bar data={data22} options={options22} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* 3 GRAFICOS Digital Innovation Measurement */}
          {dimData ? (
            <Row>
              <Col xs="12">
                <Card style={{ backgroundColor: '#FAFAFA', padding: "50px" }}>
                  <CardBody>
                    <ChartDIM dataSets={dimData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (null)}

          {/* BLUE OCEAN */}
          <Row>
            {/* BLUE OCEAN */}
            {/* solo si se hizo blue ocean */}
            {sprintsJugados.includes("BlueOcean") ? (
              armarBlueOcean(cartas, jugadas)
            ) : null}
          </Row>

          {/* SUSTAINABILITY */}
          {sprintsJugados.includes("Sustainability") && sustainData ? (
            <Row>
              <Col xs="12">
                <Card style={{ backgroundColor: '#FFFFFF', padding: "50px" }}>
                  <CardBody>
                    <h2 style={{ color: '#005F73' }}>{t('sustainability.label')}</h2>
                    <BarChartComponent dataValues={sustainData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}

          {/* METRICAS 1 */}
          {/* son 9 */}
          <Row>
            <Col lg="4">
              <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                <CardHeader style={{ textAlign: 'center' }}>
                  <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                    {t('metricas.trafico')}
                  </h1>
                  <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                    {trafico}
                  </CardTitle>
                  <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                    235000
                  </CardTitle>
                </CardHeader>
                <CardBody style={{ textAlign: 'center' }}>
                  <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.traficoDesc')}
                  </h5>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                <CardHeader style={{ textAlign: 'center' }}>
                  <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                    {t('metricas.conversion')}
                  </h1>
                  <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                    {parseFloat(TablaMetricas[9][2].replace('%', '')).toFixed(2)}%
                  </CardTitle>
                  <CardTitle tag="h4" style={{ color: '#000000', fontWeight: '550' }}>
                    1.00%
                  </CardTitle>
                </CardHeader>
                <CardBody style={{ textAlign: 'center' }}>
                  <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.conversionDesc')}
                  </h5>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                <CardHeader style={{ textAlign: 'center' }}>
                  <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                    {t('metricas.unique')}
                  </h1>
                  <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                    {visitors}
                  </CardTitle>
                  <CardTitle tag="h4" style={{ color: '#000000', fontWeight: '550' }}>
                    100000
                  </CardTitle>
                </CardHeader>
                <CardBody style={{ textAlign: 'center' }}>
                  <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.uniqueDesc')}
                  </h5>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                <CardHeader style={{ textAlign: 'center' }}>
                  <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                    {t('metricas.revenue')}
                  </h1>
                  <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                    {TablaMetricas[12][2]}
                  </CardTitle>
                  <CardTitle tag="h4" style={{ color: '#000000', fontWeight: '550' }}>
                    $ 0
                  </CardTitle>
                </CardHeader>
                <CardBody style={{ textAlign: 'center' }}>
                  <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.revenueDesc')}
                  </h5>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                <CardHeader style={{ textAlign: 'center' }}>
                  <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                    {t('metricas.lead')}
                  </h1>
                  <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                    {TablaMetricas[2][2]}
                  </CardTitle>
                  <CardTitle tag="h4" style={{ color: '#000000', fontWeight: '550' }}>
                    8:15:30
                  </CardTitle>
                </CardHeader>
                <CardBody style={{ textAlign: 'center' }}>
                  <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.leadDesc')}
                  </h5>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                <CardHeader style={{ textAlign: 'center' }}>
                  <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                    {t('metricas.NPS')}
                  </h1>
                  <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                    {TablaMetricas[15][2]}
                  </CardTitle>
                  <CardTitle tag="h4" style={{ color: '#000000', fontWeight: '550' }}>
                    20
                  </CardTitle>
                </CardHeader>
                <CardBody style={{ textAlign: 'center' }}>
                  <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.NPSDesc')}
                  </h5>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                <CardHeader style={{ textAlign: 'center' }}>
                  <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                    {t('metricas.ticketMedio')}
                  </h1>
                  <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                    {TablaMetricas[16][2]}
                  </CardTitle>
                  <CardTitle tag="h4" style={{ color: '#000000', fontWeight: '550' }}>
                    $ 238.89
                  </CardTitle>
                </CardHeader>
                <CardBody style={{ textAlign: 'center' }}>
                  <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.ticketMedioDesc')}
                  </h5>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                <CardHeader style={{ textAlign: 'center' }}>
                  <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                    {t('metricas.margen')}
                  </h1>
                  <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                    {TablaMetricas[18][2]}
                  </CardTitle>
                  <CardTitle tag="h4" style={{ color: '#000000', fontWeight: '550' }}>
                    $ 875.000
                  </CardTitle>
                </CardHeader>
                <CardBody style={{ textAlign: 'center' }}>
                  <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.margenDesc')}
                  </h5>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                <CardHeader style={{ textAlign: 'center' }}>
                  <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>
                    {t('metricas.cross')}
                  </h1>
                  <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>
                    {TablaMetricas[6][2]}
                  </CardTitle>
                  <CardTitle tag="h4" style={{ color: '#000000', fontWeight: '550' }}>
                    1
                  </CardTitle>
                </CardHeader>
                <CardBody style={{ textAlign: 'center' }}>
                  <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.crossDesc')}
                  </h5>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* CARTAS JUGADAS */}
          {/* for each sprint */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <h2>{t('final.cartasJugadas')}</h2>
                  {cartas ? (
                    cartas.map((carta, index) => {
                      const imageObj = images.find(img => img && img.name === carta.svg_front);
                      if (!imageObj) {
                        return null;
                      }
                      return (
                        <img
                          key={carta.svg_front}
                          src={imageObj.src}
                          alt={`Carta ${imageObj.name}`}
                          width="250"
                        //className={selectedCards.includes(imageObj.name) ? "cardEffect cardSelected" : "cardEffect"}
                        //onClick={() => handleCardClick(imageObj.name)}
                        />
                      );
                    })
                  ) : (
                    <p>Cargando cartas jugadas...</p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/*		CANVAS 	*/}
          {partidaVirtual ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <h2>Canvas</h2>
                    {jugadas ? (
                      CanvasFinal(selectedCanvas)
                    ) : (
                      <p>Cargando cartas jugadas...</p>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (null)}

          {/*		FRAMEWORK DATA DRIVEN		 */}
          {partidaVirtual && sprintsJugados.includes("DataDriven") ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <h2>{t('canvas.de')} Data Driven</h2>
                    {jugadas ? (
                      SprintDataDrivenVirtualFinal()
                    ) : (
                      <p>Cargando cartas jugadas en Data...</p>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (null)}


          {/*		FRAMEWORK PLATFORMS		 */}
          {partidaVirtual && sprintsJugados.includes("Platform") ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <h2>{t('canvas.de')} Platforms</h2>
                    {cartas ? (
                      <img
                        key={`canvasPlataform`}
                        src={canvasPlatform}
                        alt={`Canvas Platforms`}
                      />
                    ) : (
                      <p>{t('common.cargandoResultados')}</p>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (null)}
          {sprintsJugados.includes("Platform") ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <h2>{t('canvas.de')} Platforms</h2>
                    {cartas ? (
                      jugadasSprint(cartas, "Platform")
                    ) : (
                      <p>{t('common.cargandoResultados')}</p>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (null)}

          {/*		JUGADAS ECOSYSTEM		 */}
          {sprintsJugados.includes("Ecosystem") ? (
            <div>
              <Row>
                <Col xs="12" md="12">
                  <Card style={{ padding: "50px" }}>
                    <CardBody>
                      <EcosystemCanvas />
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col xs="12" md="12">
                  <Card style={{ padding: "50px" }}>
                    <CardBody>
                      <h2>{t('canvas.de')} Ecosystem</h2>
                      {cartas ? (
                        jugadasSprint(cartas, "Ecosystem")
                      ) : (
                        <p>{t('common.cargandoResultados')}</p>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (null)}
        </>
      )
      }

    </div >
  );
};

export default Final;
