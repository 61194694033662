import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import img from "../assets/img/fondo3.png";
import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    Input,
    FormGroup,
    Label
} from "reactstrap";
import { host } from "../variables/connection.js"
// ${host}

const ModificarPartidas = () => {
    const [partidas, setPartidas] = useState([]);
    const [selectedPartida, setSelectedPartida] = useState(null);
    const navigate = useNavigate();
    const [message, setMessage] = useState('');

    const estadoPartida = selectedPartida && selectedPartida.activo === 1 ? "Partida Activa" : "Partida Inactiva";

    useEffect(() => {
        fetchPartidas();
    }, []);

    const handlePartidaChange = (e) => {
        const partidaSeleccionada = partidas.find(partida => partida.nombre === e.target.value);
        if (partidaSeleccionada) {
            setSelectedPartida(partidaSeleccionada);
        } else {
            setSelectedPartida(null);
        }
    };

    const handleDelete = async () => {
        if (selectedPartida) {
            try {
                const response = await fetch(`https://${host}/api/partidas/${selectedPartida.id}`, { method: 'DELETE' });
                if (response.ok) {
                    setMessage('Partida borrada exitosamente');
                    setSelectedPartida(null); 
                    await fetchPartidas(); 
                    
                    // Verifica si la partida seleccionada todavía existe
                    const exists = partidas.some(p => p.id === selectedPartida.id);
                    if (!exists) {
                        setSelectedPartida(null);
                    }
                } else {
                    setMessage('Error al borrar la partida.');
                }
            } catch (error) {
                setMessage('Hubo un error al borrar la partida.');
            }
        }
    };

    const fetchPartidas = async () => {
        try {
            const response = await fetch(`https://${host}/api/partidas`);
            
            if (!response.ok) {
                throw new Error('Error en la respuesta del servidor');
            }
    
            // Si hay contenido en la respuesta, analizarlo como JSON
            const text = await response.text();
            if (text) {
                const data = JSON.parse(text);
                if (Array.isArray(data.data)) {
                    setPartidas(data.data);
    
                    // Si no hay partidas después de actualizar, establecer selectedPartida a null.
                    if (data.data.length === 0) {
                        setSelectedPartida(null);
                    } else {
                        setSelectedPartida(data.data[0]);
                    }
                } else {
                    console.error("Formato de datos no esperado:", data);
                }
            } else {
                // Si no hay contenido en la respuesta, establecer partidas como un array vacío y selectedPartida como null
                setPartidas([]);
                setSelectedPartida(null);
            }
        } catch (error) {
            console.error("Error al obtener los datos:", error);
        }
    };


    const handleToggleActive = async () => {
        if (selectedPartida) {
            try {
                const response = await fetch(`https://${host}/api/partidas/${selectedPartida.id}`, { method: 'PATCH' });
                if (response.ok) {
                    // Cambia el estado de selectedPartida directamente y luego actualiza el array de partidas
                    const updatedPartida = { ...selectedPartida, activo: selectedPartida.activo === 1 ? 0 : 1 };
                    const updatedPartidas = partidas.map(p => p.id === selectedPartida.id ? updatedPartida : p);
                    setPartidas(updatedPartidas);
                    setSelectedPartida(updatedPartida);

                    setMessage(`Partida ${selectedPartida.activo === 1 ? "desactivada" : "activada"} exitosamente`);
                } else {
                    setMessage('Error al cambiar el estado de la partida.');
                }
            } catch (error) {
                setMessage('Hubo un error al cambiar el estado de la partida.');
            }
        }
    };

    return (
        <div className="content"
            style={{
                backgroundImage: `url(${img})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                width: '100vw',
            }}>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366' }}>
                        <CardBody>
                            <h2 style={{ color: '#E8E8E8' }}>Gestión de Partidas</h2>

                            <FormGroup style={{ marginBottom: '10px', maxWidth: '50%' }}>
                                <Label style={{ color: '#E8E8E8' }}>Seleccione una Partida</Label>
                                <Input
                                    style={{
                                        color: '#E8E8E8',
                                        borderColor: '#2C83D5',
                                        fontSize: '18px',
                                        backgroundColor: 'transparent',
                                        padding: '10px 15px',
                                        height: 'auto'
                                    }}
                                    type="select"
                                    value={selectedPartida ? selectedPartida.nombre : ''}
                                    onChange={handlePartidaChange}
                                >
                                    <option value="" disabled>Seleccione una partida</option>
                                    {partidas.map(partida => (
                                        <option key={partida.id} value={partida.nombre}>
                                            {partida.nombre}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>

                            {selectedPartida && (
                                <FormGroup style={{ marginBottom: '10px', maxWidth: '50%' }}>
                                    <Label style={{ color: '#E8E8E8' }}>Estado de la Partida</Label>
                                    <Input
                                        style={{
                                            color: '#E8E8E8',
                                            borderColor: '#2C83D5',
                                            fontSize: '18px',
                                            backgroundColor: 'transparent'
                                        }}
                                        value={estadoPartida}
                                        readOnly
                                    />
                                </FormGroup>
                            )}

                            <Button onClick={handleDelete} style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px', marginRight: '10px' }}>
                                Borrar
                            </Button>
                            <Button onClick={handleToggleActive} style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px' }}>
                                Activar/Desactivar
                            </Button>
                            {message && (
                                <p style={{ color: '#FFA07A', marginTop: '10px' }}>{message}</p>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ModificarPartidas;