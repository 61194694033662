// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardEffect {
    cursor: pointer;
    transition: transform 0.3s, filter 0.3s;
    margin: 8px;
    position: relative;
    overflow: hidden;
  }
  
  .cardSelected {
    transform: scale(1.05);
    border: 3px solid gold;
    animation: glow 2s infinite alternate, rotateGlow 4s infinite linear;
    filter: brightness(1.1);
  }
  
  @keyframes glow {
    0% {
      box-shadow: 0 0 3px 3px rgba(255, 215, 0, 0.5), 
                  0 0 6px 2px rgba(255, 215, 0, 0.4);
    }
    50% {
      box-shadow: 0 0 4px 2px rgba(255, 215, 0, 0.6), 
                  0 0 8px 3px rgba(255, 215, 0, 0.5);
    }
    100% {
      box-shadow: 0 0 3px 3px rgba(255, 215, 0, 0.5), 
                  0 0 6px 2px rgba(255, 215, 0, 0.4);
    }
  }
  
  @keyframes rotateGlow {
    0% {
      box-shadow: 0 0 4px 2px rgba(255, 215, 0, 0.6), 
                  0 0 8px 3px rgba(255, 215, 0, 0.5);
    }
    25% {
      box-shadow: 8px -8px 4px 2px rgba(255, 215, 0, 0.6), 
                  10px -10px 8px 3px rgba(255, 215, 0, 0.5);
    }
    50% {
      box-shadow: 0 0 4px 2px rgba(255, 215, 0, 0.6), 
                  0 0 8px 3px rgba(255, 215, 0, 0.5);
    }
    75% {
      box-shadow: -8px 8px 4px 2px rgba(255, 215, 0, 0.6), 
                  -10px 10px 8px 3px rgba(255, 215, 0, 0.5);
    }
    100% {
      box-shadow: 0 0 4px 2px rgba(255, 215, 0, 0.6), 
                  0 0 8px 3px rgba(255, 215, 0, 0.5);
    }
  }`, "",{"version":3,"sources":["webpack://./src/views/Trends.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,uCAAuC;IACvC,WAAW;IACX,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,sBAAsB;IACtB,oEAAoE;IACpE,uBAAuB;EACzB;;EAEA;IACE;MACE;oDAC8C;IAChD;IACA;MACE;oDAC8C;IAChD;IACA;MACE;oDAC8C;IAChD;EACF;;EAEA;IACE;MACE;oDAC8C;IAChD;IACA;MACE;2DACqD;IACvD;IACA;MACE;oDAC8C;IAChD;IACA;MACE;2DACqD;IACvD;IACA;MACE;oDAC8C;IAChD;EACF","sourcesContent":[".cardEffect {\n    cursor: pointer;\n    transition: transform 0.3s, filter 0.3s;\n    margin: 8px;\n    position: relative;\n    overflow: hidden;\n  }\n  \n  .cardSelected {\n    transform: scale(1.05);\n    border: 3px solid gold;\n    animation: glow 2s infinite alternate, rotateGlow 4s infinite linear;\n    filter: brightness(1.1);\n  }\n  \n  @keyframes glow {\n    0% {\n      box-shadow: 0 0 3px 3px rgba(255, 215, 0, 0.5), \n                  0 0 6px 2px rgba(255, 215, 0, 0.4);\n    }\n    50% {\n      box-shadow: 0 0 4px 2px rgba(255, 215, 0, 0.6), \n                  0 0 8px 3px rgba(255, 215, 0, 0.5);\n    }\n    100% {\n      box-shadow: 0 0 3px 3px rgba(255, 215, 0, 0.5), \n                  0 0 6px 2px rgba(255, 215, 0, 0.4);\n    }\n  }\n  \n  @keyframes rotateGlow {\n    0% {\n      box-shadow: 0 0 4px 2px rgba(255, 215, 0, 0.6), \n                  0 0 8px 3px rgba(255, 215, 0, 0.5);\n    }\n    25% {\n      box-shadow: 8px -8px 4px 2px rgba(255, 215, 0, 0.6), \n                  10px -10px 8px 3px rgba(255, 215, 0, 0.5);\n    }\n    50% {\n      box-shadow: 0 0 4px 2px rgba(255, 215, 0, 0.6), \n                  0 0 8px 3px rgba(255, 215, 0, 0.5);\n    }\n    75% {\n      box-shadow: -8px 8px 4px 2px rgba(255, 215, 0, 0.6), \n                  -10px 10px 8px 3px rgba(255, 215, 0, 0.5);\n    }\n    100% {\n      box-shadow: 0 0 4px 2px rgba(255, 215, 0, 0.6), \n                  0 0 8px 3px rgba(255, 215, 0, 0.5);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
