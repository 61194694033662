// template para layouts

import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
//import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// assets
import logo from "assets/img/react-logo.png";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";

// tipologia
import PropTypes from "prop-types";

var ps;

function Layout(props) {
    //const fn = "Layout.js"

    // desempacar props
    const routes = props.routes
    const nav = props.nav

    // estados
    const location = useLocation();
    const mainPanelRef = React.useRef(null);
    const [sidebarOpened, setsidebarOpened] = React.useState(
        document.documentElement.className.indexOf("nav-open") !== -1
    );

    // efectos
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            ps = new PerfectScrollbar(mainPanelRef.current, {
                suppressScrollX: true,
            });
            let tables = document.querySelectorAll(".table-responsive");
            for (let i = 0; i < tables.length; i++) {
                ps = new PerfectScrollbar(tables[i]);
            }
        }
        // Specify how to clean up after this effect:
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
                document.documentElement.classList.add("perfect-scrollbar-off");
                document.documentElement.classList.remove("perfect-scrollbar-on");
            }
        };
    });

    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            let tables = document.querySelectorAll(".table-responsive");
            for (let i = 0; i < tables.length; i++) {
                ps = new PerfectScrollbar(tables[i]);
            }
        }
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        if (mainPanelRef.current) {
            mainPanelRef.current.scrollTop = 0;
        }
    }, [location]);

    // handlers
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === props.layoutName) {
                return (
                    <Route path={prop.path} element={prop.component} key={key} exact />
                );
            } else {
                return null;
            }
        });
    };
    // this function opens and closes the sidebar on small devices
    const toggleSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        setsidebarOpened(!sidebarOpened);
    };
    const getBrandText = (path) => {
        for (let i = 0; i < routes.length; i++) {
            if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
                return routes[i].name;
            }
        }
        return "Brand";
    };

    // render
    return (
        <BackgroundColorContext.Consumer>
            {({ color, changeColor }) => (
                <React.Fragment>
                    <div className="wrapper">
                        <Sidebar
                            routes={routes}
                            logo={{
                                outterLink: "",
                                text: "The RoadMap Game",
                                imgSrc: logo,
                            }}
                            toggleSidebar={toggleSidebar}
                        />
                        <div className="main-panel" ref={mainPanelRef} data={color}>
                            <AdminNavbar
                                brandText={getBrandText(location.pathname)}
                                toggleSidebar={toggleSidebar}
                                sidebarOpened={sidebarOpened}
                            />
                            <Routes>
                                {getRoutes(routes)}
                                <Route
                                    path="/"
                                    element={<Navigate to={nav} replace />}
                                />
                            </Routes>
                            {
                                // we don't want the Footer to be rendered on map page
                                //  location.pathname === "/admin/maps" ? null : <Footer fluid />
                            }
                        </div>
                    </div>
                </React.Fragment>
            )}
        </BackgroundColorContext.Consumer>
    );
}

Layout.propTypes = {
    routes: PropTypes.array.isRequired,
    layoutName: PropTypes.string.isRequired,
    nav: PropTypes.string.isRequired,
  };

export default Layout;
