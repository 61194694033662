// helpers para jugadas individuales

import { host } from "./connection.js"

// ${host}

// agarrar puntos del equipo de la db
async function verJugadasEquipo(partidaId, equipoId) {
	console.debug(`viendo jugadas para equipo ${equipoId} en ${partidaId}`)
	const apiUrl = `https://${host}/juego/${partidaId}/${equipoId}/jugadas`;

	try {
		// no lo toma del cache
		const response = await fetch(apiUrl, { method: 'GET', cache: "no-store", headers: { 'Content-Type': 'application/json' } });
		console.debug(`response es ${response}`)
		console.debug(`response ok es ${response.ok}`)

		if (!response.ok || !response) {
			const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
			throw new Error(errorMessage);
		}

		else if (response.status === 204) {
			console.debug(`no hay jugadas (204)`)
			return null
		}

		else {
			// esto viene siendo un dolor...
			try {
				console.debug(`jugadas response es ${JSON.stringify(response)}`)
				const data = await response.json()
				console.debug(`hay ${data.data.length} jugadas`)
				return data.data;
			} catch (error) {
				console.debug(`no hay jugadas`)
				return null
			}
		} 

	} catch (error) {
		console.error('Fetch Data Error:', error);
		throw error;
	}
}

// !!!!!!!!!!!! HACER UNA VERSION MAESTRA CON SWITCH PARA PRES/VIRT, BLUEOCEAN

// jugar una carta individual
async function jugarCarta(partidaId, equipoId, cartaId) {
	const apiUrl = `https://${host}/juego/${partidaId}/${equipoId}/jugadas/${cartaId}`;

	try {
		const response = await fetch(apiUrl, { method: 'POST', headers: { 'Content-Type': 'application/json' } });

		if (!response.ok) {
			const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
			throw new Error(errorMessage);
		}

		const data = await response.json();
		return data.data;
	} catch (error) {
		console.error('Fetch Data Error:', error);
		throw error;
	}
}

// jugar una carta individual CON RECUADRO
async function jugarCartaRecuadro(partidaId, equipoId, cartaId, recuadroNom) {
	const apiUrl = `https://${host}/juego/${partidaId}/${equipoId}/jugadasrecuadro/${recuadroNom}/${cartaId}`;

	try {
		const response = await fetch(apiUrl, { method: 'POST', headers: { 'Content-Type': 'application/json' } });

		if (!response.ok) {
			const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
			throw new Error(errorMessage);
		}

		const data = await response.json();
		return data.data;
	} catch (error) {
		console.error('Fetch Data Error:', error);
		throw error;
	}
}

// jugar una carta individual blue ocean CON RECUADRO
async function jugarCartaRecuadroBlueOcean(partidaId, equipoId, cartaId, recuadroNom, val) {
	console.debug(`jugando carta ${cartaId} en blueocean virtual, recuadro ${recuadroNom} con valor ${val}`)
	const apiUrl = `https://${host}/juego/${partidaId}/${equipoId}/jugadasrecuadroblueocean/${recuadroNom}/${cartaId}/${val}`;

	try {
		const response = await fetch(apiUrl, { method: 'POST', headers: { 'Content-Type': 'application/json' } });

		if (!response.ok) {
			const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
			throw new Error(errorMessage);
		}

		const data = await response.json();
		return data.data;
	} catch (error) {
		console.error('Fetch Data Error:', error);
		throw error;
	}
}

// jugar una carta individual en Blue Ocean
async function jugarCartaBlueOcean(partidaId, equipoId, cartaId, val) {
	console.debug(`jugando carta ${cartaId} en blueocean con valor ${val}`)
	const apiUrl = `https://${host}/juego/${partidaId}/${equipoId}/jugadasblueocean/${cartaId}/${val}`;

	try {
		const response = await fetch(apiUrl, { method: 'POST', headers: { 'Content-Type': 'application/json' } });

		if (!response.ok) {
			const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
			throw new Error(errorMessage);
		}

		const data = await response.json();
		return data.data;
	} catch (error) {
		console.error('Fetch Data Error:', error);
		throw error;
	}
}

// jugar array de cartas
async function jugarVariasCartas(partidaId, equipoId, arrayCartas) {
	for (let i = 0; i < arrayCartas.length; i++) {
		jugarCarta(partidaId, equipoId, arrayCartas[i])
	}
}

// jugar array de cartas en Blue Ocean
async function jugarVariasCartasBlueOcean(partidaId, equipoId, objetosCartas) {
	for (let i = 0; i < objetosCartas.length; i++) {
		jugarCartaBlueOcean(partidaId, equipoId, objetosCartas[i].id, objetosCartas[i].blueocean)
	}
}

// jugar array de cartas virtual
async function jugarVariasCartasVirtual(partidaId, equipoId, objetosCartas) {
	for (let i = 0; i < objetosCartas.length; i++) {
		jugarCartaRecuadro(partidaId, equipoId, objetosCartas[i].id, objetosCartas[i].recuadro)
	}
}

// jugar array de cartas virtual
// remueve duplicados
async function jugarVariasCartasBlueOceanVirtual(partidaId, equipoId, objetosCartas) {
	const processedIds = new Set();
	for (let i = 0; i < objetosCartas.length; i++) {
		const carta = objetosCartas[i];
	
		// Check if the unique id has already been processed
		if (!processedIds.has(carta.id)) {
		  jugarCartaRecuadroBlueOcean(partidaId, equipoId, carta.id, carta.recuadro, carta.blueocean);
	
		  // Mark the id as processed
		  processedIds.add(carta.id);
		} else {
			continue;
		}
	}
}

// verificar si equipo ya jugo ese mazo
async function verificarMazoEquipo(partidaId, equipoId, mazo) {
	console.debug(`verificando si eq ${equipoId} en p ${partidaId} ya jugo ${mazo}`)

	try {
		// traer lista de jugadas
		const jugadas = await verJugadasEquipo(partidaId, equipoId);

		if (!jugadas || jugadas == null) {
			console.debug(`equipo ${equipoId} no tiene jugadas guardadas`)
			return true;
		}

		// extraer lista de mazos jugados
		const mazosJugados = jugadas.map(obj => obj.mazo)

		if (mazosJugados.includes(mazo)) {
			console.debug(`equipo ${equipoId} ya jugo el sprint ${mazo}`)
			return false;
		} else {
			console.debug(`equipo ${equipoId} todavia no jugo el sprint ${mazo}`)
			return true;
		}
	} catch (error) {
		console.error('Error en verificarMazoEquipo:', error);
		throw error;
	}
}

export default {
	verJugadasEquipo,
	jugarCarta,
	jugarCartaBlueOcean,
	jugarCartaRecuadro,
	jugarVariasCartas,
	jugarVariasCartasBlueOcean,
	jugarVariasCartasBlueOceanVirtual,
	jugarVariasCartasVirtual,
	verificarMazoEquipo
}