// Sprint.js
// Vista que permite jugar una serie de cartas

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

// assets
import img from "../assets/img/fondo3.png";
import gifBarraNombreSprint from "../assets/img/corriendo.gif"; // aka "img2"
import imgOk from "../assets/img/send_ok.png";

// fondos de canvas
import canvasPostit from "../assets/img/canvas1.png";
import canvasEnBlanco from "../assets/img/canvas2.png";
import canvasB2B from "../assets/img/canvas3.png";
import canvasNavigator from "../assets/img/canvas4.png";
import canvasPlatform from "../assets/img/canvasPlataformas.png";
import canvasDataDriven from "../assets/img/canvasDataDriven.png";

// recuadros canvas 1 postits
import recuadro1ActividadesClaves from "../assets/recuadrosCanvas/canvas1/ActividadesClaves.png"
import recuadro1Canales from "../assets/recuadrosCanvas/canvas1/Canales.png"
import recuadro1EstructuraDeCostos from "../assets/recuadrosCanvas/canvas1/EstructuraDeCostos.png"
import recuadro1FlujosDeIngreso from "../assets/recuadrosCanvas/canvas1/FlujosDeIngreso.png"
import recuadro1PropuestaDeValor from "../assets/recuadrosCanvas/canvas1/PropuestaDeValor.png"
import recuadro1RecursosClaves from "../assets/recuadrosCanvas/canvas1/RecursosClaves.png"
import recuadro1RelacionesConClientes from "../assets/recuadrosCanvas/canvas1/RelacionesConClientes.png"
import recuadro1SegmentosDeCliente from "../assets/recuadrosCanvas/canvas1/SegmentosDeCliente.png"
import recuadro1SociosClaves from "../assets/recuadrosCanvas/canvas1/SociosClaves.png"

// recuadros canvas 2 en blanco
import recuadro2ActividadesClaves from "../assets/recuadrosCanvas/canvas2/ActividadesClaves.png"
import recuadro2Canales from "../assets/recuadrosCanvas/canvas2/Canales.png"
import recuadro2EstructuraDeCostos from "../assets/recuadrosCanvas/canvas2/EstructuraDeCostos.png"
import recuadro2FlujosDeIngreso from "../assets/recuadrosCanvas/canvas2/FlujosDeIngreso.png"
import recuadro2PropuestaDeValor from "../assets/recuadrosCanvas/canvas2/PropuestaDeValor.png"
import recuadro2RecursosClaves from "../assets/recuadrosCanvas/canvas2/RecursosClaves.png"
import recuadro2RelacionesConClientes from "../assets/recuadrosCanvas/canvas2/RelacionesConClientes.png"
import recuadro2SegmentosDeCliente from "../assets/recuadrosCanvas/canvas2/SegmentosDeCliente.png"
import recuadro2SociosClaves from "../assets/recuadrosCanvas/canvas2/SociosClaves.png"

// recuadros canvas 3 b2b
import recuadro3ActividadesClaves from "../assets/recuadrosCanvas/canvas3/ActividadesClaves.png"
import recuadro3Canales from "../assets/recuadrosCanvas/canvas3/Canales.png"
import recuadro3EstructuraDeCostos from "../assets/recuadrosCanvas/canvas3/EstructuraDeCostos.png"
import recuadro3FlujosDeIngreso from "../assets/recuadrosCanvas/canvas3/FlujosDeIngreso.png"
import recuadro3PropuestaDeValor from "../assets/recuadrosCanvas/canvas3/PropuestaDeValor.png"
import recuadro3RecursosClaves from "../assets/recuadrosCanvas/canvas3/RecursosClaves.png"
import recuadro3RelacionesConClientes from "../assets/recuadrosCanvas/canvas3/RelacionesConClientes.png"
import recuadro3SegmentosDeCliente from "../assets/recuadrosCanvas/canvas3/SegmentosDeCliente.png"
import recuadro3SociosClaves from "../assets/recuadrosCanvas/canvas3/SociosClaves.png"
import recuadro3Consumidores from "../assets/recuadrosCanvas/canvas3/Consumidores.png"
import recuadro3Influencer from "../assets/recuadrosCanvas/canvas3/Influencer.png"
import recuadro3Intermediarios from "../assets/recuadrosCanvas/canvas3/Intermediarios.png"

// recuadros canvas 4 navigator
import recuadro4Que from "../assets/recuadrosCanvas/canvas4/Que.png"
import recuadro4Porque from "../assets/recuadrosCanvas/canvas4/Porque.png"
import recuadro4Quien from "../assets/recuadrosCanvas/canvas4/Quien.png"
import recuadro4Como from "../assets/recuadrosCanvas/canvas4/Como.png"
import recuadro4Propuesta from "../assets/recuadrosCanvas/canvas4/Propuesta.png"
import recuadro4Captura from "../assets/recuadrosCanvas/canvas4/Captura.png"
import recuadro4Creacion from "../assets/recuadrosCanvas/canvas4/Creacion.png"

// recuadros canvas 5 data driven
import recuadro5DataSources from "../assets/recuadrosCanvas/canvas5/DataSources.png"
import recuadro5Analytics from "../assets/recuadrosCanvas/canvas5/Analytics.png"
import recuadro5ProductOrService from "../assets/recuadrosCanvas/canvas5/ProductOrService.png"
import recuadro5Benefit from "../assets/recuadrosCanvas/canvas5/Benefit.png"
import recuadro5ImplicationsMonetization from "../assets/recuadrosCanvas/canvas5/ImplicationsMonetization.png"
import recuadro5ImplicationsCosts from "../assets/recuadrosCanvas/canvas5/ImplicationsCosts.png"

// recuadros canvas 6 platform NO SE USA
/*import recuadro6DataSources from "../assets/recuadrosCanvas/canvas6/DataSources.png"
import recuadro6Analytics from "../assets/recuadrosCanvas/canvas6/Analytics.png"
import recuadro6ProductOrService from "../assets/recuadrosCanvas/canvas6/ProductOrService.png"
import recuadro6Benefit from "../assets/recuadrosCanvas/canvas6/Benefit.png"
import recuadro6ImplicationsMonetization from "../assets/recuadrosCanvas/canvas6/ImplicationsMonetization.png"
import recuadro6ImplicationsCosts from "../assets/recuadrosCanvas/canvas6/ImplicationsCosts.png"*/


import './Trends.css'; // efecto dorado en cartas
import {
	Button,
	ButtonGroup,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	Label,
	FormGroup,
	Input,
	Table,
	Row,
	Col,
	UncontrolledTooltip,
	CustomInput,
} from "reactstrap";

// helpers
import puntosHelpers from "../variables/puntos.js";
import { host } from "../variables/connection.js";
import cartasHelpers from "../variables/cartas.js";
import barajaHelpers from "../variables/baraja.js";
import jugadasHelpers from "../variables/jugadas.js";
import tokensHelpers from "../variables/tokens.js";
import sprintHelpers from "../variables/sprints.js";
import tiempoHelpers from "../variables/tiempo.js";
import canvasHelpers from "../variables/canvas.js";

// componentes generales
import barraComponent from 'components/Navbars/BarraSprint';
import Temporizador from 'components/Tiempo/Temporizador';

// ecosystem
import EcosystemCanvas from 'components/Sprints/EcosystemCanvas';
import ConcentricClickableSectors from 'components/Sprints/EcosystemCircle';
import LargeInputBoxes from 'components/Sprints/EcosystemInputs';

const BarraSprint = barraComponent.BarraSprint;

// cartas
const cartasEnBaraja = barajaHelpers.cartasEnBaraja;
const cartasEnMazo = cartasHelpers.cartasEnMazo;
const cartaPorNumero = cartasHelpers.cartaPorNumero;
const variasCartasPorID = cartasHelpers.variasCartasPorID;

// puntos
const fetchData = puntosHelpers.fetchData;
const agregarPuntosEquipo = puntosHelpers.agregarPuntosEquipo
const puntosCartas = puntosHelpers.puntosCartas;
const calcularPuntosSprint = puntosHelpers.calcularPuntosSprint;
const fetchCardData = puntosHelpers.fetchCardData
const calcularPuntosMaturity = puntosHelpers.calcularPuntosMaturity;

// sprint
const getSprintEnPartida = sprintHelpers.getSprintEnPartida;
const numeroSprintsEnPartida = sprintHelpers.numeroSprintsEnPartida;
const addRevBeforeLastFourChars = sprintHelpers.addRevBeforeLastFourChars;

// jugadas
const verJugadasEquipo = jugadasHelpers.verJugadasEquipo;
const verificarMazoEquipo = jugadasHelpers.verificarMazoEquipo;
const jugarVariasCartas = jugadasHelpers.jugarVariasCartas;
const jugarVariasCartasBlueOcean = jugadasHelpers.jugarVariasCartasBlueOcean;
const jugarVariasCartasBlueOceanVirtual = jugadasHelpers.jugarVariasCartasBlueOceanVirtual;
const jugarVariasCartasVirtual = jugadasHelpers.jugarVariasCartasVirtual;

// canvas
const DropZone = canvasHelpers.DropZone;
const DraggableCard = canvasHelpers.DraggableCard;
const traerFramework = canvasHelpers.traerFramework;
const traerRecuadros = canvasHelpers.traerRecuadros;

// misc
const decodeToken = tokensHelpers.decodeToken;
const secondsToMinutesAndSeconds = tiempoHelpers.secondsToMinutesAndSeconds;

function Sprint() {

	//////////////////////
	/*		CONFIG		*/
	//////////////////////

	console.debug("Arrancando Sprint.js")

	// conf
	const { t, i18n } = useTranslation()
	const [idioma, setIdioma] = useState("es")
	const [path, setPath] = useState("../assets/es/cartas/")
	const [todosSprints, setTodosSprints] = useState(null);
	const [selectedPartida, setSelectedPartida] = useState(null);
	const [selectedEquipo, setSelectedEquipo] = useState(null);
	const [selectedSprint, setSelectedSprint] = useState("SprintMain");
	const [selectedCanvas, setSelectedCanvas] = useState("canvasPostit")
	const [mazo, setMazo] = useState(null); // nombre del sprint
	const [jugadas, setJugadas] = useState([]); // array de todas las jugadas

	// nav
	const navigate = useNavigate();
	const [cartasLoaded, setCartasLoaded] = useState(false)
	const [arranqueListo, setArranqueListo] = useState(true) // hacer visible los elementos al empezar
	const [yaSeGuardo, setYaSeGuardo] = useState(false)
	const [sigRuta, setSigRuta] = useState("/CapitanPresencial/EstadoResultados") // default bien ?

	// cartas
	const [mazoTieneRev, setMazoTieneRev] = useState(false);
	const [cartas, setCartas] = useState([]);
	const [images, setImages] = useState([]);

	// selecciones
	const [selectedCard, setSelectedCard] = useState(null);
	const [selectedCards, setSelectedCards] = useState([]); // Seleccionar múltiples cartas
	const [selectedCardsModal, setSelectedCardsModal] = useState([]); // para display
	const [limiteMovimientos, setLimiteMovimientos] = useState(5); // cant maxima de jugadas x sprint
	const [cantCartasJugadas, setCantCartasJugadas] = useState(0) // especifico a Blue Ocean x ahora
	const [cantMovsDisp, setCantMovsDisp] = useState(0)
	const [numCartasBaraja, setNumCartasBaraja] = useState(0) // numero de cartas en la baraja
	//const [cartaLock, setCartaLock] = useState(0) // trabar proceso de seleccion para que no se pisen los estados

	// canvas
	const correctorGlobal = 95 // modificar aca si las banderas se salen de los recuadros
	const [tableroWidth, setTableroWidth] = useState(0);
	const [tableroHeight, setTableroHeight] = useState(0);
	const [tableroWidthZero, setTableroWidthZero] = useState(0);
	const [tableroHeightZero, setTableroHeightZero] = useState(0);
	const [droppedCards, setDroppedCards] = useState({
		1: [],
		2: [],
		3: [],
		4: [],
		5: [],
		6: [],
		7: [],
		8: [],
		9: []
	});

	// modal
	const [opcionesCartas, setOpcionesCartas] = useState([]); // opciones disponibles
	const [selectedOption, setSelectedOption] = useState(); // la opcion ind elegida, o la default de la carta
	const [numOpcionesCartas, setNumOpcionesCartas] = useState(1); // numero de opciones seleccionables
	const [selectedZone, setSelectedZone] = useState() // el recuadro canvas de la carta en modal
	const [modalOpen, setModalOpen] = useState(false); // solapa
	const [nombreSprint, setNombreSprint] = useState("Trends"); // BarraNombreSprint --> es igual a mazo

	// tiempo
	const [tiempoSprint, setTiempoSprint] = useState(300)
	const [tiempoRestante, setTiempoRestante] = useState(300);
	const [temporizadorPausado, setTemporizadorPausado] = useState(false);
	const [temporizadorDetenido, setTemporizadorDetenido] = useState(false);
	const [temporizadorInterval, setTemporizadorInterval] = useState(null); // Define la variable de intervalo

	// ecosystem
	const [ecosystemDineroOrig, setEcoSystemDineroOrig] = useState(0)
	const [ecosystemCirculo, setEcoSystemCirculo] = useState({})
	const [ecosystemOferta, setEcoSystemOferta] = useState("")
	const [ecosystemPropuesta, setEcoSystemPropuesta] = useState("")

	//////////////////////
	/* 		SETUP		*/
	//////////////////////

	async function setearConfDesdeToken() {

		console.debug(`Seteando conf inicial...`)

		var token
		// recibir token de local storage, o hacer refresh
		try {
			token = localStorage.getItem('token')
		} catch (error) {
			console.error(`no hay token!`)
			window.location.reload()
		}
		if (!token) {
			console.error(`falta token!`)
			window.location.reload()
		}

		//console.debug('Token found:', token);
		const claims = decodeToken(token);
		console.debug('Token claims:', claims);

		// armar idioma
		if (claims.idioma) {
			console.debug(`seteando path a ../assets/${claims.idioma}/cartas/...`)
			setIdioma(claims.idioma)
			setPath(`../assets/${claims.idioma}/cartas/`)
			i18n.changeLanguage(claims.idioma)
		}

		// configuracion de juego
		if (claims.role === "Player" || claims.role === "Captain") {
			// setear partida y equipo
			setSelectedPartida(claims.partida)
			setSelectedEquipo(claims.equipo)
			console.debug(`Seteando sprint para partida ${claims.partida}, equipo ${claims.equipo}...`)

			// traer el sprint ID al que esta asociado el equipo
			const datosEquipo = await fetchData(claims.partida, claims.equipo)
			const datosSprints = await numeroSprintsEnPartida(claims.partida)
			//console.debug(`datos equipo son ${JSON.stringify(datosEquipo)}`)
			console.debug(`Equipo esta en sprint id ${datosEquipo[0]["sprint_id"]}`)
			console.debug(`datos de todos los sprints en p son ${JSON.stringify(datosSprints)}`)

			// determinar proxima ruta
			var sigRutaRole
			var sigRutaPres
			switch (claims.role) {
				case "Player": sigRutaRole = "Jugador"; break;
				case "Captain": sigRutaRole = "Capitan"; break;
				default: sigRutaRole = "Jugador"; break;
			}
			switch (claims.presencial) {
				case 1: sigRutaPres = "Presencial"; break;
				case 0: sigRutaPres = "Virtual"; break;
				default: sigRutaPres = "Presencial"; break;
			}

			console.debug(`sig ruta es /${sigRutaRole}${sigRutaPres}/Recuadro`)
			setSigRuta(`/${sigRutaRole}${sigRutaPres}/Recuadro`)

			// determinar el sprint
			var datosSprint
			// si no tiene un sprint asociado, ir al primer sprint en esa partida
			if (datosEquipo[0]["sprint_id"]) {
				// usar el sprint ID para encontrar el nombre del sprint
				datosSprint = datosSprints.find(x => x.id === Number(datosEquipo[0]["sprint_id"]));
			} else {
				console.debug("No se encontro sprint para este equipo, usando el primero asociado a la partida")
				datosSprint = datosSprints[0]
			}
			console.debug(`datos sprint ind son ${JSON.stringify(datosSprint)}`)

			// armar BarraNombreSprint
			console.debug(`seteando nombreSprint en ${datosSprint.nombre}`)
			setNombreSprint(datosSprint.nombre)

			// determinar cual es el proximo sprint
			var proxSprintDatos = datosSprints.find(x => x.id === (Number(datosSprint["id"]) + 1))
			// determinar si ya se jugo ese sprint
			const mazoNoJugado = await verificarMazoEquipo(claims.partida, claims.equipo, datosSprint.nombre)

			if (mazoNoJugado && proxSprintDatos) {
				// no se jugo este sprint todavia y quedan otros sprint despues
				console.debug(`Despues de guardar el sprint y el quiz, asignando a ${proxSprintDatos["nombre"]}`)
			} else if (mazoNoJugado && !proxSprintDatos) {
				console.debug(`Estas en el ultimo sprint! Falta un quiz`)
			} else if (!mazoNoJugado && proxSprintDatos) {
				console.debug(`Ya se jugaron cartas para este sprint! Enviando al recuadro`)
				navigate(`/${sigRutaRole}${sigRutaPres}/Recuadro`)
			} else if (!mazoNoJugado && !proxSprintDatos) {
				console.debug(`Buscabas mas puntos? Volviendo al Final...`)
				navigate(`/${sigRutaRole}${sigRutaPres}/Final`)
			} else {
				console.error(`Algo se rompio! Haciendo refresh a ver si se arregla...`)
				window.location.reload()
			}

			// armar mazo
			setTodosSprints(datosSprints)
			setMazo(datosSprint["nombre"])

			var maxJugadas = 5

			// armar reversos y maximo de jugadas 
			// para rev, ID es 1_REV, etc
			switch (datosSprint["nombre"]) {
				case "Patterns":
					setMazoTieneRev(true);
					maxJugadas = datosSprint.movimientos_disp || 5;
					break;
				case "DigitalDrivers":
					setMazoTieneRev(true);
					maxJugadas = datosSprint.movimientos_disp || 5;
					break;
				case "Platform":
					setMazoTieneRev(true);
					maxJugadas = datosSprint.movimientos_disp || 105; // <------ HARDCODEADO
					break;
				case "Test":
					setMazoTieneRev(true);
					maxJugadas = datosSprint.movimientos_disp || 5;
					break;
				case "DataDriven":
					maxJugadas = datosSprint.movimientos_disp || 10; // <------ HARDCODEADO
					break;
				case "BlueOcean":
					maxJugadas = datosSprint.movimientos_disp || 5;
					break;
				case "Sustainability":
					setMazoTieneRev(true);
					maxJugadas = datosSprint.movimientos_disp || 5;
					break;
				case "Ecosystem":
					setMazoTieneRev(true);
					maxJugadas = 19 + 13; // <------ HARDCODEADO: 19 cartas de opcion unica + 13 de costos de estructura
					setEcoSystemDineroOrig(datosEquipo[0].ecosistema_dinero_orig)
					break;
				default:
					setMazoTieneRev(false);
					maxJugadas = datosSprint.movimientos_disp || 5;
					break;
			}

			// determinar cant maxima de jugadas disponibles
			console.debug(`maxJugadas en ${datosSprint.nombre} es ${maxJugadas}; en db es ${datosSprint.movimientos_disp}`)
			setLimiteMovimientos(maxJugadas)

			// traer cartas
			console.debug(`Trayendo cartas para sprint ${datosSprint["nombre"]}, dur ${datosSprint["duracion_min"]} min ...`)
			//const cartasData = await cartasEnMazo(datosSprint["nombre"]);
			const cartasData = await cartasEnBaraja(claims.partida, claims.equipo, datosSprint["id"]);
			if (!cartasData) { console.error(`No hay cartas para mazo ${datosSprint["nombre"]}`) }

			// guardar numero de cartas en la baraja, para poder controlar movs
			setNumCartasBaraja(cartasData.length)

			// armar el sprint
			var selectedSprintS
			if (claims.presencial === 1) {
				// PRESENCIAL
				switch (datosSprint["nombre"]) {
					case "BlueOcean":
						console.debug("Armando Blue Ocean presencial...")
						selectedSprintS = "SprintBlueOcean"
						break;
					case "Platform":
						console.debug("Armando Platform presencial...")
						selectedSprintS = "SprintPlatform"
						break;
					case "Ecosystem":
						console.debug("Armando Ecosystem presencial...")
						selectedSprintS = "SprintEcosystem"
						break;
					default:
						console.debug(`Armando ${datosSprint["nombre"]} presencial...`)
						selectedSprintS = "SprintMain"
						break;
				}

				setCartas(cartasData);

			} else if (claims.presencial === 0) {
				// VIRTUAL
				switch (datosSprint["nombre"]) {
					case "BlueOcean":
						console.debug("Armando Blue Ocean virtual...")
						selectedSprintS = "SprintBlueOceanVirtual"
						break;
					case "Platform":
						console.debug("Armando Platform virtual...")
						selectedSprintS = "SprintPlatformVirtual"
						break;
					case "DataDriven":
						console.debug("Armando Data Driven virtual...")
						selectedSprintS = "SprintDataDrivenVirtual"
						break;
					case "Ecosystem":
						console.debug("Armando Ecosystem virtual (= que presencial)...")
						selectedSprintS = "SprintEcosystem"
						break;
					default:
						console.debug(`Armando ${datosSprint["nombre"]} virtual...`)
						selectedSprintS = "SprintVirtual"
						break;
				}

				// traer el framework basado en partida
				const frameworkID = await traerFramework(claims.partida)
				console.debug(`frameworkID es ${frameworkID}...`)

				// setear img framework
				var selecciondeCanvas
				switch (frameworkID) {
					case 1: selecciondeCanvas = "canvasPostit"; break; // Talleres 
					case 2: selecciondeCanvas = "canvasEnBlanco"; break;
					case 3: selecciondeCanvas = "canvasB2B"; break;
					case 4: selecciondeCanvas = "canvasNavigator"; break;
					case 5: selecciondeCanvas = "canvasDataDriven"; break;
					case 6: selecciondeCanvas = "canvasPlatform"; break;
					default: selecciondeCanvas = null; break;
				}
				console.debug(`canvas seleccionado es ${selecciondeCanvas}`)
				setSelectedCanvas(selecciondeCanvas)

				// traer recuadros canvas del framework
				var selecciondeRecuadros = await traerRecuadros(frameworkID);
				console.debug(`recuadros seleccionados son ${JSON.stringify(selecciondeRecuadros)}`)

				// convierte a los recuadros en un array de IDs
				var recuadrosViejosArr = selecciondeRecuadros.map(x => x.id)
				console.debug(`recuadrosViejosArr es ${JSON.stringify(recuadrosViejosArr)}`)
				var recuadrosViejos = {}

				// traer las jugadas de sprint anteriores
				var datosJugadas = await verJugadasEquipo(claims.partida, claims.equipo)
				console.debug(`jugadas anteriores por este equipo son ${JSON.stringify(datosJugadas)}`)

				// filtrar jugadas para los que tengan recuadro
				if (datosJugadas) {
					datosJugadas.filter(x => x.recuadro)
					setJugadas(datosJugadas)
					console.debug(`jugadas anteriores con recuadro son ${JSON.stringify(datosJugadas)}`)

					// traer todas las imagenes para las cartas jugadas previamente
					const arrayCartasViejas = datosJugadas.map(x => x.carta_id)
					console.debug(`arrayCartasViejas es ${arrayCartasViejas}`)
					const cartasViejasData = await variasCartasPorID(arrayCartasViejas)
					console.debug(`cartasViejasData es ${JSON.stringify(cartasViejasData)}`)
					var imgsCartasViejas = await fetchImagesDerecho(cartasViejasData)
					console.debug(`imgsCartasViejas son:`, imgsCartasViejas)

					// armar las imagenes de los sprints anteriores y ponerlos en el tablero
					// mapear los recuadros, sin que se puedan deseleccionar
					try {
						for (let i = 0; i < recuadrosViejosArr.length; i++) {
							var keyD = recuadrosViejosArr[i];
							var jugD = datosJugadas.filter(x => x.recuadrocanvas_id === keyD);

							// Convertir jugadas a imágenes
							console.debug(`jugD viejo es ${JSON.stringify(jugD)}`);
							console.debug(`cartasviejasdata es array?`, Array.isArray(cartasViejasData)); // Check if it logs true
							console.debug(`imgscartas es array?`, Array.isArray(imgsCartasViejas)); // Check if it logs true

							for (let i = 0; i < jugD.length; i++) {
								var carta = cartasViejasData.find(x => x && x.id === jugD[i].carta_id);

								if (carta) {
									var svgpath = carta.svg_front;
									var imgInfo = imgsCartasViejas.find(x => x && x.name === svgpath);

									if (imgInfo) {
										var name = imgInfo.name;
										var src = imgInfo.src;
										jugD[i] = { name: name, src: src };
									}
								}
							}

							console.debug(`jugD nuevo es ${JSON.stringify(jugD)}`);
							recuadrosViejos[keyD] = jugD;
						}

						console.debug(`recuadrosViejos son ${JSON.stringify(recuadrosViejos)}`);
						setDroppedCards(recuadrosViejos);
					} catch (error) {
						console.error(error);
					}

				} else {
					// si no hay jugadas anteriores, monta un array de objetos con arrays vacios
					for (let i = 0; i < recuadrosViejosArr.length; i++) {
						var keyD = recuadrosViejosArr[i]
						recuadrosViejos[keyD] = []
					}
					setDroppedCards(recuadrosViejos)
				}

				// aca, esto se usa para las cartas jugables que van en el sidebar, NO PONER LAS CARTAS VIEJAS
				setCartas(cartasData)

			} else { console.error(`no se pudo determinar presencialidad!`) }
			console.debug(`intentando renderizar a ${selectedSprintS}`)
			setSelectedSprint(selectedSprintS)
			//selectedSprint = selectedSprintS

			// establecer tiempo
			const tiempo = Number(datosSprint.duracion_min) * 60 || 300; // si no lo encuentra, 5 min
			setTiempoRestante(tiempo);
			setTiempoSprint(tiempo)
			console.debug(`Tiempo de sprint es ${tiempo} segundos`)

		} else if (claims.role === "Admin") {
			//setUserType("Admin")
		} else {
			console.error("y vos quien sos ???")
		}

	}

	// arrancar al inicio
	useEffect(() => {
		setearConfDesdeToken()
	}, [])

	//////////////////////
	/* 		TIEMPO		*/
	//////////////////////

	// para frenar bucle y bajada de tiempo, comentar Temporizador
	Temporizador(tiempoRestante, setTiempoRestante, temporizadorPausado, setTemporizadorPausado, temporizadorDetenido)

	const reiniciarTemporizador = () => {
		setTiempoRestante(tiempoSprint);
		setTemporizadorPausado(false);
		setTemporizadorDetenido(false);
	};

	//////////////////////
	/* 		BOTONES		*/
	//////////////////////

	async function botonArrancar(event) {
		event.preventDefault(); // Prevenir recarga del formulario
		// arrancar musica, especifico para resolver problema autoplay

		// arrancar tiempo
		reiniciarTemporizador();

		// destrabar movimiento de cartas
		//setCartaLock(0)

		// hacer visible a las cartas
		setArranqueListo(false)
	}

	async function botonGuardar(event) {
		event.preventDefault(); // Prevenir recarga del formulario
		//console.debug(`sigSprint en boton guardar es ${JSON.stringify(sigSprint)}`)

		if (yaSeGuardo === true) {
			return;
		}

		console.debug(`boton guardar en sprint ${selectedSprint}`)

		// Show a confirmation dialog to the user
		const userConfirmed = window.confirm(t('common.confirmar_guardar'));

		if (userConfirmed) {
			setYaSeGuardo(true);

			switch (selectedSprint) {
				case "SprintBlueOcean": await calcularPuntosBlueOcean(tiempoRestante); break;
				case "SprintBlueOceanVirtual": await calcularPuntosBlueOceanVirtual(tiempoRestante); break;
				case "SprintVirtual": await calcularPuntosVirtual(tiempoRestante); break;
				case "SprintDataDrivenVirtual": await calcularPuntosVirtual(tiempoRestante); break;
				case "SprintEcosystem": await calcularPuntosEcosystem(tiempoRestante); break;
				default: await calcularPuntos(tiempoRestante); break; // platform y el comun
			}

			// movimos el navigate porque no esperaba a calcularPuntos
		}
	}

	//////////////////////
	/* 		IMAGES		*/
	//////////////////////

	async function fetchImages(datosCartas) {
		try {
			const promiseFrente = datosCartas.map(fetchImageData)
			var imageData = await Promise.all(promiseFrente);
			if (!imageData) { throw new Error(`fetchImageData vuelve vacio`) }

			console.debug(`se trajeron ${imageData.length} cartas, como ${imageData[0].name}`)

			// agregar imgs del reverso si hace falta
			var imageDataRev = []
			if (mazoTieneRev === true) {
				const promiseRev = datosCartas.map(fetchImageDataRev)
				imageDataRev = await Promise.all(promiseRev);
				if (!imageDataRev) { throw new Error(`fetchImageDataRev vuelve vacio`) }

				console.debug('primer carta en imageData es:', imageData[0].name, imageDataRev[0].name)
			}

			imageData = imageData.concat(imageDataRev)

			console.debug(`desp de fijarse rev, se trajeron ${imageData.length} cartas, ${imageDataRev.length} son rev`)

			var listaCartas = []
			for (let i = 0; i < imageData.length; i++) {
				var imagenInd = imageData[i]
				if (!imagenInd) {
					console.debug(`falta carta ${i}/${imageData.length}, borrando...`)
					continue
				}
				if (!imagenInd.src) {
					console.debug(`falta src ${imagenInd}, borrando...`)
					imageData.splice(i)
					continue
				}
				listaCartas.push(imagenInd.name)
				//console.debug(imageData[i].name)
			}

			console.debug(`lista tiene ${listaCartas.length}: ${listaCartas}`)
			console.debug(`listaCartas verificado tiene ${listaCartas.length} imagenes`)

			setImages(imageData);
			setCartasLoaded(true)
		} catch (error) {
			console.error(`error de fetchImages:`, error.message)
			return
		}
	}

	async function fetchImagesDerecho(datosCartas) {

		if (!datosCartas || datosCartas.length === 0 || datosCartas === null || datosCartas === undefined) { return }

		// limpieza
		var datosCartasClean = datosCartas.filter(x => x !== null && x !== undefined)

		const promiseFrente = datosCartasClean.map(fetchImageData)
		var imageData = await Promise.all(promiseFrente);

		console.debug(`se trajeron ${imageData.length} cartas derecho, como ${imageData[0].name}`)

		var listaCartas = []
		for (let i = 0; i < imageData.length; i++) {
			var imagenInd = imageData[i]
			if (!imagenInd) {
				console.debug(`falta carta ${i}/${imageData.length}, borrando...`)
				continue
			}
			if (!imagenInd.src) {
				console.debug(`falta src ${imagenInd}, borrando...`)
				imageData.splice(i)
				continue
			}

			listaCartas.push(imagenInd.name)
			//console.debug(imageData[i].name)
		}

		console.debug(`lista derecho tiene ${listaCartas.length}: ${listaCartas}`)
		console.debug(`fetchImagesDerecho len es ${imageData.length}`)
		console.debug(`fetchImagesDerecho 0 :`, imageData[0])

		setCartasLoaded(true)
		// NO SETEAR ESTADO :p
		return imageData
	}

	async function fetchImagesIntegrada(datosCartas) {

		console.debug(`fetchImagesIntegrada : abriendo ${datosCartas.length} datosCartas len`)

		if (!datosCartas || datosCartas.length === 0 || datosCartas === null || datosCartas === undefined) { return }

		// limpieza
		var datosCartasClean = datosCartas.filter(x => x !== null && x !== undefined)
		console.debug(`fetchImagesIntegrada : ${datosCartasClean.length} datosCartasClean len`)

		console.debug(`fetchImagesIntegrada : abriendo ${datosCartas.length} datosCartas len`)

		try {
			const promiseFrente = datosCartasClean.map(fetchImageData)
			var imageData = await Promise.all(promiseFrente);

			console.debug(`fetchImagesIntegrada : imageData trae ${JSON.stringify(imageData)}`)

			if (imageData[0].name) {
				console.debug(`se trajeron ${imageData.length} cartas, como ${imageData[0].name}`)
			}

		} catch (error) {
			console.error(error)
		}

		// determinar cuales mazos le pasamos
		// var mazosCartas = datosCartas.map(x=>x.mazo)

		// agregar imgs del reverso si hace falta
		var imageDataRev = []
		try {
			if (mazoTieneRev === true) {
				const promiseRev = datosCartasClean.map(fetchImageDataRev)
				imageDataRev = await Promise.all(promiseRev);

				console.debug('primer carta en imageData es:', imageData[0].name, imageDataRev[0].name)
			}
		} catch (error) {
			console.debug(error)
		}

		//imageData = imageData.concat(imageDataRev)
		//console.debug(`desp de fijarse rev, se trajeron ${imageData.length} cartas, ${imageDataRev.length} son rev`)

		var listaCartas = []
		for (let i = 0; i < imageData.length; i++) {
			var imagenInd = imageData[i]
			if (!imagenInd) {
				console.debug(`falta carta ${i}/${imageData.length}, borrando...`)
				continue
			}
			if (!imagenInd.src) {
				console.debug(`falta src ${imagenInd}, borrando...`)
				imageData.splice(i)
				continue
			}

			// integrar el reverso como rev
			if (mazoTieneRev === true && imageDataRev[i]) {
				if (imageDataRev[i].src) {
					imagenInd.rev = imageDataRev[i].src
				}
			}


			listaCartas.push(imagenInd.name)
			//console.debug(imageData[i].name)
		}

		console.debug(`lista tiene ${listaCartas.length}: ${listaCartas}`)
		console.debug(`listaCartas verificado tiene ${listaCartas.length} imagenes`)

		setCartasLoaded(true)

		// setear el estado
		setImages(prev => {
			var imgViejos = [prev]
			imgViejos = imgViejos.concat(imageData)
			return imgViejos
		})
	}

	const fetchImageData = async (carta) => {
		try {
			const fullPath = `${path}${carta.svg_front}`;
			const URL = `https://${host}/${fullPath}`;
			const response = await fetch(URL);
			if (!response.ok) { throw new Error(`respuesta vacia`) }
			const blob = await response.blob();
			const reader = new FileReader();
			return new Promise((resolve) => {
				reader.onloadend = () => {
					resolve({
						src: reader.result,
						name: carta.svg_front,
						i18: carta.nombre_18full
					});
				};
				reader.readAsDataURL(blob);
			});
		} catch (error) {
			console.error(`error de fetchImageData:`, error.message)
			return
		}
	};

	const fetchImageDataRev = async (carta) => {
		try {
			const fullPath = `${path}${carta.svg_back}`;
			const URL = `https://${host}/${fullPath}`;
			const response = await fetch(URL);
			if (!response.ok) { throw new Error(`respuesta vacia`) }
			const blob = await response.blob();
			const reader = new FileReader();
			return new Promise((resolve) => {
				reader.onloadend = () => {
					resolve({
						src: reader.result,
						name: carta.svg_back,
						i18: carta.nombre_18full
					});
				};
				reader.readAsDataURL(blob);
			});
		} catch (error) {
			console.error(`error de fetchImageDataRev:`, error.message)
			return
		}
	};

	var droppedNames
	if (droppedCards) {
		droppedNames = Object.values(droppedCards)
			.filter(x => x !== undefined && x !== null)
			.flat()
			.map(card => card.name);
	}

	// ajuste dinamico de height/width del canvas
	useEffect(() => {

		// Calculate the dimensions of img2 in the viewport
		const updateTableroDimensions = () => {
			const tableroElement = document.getElementById("canvas-img"); // Use an id attribute on img2
			if (tableroElement) {
				const rect = tableroElement.getBoundingClientRect();
				setTableroWidth(rect.width);
				setTableroHeight(rect.height);
				setTableroWidthZero();
				setTableroHeightZero();
			}
		};

		window.addEventListener("resize", updateTableroDimensions);
		updateTableroDimensions();

		return () => {
			// Clean up the event listener when the component unmounts
			window.removeEventListener("resize", updateTableroDimensions);
		};
	}, []); // corre una vez al inicio

	//////////////////////
	/* 		PUNTOS		*/
	//////////////////////

	// trae array de IDs para cada carta seleccionada
	async function traerCartasPorNumero(cartasSeleccionadas) {
		// traer array de cartas seleccionadas como rutas a la img
		if (!cartasSeleccionadas) {
			console.error(`no hay cartasSeleccionadas en traerCartasPorNumero`)
			return
		}
		console.debug(`traerCartasPorNumero tiene cartas ${JSON.stringify(cartasSeleccionadas)} en mazo ${mazo}`)
		var cartasArray = []
		const re = /(\d){1,3}/    // numero entre 1-3 cifras

		for (let i = 0; i < cartasSeleccionadas.length; i++) {

			// saltar las undefined
			if (cartasSeleccionadas[i] === undefined || cartasSeleccionadas[i] === null) {
				console.error(`saltando carta seleccionada #${i}, undefined`)
				continue
			}

			// Check if cartasSeleccionadas[i] is a string
			if (typeof cartasSeleccionadas[i] !== 'string') {
				console.error(`cartasSeleccionadas[${i}] is not a string, redefiniendolo: ${JSON.stringify(cartasSeleccionadas[i])}`);
				cartasSeleccionadas[i] = cartasSeleccionadas[i].id
			}

			// para cada ruta de img, extrae el ID en mazo
			try {
				var cartaIDmatch = cartasSeleccionadas[i].match(re)
				console.debug(`traerCartasPorNumero para carta seleccionada index ${i}`)
			} catch (error) {
				console.error(error)
			}

			if (!cartaIDmatch) {
				console.error(`Fallo de regex para carta ${cartasSeleccionadas[i]}`)
				continue
			}
			if (cartaIDmatch.length >= 1) {
				console.debug(cartasSeleccionadas)
				var cartaID = cartaIDmatch[0]
				console.debug(`cartaID es ${cartaID}`)
			} else {
				console.error(`Fallo de grupos de regex para carta ${cartasSeleccionadas[i]}`)
				continue;
			}

			// convierte el ID en mazo al ID en DB, para poder calcular puntos
			try {
				console.debug(`trayendo datos para para carta ${cartaID} en mazo`)
				const cartaData = await cartaPorNumero(mazo, cartaID)
				if (!cartaData) {
					console.error(`no hay cartaData para carta ${cartaID}`)
					continue
				} else {
					console.debug(`cartaData es ${cartaData}, pusheando al array`)
					cartasArray.push(cartaData["id"])
				}
			} catch (error) {
				console.error(`Error en traer cartaPorNumero para ${mazo} # ${cartaID}`)
				continue;
			}

		}
		console.debug(`traerCartasPorNumero trajo: ${cartasArray}`)
		return cartasArray
	}

	// selectedCards es array seco ["Trends/1.png", "Trends/2.png"]
	async function calcularPuntos(tiempoRestante) {
		var puntos = 0
		console.debug(`Calculando puntos, tiempo restante ${tiempoRestante}s ...`)

		// traer array de IDs para todas las cartas seleccionadas
		const cartasArray = await traerCartasPorNumero(selectedCards)
		console.debug(`IDs de cartas son ${cartasArray}...`)

		// trae los objetos para todas las cartas seleccionadas
		const objetosCartas = await fetchCardData(cartasArray);
		//console.debug(`Objetos de cartas son: ${JSON.stringify(objetosCartas)}`)

		// esto guarda las jugadas en db, no devuelve nada aca
		await jugarVariasCartas(selectedPartida, selectedEquipo, cartasArray)

		// calcula los puntos por cartas
		const puntosDeCartasData = await puntosCartas(objetosCartas)
		console.debug(`calculo de puntos de cartas devuelve ${JSON.stringify(puntosDeCartasData)}`)

		// puntos por valor_propio en cartas
		const puntosDeCartas = puntosDeCartasData.valor_propio
		puntos += puntosDeCartas
		console.debug(`Puntos por cartas son ${puntosDeCartas}...`)

		// calcula los puntos por tiempo
		var puntosSprint = await calcularPuntosSprint(selectedPartida, mazo, tiempoRestante)
		puntos += puntosSprint
		console.debug(`Puntos por tiempo restante son ${puntosSprint}...`)

		console.debug(`Puntos totales del sprint son ${puntos}, guardando...`)

		// puntos
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "puntos": puntos })

		// porter
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_buyers_mod": puntosDeCartasData.porter_buyers })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_entrants_mod": puntosDeCartasData.porter_entrants })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_rivalry_mod": puntosDeCartasData.porter_rivalry })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_substitutes_mod": puntosDeCartasData.porter_substitute })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_suppliers_mod": puntosDeCartasData.porter_suppliers })

		// amit
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "driver_complementary_mod": puntosDeCartasData.driver_complementary })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "driver_efficiency_mod": puntosDeCartasData.driver_efficiency })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "driver_lockin_mod": puntosDeCartasData.driver_lockin })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "driver_novelty_mod": puntosDeCartasData.driver_novelty })

		// valwheel
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "valwheel_how_mod": puntosDeCartasData.valwheel_how })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "valwheel_what_mod": puntosDeCartasData.valwheel_what })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "valwheel_who_mod": puntosDeCartasData.valwheel_who })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "valwheel_why_mod": puntosDeCartasData.valwheel_why })

		// maturity
		calcularPuntosMaturity(selectedPartida, selectedEquipo, mazo, puntos)

		console.debug(`navegando a ${sigRuta}`)
		navigate(sigRuta);
	}

	// devuelve un array de objetos con todas las cartas seleccionadas,
	// donde blueocean esta sobreescrito con el valor elegido por el jugador
	function updateBlueOceanValues(objetosTodasCartas, datosSelectedCards) {

		const datosSoloVal = datosSelectedCards.map((object) => {
			const { src, ...rest } = object;
			return rest;
		})

		console.debug(`pisando valores blue ocean para ${JSON.stringify(datosSoloVal)}`)

		// Create a map for faster lookup of selectedCards by name
		const selectedCardsMap = new Map(datosSelectedCards.map(card => [card.name, card.val]));

		// Iterate through objetosCartas and update the blueocean property
		const updatedObjetosCartas = objetosTodasCartas.map(objeto => {
			console.debug(`para carta ${objeto.nombre_es}, pisando blue ocean ${objeto.blueocean}...`)
			const val = selectedCardsMap.get(objeto.svg_front);
			if (val !== undefined) {
				console.debug(`para carta ${objeto.nombre_es}, pisando blue ocean ${objeto.blueocean} a ${val}`)
				// If a matching card is found, update the blueocean property
				return { ...objeto, blueocean: val };
			} else {
				console.error(`para carta ${objeto.nombre_es}, val es undefined!`)
			}
			return objeto; // If no match is found, keep the original object
		});

		return updatedObjetosCartas;
	}

	// devuelve un array de objetos con todas las cartas seleccionadas,
	// donde agregamos un valor recuadro, con el id pasado por selectedCards
	function updateVirtualValues(objetosCartas, datosSelectedCards) {

		console.debug(`pisando valores virtuales para ${JSON.stringify(datosSelectedCards)}`)
		console.debug(`primer carta es ${datosSelectedCards[0].name}, ${datosSelectedCards[0].recuadro}`)

		// Create a map for faster lookup of selectedCards by name
		const selectedCardsMap = new Map(datosSelectedCards.map(card => [card.name, card.recuadro]));

		console.debug(`selectedCardsMap es ${JSON.stringify(selectedCardsMap)}`)

		// Iterate through objetosCartas and add the recuadro property
		const updatedObjetosCartas = objetosCartas.map(objeto => {
			console.debug(`entrando a UpdatedObjetosCartas para ${JSON.stringify(objeto)}`)
			console.debug(`objeto.svg_front: ${objeto.svg_front}`);
			const recuadro = selectedCardsMap.get(objeto.svg_front);
			console.debug(`recuadro para ${objeto.svg_front} es ${recuadro}`)
			if (recuadro !== undefined) {
				// If a matching card is found, add the recuadro property
				return { ...objeto, recuadro: recuadro };
			}
			console.debug(`saliendo de UpdatedObjetosCartas para ${JSON.stringify(objeto)}`)
			return objeto; // If no match is found, keep the original object
		});

		console.debug(`updateVirtualValues devuelve ${updatedObjetosCartas}`)
		return updatedObjetosCartas;
	}

	// devuelve un array de objetos con todas las cartas seleccionadas,
	// donde agregamos un valor recuadro, con el id pasado por selectedCards
	function updateVirtualBlueOceanValues(objetosCartas, datosSelectedCards) {

		console.debug(`pisando valores blue ocean virtuales para ${JSON.stringify(datosSelectedCards)}`)
		console.debug(`primer carta es ${datosSelectedCards[0].name}, ${datosSelectedCards[0].recuadro}`)

		// RECUADRO

		const virtual = updateVirtualValues(objetosCartas, datosSelectedCards)
		console.debug(`blue ocean virtual (recuadros) devuelve ${JSON.stringify(virtual)}`)
		const updatedObjetosCartasBlue = updateBlueOceanValues(virtual, datosSelectedCards)
		console.debug(`blue ocean virtual (blue) devuelve ${JSON.stringify(virtual)}`)

		/*
		// Create a map for faster lookup of selectedCards by name
		const selectedCardsMapRecuadro = new Map(datosSelectedCards.map(card => [card.name, card.recuadro]));
		console.debug(`selectedCardsMap es ${JSON.stringify(selectedCardsMapRecuadro)}`)

		// Iterate through objetosCartas and add the recuadro property
		const updatedObjetosCartasRecuadro = objetosCartas.map(objeto => {
			console.debug(`entrando a UpdatedObjetosCartas para ${JSON.stringify(objeto)}`)
			console.debug(`objeto.svg_front: ${objeto.svg_front}`);
			const recuadro = selectedCardsMapRecuadro.get(objeto.svg_front);
			console.debug(`recuadro para ${objeto.svg_front} es ${recuadro}`)
			if (recuadro !== undefined) {
				// If a matching card is found, add the recuadro property
				return { ...objeto, recuadro: recuadro };
			}
			console.debug(`saliendo de UpdatedObjetosCartas para ${JSON.stringify(objeto)}`)
			return objeto; // If no match is found, keep the original object
		});

		// BLUE OCEAN

		// Create a map for faster lookup of selectedCards by name
		const selectedCardsMapBlue = new Map(updatedObjetosCartasRecuadro.map(card => [card.name, card.val]));

		// Iterate through objetosCartas and update the blueocean property
		const updatedObjetosCartasBlue = updatedObjetosCartasRecuadro.map(objeto => {
			const val = selectedCardsMapBlue.get(objeto.svg_front);
			if (val !== undefined) {
				// If a matching card is found, update the blueocean property
				return { ...objeto, blueocean: val };
			}
			return objeto; // If no match is found, keep the original object
		});
		*/

		console.debug(`updateVirtualValues devuelve ${JSON.stringify(updatedObjetosCartasBlue)}`)
		return updatedObjetosCartasBlue;
	}

	// selectedCards es array de objetos
	async function calcularPuntosBlueOcean(tiempoRestante) {
		var puntos = 0
		console.debug(`Calculando puntos para blue ocean, tiempo restante ${tiempoRestante}s ...`)
		console.debug(`puntos: selectedCards es ${JSON.stringify(selectedCards)}`)

		// solo aceptar las primeras N selecciones por si se rompe
		var selectedCardsLimitado = []
		for (let i = 0; i < limiteMovimientos * 2; i++) { // *2 hasta resolver el bug de duplicacion
			const card = selectedCards[i];
			console.debug(`selectedcards card es ${JSON.stringify(card)}, tipo ${typeof card}`);
			if (typeof card !== 'object' || typeof card.val !== 'number') {
				continue; // Skip to the next iteration
			}
			selectedCardsLimitado.push(card); // Push the card into the new array
		}
		console.debug(`puntos: selectedCardsLimitado es ${JSON.stringify(selectedCardsLimitado)}`)


		// separar selectedCards a solo un array de paths
		const selectedCardsPaths = selectedCardsLimitado.filter(x => x.val >= 0).map(x => x.name)
		console.debug(`puntos: selectedCardsPaths es ${JSON.stringify(selectedCardsPaths)}`)

		// traer array de IDs para todas las cartas seleccionadas
		const cartasArray = await traerCartasPorNumero(selectedCardsPaths)
		console.debug(`IDs de cartas son ${cartasArray}...`)

		// trae los objetos para todas las cartas seleccionadas
		const objetosCartas = await fetchCardData(cartasArray);
		//console.debug(`Objetos de cartas son: ${JSON.stringify(objetosCartas)}`)

		// sobreescribir todas las cartas en el mazo con los valores blue ocean elegidos
		const objetosCartasMod = updateBlueOceanValues(cartas, selectedCardsLimitado)
		//console.debug(`Objetos de cartas mod son: ${JSON.stringify(objetosCartasMod)}`)

		// esto guarda las jugadas en db, no devuelve nada aca
		await jugarVariasCartasBlueOcean(selectedPartida, selectedEquipo, objetosCartasMod)

		// calcula los puntos por cartas
		const puntosDeCartasData = await puntosCartas(objetosCartas)
		console.debug(`calculo de puntos de cartas devuelve ${JSON.stringify(puntosDeCartasData)}`)
		const puntosDeCartas = puntosDeCartasData.valor_propio
		puntos += puntosDeCartas
		console.debug(`Puntos por cartas son ${puntosDeCartas}...`)

		// calcula los puntos por tiempo
		var puntosSprint = await calcularPuntosSprint(selectedPartida, mazo, tiempoRestante)
		puntos += puntosSprint
		console.debug(`Puntos por tiempo restante son ${puntosSprint}...`)

		console.debug(`Puntos totales del sprint son ${puntos}, guardando...`)

		// puntos
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "puntos": puntos })

		// porter
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_buyers_mod": puntosDeCartasData.porter_buyers })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_entrants_mod": puntosDeCartasData.porter_entrants })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_rivalry_mod": puntosDeCartasData.porter_rivalry })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_substitutes_mod": puntosDeCartasData.porter_substitute })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_suppliers_mod": puntosDeCartasData.porter_suppliers })

		// amit
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "driver_complementary_mod": puntosDeCartasData.driver_complementary })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "driver_efficiency_mod": puntosDeCartasData.driver_efficiency })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "driver_lockin_mod": puntosDeCartasData.driver_lockin })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "driver_novelty_mod": puntosDeCartasData.driver_novelty })

		// valwheel
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "valwheel_how_mod": puntosDeCartasData.valwheel_how })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "valwheel_what_mod": puntosDeCartasData.valwheel_what })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "valwheel_who_mod": puntosDeCartasData.valwheel_who })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "valwheel_why_mod": puntosDeCartasData.valwheel_why })

		console.debug(`navegando a ${sigRuta}`)
		navigate(sigRuta);
	}

	// selectedCards es array de objetos
	async function calcularPuntosBlueOceanVirtual(tiempoRestante) {
		var puntos = 0
		console.debug(`Calculando puntos para blue ocean virtual, tiempo restante ${tiempoRestante}s ...`)

		// calcula los puntos por tiempo
		var puntosSprint = await calcularPuntosSprint(selectedPartida, mazo, tiempoRestante)
		puntos += puntosSprint
		console.debug(`Puntos por tiempo restante son ${puntosSprint}...`)

		if (selectedCards.length > 0) {
			console.debug(`puntos: selectedCards es ${JSON.stringify(selectedCards)}`)
			// separar selectedCards a solo un array de paths
			const selectedCardsPaths = selectedCards.map(x => x.name)
			console.debug(`puntos: selectedCardsPaths es ${JSON.stringify(selectedCardsPaths)}`)

			// traer array de IDs para todas las cartas seleccionadas
			const cartasArray = await traerCartasPorNumero(selectedCardsPaths)
			console.debug(`IDs de cartas son ${cartasArray}...`)

			// trae los objetos para todas las cartas seleccionadas
			const objetosCartas = await fetchCardData(cartasArray);
			//console.debug(`Objetos de cartas son: ${JSON.stringify(objetosCartas)}`)

			// sobreescribir solo las cartas seleccionadas con los recuadros
			const objetosCartasMod = updateVirtualBlueOceanValues(objetosCartas, selectedCards)
			//console.debug(`Objetos de cartas mod son: ${JSON.stringify(objetosCartasMod)}`)

			// esto guarda las jugadas en db, no devuelve nada aca
			await jugarVariasCartasBlueOceanVirtual(selectedPartida, selectedEquipo, objetosCartasMod)

			// calcula los puntos por cartas
			const puntosDeCartasData = await puntosCartas(objetosCartas)
			console.debug(`calculo de puntos de cartas devuelve ${JSON.stringify(puntosDeCartasData)}`)
			const puntosDeCartas = puntosDeCartasData.valor_propio
			puntos += puntosDeCartas
			console.debug(`Puntos por cartas son ${puntosDeCartas}...`)

			// porter
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_buyers_mod": puntosDeCartasData.porter_buyers })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_entrants_mod": puntosDeCartasData.porter_entrants })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_rivalry_mod": puntosDeCartasData.porter_rivalry })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_substitutes_mod": puntosDeCartasData.porter_substitute })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_suppliers_mod": puntosDeCartasData.porter_suppliers })

			// amit
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "driver_complementary_mod": puntosDeCartasData.driver_complementary })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "driver_efficiency_mod": puntosDeCartasData.driver_efficiency })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "driver_lockin_mod": puntosDeCartasData.driver_lockin })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "driver_novelty_mod": puntosDeCartasData.driver_novelty })

			// valwheel
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "valwheel_how_mod": puntosDeCartasData.valwheel_how })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "valwheel_what_mod": puntosDeCartasData.valwheel_what })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "valwheel_who_mod": puntosDeCartasData.valwheel_who })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "valwheel_why_mod": puntosDeCartasData.valwheel_why })
		} else {
			console.error(`no jugaste cartas! ups....`)
		}

		console.debug(`Puntos totales del sprint son ${puntos}, guardando...`)

		// puntos
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "puntos": puntos })


		console.debug(`navegando a ${sigRuta}`)
		navigate(sigRuta);
	}

	// selectedCards es array de objetos
	async function calcularPuntosVirtual(tiempoRestante) {
		var puntos = 0
		console.debug(`Calculando puntos para virtual, tiempo restante ${tiempoRestante}s ...`)

		// calcula los puntos por tiempo
		var puntosSprint = await calcularPuntosSprint(selectedPartida, mazo, tiempoRestante)
		puntos += puntosSprint
		console.debug(`Puntos por tiempo restante son ${puntosSprint}...`)

		if (selectedCards.length > 0) {
			console.debug(`puntos: selectedCards es ${JSON.stringify(selectedCards)}`)
			// separar selectedCards a solo un array de paths
			const selectedCardsPaths = selectedCards.map(x => x.name)
			console.debug(`puntos: selectedCardsPaths es ${JSON.stringify(selectedCardsPaths)}`)

			// traer array de IDs para todas las cartas seleccionadas
			const cartasArray = await traerCartasPorNumero(selectedCardsPaths)
			console.debug(`IDs de cartas son ${cartasArray}...`)

			// trae los objetos para todas las cartas seleccionadas
			const objetosCartas = await fetchCardData(cartasArray);
			//console.debug(`Objetos de cartas son: ${JSON.stringify(objetosCartas)}`)

			// sobreescribir solo las cartas seleccionadas con los recuadros
			const objetosCartasMod = updateVirtualValues(objetosCartas, selectedCards)
			//console.debug(`Objetos de cartas mod son: ${JSON.stringify(objetosCartasMod)}`)

			// esto guarda las jugadas en db, no devuelve nada aca
			await jugarVariasCartasVirtual(selectedPartida, selectedEquipo, objetosCartasMod)

			// calcula los puntos por cartas
			const puntosDeCartasData = await puntosCartas(objetosCartas)
			console.debug(`calculo de puntos de cartas devuelve ${JSON.stringify(puntosDeCartasData)}`)
			const puntosDeCartas = puntosDeCartasData.valor_propio
			puntos += puntosDeCartas
			console.debug(`Puntos por cartas son ${puntosDeCartas}...`)

			// porter
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_buyers_mod": puntosDeCartasData.porter_buyers })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_entrants_mod": puntosDeCartasData.porter_entrants })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_rivalry_mod": puntosDeCartasData.porter_rivalry })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_substitutes_mod": puntosDeCartasData.porter_substitute })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "porter_suppliers_mod": puntosDeCartasData.porter_suppliers })

			// amit
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "driver_complementary_mod": puntosDeCartasData.driver_complementary })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "driver_efficiency_mod": puntosDeCartasData.driver_efficiency })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "driver_lockin_mod": puntosDeCartasData.driver_lockin })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "driver_novelty_mod": puntosDeCartasData.driver_novelty })

			// valwheel
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "valwheel_how_mod": puntosDeCartasData.valwheel_how })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "valwheel_what_mod": puntosDeCartasData.valwheel_what })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "valwheel_who_mod": puntosDeCartasData.valwheel_who })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "valwheel_why_mod": puntosDeCartasData.valwheel_why })
		} else {
			console.error(`no jugaste cartas! ups....`)
		}

		console.debug(`Puntos totales del sprint son ${puntos}, guardando...`)

		// puntos
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "puntos": puntos })


		console.debug(`navegando a ${sigRuta}`)
		navigate(sigRuta);
	}

	// puntos se calculan basado en ganancia
	// selectedCards es array seco
	async function calcularPuntosEcosystem(tiempoRestante) {
		const fn = "TEST Sprint/calcularPuntosEcosystem"

		// DIVISOR GENERAL DE PUNTAJE HARDCODEADO
		const divisor = 2000

		var puntos = 0
		console.debug(`Calculando puntos para ecosystem, tiempo restante ${tiempoRestante}s ...`)

		// traer array de IDs para todas las cartas seleccionadas
		const cartasArray = await traerCartasPorNumero(selectedCards)
		console.debug(`IDs de cartas son ${cartasArray}...`)

		// trae los objetos para todas las cartas seleccionadas
		const objetosCartas = await fetchCardData(cartasArray);
		//console.debug(`Objetos de cartas son: ${JSON.stringify(objetosCartas)}`)

		// sumar todos los modificadores de las cartas
		var mod = 0
		for (let i = 0; i < objetosCartas.length; i++) {
			const carta = objetosCartas[i]
			console.debug(`${fn}: carta es ${JSON.stringify(carta)}`)
			const val = Number(carta['valor_propio'])
			console.debug(`${fn}: ${mod} + ${val} de carta ${carta.id} = ${mod+val}`)
			mod += val;
		}
		// asegurar que no pase a nan
		if (isNaN(mod)){mod=0.23}

		// aplicar modificador a dineroOrig
		const dineroGanado = ecosystemDineroOrig * mod
		
		// aplicar puntos por ganancia
		var puntosPorGanancia = dineroGanado / divisor
		if (puntosPorGanancia < 0){puntosPorGanancia = 0}
		console.debug(`Puntos por ganancia son ${puntosPorGanancia}; ganancia ${dineroGanado} sobre ${ecosystemDineroOrig}`)
		puntos += puntosPorGanancia

		// esto guarda las jugadas en db, no devuelve nada aca
		await jugarVariasCartas(selectedPartida, selectedEquipo, cartasArray)

		// calcula los puntos por tiempo
		var puntosSprint = await calcularPuntosSprint(selectedPartida, mazo, tiempoRestante)
		puntos += puntosSprint
		console.debug(`Puntos por tiempo restante son ${puntosSprint}, guardando...`)

		// guardar puntos
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "puntos": puntos })
		agregarPuntosEquipo(selectedPartida, selectedEquipo, { "ecosistema_dinero_mod": dineroGanado }) // el back lo suma esto al orig ya

		// x si las dudas
		//const circulo = JSON.stringify(ecosystemCirculo)

		// guardar todo lo particular a ecosystem
		await puntosHelpers.agregarEcosystem(
			selectedPartida, selectedEquipo,
			ecosystemCirculo, ecosystemOferta, ecosystemPropuesta
		)

		console.debug(`navegando a ${sigRuta}`)
		navigate(sigRuta);
	}

	//////////////////////
	/* 		CLICKS		*/
	//////////////////////

	// para contar la cant de cartas en todas las sub-array de droppedCards
	function countSubArrayElements(data) {
		// Initialize a count variable
		let count = 0;

		// Loop through the object's values (which are arrays)
		for (const subArray of Object.values(data)) {
			// Iterate through each element in the sub-array
			for (const element of subArray) {
				count++
			}
		}

		return count;
	}

	// filtrada para solo incluir cierto array
	// ROTO
	function countSubArrayElementsFiltrada(data, included) {
		let count = 0;
		const values = Object.values(data)

		console.debug(`countSubArr : data values tiene len ${values.length}`)
		console.debug(`countSubArr : subArray[11] tiene len ${values[11].length}`)

		// Loop through the object's values (which are arrays)
		for (let i = 0; i < values.length; i++) {
			const subArray = values[i]
			// filtrar el subarray
			const subArrayFiltrado = filterArrayInclude(subArray, included)

			// Iterate through each element in the sub-array
			for (let i = 0; i < subArrayFiltrado.length; i++) {
				count++
			}
		}

		console.debug(`countSubArr : ${count}`)
		return count;
	}

	// excluir DataDriven y Platform de jugadas
	function filterArrayExclude(inputArray, excluded) {
		// Use the filter method to exclude objects with specified mazos
		const filteredArray = inputArray.filter(obj => !excluded.includes(obj.mazo));
		return filteredArray;
	}

	// solo incluye los mazos que le digas (SIN TESTEAR)
	function filterArrayInclude(inputArray, included) {
		// Use the filter method to exclude objects with specified mazos
		const filteredArray = inputArray.filter(obj => included.includes(obj.mazo));
		return filteredArray;
	}

	// bugeado, no toma a los valores de la conf ini
	// blue ocean virt bugeado x2: toma cada carta x2, y tambien permite seguir guardando igual con modal
	function movimientosDisponibles() {

		var res

		if (selectedSprint === "SprintVirtual" || selectedSprint === "SprintBlueOceanVirtual" || selectedSprint === "SprintPlatformVirtual") {
			// sprint virtuales toman a DroppedCards
			if (!droppedCards) { console.debug(`movDisp: no hay droppedCards`); return limiteMovimientos }

			// contar las cartas en cada zona
			const cantCartasOrig = countSubArrayElements(droppedCards)

			// filtrar jugadas para no contar a los que usan un canvas distinto
			const excludedMazos = ["DataDriven", "Platform", "Ecosystem"];
			const jugadasFiltradas = filterArrayExclude(jugadas, excludedMazos);
			console.debug(`movDisp: hay ${selectedCards.length} selected para guardar, ${jugadas.length} jugadas sin filtrar y ${jugadasFiltradas.length} filtradas`)

			// menos las cartas jugadas en rondas previas
			const cantCartas = cantCartasOrig - jugadasFiltradas.length
			console.debug(`movDisp: ${cantCartasOrig} - ${jugadasFiltradas.length} = ${cantCartas}`)

			console.debug(`movDisp: lim es ${limiteMovimientos}, dropped son ${cantCartas}`)

			res = Number(Math.max(limiteMovimientos - cantCartas, 0))
			/*
			} else if (selectedCardsModal.length > 0) {
				// PLATFORM y BLUE OCEAN
				return Math.max(limiteMovimientos - selectedCardsModal.length, 0)
			*/

		} else if (selectedSprint === "SprintDataDrivenVirtual") {
			// canvas propio, siempre 10 jugadas (2 x categoria)

			// sacar las jugadas que tengan recuadro null (blue ocean)
			const jugadasSinNull = jugadas.filter(obj => obj.recuadrocanvas_id !== null);
			console.debug(`movDisp: jugadas es ${jugadas.length}, sin null ${jugadasSinNull.length}`)

			// cartas disponibles
			const cartasDisp = numCartasBaraja
			// cartas ya jugadas en el sprint (existentes-historicas = solo actuales)
			const cartasJugadas = countSubArrayElements(droppedCards) - jugadasSinNull.length

			//console.debug(`movDisp: jugadas es ${JSON.stringify(jugadas)}`)
			//console.debug(`movDisp: droppedCards es ${JSON.stringify(droppedCards)}`)


			console.debug(`movDisp: selectedCards=${selectedCards.length}, (jugadas-blueocean)-sub(droppedCards)=${jugadasSinNull.length - countSubArrayElements(droppedCards)}`)

			/*
			no incrementan
				cantCartasJugadas=${cantCartasJugadas}, 
				mazo-cartas=${cartasDisp-cartas.length}
			*/

			console.debug(`movDisp: hay ${cartasDisp} en mazo DD, - ${cartasJugadas} jugadas, lim ${limiteMovimientos}`)

			res = Number(Math.max(limiteMovimientos - cartasJugadas, 0))

		} else if (selectedSprint === "SprintBlueOcean" || selectedSprint === "SprintEcosystem") {
			// DUPLICAN POR ALGUN MOTIVO, HARDCODEADO DIVIDIR AL MEDIO
			if (cantCartasJugadas > 0) {
				const jugadasFixed = Math.ceil(cantCartasJugadas/2) // divide al medio y redondea para arriba
				res = Number(Math.max(limiteMovimientos - jugadasFixed, 0))
			}
			else {
				res = limiteMovimientos
			}

		} else {
			// todas las otras
			console.debug(`movDisp: lim es ${limiteMovimientos}, selected son ${selectedCards.length}`)
			res = Number(Math.max(limiteMovimientos - selectedCards.length, 0))
		}

		//setCantMovsDisp(res) <- too many rerenders
		console.debug(`movDisp es ${res}`)
		return res
	}

	// cardName es el path

	const handleCardClick = (cardName) => {

		const movs = movimientosDisponibles()

		// Si la carta ya está seleccionada, la deseleccionamos.
		if (selectedCards.includes(cardName)) {
			setSelectedCards(selectedCards.filter(name => name !== cardName));
		} else {
			// no permitir click si ya se llego al limite
			if (movs === 0) { return }
			// De lo contrario, la añadimos.
			setSelectedCards([...selectedCards, cardName]);
		}
	};

	const handleCardClickModal = (cardName) => {

		const movs = movimientosDisponibles()

		setTemporizadorPausado(true) // <--------------------------------------------------------------------------------------
		// Si la carta ya está seleccionada, la deseleccionamos. De lo contrario, la añadimos.
		setSelectedCard(addRevBeforeLastFourChars(cardName));
		if (selectedCardsModal.includes(cardName)) {
			console.debug(`intentaste deseleccionar una carta, aca no se puede eso`)
			//setSelectedCards(selectedCards.filter(name => name !== cardName));
			return;
		} else {
			// no permitir click si ya se llego al limite
			if (movs === 0) { return }
			// armar al selector multiple choice especifico para Platform o Ecosystem
			if (selectedSprint === "SprintPlatform" || selectedSprint === "SprintPlatformVirtual") {
				selectorOpcionesCartasPlatform(cardName)
			} else if (selectedSprint === "SprintEcosystem" || selectedSprint === "SprintEcosystemVirtual") {
				selectorOpcionesCartasEcosystem(cardName)
			}
		}
	};

	const handleCardClickBlueOcean = (cardName) => {

		console.debug(`movs disp son ${movimientosDisponibles()}`)

		if (movimientosDisponibles() === 0) { return null }
		else {
			setTemporizadorPausado(true) // <--------------------------------------------------------------------------------------

			// Si la carta ya está seleccionada, nada. De lo contrario, la añadimos.
			setSelectedCard(cardName);
			if (selectedCardsModal.includes(cardName)) {
				console.debug(`intentaste deseleccionar una carta, aca no se puede eso`)
				//setSelectedCards(selectedCards.filter(name => name !== cardName));
				return;
			} else {
				// armar al selector multiple choice especifico para Blue Ocean
				selectorOpcionesCartasBlueOcean(cardName)
			}
		}
	};

	//////////////////////
	/* 		DROPZONE	*/
	//////////////////////

	// activa al dropear la carta sobre el recuadro
	const handleCardDrop = (zone) => (item) => {

		console.debug(`handleCardDrop: dropeando carta ${JSON.stringify(item)} en ${zone}`)

		// guardando seleccion para max de jugadas
		console.debug(`handleCardDrop: cantCartasJugadas es ${cantCartasJugadas}`)
		const cant1 = cantCartasJugadas + 1
		setCantCartasJugadas(cant1)

		if (selectedSprint === "SprintBlueOceanVirtual") {
			setTemporizadorPausado(true)
			setSelectedCard(item.id);
			setSelectedZone(zone)
			selectorOpcionesCartasBlueOcean(item.id)
		}

		// guardar la seleccion para puntaje
		var cardObj = item
		cardObj.recuadro = zone
		setSelectedCards(prev => {
			let newSelected = [...prev];
			// agregar validacion para prevenir duplicados <------------------------
			newSelected.push(cardObj)
			return newSelected
		})

		// guardar el elemento visual
		setDroppedCards(prev => {
			const isAlreadyDropped = (prev[zone] || []).some(card => card.id === item.id);
			if (isAlreadyDropped) return prev;
			const currentCards = prev[zone] || [];
			return { ...prev, [zone]: [...currentCards, item] };
		});
		handleRemove(item.id);
	};

	// activa al clickear sobre una carta que ya esta en un recuadro
	const handleCardClickDropped = (card, recuadro) => {
		console.debug(`handleCardClickDropped con ${card.name} en ${recuadro}`)

		console.debug(`handleCardClickDropped: hay ${cartas.length} cartas, ${droppedCards[recuadro].length} en ese recuadro, ${cantCartasJugadas} jugadas, ${selectedCards.length} selected`)

		if (!droppedCards) {
			console.debug(`CONTROL handleCardClickDropped: no hay droppedCards`)
			return
		}

		try {
			//if(cartaLock===1){ return }
			//cartaLock(1) // lock movimiento de cartas

			// ignorar si carta no pertenece a este sprint
			//var parts = card.name.split('/');
			//console.debug(`handleCardClickDropped validando ${parts[0]} vs ${nombreSprint}: ${parts[0]===nombreSprint}`)
			//if (parts[0] !== nombreSprint){ return }

			// copiar obj existente
			let newDroppedCards = { ...droppedCards }
			console.debug(`handleCardClickDropped arr viejo es ${JSON.stringify(newDroppedCards)}`)

			// resolver lio de nomenclatura
			//var nomRecuadro = newDroppedCards[recuadro-1].nombre
			//console.debug(`nomRecuadro es ${nomRecuadro}`)
			var nomRecuadro = recuadro

			// entrar a ese recuadro y borrar esa carta especifica

			newDroppedCards[nomRecuadro] = newDroppedCards[nomRecuadro].filter(x => x.id !== card.name)
			console.debug(`handleCardClickDropped arr nuevo es ${JSON.stringify(newDroppedCards)}`)
			// sobreescribir el obj de recuadros
			setDroppedCards(newDroppedCards)

			// guardando seleccion para max de jugadas
			if (cantCartasJugadas === 0) {
				console.debug(`CONTROL : cantCartasJugadas = 0, saliendo`)
				//return 
			}
			else {
				console.debug(`CONTROL : cantCartasJugadas != 0, sacando 1`)
				setCantCartasJugadas(cant => cant - 1)
			}

			// borrar la carta de la seleccion para puntaje
			const totalEnRecuadros = countSubArrayElements(newDroppedCards)
			console.debug(`CONTROL: selectedCards es ${JSON.stringify(selectedCards)}`)
			console.debug(`CONTROL: newDroppedCards es tipo ${typeof newDroppedCards}`)
			console.debug(`CONTROL: newDroppedCards tiene ${totalEnRecuadros}`)
			if (totalEnRecuadros === 0) {
				console.debug(`CONTROL: 0 en recuadro -> 0 selected`)
				setSelectedCards([])
			} else {
				console.debug(`CONTROL: card.name es ${card.name}`)
				setSelectedCards(x => x.filter(carta => carta.id !== card.name)) // carta.name antes
			}

			// devolver la carta a la barra
			setCartas(prev => [...prev, { svg_front: card.name }]); // Asumiendo que sólo necesitas el name para representar la carta.
		} catch (error) {
			console.error(error)
		}
	};

	// controlar movimientos de cartas virt
	useEffect(() => {
		console.debug(`control cartas: hay ${cartas.length} cartas de ${numCartasBaraja} en baraja, ${countSubArrayElements(droppedCards)} en recuadros, ${cantCartasJugadas} jugadas, ${selectedCards.length} selected`)
	}, [cartas, droppedCards, cantCartasJugadas, selectedCards, numCartasBaraja]);

	// esto remueve a la carta de la barra al costado
	const handleRemove = (id) => {
		setCartas(x => x.filter(carta => carta.svg_front !== id));
	};


	//////////////////////
	/* 		MODAL		*/
	//////////////////////

	// traer lista de nombres de cartas para usar para el selector
	const selectorOpcionesCartasPlatform = (nombreCarta) => {
		console.debug(`selectorOpcionesCartas para ${nombreCarta}`) // devuelve "Platform/1.png"

		// encontrar indice en cartas de nombreCarta
		let datosCarta = cartas.find(obj => obj.svg_front === nombreCarta);
		console.debug(`datosCarta es ${JSON.stringify(datosCarta)}`)
		let index = cartas.indexOf(datosCarta)
		console.debug(`index is ${index}`)

		// traer opciones
		var arrayCartas = [
			cartas[index],
			cartas[index + 1],
			cartas[index + 2],
			cartas[index + 3],
			cartas[index + 4]
		]
		arrayCartas.filter(v => v !== null); // remover nulls
		console.debug(`arrayCartas en selector es ${JSON.stringify(arrayCartas)}`)

		// determinar cual es el maximo de opciones que se puede seleccionar
		switch (nombreCarta) {
			case "Platform/1.png": setNumOpcionesCartas(1); break;
			case "Platform/6.png": setNumOpcionesCartas(2); break;
			case "Platform/11.png": setNumOpcionesCartas(2); break;
			case "Platform/16.png": setNumOpcionesCartas(1); break;
			case "Platform/21.png": setNumOpcionesCartas(2); break;
			case "Platform/26.png": setNumOpcionesCartas(3); break;
			case "Platform/31.png": setNumOpcionesCartas(2); break;
			case "Platform/36.png": setNumOpcionesCartas(1); break;
			case "Platform/41.png": setNumOpcionesCartas(3); break;
			case "Platform/46.png": setNumOpcionesCartas(3); break;
			case "Platform/51.png": setNumOpcionesCartas(1); break;
			case "Platform/56.png": setNumOpcionesCartas(2); break;
			case "Platform/61.png": setNumOpcionesCartas(1); break;
			case "Platform/66.png": setNumOpcionesCartas(3); break;
			case "Platform/71.png": setNumOpcionesCartas(1); break;
			case "Platform/76.png": setNumOpcionesCartas(1); break;
			case "Platform/81.png": setNumOpcionesCartas(2); break;
			case "Platform/86.png": setNumOpcionesCartas(5); break;
			case "Platform/91.png": setNumOpcionesCartas(2); break;
			case "Platform/96.png": setNumOpcionesCartas(1); break;
			case "Platform/101.png": setNumOpcionesCartas(2); break;
			default: break;
		}

		// armar el selector
		// array de objetos carta
		setOpcionesCartas(arrayCartas)
		setModalOpen(true);

	}

	// traer lista de nombres de cartas para usar para el selector
	const selectorOpcionesCartasEcosystem = (nombreCarta) => {
		console.debug(`selectorOpcionesCartas para ${nombreCarta}`) // devuelve "Platform/1.png"

		// encontrar indice en cartas de nombreCarta
		let datosCarta = cartas.find(obj => obj.svg_front === nombreCarta);
		console.debug(`datosCarta es ${JSON.stringify(datosCarta)}`)
		let index = cartas.indexOf(datosCarta)
		console.debug(`index is ${index}`)

		// encontrar categoria
		const cat = datosCarta.categoria
		// encontrar todas de esa categoria
		var arrayCartas = cartas.filter(c => c.categoria === cat)
		// sacar nulls
		arrayCartas.filter(v => v !== null);
		console.debug(`arrayCartas en selector es ${JSON.stringify(arrayCartas)}`)

		// determinar cual es el maximo de opciones que se puede seleccionar
		var numOpciones
		switch (cat) {
			case "Costos de Estructura": numOpciones = 13; break;
			default: numOpciones = 1; break;
		}
		setNumOpcionesCartas(numOpciones)

		// armar el selector
		// array de objetos carta
		setOpcionesCartas(arrayCartas)
		setModalOpen(true);

	}

	// traer lista de nombres de cartas para usar para el selector
	const selectorOpcionesCartasBlueOcean = (nombreCarta) => {
		console.debug(`selectorOpcionesCartas para ${nombreCarta}`) // devuelve "BlueOcean/7.png"

		// encontrar indice en cartas de nombreCarta
		let datosCarta = cartas.find(obj => obj.svg_front === nombreCarta);
		console.debug(`datosCarta es ${JSON.stringify(datosCarta)}`);
		let index = cartas.indexOf(datosCarta);
		console.debug(`index is ${index}`);

		// determinar el valor base, o default a 0
		let valorBaseCarta = datosCarta.blueocean || 0;
		let objBaseCarta = modificadoresBlueOcean.find(x => x.val === valorBaseCarta);

		// armar el selector
		setTemporizadorPausado(true)
		setOpcionesCartas(modificadoresBlueOcean);
		setSelectedOption(objBaseCarta.val)
		setModalOpen(true);
	}

	// acepta {name: "BlueOcean/9.png", val: 3}
	const handleSelectorGuardar = (seleccionNueva) => {
		var seleccionNuevaPeroAsegurandonosQueSeaNumero = Number(seleccionNueva)
		if (seleccionNuevaPeroAsegurandonosQueSeaNumero === undefined || seleccionNuevaPeroAsegurandonosQueSeaNumero === NaN || seleccionNuevaPeroAsegurandonosQueSeaNumero === null) { return }
		console.debug(`guardando seleccion ${seleccionNuevaPeroAsegurandonosQueSeaNumero} para carta ${selectedCard}`)
		// agarra todas las cartas jugadas hasta el momento 
		var arrayCartasParaMandar = selectedCards

		// determinar carta para display para tacharlo
		var arrayModalParaMandar = selectedCards
		arrayModalParaMandar.push(selectedCard) // pushea el path directamente


		// armar objeto carta con valor para sobreescribir
		if (selectedSprint === "SprintBlueOceanVirtual") {
			var carta = {
				name: selectedCard,
				val: seleccionNuevaPeroAsegurandonosQueSeaNumero,
				recuadro: selectedZone
			}
		} else {
			var carta = {
				name: selectedCard,
				val: seleccionNuevaPeroAsegurandonosQueSeaNumero,
			}
		}

		console.debug(`guardando seleccion carta ${JSON.stringify(carta)}`)
		arrayCartasParaMandar.push(carta) // pushea un objeto custom para blue ocean

		// incrementar contador de jugadas
		const cant = cantCartasJugadas
		setCantCartasJugadas(cant + 1)

		setSelectedCards(arrayCartasParaMandar)
		setSelectedCardsModal(arrayModalParaMandar)
		setTemporizadorPausado(false) // <--------------------------------------------------------
	}

	const handleModalConfirm = (optionsRutas) => {
		console.debug('Opciones seleccionadas:', optionsRutas);
	};

	// opciones disponibles para Blue Ocean
	const modificadoresBlueOcean = [
		{ "texto": `${t('sprints.Aumentar')} ++`, "val": 7 },
		{ "texto": `${t('sprints.Aumentar')} +`, "val": 6 },
		{ "texto": `${t('sprints.Aumentar')}`, "val": 5 },

		{ "texto": `${t('sprints.Crear')}`, "val": 4 },

		{ "texto": `${t('sprints.Reducir')}`, "val": 3 },
		{ "texto": `${t('sprints.Reducir')} -`, "val": 2 },
		{ "texto": `${t('sprints.Reducir')} --`, "val": 1 },

		{ "texto": `${t('sprints.Eliminar')}`, "val": 0 },
	]

	// solapa para single-select de modificador Blue Ocean
	function Modal({ isOpen, onClose, onConfirm, datosOpciones, datosNumOpciones }) {

		const [selectedOptionModal, setSelectedOptionModal] = useState(selectedOption)

		if (!datosOpciones || !datosNumOpciones || movimientosDisponibles === 0) { return null }

		// traer array de opciones para sobreescribir al valor blueocean
		const options = datosOpciones.filter(v => v !== null);
		console.debug(`opcion default en modal es ${selectedOption}`)

		const handleOptionChange = (event) => {
			const value = parseInt(event.target.value);
			console.debug("Nuevo valor seleccionado:", value);
			setSelectedOptionModal(value);
		};

		const handleSubmit = () => {
			console.debug(`submitting con ${selectedOptionModal}`)
			onConfirm(handleSelectorGuardar(Number(selectedOptionModal)));
			onClose();
		};

		const modalStyle = {
			display: isOpen ? 'block' : 'none',
			position: 'fixed',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			padding: '20px',
			backgroundColor: 'orange',
			zIndex: 1000
		};

		const overlayStyle = {
			display: isOpen ? 'block' : 'none',
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			zIndex: 999
		};

		const selectedCardImage = images.find(img => img && img.name === selectedCard);

		// Estilo en línea para el radio activo
		const activeRadioStyle = {
			backgroundColor: '#003366',
			borderColor: '#003366',
		};

		return (
			<>

				<div style={overlayStyle} onClick={onClose}></div>
				<div style={modalStyle}>

					{
						selectedCardImage ? (
							<img
								key={selectedCard}
								src={selectedCardImage.src}
								alt={`Carta ${selectedCardImage.name}`}
								width="250"
							/>
						) : null
					}
					<div>{t('sprints.modalDesc')}</div>
					<ul>
						{options.map((option) => (
							<li style={{ color: 'black' }}>
								<label key={option.val}>
									<input
										type="radio"
										value={option.val}
										onChange={handleOptionChange}
										checked={selectedOptionModal === option.val}
										style={selectedOptionModal === option.val ? activeRadioStyle : {}}  // Aplica el estilo si esta opcion es la seleccionada
									/>
									{option.texto}
								</label>
							</li>
						))}
						<li><button onClick={handleSubmit}>{t('common.Confirmar_final')}</button></li>
					</ul>
				</div>
			</>
		);
	}


	//////////////////////
	/* 		SPRINTS		*/
	//////////////////////

	// Trends, Patterns, Digital Drivers,...
	// roto jugadas max no se resetea en metricas y test 2023/11/13
	function SprintMain() {
		console.debug(`Entrando a SprintMain...`)

		/*    CARTAS    */

		useEffect(() => {
			if (cartas.length > 0) {
				fetchImages(cartas);
			}
		}, [cartas, mazoTieneRev]);

		return (
			<>
				{arranqueListo ? (
					<p>{t('common.apreta_arranque')}</p>
				) : (
					<>
						<Row>
							<Col xs="12" style={{ overflowY: 'auto' }}>
								<Card style={{ backgroundColor: '#003366', height: 'auto', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
									<CardBody>

										{cartas.map((carta, index) => {

											// frente
											const imageObj = images.find(img => img && img.name === carta.svg_front);
											if (!imageObj) {
												return null;
											}

											if (mazoTieneRev) {
												// reverso
												const imageObjRev = images.find(img => img && img.name === carta.svg_back);
												if (!imageObjRev) {
													return null;
												}

												return (
													<img
														key={selectedCards.includes(imageObj.name) ? carta.svg_back : carta.svg_front}
														src={selectedCards.includes(imageObj.name) ? imageObjRev.src : imageObj.src}
														alt={`Carta ${imageObj.name}`}
														width="250"
														className={selectedCards.includes(imageObj.name) ? "cardEffect cardSelected" : "cardEffect"}
														onClick={() =>
															handleCardClick(imageObj.name)
														}
													/>
												);

											} else {
												return (
													<img
														key={carta.svg_front}
														src={imageObj.src}
														alt={`Carta ${imageObj.name}`}
														width="250"
														className={selectedCards.includes(imageObj.name) ? "cardEffect cardSelected" : "cardEffect"}
														onClick={() =>
															handleCardClick(imageObj.name)
														}
													/>
												);
											}
										})}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</>
				)}
			</>
		);

	}

	// roto todavia que en final te devuelve core interaction x2 etc, solo presencial. 2023/11/13
	function SprintPlatform() {

		console.debug("Arrancando SprintPlatform")

		/*    CARTAS    */

		useEffect(() => {
			async function fetchImages() {
				var imageData = await Promise.all(cartas.map(fetchImageData));

				console.debug(`se trajeron ${imageData.length} cartas, como ${imageData[0].name}`)

				// agregar imgs del reverso si hace falta
				var imageDataRev = []

				imageDataRev = await Promise.all(cartas.map(fetchImageDataRev));

				console.debug('primer carta en imageData es:', imageData[0].name, imageDataRev[0].name)


				imageData = imageData.concat(imageDataRev)

				console.debug(`desp de fijarse rev, se trajeron ${imageData.length} cartas, ${imageDataRev.length} son rev`)

				var listaCartas = []
				for (let i = 0; i < imageData.length; i++) {
					var imagenInd = imageData[i]
					if (!imagenInd) {
						console.debug(`falta carta ${i}/${imageData.length}, borrando...`)
						continue
					}
					if (!imagenInd.src) {
						console.debug(`falta src ${imagenInd}, borrando...`)
						imageData.splice(i)
						continue
					}
					listaCartas.push(imagenInd.name)
					//console.debug(imageData[i].name)
				}

				console.debug(`lista tiene ${listaCartas.length}: ${listaCartas}`)
				console.debug(`listaCartas verificado tiene ${listaCartas.length} imagenes`)

				setImages(imageData);
				setCartasLoaded(true);
			}
			if (cartas.length > 0) {
				fetchImages();
			}
		}, [cartas]);

		// acepta la lista de opciones del selector y los guarda como selected
		// acepta array de objetos cartas
		const handleSelectorGuardar = (arraySeleccionNueva) => {
			if (!arraySeleccionNueva) { return }
			console.debug(`guardando seleccion ${arraySeleccionNueva}`)
			var arrayCartasParaMandar = selectedCards

			// determinar primera carta para display para tacharlo
			var primera = opcionesCartas[0].svg_front
			console.debug(`bloqueando primera carta, ${primera}`)
			var arrayModalParaMandar = selectedCards
			arrayModalParaMandar.push(primera)

			for (let i = 0; i < arraySeleccionNueva.length; i++) {
				if (arraySeleccionNueva[i] === null) { continue }
				var carta = arraySeleccionNueva[i]
				console.debug(`guardando seleccion carta ${carta}`)
				arrayCartasParaMandar.push(carta)
			}
			console.debug(`seleccionando array ${arrayCartasParaMandar}`)
			setSelectedCards(arrayCartasParaMandar)
			setSelectedCardsModal(arrayModalParaMandar)
			setTemporizadorPausado(false) // <--------------------------------------------------------
		}

		// solapa para multiple choice de cartas
		function Modal({ isOpen, onClose, onConfirm, datosOpciones, datosNumOpciones }) {
			const [selectedOptions, setSelectedOptions] = useState([]);

			if (!datosOpciones || !datosNumOpciones) { return null }

			// traer array de objetos cartas
			const options = datosOpciones.filter(v => v !== null);
			//console.debug(`opciones en Modal son ${JSON.stringify(options)}`)

			const handleOptionChange = (event) => {
				//console.debug(`seleccionadas antes de clic eran ${selectedOptions}`)
				const value = event.target.value;
				//console.debug(`valor del clic es ${value}`)
				setSelectedOptions((prev) =>
					event.target.checked ? [...prev, value] : prev.filter(item => item !== value)
				);
			};

			const handleSubmit = () => {
				console.debug(`handleSubmit con ${selectedOptions.length} opciones de ${datosNumOpciones} max`)
				if (selectedOptions.length <= datosNumOpciones) {
					console.debug(`submitting con ${JSON.stringify(selectedOptions)}`)
					onConfirm(handleSelectorGuardar(selectedOptions));
					onClose();
				} else {
					console.debug(`te pasaste de opciones`) // 				<--------
				}
			};

			const modalStyle = {
				display: isOpen ? 'block' : 'none',
				position: 'fixed',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				padding: '20px',
				backgroundColor: 'orange',
				zIndex: 1000
			};

			const overlayStyle = {
				display: isOpen ? 'block' : 'none',
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				zIndex: 999
			};

			const selectedCardImage = images.find(img => img && img.name === selectedCard);

			return (
				<>

					<div style={overlayStyle} onClick={onClose}></div>
					<div style={modalStyle}>

						{
							selectedCardImage ? (
								<img
									key={selectedCard}
									src={selectedCardImage.src}
									alt={`Carta ${selectedCardImage.name}`}
									width="250"
								/>
							) : null
						}
						<div>{t('sprints.seleccionaHasta')} {datosNumOpciones} {t('sprints.opcionesYApreta')}</div>
						<ul>
							{options.map((option) => (
								<li style={{ color: 'black' }}>
									<label key={option.svg_front}>
										<input
											type="checkbox"
											value={option.svg_front}
											onChange={handleOptionChange}
											checked={selectedOptions.includes(option.svg_front)}
										/>
										{t(option.nombre_18full)}
									</label>
								</li>
							))}

							{/*
					<img
						src={options[0].svg_front}
						alt={`Carta ${options[0].nombre_es}`}
						width="250"
					/>
					*/}
							<li><button onClick={handleSubmit}>{t('common.Confirmar_final')}</button></li>
						</ul>
					</div>
				</>
			);
		}

		const handleModalConfirm = (optionsRutas) => {
			console.debug('Opciones seleccionadas:', optionsRutas);
		};

		return (
			<>
				{arranqueListo ? (
					<p>...</p>
				) : (
					<>
						<Modal
							isOpen={modalOpen}
							onClose={() => setModalOpen(false)}
							onConfirm={handleModalConfirm}
							datosOpciones={opcionesCartas}
							datosNumOpciones={numOpcionesCartas}
						/>
						<Row>
							<Col xs="12" style={{ overflowY: 'auto' }}>
								<Card style={{ backgroundColor: '#003366', height: 'auto', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
									<CardBody>
										{cartas.map((carta, index) => {
											const imageObj = images.find(img => img && img.name === carta.svg_front);
											if (!imageObj) {
												return null;
											}
											return (
												<img
													key={carta.svg_front}
													src={imageObj.src}
													alt={`Carta ${imageObj.name}`}
													width="250"
													className={selectedCardsModal.includes(imageObj.name) ? "cardEffect cardSelected" : "cardEffect"}
													onClick={selectedCardsModal.includes(imageObj.name) ? null : () => handleCardClickModal(imageObj.name)} // Evitamos los clics si la carta ya ha sido seleccionada.
													style={selectedCardsModal.includes(imageObj.name) ? { opacity: 0.5, cursor: 'not-allowed' } : {}} // Estilo adicional para cartas seleccionadas.
												/>
											);
										})}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</>
				)}
			</>
		);
	}

	function SprintBlueOcean() {

		console.debug("Arrancando SprintBlueOcean")

		/*    CARTAS    */

		useEffect(() => {
			async function fetchImages() {
				var imageData = await Promise.all(cartas.map(fetchImageData));

				console.debug(`se trajeron ${imageData.length} cartas, como ${imageData[0].name}`)

				var listaCartas = []
				for (let i = 0; i < imageData.length; i++) {
					var imagenInd = imageData[i]
					if (!imagenInd) {
						console.debug(`falta carta ${i}/${imageData.length}, borrando...`)
						continue
					}
					if (!imagenInd.src) {
						console.debug(`falta src ${imagenInd}, borrando...`)
						imageData.splice(i)
						continue
					}
					listaCartas.push(imagenInd.name)
					//console.debug(imageData[i].name)
				}

				console.debug(`listaCartas verificado tiene ${listaCartas.length} imagenes`)

				setImages(imageData);
				setCartasLoaded(true);
			}
			if (cartas.length > 0) {
				fetchImages();
			}
		}, [cartas]);

		return (
			<>
				{arranqueListo ? (
					<p>...</p>
				) : (
					<>
						<Modal
							isOpen={modalOpen}
							onClose={() => setModalOpen(false)}
							onConfirm={handleModalConfirm}
							datosOpciones={opcionesCartas}
							datosNumOpciones={numOpcionesCartas}
						/>
						<Row>
							<Col xs="12" style={{ overflowY: 'auto' }}>
								<Card style={{ backgroundColor: '#003366', height: 'auto', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
									<CardBody>
										{cartas.map((carta, index) => {
											const imageObj = images.find(img => img && img.name === carta.svg_front);
											if (!imageObj) {
												return null;
											}
											return (
												<img
													key={carta.svg_front}
													src={imageObj.src}
													alt={`Carta ${imageObj.name}`}
													width="250"
													className={selectedCardsModal.includes(imageObj.name) ? "cardEffect cardSelected" : "cardEffect"}
													onClick={selectedCardsModal.includes(imageObj.name) || movimientosDisponibles === 0 ? null : () => handleCardClickBlueOcean(imageObj.name)} // Evitamos los clics si la carta ya ha sido seleccionada.
													style={selectedCardsModal.includes(imageObj.name) ? { opacity: 0.5, cursor: 'not-allowed' } : {}} // Estilo adicional para cartas seleccionadas.
												/>
											);
										})}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</>
				)}
			</>
		);
	}

	// ecosystem, parecido a platform 
	// cartas multiple choice con reverso
	// copia lo roto de platforms
	function SprintEcosystem() {

		console.debug("Arrancando SprintEcosystem")

		/*    CARTAS    */

		useEffect(() => {
			async function fetchImages() {
				var imageData = await Promise.all(cartas.map(fetchImageData));

				//console.debug(`se trajeron ${imageData.length} cartas, como ${imageData[0].name}`)

				// agregar imgs del reverso si hace falta
				var imageDataRev = []

				imageDataRev = await Promise.all(cartas.map(fetchImageDataRev));

				console.debug('primer carta en imageData es:', imageData[0].name, imageDataRev[0].name)


				imageData = imageData.concat(imageDataRev)

				console.debug(`desp de fijarse rev, se trajeron ${imageData.length} cartas, ${imageDataRev.length} son rev`)

				var listaCartas = []
				for (let i = 0; i < imageData.length; i++) {
					var imagenInd = imageData[i]
					if (!imagenInd) {
						console.debug(`falta carta ${i}/${imageData.length}, borrando...`)
						continue
					}
					if (!imagenInd.src) {
						console.debug(`falta src ${imagenInd}, borrando...`)
						imageData.splice(i)
						continue
					}
					listaCartas.push(imagenInd.name)
					//console.debug(imageData[i].name)
				}

				console.debug(`lista tiene ${listaCartas.length}: ${listaCartas}`)
				console.debug(`listaCartas verificado tiene ${listaCartas.length} imagenes`)

				setImages(imageData);
				setCartasLoaded(true);
			}
			if (cartas.length > 0) {
				fetchImages();
			}
		}, [cartas]);

		// acepta la lista de opciones del selector y los guarda como selected
		// acepta array de objetos cartas
		const handleSelectorGuardar = (arraySeleccionNueva) => {
			if (!arraySeleccionNueva) { return }
			console.debug(`guardando seleccion ${arraySeleccionNueva}`)
			var arrayCartasParaMandar = selectedCards

			// determinar primera carta para display para tacharlo
			var primera = opcionesCartas[0].svg_front
			console.debug(`bloqueando primera carta, ${primera}`)
			var arrayModalParaMandar = selectedCards
			arrayModalParaMandar.push(primera)

			for (let i = 0; i < arraySeleccionNueva.length; i++) {
				if (arraySeleccionNueva[i] === null) { continue }
				var carta = arraySeleccionNueva[i]
				console.debug(`guardando seleccion carta ${carta}`)
				arrayCartasParaMandar.push(carta)
			}
			console.debug(`seleccionando array ${arrayCartasParaMandar}`)
			setSelectedCards(arrayCartasParaMandar)
			setSelectedCardsModal(arrayModalParaMandar)
			setTemporizadorPausado(false) // <--------------------------------------------------------
		}

		// solapa para multiple choice de cartas
		function Modal({ isOpen, onClose, onConfirm, datosOpciones, datosNumOpciones }) {
			const [selectedOptions, setSelectedOptions] = useState([]);

			if (!datosOpciones || !datosNumOpciones) { return null }

			// traer array de objetos cartas
			const options = datosOpciones.filter(v => v !== null);
			//console.debug(`opciones en Modal son ${JSON.stringify(options)}`)

			const handleOptionChange = (event) => {
				//console.debug(`seleccionadas antes de clic eran ${selectedOptions}`)
				const value = event.target.value;
				//console.debug(`valor del clic es ${value}`)
				setSelectedOptions((prev) =>
					event.target.checked ? [...prev, value] : prev.filter(item => item !== value)
				);
			};

			const handleSubmit = () => {
				console.debug(`handleSubmit con ${selectedOptions.length} opciones de ${datosNumOpciones} max`)
				if (selectedOptions.length <= datosNumOpciones) {
					console.debug(`submitting con ${JSON.stringify(selectedOptions)}`)
					onConfirm(handleSelectorGuardar(selectedOptions));
					onClose();
				} else {
					console.debug(`te pasaste de opciones`) // 				<--------
				}
			};

			const modalStyle = {
				display: isOpen ? 'block' : 'none',
				position: 'fixed',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				padding: '15px',
				backgroundColor: 'orange',
				zIndex: 1000
			};

			const overlayStyle = {
				display: isOpen ? 'block' : 'none',
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				zIndex: 999
			};

			const selectedCardImage = images.find(img => img && img.name === selectedCard);

			return (
				<>

					<div style={overlayStyle} onClick={onClose}></div>
					<div style={modalStyle}>

						{
							selectedCardImage ? (
								<img
									key={selectedCard}
									src={selectedCardImage.src}
									alt={`Carta ${selectedCardImage.name}`}
									width="150"
								/>
							) : null
						}
						<div>{t('sprints.seleccionaHasta')} {datosNumOpciones} {t('sprints.opcionesYApreta')}</div>
						<ul>
							{options.map((option) => (
								<li style={{ color: 'black' }}>
									<label key={option.svg_front}>
										<input
											type="checkbox"
											value={option.svg_front}
											onChange={handleOptionChange}
											checked={selectedOptions.includes(option.svg_front)}
										/>
										{t(option.nombre_18full)}
									</label>
								</li>
							))}

							{/*
					<img
						src={options[0].svg_front}
						alt={`Carta ${options[0].nombre_es}`}
						width="250"
					/>
					*/}
							<li><button onClick={handleSubmit}>{t('common.Confirmar_final')}</button></li>
						</ul>
					</div>
				</>
			);
		}

		const handleModalConfirm = (optionsRutas) => {
			console.debug('Opciones seleccionadas:', optionsRutas);
		};

		return (
			<>
				{arranqueListo ? (
					<p>...</p>
				) : (
					<>
						<Row>
							<Col xs="12">
								<Card style={{ backgroundColor: '#003366', padding: "50px" }}>
									<CardBody>
										<EcosystemCanvas />
									</CardBody>
								</Card>
							</Col>
						</Row>

						<Modal
							isOpen={modalOpen}
							onClose={() => setModalOpen(false)}
							onConfirm={handleModalConfirm}
							datosOpciones={opcionesCartas}
							datosNumOpciones={numOpcionesCartas}
						/>
						<Row>
							<Col xs="12" style={{ overflowY: 'auto' }}>
								<Card style={{ backgroundColor: '#003366', height: 'auto', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
									<CardBody>
										{cartas.map((carta, index) => {
											const imageObj = images.find(img => img && img.name === carta.svg_front);
											if (!imageObj) {
												return null;
											}
											return (
												<img
													key={carta.svg_front}
													src={imageObj.src}
													alt={`Carta ${imageObj.name}`}
													width="250"
													className={selectedCardsModal.includes(imageObj.name) ? "cardEffect cardSelected" : "cardEffect"}
													onClick={selectedCardsModal.includes(imageObj.name) ? null : () => handleCardClickModal(imageObj.name)} // Evitamos los clics si la carta ya ha sido seleccionada.
													style={selectedCardsModal.includes(imageObj.name) ? { opacity: 0.5, cursor: 'not-allowed' } : {}} // Estilo adicional para cartas seleccionadas.
												/>
											);
										})}
									</CardBody>
								</Card>
							</Col>
						</Row>

						<Row>
							<Col xs="12" md="8">
								<Card style={{ backgroundColor: '#003366', padding: "50px" }}>
									<CardBody>
										<h1>ECOSYSTEM: LIFE AREAS</h1>
										<ConcentricClickableSectors get={ecosystemCirculo} set={setEcoSystemCirculo}/>
									</CardBody>
								</Card>
							</Col>
							<Col xs="12" md="4">
								<Card style={{ padding: "50px" }}>
									<CardBody>
										<h3>Círculo Externo: Facilitador</h3>
										<h3>Círculo Medio: Realizador</h3>
										<h3>Círculo Interno: Orquestador</h3>
									</CardBody>
								</Card>
							</Col>
						</Row>

						<Row>
							<Col xs="12">
								<Card style={{ backgroundColor: '#003366', padding: "50px" }}>
									<CardBody>
										<LargeInputBoxes 
											ofertaGet={ecosystemOferta} 
											ofertaSet={setEcoSystemOferta} 
											propuestaGet={ecosystemPropuesta}
											propuestaSet={setEcoSystemPropuesta}
										/>
									</CardBody>
								</Card>
							</Col>
						</Row>

					</>
				)}
			</>
		);
	}

	//// VIRTUALES 

	function SprintVirtual() {
		console.debug(`Entrando a SprintVirtual...`)

		// no permitir click si ya se llego al limite
		const movs = movimientosDisponibles()
		//console.debug(`movs disponibles son ${movs}, tipo ${typeof movs}`)
		//console.debug(`movs es 0 ? ${movs === 0}`)

		//////// TIENE QUE TRAER BARAJA, NO MAZO

		useEffect(() => {
			if (cartas.length > 0) {
				fetchImagesIntegrada(cartas);
			}
		}, [cartas]);

		// determinar cuales recuadros devolver segun el canvas
		// platform, datadriven y blue ocean tienen sprints propios

		console.debug(`selectedCanvas es ${selectedCanvas}`)

		// 1, talleres
		if (selectedCanvas === "canvasPostit") {
			console.debug(`armando canvas 1...`)
			return (
				<>
					{arranqueListo ? (
						<p>{t('common.apreta_arranque')}</p>
					) : (
						<>
							<DndProvider backend={HTML5Backend}>
								<Row>
									<Col xs="9">
										<div style={{ display: 'flex', flexDirection: 'row', margin: '0' }}>
											<DropZone recuadro={"1"} onDrop={handleCardDrop("1")} droppedCard={droppedCards["1"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro1SociosClaves})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '400px'
											}} />
											<div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '0' }}>
												<DropZone recuadro={"2"} onDrop={handleCardDrop("2")} droppedCard={droppedCards["2"]} onCardClick={handleCardClickDropped} style={{
													height: '50%',
													margin: '0',
													backgroundImage: `url(${recuadro1ActividadesClaves})`,
													backgroundSize: '100% 100%',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													overflowY: 'auto',
													maxHeight: '200px'
												}} />
												<DropZone recuadro={"3"} onDrop={handleCardDrop("3")} droppedCard={droppedCards["3"]} onCardClick={handleCardClickDropped} style={{
													height: '50%',
													backgroundImage: `url(${recuadro1RecursosClaves})`,
													backgroundSize: '100% 100%',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													overflowY: 'auto',
													maxHeight: '200px'
												}} />
											</div>
											<DropZone recuadro={"4"} onDrop={handleCardDrop("4")} droppedCard={droppedCards["4"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro1PropuestaDeValor})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '400px'
											}} />
											<div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '0' }}>
												<DropZone recuadro={"5"} onDrop={handleCardDrop("5")} droppedCard={droppedCards["5"]} onCardClick={handleCardClickDropped} style={{
													height: '50%',
													backgroundImage: `url(${recuadro1RelacionesConClientes})`,
													backgroundSize: '100% 100%',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													overflowY: 'auto',
													maxHeight: '200px'
												}} />
												<DropZone recuadro={"6"} onDrop={handleCardDrop("6")} droppedCard={droppedCards["6"]} onCardClick={handleCardClickDropped} style={{
													height: '50%',
													backgroundImage: `url(${recuadro1Canales})`,
													backgroundSize: '100% 100%',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													overflowY: 'auto',
													maxHeight: '200px'
												}} />
											</div>
											<DropZone recuadro={"7"} onDrop={handleCardDrop("7")} droppedCard={droppedCards["7"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro1SegmentosDeCliente})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
											}} />
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', height: '35%' }}>
											<DropZone recuadro={"8"} onDrop={handleCardDrop("8")} droppedCard={droppedCards["8"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro1EstructuraDeCostos})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
											}} />
											<DropZone recuadro={"9"} onDrop={handleCardDrop("9")} droppedCard={droppedCards["9"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro1FlujosDeIngreso})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
											}} />
										</div>
									</Col>
									<Col xs="3" style={{ overflowY: 'auto', maxHeight: '830px' }}>
										<Card style={{ backgroundColor: '#003366', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
											<CardBody>
												{droppedNames && cartas && movs > 0 ? (
													cartas
														.filter(carta => carta !== undefined && carta !== null)
														.filter(carta => !droppedNames.includes(carta.svg_front))
														.map((carta, index) => {
															const imageObj = images.find(img => img && img.name === carta.svg_front);
															if (!imageObj) {
																return null;
															}
															return (
																<DraggableCard
																	key={carta.svg_front}
																	id={carta.svg_front}
																	name={imageObj.name}
																	src={imageObj.src}
																	rev={imageObj.rev}
																	alt={`Carta ${imageObj.name}`}
																	handleRemove={handleRemove}
																/>
															);
														})
												) : (null)}
											</CardBody>
										</Card>
									</Col>
								</Row>
							</DndProvider>
						</>
					)
					}
				</>
			);
		}

		// 2
		if (selectedCanvas === "canvasEnBlanco") {
			console.debug(`armando canvas 2...`)
			return (
				<>
					{arranqueListo ? (
						<p>{t('common.apreta_arranque')}</p>
					) : (
						<>
							<DndProvider backend={HTML5Backend}>
								<Row>
									<Col xs="9">
										<div style={{ display: 'flex', flexDirection: 'row', margin: '0' }}>
											<DropZone recuadro={"10"} onDrop={handleCardDrop("10")} droppedCard={droppedCards["10"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro2SociosClaves})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '400px'
											}} />
											<div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '0' }}>
												<DropZone recuadro={"11"} onDrop={handleCardDrop("11")} droppedCard={droppedCards["11"]} onCardClick={handleCardClickDropped} style={{
													height: '50%',
													margin: '0',
													backgroundImage: `url(${recuadro2ActividadesClaves})`,
													backgroundSize: '100% 100%',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													overflowY: 'auto',
													maxHeight: '200px'
												}} />
												<DropZone recuadro={"12"} onDrop={handleCardDrop("12")} droppedCard={droppedCards["12"]} onCardClick={handleCardClickDropped} style={{
													height: '50%',
													backgroundImage: `url(${recuadro2RecursosClaves})`,
													backgroundSize: '100% 100%',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													overflowY: 'auto',
													maxHeight: '200px'
												}} />
											</div>
											<DropZone recuadro={"13"} onDrop={handleCardDrop("13")} droppedCard={droppedCards["13"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro2PropuestaDeValor})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '400px'
											}} />
											<div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '0' }}>
												<DropZone recuadro={"14"} onDrop={handleCardDrop("14")} droppedCard={droppedCards["14"]} onCardClick={handleCardClickDropped} style={{
													height: '50%',
													backgroundImage: `url(${recuadro2RelacionesConClientes})`,
													backgroundSize: '100% 100%',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													overflowY: 'auto',
													maxHeight: '200px'
												}} />
												<DropZone recuadro={"15"} onDrop={handleCardDrop("15")} droppedCard={droppedCards["15"]} onCardClick={handleCardClickDropped} style={{
													height: '50%',
													backgroundImage: `url(${recuadro2Canales})`,
													backgroundSize: '100% 100%',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													overflowY: 'auto',
													maxHeight: '200px'
												}} />
											</div>
											<DropZone recuadro={"16"} onDrop={handleCardDrop("16")} droppedCard={droppedCards["16"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro2SegmentosDeCliente})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
											}} />
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', height: '35%' }}>
											<DropZone recuadro={"17"} onDrop={handleCardDrop("17")} droppedCard={droppedCards["17"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro2EstructuraDeCostos})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
											}} />
											<DropZone recuadro={"18"} onDrop={handleCardDrop("18")} droppedCard={droppedCards["18"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro2FlujosDeIngreso})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
											}} />
										</div>
									</Col>
									<Col xs="3" style={{ overflowY: 'auto', maxHeight: '830px' }}>
										<Card style={{ backgroundColor: '#003366', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
											<CardBody>
												{droppedNames && cartas && movs > 0 ? (
													cartas
														.filter(carta => carta !== undefined && carta !== null)
														.filter(carta => !droppedNames.includes(carta.svg_front))
														.map((carta, index) => {
															const imageObj = images.find(img => img && img.name === carta.svg_front);
															if (!imageObj) {
																return null;
															}
															return (
																<DraggableCard
																	key={carta.svg_front}
																	id={carta.svg_front}
																	name={imageObj.name}
																	src={imageObj.src}
																	rev={imageObj.rev}
																	alt={`Carta ${imageObj.name}`}
																	handleRemove={handleRemove}
																/>
															);
														})
												) : (null)}
											</CardBody>
										</Card>
									</Col>
								</Row>
							</DndProvider>
						</>
					)
					}
				</>
			);
		}

		// 3
		if (selectedCanvas === "canvasB2B") {
			console.debug(`armando canvas 3...`)
			return (
				<>
					{arranqueListo ? (
						<p>{t('common.apreta_arranque')}</p>
					) : (
						<>
							<DndProvider backend={HTML5Backend}>
								<Row>
									<Col xs="9">
										<div style={{ display: 'flex', height: '100%', margin: '0', width: '100%', maxHeight: '800px' }}>
											<div style={{ display: 'flex', flexDirection: 'column', height: '100%', margin: '0', width: '50%' }}>
												<div style={{ display: 'flex', flexDirection: 'row', margin: '1px', width: '100%' }}>
													<div style={{ display: 'flex', flexDirection: 'row', width: '33%', margin: '0' }}>
														<DropZone recuadro={"19"} onDrop={handleCardDrop("19")} droppedCard={droppedCards["19"]} onCardClick={handleCardClickDropped} style={{
															backgroundImage: `url(${recuadro3SociosClaves})`,
															backgroundSize: '100% 100%',
															backgroundPosition: 'center',
															backgroundRepeat: 'no-repeat',
															overflowY: 'auto',
															maxHeight: '400px',
														}} />
													</div>
													<div style={{ display: 'flex', flexDirection: 'column', width: '33%', margin: '0' }}>
														<DropZone recuadro={"20"} onDrop={handleCardDrop("20")} droppedCard={droppedCards["20"]} onCardClick={handleCardClickDropped} style={{
															height: '50%',
															margin: '0',
															backgroundImage: `url(${recuadro3ActividadesClaves})`,
															backgroundSize: '100% 100%',
															backgroundPosition: 'center',
															backgroundRepeat: 'no-repeat',
															overflowY: 'auto',
															maxHeight: '200px'
														}} />
														<DropZone recuadro={"21"} onDrop={handleCardDrop("21")} droppedCard={droppedCards["21"]} onCardClick={handleCardClickDropped} style={{
															height: '50%',
															backgroundImage: `url(${recuadro3RecursosClaves})`,
															backgroundSize: '100% 100%',
															backgroundPosition: 'center',
															backgroundRepeat: 'no-repeat',
															overflowY: 'auto',
															maxHeight: '200px'
														}} />
													</div>
													<div style={{ display: 'flex', flexDirection: 'row', width: '33%', margin: '0' }}>
														<DropZone recuadro={"22"} onDrop={handleCardDrop("22")} droppedCard={droppedCards["22"]} onCardClick={handleCardClickDropped} style={{
															backgroundImage: `url(${recuadro3PropuestaDeValor})`,
															backgroundSize: '100% 100%',
															backgroundPosition: 'center',
															backgroundRepeat: 'no-repeat',
															overflowY: 'auto',
															maxHeight: '400px',
														}} />

													</div>
												</div>

												<div style={{ display: 'flex', flexDirection: 'row', margin: '0', width: '100%' }}>
													<DropZone recuadro={"26"} onDrop={handleCardDrop("26")} droppedCard={droppedCards["26"]} onCardClick={handleCardClickDropped} style={{
														backgroundImage: `url(${recuadro3EstructuraDeCostos})`,
														backgroundSize: '100% 100%',
														backgroundPosition: 'center',
														backgroundRepeat: 'no-repeat',
														maxHeight: '200px'
													}} />
												</div>
											</div>

											<div style={{ display: 'flex', flexDirection: 'row', height: '100%', margin: '0', width: '50%' }}>
												<div style={{ display: 'flex', flexDirection: 'column', width: '66%', margin: '0' }}>
													<div style={{ display: 'flex', flexDirection: 'row', width: '100%', margin: '0' }}>
														<div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '0' }}>
															<DropZone recuadro={"23"} onDrop={handleCardDrop("23")} droppedCard={droppedCards["23"]} onCardClick={handleCardClickDropped} style={{
																height: '50%',
																backgroundImage: `url(${recuadro3RelacionesConClientes})`,
																backgroundSize: '100% 100%',
																backgroundPosition: 'center',
																backgroundRepeat: 'no-repeat',
																overflowY: 'auto',
																maxHeight: '200px'
															}} />
															<DropZone recuadro={"24"} onDrop={handleCardDrop("24")} droppedCard={droppedCards["24"]} onCardClick={handleCardClickDropped} style={{
																height: '50%',
																backgroundImage: `url(${recuadro3Canales})`,
																backgroundSize: '100% 100%',
																backgroundPosition: 'center',
																backgroundRepeat: 'no-repeat',
																overflowY: 'auto',
																maxHeight: '200px'
															}} />
														</div>
														<DropZone recuadro={"25"} onDrop={handleCardDrop("25")} droppedCard={droppedCards["25"]} onCardClick={handleCardClickDropped} style={{
															backgroundImage: `url(${recuadro3SegmentosDeCliente})`,
															backgroundSize: '100% 100%',
															backgroundPosition: 'center',
															backgroundRepeat: 'no-repeat',
															maxHeight: '400px'
														}} />
													</div>

													<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
														<DropZone recuadro={"27"} onDrop={handleCardDrop("27")} droppedCard={droppedCards["27"]} onCardClick={handleCardClickDropped} style={{
															backgroundImage: `url(${recuadro3FlujosDeIngreso})`,
															backgroundSize: '100% 100%',
															backgroundPosition: 'center',
															backgroundRepeat: 'no-repeat',
														}} />
													</div>

												</div>
												<div style={{ display: 'flex', flexDirection: 'column', width: '33%', margin: '0' }}>
													<DropZone recuadro={"28"} onDrop={handleCardDrop("28")} droppedCard={droppedCards["28"]} onCardClick={handleCardClickDropped} style={{
														backgroundImage: `url(${recuadro3Consumidores})`,
														backgroundSize: '100% 100%',
														backgroundPosition: 'center',
														backgroundRepeat: 'no-repeat',
													}} />
													<DropZone recuadro={"29"} onDrop={handleCardDrop("29")} droppedCard={droppedCards["29"]} onCardClick={handleCardClickDropped} style={{
														backgroundImage: `url(${recuadro3Influencer})`,
														backgroundSize: '100% 100%',
														backgroundPosition: 'center',
														backgroundRepeat: 'no-repeat',
													}} />
													<DropZone recuadro={"30"} onDrop={handleCardDrop("30")} droppedCard={droppedCards["30"]} onCardClick={handleCardClickDropped} style={{
														backgroundImage: `url(${recuadro3Intermediarios})`,
														backgroundSize: '100% 100%',
														backgroundPosition: 'center',
														backgroundRepeat: 'no-repeat',
														height: '120%'
													}} />
												</div>
											</div>
										</div>
									</Col>

									<Col xs="3" style={{ overflowY: 'auto', maxHeight: '830px' }}>
										<Card style={{ backgroundColor: '#003366', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
											<CardBody>
												{droppedNames && cartas && movs > 0 ? (
													cartas
														.filter(carta => carta !== undefined && carta !== null)
														.filter(carta => !droppedNames.includes(carta.svg_front))
														.map((carta, index) => {
															const imageObj = images.find(img => img && img.name === carta.svg_front);
															if (!imageObj) {
																return null;
															}
															return (
																<DraggableCard
																	key={carta.svg_front}
																	id={carta.svg_front}
																	name={imageObj.name}
																	src={imageObj.src}
																	rev={imageObj.rev}
																	alt={`Carta ${imageObj.name}`}
																	handleRemove={handleRemove}
																/>
															);
														})
												) : (null)}
											</CardBody>
										</Card>
									</Col>
								</Row>
							</DndProvider>
						</>
					)
					}
				</>
			);
		}

		// 4
		if (selectedCanvas === "canvasNavigator") {
			console.debug(`armando canvas 4...`)
			return (
				<>
					{arranqueListo ? (
						<p>{t('common.apreta_arranque')}</p>
					) : (
						<>
							<DndProvider backend={HTML5Backend}>
								<Row>
									<Col xs="9">
										<div style={{ display: 'flex', flexDirection: 'row', margin: '0' }}>
											<DropZone recuadro={"31"} onDrop={handleCardDrop("31")} droppedCard={droppedCards["31"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro4Que})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '400px'
											}} />
											<DropZone recuadro={"35"} onDrop={handleCardDrop("35")} droppedCard={droppedCards["35"]} onCardClick={handleCardClickDropped} style={{
												height: '50%',
												backgroundImage: `url(${recuadro4Propuesta})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '200px'
											}} />
											<DropZone recuadro={"33"} onDrop={handleCardDrop("33")} droppedCard={droppedCards["33"]} onCardClick={handleCardClickDropped} style={{
												height: '50%',
												backgroundImage: `url(${recuadro4Quien})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '200px'
											}} />
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', margin: '0' }}>
											<DropZone recuadro={"32"} onDrop={handleCardDrop("32")} droppedCard={droppedCards["32"]} onCardClick={handleCardClickDropped} style={{
												height: '50%',
												margin: '0',
												backgroundImage: `url(${recuadro4Porque})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '200px'
											}} />

											<DropZone recuadro={"36"} onDrop={handleCardDrop("36")} droppedCard={droppedCards["36"]} onCardClick={handleCardClickDropped} style={{
												height: '50%',
												backgroundImage: `url(${recuadro4Captura})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '200px'
											}} />

											<DropZone recuadro={"37"} onDrop={handleCardDrop("37")} droppedCard={droppedCards["37"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro4Creacion})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
											}} />
											<DropZone recuadro={"34"} onDrop={handleCardDrop("34")} droppedCard={droppedCards["34"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro4Como})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '400px'
											}} />
										</div>
									</Col>
									<Col xs="3" style={{ overflowY: 'auto', maxHeight: '830px' }}>
										<Card style={{ backgroundColor: '#003366', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
											<CardBody>
												{droppedNames && cartas && movs > 0 ? (
													cartas
														.filter(carta => carta !== undefined && carta !== null)
														.filter(carta => !droppedNames.includes(carta.svg_front))
														.map((carta, index) => {
															const imageObj = images.find(img => img && img.name === carta.svg_front);
															if (!imageObj) {
																return null;
															}
															return (
																<DraggableCard
																	key={carta.svg_front}
																	id={carta.svg_front}
																	name={imageObj.name}
																	src={imageObj.src}
																	rev={imageObj.rev}
																	alt={`Carta ${imageObj.name}`}
																	handleRemove={handleRemove}
																/>
															);
														})
												) : (null)}
											</CardBody>
										</Card>
									</Col>
								</Row>
							</DndProvider>
						</>
					)
					}
				</>
			);
		}


	}

	// mismo canvas comun con modal
	function SprintBlueOceanVirtual() {
		console.debug(`Entrando a Sprint Blue Ocean Virtual...`)

		// no permitir click si ya se llego al limite
		const movs = movimientosDisponibles()

		//////// TIENE QUE TRAER BARAJA, NO MAZO

		useEffect(() => {
			if (cartas.length > 0) {
				fetchImagesIntegrada(cartas);
			}
		}, [cartas]);

		// determinar cuales recuadros devolver segun el canvas
		// platform, datadriven y blue ocean tienen sprints propios

		console.debug(`selectedCanvas es ${selectedCanvas}`)

		// 1, talleres
		if (selectedCanvas === "canvasPostit") {
			console.debug(`armando canvas 1...`)
			return (
				<>
					{arranqueListo ? (
						<p>{t('common.apreta_arranque')}</p>
					) : (
						<>
							<DndProvider backend={HTML5Backend}>
								<Modal
									isOpen={modalOpen}
									onClose={() => setModalOpen(false)}
									onConfirm={handleModalConfirm}
									datosOpciones={opcionesCartas}
									datosNumOpciones={numOpcionesCartas}
								/>
								<Row>
									<Col xs="9">
										<div style={{ display: 'flex', flexDirection: 'row', margin: '0' }}>
											<DropZone recuadro={"1"} onDrop={handleCardDrop("1")} droppedCard={droppedCards["1"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro1SociosClaves})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '400px'
											}} />
											<div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '0' }}>
												<DropZone recuadro={"2"} onDrop={handleCardDrop("2")} droppedCard={droppedCards["2"]} onCardClick={handleCardClickDropped} style={{
													height: '50%',
													margin: '0',
													backgroundImage: `url(${recuadro1ActividadesClaves})`,
													backgroundSize: '100% 100%',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													overflowY: 'auto',
													maxHeight: '200px'
												}} />
												<DropZone recuadro={"3"} onDrop={handleCardDrop("3")} droppedCard={droppedCards["3"]} onCardClick={handleCardClickDropped} style={{
													height: '50%',
													backgroundImage: `url(${recuadro1RecursosClaves})`,
													backgroundSize: '100% 100%',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													overflowY: 'auto',
													maxHeight: '200px'
												}} />
											</div>
											<DropZone recuadro={"4"} onDrop={handleCardDrop("4")} droppedCard={droppedCards["4"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro1PropuestaDeValor})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '400px'
											}} />
											<div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '0' }}>
												<DropZone recuadro={"5"} onDrop={handleCardDrop("5")} droppedCard={droppedCards["5"]} onCardClick={handleCardClickDropped} style={{
													height: '50%',
													backgroundImage: `url(${recuadro1RelacionesConClientes})`,
													backgroundSize: '100% 100%',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													overflowY: 'auto',
													maxHeight: '200px'
												}} />
												<DropZone recuadro={"6"} onDrop={handleCardDrop("6")} droppedCard={droppedCards["6"]} onCardClick={handleCardClickDropped} style={{
													height: '50%',
													backgroundImage: `url(${recuadro1Canales})`,
													backgroundSize: '100% 100%',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													overflowY: 'auto',
													maxHeight: '200px'
												}} />
											</div>
											<DropZone recuadro={"7"} onDrop={handleCardDrop("7")} droppedCard={droppedCards["7"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro1SegmentosDeCliente})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
											}} />
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', height: '35%' }}>
											<DropZone recuadro={"8"} onDrop={handleCardDrop("8")} droppedCard={droppedCards["8"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro1EstructuraDeCostos})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
											}} />
											<DropZone recuadro={"9"} onDrop={handleCardDrop("9")} droppedCard={droppedCards["9"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro1FlujosDeIngreso})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
											}} />
										</div>
									</Col>
									<Col xs="3" style={{ overflowY: 'auto', maxHeight: '830px' }}>
										<Card style={{ backgroundColor: '#003366', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
											<CardBody>
												{droppedNames && cartas && movs > 0 ? (
													cartas
														.filter(carta => carta !== undefined && carta !== null)
														.filter(carta => !droppedNames.includes(carta.svg_front))
														.map((carta, index) => {
															const imageObj = images.find(img => img && img.name === carta.svg_front);
															if (!imageObj) {
																return null;
															}
															return (
																<DraggableCard
																	key={carta.svg_front}
																	id={carta.svg_front}
																	name={imageObj.name}
																	src={imageObj.src}
																	rev={imageObj.rev}
																	alt={`Carta ${imageObj.name}`}
																	handleRemove={handleRemove}
																/>
															);
														})
												) : (null)}
											</CardBody>
										</Card>
									</Col>
								</Row>
							</DndProvider>
						</>
					)
					}
				</>
			);
		}

		// 2
		if (selectedCanvas === "canvasEnBlanco") {
			console.debug(`armando canvas 2...`)
			return (
				<>
					{arranqueListo ? (
						<p>{t('common.apreta_arranque')}</p>
					) : (
						<>
							<DndProvider backend={HTML5Backend}>
								<Modal
									isOpen={modalOpen}
									onClose={() => setModalOpen(false)}
									onConfirm={handleModalConfirm}
									datosOpciones={opcionesCartas}
									datosNumOpciones={numOpcionesCartas}
								/>
								<Row>
									<Col xs="9">
										<div style={{ display: 'flex', flexDirection: 'row', margin: '0' }}>
											<DropZone recuadro={"10"} onDrop={handleCardDrop("10")} droppedCard={droppedCards["10"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro2SociosClaves})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '400px'
											}} />
											<div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '0' }}>
												<DropZone recuadro={"11"} onDrop={handleCardDrop("11")} droppedCard={droppedCards["11"]} onCardClick={handleCardClickDropped} style={{
													height: '50%',
													margin: '0',
													backgroundImage: `url(${recuadro2ActividadesClaves})`,
													backgroundSize: '100% 100%',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													overflowY: 'auto',
													maxHeight: '200px'
												}} />
												<DropZone recuadro={"12"} onDrop={handleCardDrop("12")} droppedCard={droppedCards["12"]} onCardClick={handleCardClickDropped} style={{
													height: '50%',
													backgroundImage: `url(${recuadro2RecursosClaves})`,
													backgroundSize: '100% 100%',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													overflowY: 'auto',
													maxHeight: '200px'
												}} />
											</div>
											<DropZone recuadro={"13"} onDrop={handleCardDrop("13")} droppedCard={droppedCards["13"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro2PropuestaDeValor})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '400px'
											}} />
											<div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '0' }}>
												<DropZone recuadro={"14"} onDrop={handleCardDrop("14")} droppedCard={droppedCards["14"]} onCardClick={handleCardClickDropped} style={{
													height: '50%',
													backgroundImage: `url(${recuadro2RelacionesConClientes})`,
													backgroundSize: '100% 100%',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													overflowY: 'auto',
													maxHeight: '200px'
												}} />
												<DropZone recuadro={"15"} onDrop={handleCardDrop("15")} droppedCard={droppedCards["15"]} onCardClick={handleCardClickDropped} style={{
													height: '50%',
													backgroundImage: `url(${recuadro2Canales})`,
													backgroundSize: '100% 100%',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													overflowY: 'auto',
													maxHeight: '200px'
												}} />
											</div>
											<DropZone recuadro={"16"} onDrop={handleCardDrop("16")} droppedCard={droppedCards["16"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro2SegmentosDeCliente})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
											}} />
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', height: '35%' }}>
											<DropZone recuadro={"17"} onDrop={handleCardDrop("17")} droppedCard={droppedCards["17"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro2EstructuraDeCostos})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
											}} />
											<DropZone recuadro={"18"} onDrop={handleCardDrop("18")} droppedCard={droppedCards["18"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro2FlujosDeIngreso})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
											}} />
										</div>
									</Col>
									<Col xs="3" style={{ overflowY: 'auto', maxHeight: '830px' }}>
										<Card style={{ backgroundColor: '#003366', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
											<CardBody>
												{droppedNames && cartas && movs > 0 ? (
													cartas
														.filter(carta => carta !== undefined && carta !== null)
														.filter(carta => !droppedNames.includes(carta.svg_front))
														.map((carta, index) => {
															const imageObj = images.find(img => img && img.name === carta.svg_front);
															if (!imageObj) {
																return null;
															}
															return (
																<DraggableCard
																	key={carta.svg_front}
																	id={carta.svg_front}
																	name={imageObj.name}
																	src={imageObj.src}
																	rev={imageObj.rev}
																	alt={`Carta ${imageObj.name}`}
																	handleRemove={handleRemove}
																/>
															);
														})
												) : (null)}
											</CardBody>
										</Card>
									</Col>
								</Row>
							</DndProvider>
						</>
					)
					}
				</>
			);
		}

		// 3
		if (selectedCanvas === "canvasB2B") {
			console.debug(`armando canvas 3...`)
			return (
				<>
					{arranqueListo ? (
						<p>{t('common.apreta_arranque')}</p>
					) : (
						<>
							<DndProvider backend={HTML5Backend}>
								<Modal
									isOpen={modalOpen}
									onClose={() => setModalOpen(false)}
									onConfirm={handleModalConfirm}
									datosOpciones={opcionesCartas}
									datosNumOpciones={numOpcionesCartas}
								/>
								<Row>
									<Col xs="9">
										<div style={{ display: 'flex', height: '100%', margin: '0', width: '100%', maxHeight: '800px' }}>
											<div style={{ display: 'flex', flexDirection: 'column', height: '100%', margin: '0', width: '50%' }}>
												<div style={{ display: 'flex', flexDirection: 'row', margin: '1px', width: '100%' }}>
													<div style={{ display: 'flex', flexDirection: 'row', width: '33%', margin: '0' }}>
														<DropZone recuadro={"19"} onDrop={handleCardDrop("19")} droppedCard={droppedCards["19"]} onCardClick={handleCardClickDropped} style={{
															backgroundImage: `url(${recuadro3SociosClaves})`,
															backgroundSize: '100% 100%',
															backgroundPosition: 'center',
															backgroundRepeat: 'no-repeat',
															overflowY: 'auto',
															maxHeight: '400px',
														}} />
													</div>
													<div style={{ display: 'flex', flexDirection: 'column', width: '33%', margin: '0' }}>
														<DropZone recuadro={"20"} onDrop={handleCardDrop("20")} droppedCard={droppedCards["20"]} onCardClick={handleCardClickDropped} style={{
															height: '50%',
															margin: '0',
															backgroundImage: `url(${recuadro3ActividadesClaves})`,
															backgroundSize: '100% 100%',
															backgroundPosition: 'center',
															backgroundRepeat: 'no-repeat',
															overflowY: 'auto',
															maxHeight: '200px'
														}} />
														<DropZone recuadro={"21"} onDrop={handleCardDrop("21")} droppedCard={droppedCards["21"]} onCardClick={handleCardClickDropped} style={{
															height: '50%',
															backgroundImage: `url(${recuadro3RecursosClaves})`,
															backgroundSize: '100% 100%',
															backgroundPosition: 'center',
															backgroundRepeat: 'no-repeat',
															overflowY: 'auto',
															maxHeight: '200px'
														}} />
													</div>
													<div style={{ display: 'flex', flexDirection: 'row', width: '33%', margin: '0' }}>
														<DropZone recuadro={"22"} onDrop={handleCardDrop("22")} droppedCard={droppedCards["22"]} onCardClick={handleCardClickDropped} style={{
															backgroundImage: `url(${recuadro3PropuestaDeValor})`,
															backgroundSize: '100% 100%',
															backgroundPosition: 'center',
															backgroundRepeat: 'no-repeat',
															overflowY: 'auto',
															maxHeight: '400px',
														}} />

													</div>
												</div>

												<div style={{ display: 'flex', flexDirection: 'row', margin: '0', width: '100%' }}>
													<DropZone recuadro={"26"} onDrop={handleCardDrop("26")} droppedCard={droppedCards["26"]} onCardClick={handleCardClickDropped} style={{
														backgroundImage: `url(${recuadro3EstructuraDeCostos})`,
														backgroundSize: '100% 100%',
														backgroundPosition: 'center',
														backgroundRepeat: 'no-repeat',
														maxHeight: '200px'
													}} />
												</div>
											</div>

											<div style={{ display: 'flex', flexDirection: 'row', height: '100%', margin: '0', width: '50%' }}>
												<div style={{ display: 'flex', flexDirection: 'column', width: '66%', margin: '0' }}>
													<div style={{ display: 'flex', flexDirection: 'row', width: '100%', margin: '0' }}>
														<div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '0' }}>
															<DropZone recuadro={"23"} onDrop={handleCardDrop("23")} droppedCard={droppedCards["23"]} onCardClick={handleCardClickDropped} style={{
																height: '50%',
																backgroundImage: `url(${recuadro3RelacionesConClientes})`,
																backgroundSize: '100% 100%',
																backgroundPosition: 'center',
																backgroundRepeat: 'no-repeat',
																overflowY: 'auto',
																maxHeight: '200px'
															}} />
															<DropZone recuadro={"24"} onDrop={handleCardDrop("24")} droppedCard={droppedCards["24"]} onCardClick={handleCardClickDropped} style={{
																height: '50%',
																backgroundImage: `url(${recuadro3Canales})`,
																backgroundSize: '100% 100%',
																backgroundPosition: 'center',
																backgroundRepeat: 'no-repeat',
																overflowY: 'auto',
																maxHeight: '200px'
															}} />
														</div>
														<DropZone recuadro={"25"} onDrop={handleCardDrop("25")} droppedCard={droppedCards["25"]} onCardClick={handleCardClickDropped} style={{
															backgroundImage: `url(${recuadro3SegmentosDeCliente})`,
															backgroundSize: '100% 100%',
															backgroundPosition: 'center',
															backgroundRepeat: 'no-repeat',
															maxHeight: '400px'
														}} />
													</div>

													<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
														<DropZone recuadro={"27"} onDrop={handleCardDrop("27")} droppedCard={droppedCards["27"]} onCardClick={handleCardClickDropped} style={{
															backgroundImage: `url(${recuadro3FlujosDeIngreso})`,
															backgroundSize: '100% 100%',
															backgroundPosition: 'center',
															backgroundRepeat: 'no-repeat',
														}} />
													</div>

												</div>
												<div style={{ display: 'flex', flexDirection: 'column', width: '33%', margin: '0' }}>
													<DropZone recuadro={"28"} onDrop={handleCardDrop("28")} droppedCard={droppedCards["28"]} onCardClick={handleCardClickDropped} style={{
														backgroundImage: `url(${recuadro3Consumidores})`,
														backgroundSize: '100% 100%',
														backgroundPosition: 'center',
														backgroundRepeat: 'no-repeat',
													}} />
													<DropZone recuadro={"29"} onDrop={handleCardDrop("29")} droppedCard={droppedCards["29"]} onCardClick={handleCardClickDropped} style={{
														backgroundImage: `url(${recuadro3Influencer})`,
														backgroundSize: '100% 100%',
														backgroundPosition: 'center',
														backgroundRepeat: 'no-repeat',
													}} />
													<DropZone recuadro={"30"} onDrop={handleCardDrop("30")} droppedCard={droppedCards["30"]} onCardClick={handleCardClickDropped} style={{
														backgroundImage: `url(${recuadro3Intermediarios})`,
														backgroundSize: '100% 100%',
														backgroundPosition: 'center',
														backgroundRepeat: 'no-repeat',
														height: '120%'
													}} />
												</div>
											</div>
										</div>
									</Col>

									<Col xs="3" style={{ overflowY: 'auto', maxHeight: '830px' }}>
										<Card style={{ backgroundColor: '#003366', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
											<CardBody>
												{droppedNames && cartas && movs > 0 ? (
													cartas
														.filter(carta => carta !== undefined && carta !== null)
														.filter(carta => !droppedNames.includes(carta.svg_front))
														.map((carta, index) => {
															const imageObj = images.find(img => img && img.name === carta.svg_front);
															if (!imageObj) {
																return null;
															}
															return (
																<DraggableCard
																	key={carta.svg_front}
																	id={carta.svg_front}
																	name={imageObj.name}
																	src={imageObj.src}
																	rev={imageObj.rev}
																	alt={`Carta ${imageObj.name}`}
																	handleRemove={handleRemove}
																/>
															);
														})
												) : (null)}
											</CardBody>
										</Card>
									</Col>
								</Row>
							</DndProvider>
						</>
					)
					}
				</>
			);
		}

		// 4
		if (selectedCanvas === "canvasNavigator") {
			console.debug(`armando canvas 4...`)
			return (
				<>
					{arranqueListo ? (
						<p>{t('common.apreta_arranque')}</p>
					) : (
						<>
							<DndProvider backend={HTML5Backend}>
								<Modal
									isOpen={modalOpen}
									onClose={() => setModalOpen(false)}
									onConfirm={handleModalConfirm}
									datosOpciones={opcionesCartas}
									datosNumOpciones={numOpcionesCartas}
								/>
								<Row>
									<Col xs="9">
										<div style={{ display: 'flex', flexDirection: 'row', margin: '0' }}>
											<DropZone recuadro={"31"} onDrop={handleCardDrop("31")} droppedCard={droppedCards["31"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro4Que})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '400px'
											}} />
											<DropZone recuadro={"35"} onDrop={handleCardDrop("35")} droppedCard={droppedCards["35"]} onCardClick={handleCardClickDropped} style={{
												height: '50%',
												backgroundImage: `url(${recuadro4Propuesta})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '200px'
											}} />
											<DropZone recuadro={"33"} onDrop={handleCardDrop("33")} droppedCard={droppedCards["33"]} onCardClick={handleCardClickDropped} style={{
												height: '50%',
												backgroundImage: `url(${recuadro4Quien})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '200px'
											}} />
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', margin: '0' }}>
											<DropZone recuadro={"32"} onDrop={handleCardDrop("32")} droppedCard={droppedCards["32"]} onCardClick={handleCardClickDropped} style={{
												height: '50%',
												margin: '0',
												backgroundImage: `url(${recuadro4Porque})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '200px'
											}} />

											<DropZone recuadro={"36"} onDrop={handleCardDrop("36")} droppedCard={droppedCards["36"]} onCardClick={handleCardClickDropped} style={{
												height: '50%',
												backgroundImage: `url(${recuadro4Captura})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '200px'
											}} />

											<DropZone recuadro={"37"} onDrop={handleCardDrop("37")} droppedCard={droppedCards["37"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro4Creacion})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
											}} />
											<DropZone recuadro={"34"} onDrop={handleCardDrop("34")} droppedCard={droppedCards["34"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro4Como})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												maxHeight: '400px'
											}} />
										</div>
									</Col>
									<Col xs="3" style={{ overflowY: 'auto', maxHeight: '830px' }}>
										<Card style={{ backgroundColor: '#003366', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
											<CardBody>
												{droppedNames && cartas && movs > 0 ? (
													cartas
														.filter(carta => carta !== undefined && carta !== null)
														.filter(carta => !droppedNames.includes(carta.svg_front))
														.map((carta, index) => {
															const imageObj = images.find(img => img && img.name === carta.svg_front);
															if (!imageObj) {
																return null;
															}
															return (
																<DraggableCard
																	key={carta.svg_front}
																	id={carta.svg_front}
																	name={imageObj.name}
																	src={imageObj.src}
																	rev={imageObj.rev}
																	alt={`Carta ${imageObj.name}`}
																	handleRemove={handleRemove}
																/>
															);
														})
												) : (null)}
											</CardBody>
										</Card>
									</Col>
								</Row>
							</DndProvider>
						</>
					)
					}
				</>
			);
		}


	}

	// propio canvas interactivo
	function SprintDataDrivenVirtual() {
		console.debug(`Entrando a Sprint DataDriven Virtual...`)

		// no permitir click si ya se llego al limite
		const movs = movimientosDisponibles()

		//////// TIENE QUE TRAER BARAJA, NO MAZO

		useEffect(() => {
			if (cartas.length > 0) {
				fetchImagesIntegrada(cartas);
			}
		}, [cartas]);

		return (
			<>
				{arranqueListo ? (
					<p>{t('common.apreta_arranque')}</p>
				) : (
					<>
						<DndProvider backend={HTML5Backend}>
							<Row>
								<Col xs="9">
									<div style={{ display: 'flex', flexDirection: 'row', margin: '0', minHeight: '600px' }}>
										<DropZone recuadro={"38"} onDrop={handleCardDrop("38")} droppedCard={droppedCards["38"]} onCardClick={handleCardClickDropped} style={{
											backgroundImage: `url(${recuadro5DataSources})`,
											backgroundSize: '100% 100%',
											backgroundPosition: 'center',
											backgroundRepeat: 'no-repeat',
											overflowY: 'auto',
											maxHeight: '800px'
										}} />
										<DropZone recuadro={"39"} onDrop={handleCardDrop("39")} droppedCard={droppedCards["39"]} onCardClick={handleCardClickDropped} style={{
											backgroundImage: `url(${recuadro5Analytics})`,
											backgroundSize: '100% 100%',
											backgroundPosition: 'center',
											backgroundRepeat: 'no-repeat',
											overflowY: 'auto',
											maxHeight: '800px'
										}} />
										<DropZone recuadro={"40"} onDrop={handleCardDrop("40")} droppedCard={droppedCards["40"]} onCardClick={handleCardClickDropped} style={{
											backgroundImage: `url(${recuadro5ProductOrService})`,
											backgroundSize: '100% 100%',
											backgroundPosition: 'center',
											backgroundRepeat: 'no-repeat',
											overflowY: 'auto',
											maxHeight: '800px'
										}} />
										<DropZone recuadro={"41"} onDrop={handleCardDrop("41")} droppedCard={droppedCards["41"]} onCardClick={handleCardClickDropped} style={{
											backgroundImage: `url(${recuadro5Benefit})`,
											backgroundSize: '100% 100%',
											backgroundPosition: 'center',
											backgroundRepeat: 'no-repeat',
											overflowY: 'auto',
											maxHeight: '800px'
										}} />
										<div style={{ display: 'flex', flexDirection: 'column', margin: '0', width: '100%' }}>
											<DropZone recuadro={"42"} onDrop={handleCardDrop("42")} droppedCard={droppedCards["42"]} onCardClick={handleCardClickDropped} style={{
												backgroundImage: `url(${recuadro5ImplicationsMonetization})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												height: '300px',
											}} />
											<DropZone recuadro={"43"} onDrop={handleCardDrop("43")} droppedCard={droppedCards["43"]} onCardClick={handleCardClickDropped} style={{
												height: '50%',
												backgroundImage: `url(${recuadro5ImplicationsCosts})`,
												backgroundSize: '100% 100%',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												overflowY: 'auto',
												height: '300px',
											}} />
										</div>
									</div>
								</Col>
								<Col xs="3" style={{ overflowY: 'auto', maxHeight: '830px' }}>
									<Card style={{ backgroundColor: '#003366', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
										<CardBody>
											{droppedNames && cartas && movs > 0 ? (
												cartas
													.filter(carta => carta !== undefined && carta !== null)
													.filter(carta => !droppedNames.includes(carta.svg_front))
													.map((carta, index) => {
														const imageObj = images.find(img => img && img.name === carta.svg_front);
														if (!imageObj) {
															return null;
														}
														return (
															<DraggableCard
																key={carta.svg_front}
																id={carta.svg_front}
																name={imageObj.name}
																src={imageObj.src}
																rev={imageObj.rev}
																alt={`Carta ${imageObj.name}`}
																handleRemove={handleRemove}
															/>
														);
													})
											) : (null)}
										</CardBody>
									</Card>
								</Col>
							</Row>
						</DndProvider>
					</>
				)
				}
			</>
		);

	}

	// canvas propio, no es interactivo
	function SprintPlatformVirtual() {

		console.debug("Arrancando SprintPlatformVirtual")

		/*    CARTAS    */

		useEffect(() => {
			async function fetchImages() {
				var imageData = await Promise.all(cartas.map(fetchImageData));

				console.debug(`se trajeron ${imageData.length} cartas, como ${imageData[0].name}`)

				// agregar imgs del reverso si hace falta
				var imageDataRev = []

				imageDataRev = await Promise.all(cartas.map(fetchImageDataRev));

				console.debug('primer carta en imageData es:', imageData[0].name, imageDataRev[0].name)


				imageData = imageData.concat(imageDataRev)

				console.debug(`desp de fijarse rev, se trajeron ${imageData.length} cartas, ${imageDataRev.length} son rev`)

				var listaCartas = []
				for (let i = 0; i < imageData.length; i++) {
					var imagenInd = imageData[i]
					if (!imagenInd) {
						console.debug(`falta carta ${i}/${imageData.length}, borrando...`)
						continue
					}
					if (!imagenInd.src) {
						console.debug(`falta src ${imagenInd}, borrando...`)
						imageData.splice(i)
						continue
					}
					listaCartas.push(imagenInd.name)
					//console.debug(imageData[i].name)
				}

				console.debug(`lista tiene ${listaCartas.length}: ${listaCartas}`)
				console.debug(`listaCartas verificado tiene ${listaCartas.length} imagenes`)

				setImages(imageData);
				setCartasLoaded(true)
			}
			if (cartas.length > 0) {
				fetchImages();
			}
		}, [cartas]);

		// acepta la lista de opciones del selector y los guarda como selected
		// acepta array de objetos cartas
		const handleSelectorGuardar = (arraySeleccionNueva) => {
			if (!arraySeleccionNueva) { return }
			console.debug(`guardando seleccion ${arraySeleccionNueva}`)
			var arrayCartasParaMandar = selectedCards

			// determinar primera carta para display para tacharlo
			var primera = opcionesCartas[0].svg_front
			console.debug(`bloqueando primera carta, ${primera}`)
			var arrayModalParaMandar = selectedCards
			arrayModalParaMandar.push(primera)

			for (let i = 0; i < arraySeleccionNueva.length; i++) {
				if (arraySeleccionNueva[i] === null) { continue }
				var carta = arraySeleccionNueva[i]
				console.debug(`guardando seleccion carta ${carta}`)
				arrayCartasParaMandar.push(carta)
			}
			console.debug(`seleccionando array ${arrayCartasParaMandar}`)
			setSelectedCards(arrayCartasParaMandar)
			setSelectedCardsModal(arrayModalParaMandar)
			setTemporizadorPausado(false) // <--------------------------------------------------------
		}

		// solapa para multiple choice de cartas
		function Modal({ isOpen, onClose, onConfirm, datosOpciones, datosNumOpciones }) {
			const [selectedOptions, setSelectedOptions] = useState([]);

			if (!datosOpciones || !datosNumOpciones) { return null }

			// traer array de objetos cartas
			const options = datosOpciones.filter(v => v !== null);
			//console.debug(`opciones en Modal son ${JSON.stringify(options)}`)

			const handleOptionChange = (event) => {
				//console.debug(`seleccionadas antes de clic eran ${selectedOptions}`)
				const value = event.target.value;
				//console.debug(`valor del clic es ${value}`)
				setSelectedOptions((prev) =>
					event.target.checked ? [...prev, value] : prev.filter(item => item !== value)
				);
			};

			const handleSubmit = () => {
				console.debug(`handleSubmit con ${selectedOptions.length} opciones de ${datosNumOpciones} max`)
				if (selectedOptions.length <= datosNumOpciones) {
					console.debug(`submitting con ${JSON.stringify(selectedOptions)}`)
					onConfirm(handleSelectorGuardar(selectedOptions));
					onClose();
				} else {
					console.debug(`te pasaste de opciones`) // 				<--------
				}
			};

			const modalStyle = {
				display: isOpen ? 'block' : 'none',
				position: 'fixed',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				padding: '20px',
				backgroundColor: 'orange',
				zIndex: 1000
			};

			const overlayStyle = {
				display: isOpen ? 'block' : 'none',
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				zIndex: 999
			};

			const selectedCardImage = images.find(img => img && img.name === selectedCard);

			return (
				<>

					<div style={overlayStyle} onClick={onClose}></div>
					<div style={modalStyle}>

						{
							selectedCardImage ? (
								<img
									key={selectedCard}
									src={selectedCardImage.src}
									alt={`Carta ${selectedCardImage.name}`}
									width="250"
								/>
							) : null
						}
						<div>{t('sprints.seleccionaHasta')} {datosNumOpciones} {t('sprints.opcionesYApreta')}</div>
						<ul>
							{options.map((option) => (
								<li style={{ color: 'black' }}>
									<label key={option.svg_front}>
										<input
											type="checkbox"
											value={option.svg_front}
											onChange={handleOptionChange}
											checked={selectedOptions.includes(option.svg_front)}
										/>
										{t(option.nombre_18full)}
									</label>
								</li>
							))}

							{/*
					<img
						src={options[0].svg_front}
						alt={`Carta ${options[0].nombre_es}`}
						width="250"
					/>
					*/}
							<li><button onClick={handleSubmit}>{t('common.Confirmar_final')}</button></li>
						</ul>
					</div>
				</>
			);
		}

		const handleModalConfirm = (optionsRutas) => {
			console.debug('Opciones seleccionadas:', optionsRutas);
		};

		return (
			<>
				{arranqueListo || !droppedCards ? (
					<p>...</p>
				) : (
					<>
						<Modal
							isOpen={modalOpen}
							onClose={() => setModalOpen(false)}
							onConfirm={handleModalConfirm}
							datosOpciones={opcionesCartas}
							datosNumOpciones={numOpcionesCartas}
						/>

						{/*		FRAMEWORK PLATFORMS		 */}
						<Row>
							<Col xs="12">
								<Card>
									<CardBody>
										<h2>{t('canvas.de')} Platforms</h2>
										{cartas ? (
											<img
												key={`canvasPlataform`}
												src={canvasPlatform}
												alt={`Canvas Platforms`}
											/>
										) : (
											<p>{t('canvas.cargandoJugadas')} Platforms...</p>
										)}
									</CardBody>
								</Card>
							</Col>
						</Row>
						<Row>
							<Col xs="12" style={{ overflowY: 'auto' }}>
								<Card style={{ backgroundColor: '#003366', height: 'auto', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
									<CardBody>
										{cartas.map((carta, index) => {
											const imageObj = images.find(img => img && img.name === carta.svg_front);
											if (!imageObj) {
												return null;
											}
											return (
												<img
													key={carta.svg_front}
													src={imageObj.src}
													alt={`Carta ${imageObj.name}`}
													width="250"
													className={selectedCardsModal.includes(imageObj.name) ? "cardEffect cardSelected" : "cardEffect"}
													onClick={selectedCardsModal.includes(imageObj.name) ? null : () => handleCardClickModal(imageObj.name)} // Evitamos los clics si la carta ya ha sido seleccionada.
													style={selectedCardsModal.includes(imageObj.name) ? { opacity: 0.5, cursor: 'not-allowed' } : {}} // Estilo adicional para cartas seleccionadas.
												/>
											);
										})}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</>
				)}
			</>
		);
	}

	//////////////////////
	/* 		RENDER		*/
	//////////////////////

	function renderSelectedSprint(seleccion) {

		console.debug(`renderizando ${seleccion}...`)

		switch (seleccion) {
			// presenciales
			case "SprintMain": return SprintMain();
			case "SprintPlatform": return SprintPlatform();
			case "SprintBlueOcean": return SprintBlueOcean();
			case "SprintEcosystem": return SprintEcosystem();

			// virtuales
			case "SprintVirtual": return SprintVirtual();
			case "SprintDataDrivenVirtual": return SprintDataDrivenVirtual();
			case "SprintBlueOceanVirtual": return SprintBlueOceanVirtual();
			case "SprintPlatformVirtual": return SprintPlatformVirtual();

			default: return null
		}
	}

	return (
		<>
			<div className="content"
				style={{
					backgroundImage: `url(${img})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					height: '100vh',
					width: '100vw',
				}}>
				{BarraSprint(botonArrancar, botonGuardar, tiempoRestante, arranqueListo, yaSeGuardo, movimientosDisponibles, nombreSprint, idioma, cartasLoaded, todosSprints)}

				{selectedSprint ? (renderSelectedSprint(selectedSprint)) : (console.error(`render sprint es null`))}
			</div>
		</>
	)

}

export default Sprint;