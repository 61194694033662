// tablas fijas y formulas para resultados.js

import { Trans } from 'react-i18next'
import Conversiones from './Conversiones'

class ResultadosFijo extends Conversiones {

    // TABLAS

    datosAñoX(ventas, ingresos) {
        return [
            { concepto: <Trans i18nKey={'estadoRes.ventas'} />, cantidad: this.formatCurrency(ventas) },
            { concepto: <Trans i18nKey={'estadoRes.totalIngresos'} />, cantidad: this.formatCurrency(ingresos) },
        ]
    }

    datosAñoActualX(ventasCant, ingresosCant) {
        return [
            {
                concepto: <Trans i18nKey={'estadoRes.ventas'} />,
                cantidad: this.formatCurrency(ventasCant),
                actual: this.formatCurrency(ventasCant)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.totalIngresos'} />,
                cantidad: this.formatCurrency(ingresosCant),
                actual: this.formatCurrency(ingresosCant)
            },
        ]
    }

    datosAñoXE(personal, alquileres, marketing, amort, reparaciones, insumos, gastosOfi, otros1, otros2, otros3, totalFijos, costo1, costo2, costo3, costo4, costo5, totalGastosVar, totalEgresos, rtdoNeto, ROI) {
        return [
            {
                concepto: <Trans i18nKey={'estadoRes.gastosFijos'} />,
                cantidad: ''
            },
            {
                concepto: <Trans i18nKey={'estadoRes.personal'} />,
                cantidad: this.formatCurrency(personal)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.alquileres'} />,
                cantidad: this.formatCurrency(alquileres)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.marketing'} />,
                cantidad: this.formatCurrency(marketing)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.amortMaq'} />,
                cantidad: this.formatCurrency(amort)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.reparaciones'} />,
                cantidad: this.formatCurrency(reparaciones)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.insumos'} />,
                cantidad: this.formatCurrency(insumos)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.gastosOficina'} />,
                cantidad: this.formatCurrency(gastosOfi)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.otros'} components={{ 1: "1" }} />,
                cantidad: this.formatCurrency(otros1)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.otros'} components={{ 1: "2" }} />,
                cantidad: this.formatCurrency(otros2)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.otros'} components={{ 1: "3" }} />,
                cantidad: this.formatCurrency(otros3)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.totalGastosFijos'} />,
                cantidad: this.formatCurrency(totalFijos)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.gastosVariables'} />,
                cantidad: ''
            },
            {
                concepto: <Trans i18nKey={'estadoRes.costo'} components={{ 1: "1" }} />,
                cantidad: this.formatCurrency(costo1)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.costo'} components={{ 1: "2" }} />,
                cantidad: this.formatCurrency(costo2)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.costo'} components={{ 1: "3" }} />,
                cantidad: this.formatCurrency(costo3)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.costo'} components={{ 1: "4" }} />,
                cantidad: this.formatCurrency(costo4)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.costo'} components={{ 1: "5" }} />,
                cantidad: this.formatCurrency(costo5)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.totalGastosVariables'} />,
                cantidad: this.formatCurrency(totalGastosVar)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.totalEgresos'} />,
                cantidad: this.formatCurrency(totalEgresos)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.rtdoNeto'} />,
                cantidad: this.formatCurrency(rtdoNeto)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.ROI'} />,
                cantidad: ROI
            },
        ];
    }

    datosAñoActualXE(
        personal, alquileres, marketing, amort, reparaciones, insumos, gastosOfi, otros1, otros2, otros3, totalFijos,
        costo1, costo2, costo3, costo4, costo5, totalGastosVar, totalEgresos, rtdoNeto, ROI
    ) {

        return [
            {
                concepto: <Trans i18nKey={'estadoRes.gastosFijos'} />,
                cantidad: '',
                actual: ''
            },
            {
                concepto: <Trans i18nKey={'estadoRes.personal'} />,
                cantidad: this.formatCurrency(personal),
                actual: this.formatCurrency(personal)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.alquileres'} />,
                cantidad: this.formatCurrency(alquileres),
                actual: this.formatCurrency(alquileres)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.marketing'} />,
                cantidad: this.formatCurrency(marketing),
                actual: this.formatCurrency(marketing)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.amortMaq'} />,
                cantidad: this.formatCurrency(amort),
                actual: this.formatCurrency(amort)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.reparaciones'} />,
                cantidad: this.formatCurrency(reparaciones),
                actual: this.formatCurrency(reparaciones)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.insumos'} />,
                cantidad: this.formatCurrency(insumos),
                actual: this.formatCurrency(insumos)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.gastosOficina'} />,
                cantidad: this.formatCurrency(gastosOfi),
                actual: this.formatCurrency(gastosOfi)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.otros'} components={{ 1: "1" }} />,
                cantidad: this.formatCurrency(otros1),
                actual: this.formatCurrency(otros1)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.otros'} components={{ 1: "2" }} />,
                cantidad: this.formatCurrency(otros2),
                actual: this.formatCurrency(otros2)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.otros'} components={{ 1: "3" }} />,
                cantidad: this.formatCurrency(otros3),
                actual: this.formatCurrency(otros3)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.totalGastosFijos'} />,
                cantidad: this.formatCurrency(totalFijos),
                actual: this.formatCurrency(totalFijos)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.gastosVariables'} />,
                cantidad: '',
                actual: ''
            },
            {
                concepto: <Trans i18nKey={'estadoRes.costo'} components={{ 1: "1" }} />,
                cantidad: this.formatCurrency(costo1),
                actual: this.formatCurrency(costo1)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.costo'} components={{ 1: "2" }} />,
                cantidad: this.formatCurrency(costo2),
                actual: this.formatCurrency(costo2)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.costo'} components={{ 1: "3" }} />,
                cantidad: this.formatCurrency(costo3),
                actual: this.formatCurrency(costo3)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.costo'} components={{ 1: "4" }} />,
                cantidad: this.formatCurrency(costo4),
                actual: this.formatCurrency(costo4)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.costo'} components={{ 1: "5" }} />,
                cantidad: this.formatCurrency(costo5),
                actual: this.formatCurrency(costo5)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.totalGastosVariables'} />,
                cantidad: this.formatCurrency(totalGastosVar),
                actual: this.formatCurrency(totalGastosVar)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.totalEgresos'} />,
                cantidad: this.formatCurrency(totalEgresos),
                actual: this.formatCurrency(totalEgresos)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.rtdoNeto'} />,
                cantidad: this.formatCurrency(rtdoNeto),
                actual: this.formatCurrency(rtdoNeto)
            },
            {
                concepto: <Trans i18nKey={'estadoRes.ROI'} />,
                cantidad: ROI
            },
        ]
    }

    tableData() {
        return [
            [<Trans i18nKey={'estadoRes.item'} />, <Trans i18nKey={'estadoRes.numVendido'} />, <Trans i18nKey={'estadoRes.numVendidoInic'} />, <Trans i18nKey={'estadoRes.costoUnit'} />, <Trans i18nKey={'estadoRes.costoInic'} />,
            <Trans i18nKey={'estadoRes.precUnit'} />, <Trans i18nKey={'estadoRes.precInic'} />, <Trans i18nKey={'estadoRes.ventasArt'} />, <Trans i18nKey={'estadoRes.costoArt'} />],
            [<Trans i18nKey={'estadoRes.articulo'} components={{ 1: "1" }} />, 3000, 3000, 100, 100, 200, 200, 0, 0],
            [<Trans i18nKey={'estadoRes.articulo'} components={{ 1: "2" }} />, 2500, 2500, 150, 150, 300, 300, 0, 0],
            [<Trans i18nKey={'estadoRes.articulo'} components={{ 1: "3" }} />, 2000, 2000, 200, 200, 250, 250, 0, 0],
            [<Trans i18nKey={'estadoRes.articulo'} components={{ 1: "4" }} />, 1000, 1000, 150, 150, 200, 200, 0, 0],
            [<Trans i18nKey={'estadoRes.articulo'} components={{ 1: "5" }} />, 500, 500, 100, 100, 200, 200, 0, 0],
            [<Trans i18nKey={'estadoRes.total'} />, 0, 0, "", "", "", "", 0, 0],
        ]
    }

    tablaMetricas(revenueGrowth, margenBruto, ratioDeCambio, incrementoVentas) {
        const fn = "ResultadosFijo/TablaMetricas"
        try {
            console.debug(`${fn} con ${revenueGrowth}, ${margenBruto}, ${ratioDeCambio}, ${incrementoVentas}`)
            return [
                [<Trans i18nKey={'metricas.metricas'} />, <Trans i18nKey={'metricas.proyectado'} />, <Trans i18nKey={'metricas.final'} />],
                [<Trans i18nKey={'metricas.reliability'} />, "90%", "90%"],
                [<Trans i18nKey={'metricas.lead'} />, this.convertToHHMMSS(8.25), this.convertToHHMMSS(8.25)],
                [<Trans i18nKey={'metricas.customerEffort'} />, "15%", "15%"],
                [<Trans i18nKey={'metricas.customerSatisf'} />, "85%", "85%"],
                [<Trans i18nKey={'metricas.customerRet'} />, "35%", "35%"],
                [<Trans i18nKey={'metricas.cross'} />, 1, 1],
                [<Trans i18nKey={'metricas.customerAdq'} />, 500, 500],
                [<Trans i18nKey={'metricas.unique'} />, 100000, 100000],
                [<Trans i18nKey={'metricas.conversion'} />, "1%", "1%"],
                [<Trans i18nKey={'metricas.repeat'} />, 5556, 5556],
                [<Trans i18nKey={'metricas.netProfit'} />, this.formatCurrency(10000), this.formatCurrency(10000)],
                [<Trans i18nKey={'metricas.revenue'} />, this.formatCurrency(0), this.formatCurrency(revenueGrowth)],
                [<Trans i18nKey={'metricas.ROI'} />, "11.5%", "11.5%"],
                [<Trans i18nKey={'metricas.capitalExp'} />, this.formatCurrency(8000000), this.formatCurrency(8000000)],
                [<Trans i18nKey={'metricas.NPS'} />, 20, 20],
                [<Trans i18nKey={'metricas.ticketMedio'} />, this.formatCurrency(238.89), this.formatCurrency(238.89)],
                [<Trans i18nKey={'metricas.trafico'} />, 235000, 235000],
                [<Trans i18nKey={'metricas.margen'} />, this.formatCurrency(875000), this.formatCurrency(margenBruto)],
                [<Trans i18nKey={'metricas.marketShare'} />, "32.14%", "32.14%"],
                ["---", "---", "---"],
                [<Trans i18nKey={'metricas.cambioEstado'} />, <Trans i18nKey={'metricas.ratioCambio'} />, <Trans i18nKey={'metricas.ventasIncremento'} />],
                [this.formatCurrency(revenueGrowth), `${(ratioDeCambio * 100).toFixed(2)}%`, `${(incrementoVentas * 100).toFixed(2)}%`]
            ]
        } catch (error) {
            const msg = `${fn} error: ${error.message}`
            console.error(msg)
        }
    }

    // FUNCIONES



}

export default ResultadosFijo