// Quiz.js
// pantalla que da una pregunta multiple-choice aleatoria acorde al jugador

import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import classNames from "classnames"
import { useTranslation } from "react-i18next";

// assets
import audioFile from "../assets/music/MusicaQuizz.mp3";
import audioRespuestaCorrecta from "../assets/music/Acierto.mp3";
import audioRespuestaIncorrecta from "../assets/music/Error.mp3";
import img from "../assets/img/Mapa.png";
import { Card, CardBody, Row, Col, Button } from "reactstrap";

// internos
import puntosHelpers from "../variables/puntos.js"
import quizHelpers from "../variables/quizzes.js"
import tokenHelpers from "../variables/tokens.js"
import sprintHelpers from "../variables/sprints.js"
import equiposHelpers from "../variables/equipos.js"
import imgOk from "../assets/img/send_ok.png";

const fetchData = puntosHelpers.fetchData;
const agregarPuntosEquipo = puntosHelpers.agregarPuntosEquipo;
const calcularPuntosQuiz = puntosHelpers.calcularPuntosQuiz
const tiempoMaxQuiz = puntosHelpers.tiempoMaxQuiz // 60 segundos
const traerPregunta = quizHelpers.traerPregunta;
const decodeToken = tokenHelpers.decodeToken;
const numeroSprintsEnPartida = sprintHelpers.numeroSprintsEnPartida
const agregarEquipoAlSprint = sprintHelpers.agregarEquipoAlSprint
const equiposEnPartida = equiposHelpers.equiposEnPartida;

function Quiz() {
	console.debug("Arrancando Quiz.js")

	const navigate = useNavigate();
	const { t, i18n } = useTranslation();

	const [yaSeGuardo, setYaSeGuardo] = useState(false)

	const [vistaActual, setVistaActual] = useState(0);
	const [respuesta, setRespuesta] = useState(null);
	const [tiempoRestante, setTiempoRestante] = useState(tiempoMaxQuiz);
	const [temporizadorPausado, setTemporizadorPausado] = useState(false);
	const [temporizadorDetenido, setTemporizadorDetenido] = useState(false);
	const [temporizadorInterval, setTemporizadorInterval] = useState(null); // Define la variable de intervalo

	const [audioCorrecta] = useState(new Audio(audioRespuestaCorrecta));
	const [audioIncorrecta] = useState(new Audio(audioRespuestaIncorrecta));
	const [audio] = useState(new Audio(audioFile));
	const [audioPlaying, setAudioPlaying] = useState(false);

	const [nombreSprint, setNombreSprint] = useState(null);
	const [selectedPartida, setSelectedPartida] = useState(null);
	const [selectedEquipo, setSelectedEquipo] = useState(null);
	const [pregunta, setPregunta] = useState(null);
	const [preguntaLoad, setPreguntaLoad] = useState(null);
	const [sigRuta, setSigRuta] = useState("/CapitanPresencial/EstadoResultados") // default bien ?
	const [sigSprint, setSigSprint] = useState(null)

	// armar la configuracion
	async function setearConfDesdeToken() {
		// recibir token de local storage
		const token = localStorage.getItem('token')

		if (token) {
			console.debug('Token found:', token);
			const claims = decodeToken(token);
			console.debug('Token claims:', claims);

			// armar idioma
			if (claims.idioma) { i18n.changeLanguage(claims.idioma) }

			// configuracion de juego
			if (claims.role === "Player" || claims.role === "Captain") {
				// setear partida y equipo
				setSelectedPartida(claims.partida)
				setSelectedEquipo(claims.equipo)
				console.debug(`Seteando quiz para partida ${claims.partida}, equipo ${claims.equipo}...`)

				// traer el sprint ID al que esta asociado el equipo
				const datosEquipo = await fetchData(claims.partida, claims.equipo)
				const datosSprints = await numeroSprintsEnPartida(claims.partida)
				//console.debug(`datos equipo son ${JSON.stringify(datosEquipo)}`)
				console.debug(`Equipo esta en sprint id ${datosEquipo[0]["sprint_id"]}`)
				console.debug(`datos de todos los sprints en p son ${JSON.stringify(datosSprints)}`)

				var datosSprint
				// si no tiene un sprint asociado, ir al primer sprint en esa partida
				if (datosEquipo[0]["sprint_id"]) {
					// usar el sprint ID para encontrar el nombre del sprint
					datosSprint = datosSprints.find(x => x.id === Number(datosEquipo[0]["sprint_id"]));
				} else {
					console.error("No se encontro sprint para este equipo, usando el primero asociado a la partida")
					datosSprint = datosSprints[0]
				}
				console.debug(`datos sprint ind son ${JSON.stringify(datosSprint)}`)

				var sigRutaRole
				var sigRutaPres
				switch (claims.role) {
					case "Player": sigRutaRole = "Jugador"; break;
					case "Captain": sigRutaRole = "Capitan"; break;
					default: sigRutaRole = "Jugador"; break;
				}
				switch (claims.presencial) {
					case 1: sigRutaPres = "Presencial"; break;
					case 0: sigRutaPres = "Virtual"; break;
					default: sigRutaPres = "Presencial"; break;
				}

				var sigRutaDatos = `/${sigRutaRole}${sigRutaPres}/Tablero`
				console.debug(`sig ruta es ${sigRutaDatos}`)
				setSigRuta(sigRutaDatos)

				// determinar cual es el proximo sprint
				var proxSprintDatos = datosSprints.find(x => x.id === (Number(datosSprint["id"]) + 1))
				setSigSprint(proxSprintDatos)

				if (proxSprintDatos) {
					sigRutaDatos = `/${sigRutaRole}${sigRutaPres}/Tablero`
					console.debug(`sig ruta es ${sigRutaDatos}`)
					setSigRuta(sigRutaDatos)
					console.debug(`Despues de guardar el quiz, asignando a ${proxSprintDatos["nombre"]}`)
				} else {
					sigRutaDatos = `/${sigRutaRole}${sigRutaPres}/Final`
					console.debug(`sig ruta es ${sigRutaDatos}`)
					setSigRuta(sigRutaDatos)
					console.debug(`Estas en el ultimo sprint!`)
				}

				/*
				if (datosSprint["nombre"] === "Platform") { // <-----------------------------------------------------------------------
				  console.debug("Saltando quiz de Platform roto")
				  agregarEquipoAlSprint(claims.partida, claims.equipo, proxSprintDatos["numSprint"])
				  navigate(`/${sigRutaRole}${sigRutaPres}/Tablero`)
				}
				*/

				// asociarse a ese sprint
				setNombreSprint(datosSprint["nombre"])
				console.debug(`Trayendo pregunta ${datosEquipo[0]["posicion"]} para sprint ${datosSprint["nombre"]}...`)

				// traer la pregunta usando equipo.posicion
				try {
					var datosPreguntai18n = await traerPregunta(datosSprint["nombre"], datosEquipo[0]["posicion"])
					if (!datosPreguntai18n) { throw new Error("datosPreguntai18n volvio vacio") }

					console.debug(`datosPreguntai18n devuelve ${JSON.stringify(datosPreguntai18n)}`)
					/*
					pregunta = {
						texto: texto,
						opciones: opciones,
						respuestaCorrecta: correcta,
					}
					*/

					// traducir opciones
					const numOpciones = datosPreguntai18n["opciones"]
					var tradOpciones = []
					for (let i = 0; i < numOpciones; i++) {
						var opcion = `quizPreguntas.${datosSprint["nombre"]}.${datosEquipo[0]["posicion"]}.opciones.${i}`
						tradOpciones.push(t(opcion))
					}

					// traducir pregunta
					const datosPregunta = {
						texto: t(datosPreguntai18n.texto),
						respuestaCorrecta: t(datosPreguntai18n.respuestaCorrecta),
						opciones: tradOpciones
					}
					console.debug(`datosPregunta traducida es ${JSON.stringify(datosPregunta)}`)

					// setearlo como pregunta para el quiz
					setPreguntaLoad(datosPregunta)

				} catch (error) {
					console.error("no se pudo traer la pregunta!", error.message)
					window.location.reload()
				}

			} else if (claims.role === "Admin") {
				//setUserType("Admin")
				console.error("Estas logueado como admin, no podes hacer quizzes")
			} else {
				console.error("y vos quien sos ???")
			}
		}
	}

	// arrancar la conf al iniciar la pag
	useEffect(() => {
		setearConfDesdeToken()
	}, [])

	/* para cuando hay varias preguntas
	const cambiarVista = (numeroVista) => {
	  setVistaActual(numeroVista);
	  setRespuesta(null);
	  setTemporizadorPausado(false);
	  setTemporizadorDetenido(false);
	  reiniciarTemporizador();
	};
	*/

	const reiniciarTemporizador = () => {
		setTiempoRestante(tiempoMaxQuiz);
		setTemporizadorPausado(false);
		setTemporizadorDetenido(false);
	};

	const iniciarTemporizador = () => {
		if (!temporizadorPausado && !temporizadorDetenido) {
			const interval = setInterval(() => {
				if (tiempoRestante > 0) {
					setTiempoRestante(tiempoRestante - 1);
				} else {
					setRespuesta(t('quiz.incorrecta_tiempo'));
					clearInterval(interval);
					setTemporizadorPausado(true);
				}
			}, 1000); // actualiza cada 1 segundo
			return interval;
		}
	};

	useEffect(() => {
		const interval = iniciarTemporizador();
		return () => {
			clearInterval(interval);
		};
	}, [temporizadorPausado, temporizadorDetenido, tiempoRestante]);

	// Configura la reproducción del audio
	/*
	useEffect(() => {
  
	  // hay algo roto con permisos de autoplay aca
	  // https://stackoverflow.com/questions/57504122/browser-denying-javascript-play
  
	  if (temporizadorPausado || temporizadorDetenido) {
		audio.pause();
		audio.currentTime = 0;
		setAudioPlaying(false);
	  } else {
		audio.play();
		setAudioPlaying(true);
	  }
	}, [temporizadorPausado, temporizadorDetenido, audio]);
	*/

	// determina respuesta correcta, puntos, y guarda puntos en la DB
	//console.debug(`tiempo restante es ${tiempoRestante}, tipo ${typeof tiempoRestante}`)
	const verificarRespuesta = (opcionSeleccionada, respuestaCorrecta) => {
		//console.debug(`tiempo restante al responder ${tiempoRestante}, tipo ${typeof tiempoRestante}`);

		// ignora intentos de cambiar la respuesta
		if (temporizadorPausado || temporizadorDetenido) { return; }

		// pausa el tiempo
		setTemporizadorDetenido(true);
		clearInterval(temporizadorInterval);
		setAudioPlaying(true);

		// fijar al tiempo final en estado
		//setTiempoFinal(tiempoRestante) // asumiendo que esto esta en segundos para calcPuntos

		// calcular puntos
		var puntos = 0
		if (opcionSeleccionada === respuestaCorrecta) {
			console.debug(`Respuesta fue correcta y tiempo final fue ${tiempoRestante}s`)
			puntos = calcularPuntosQuiz(tiempoRestante, true)
			setRespuesta(`${t('quiz.correcta')} ${puntos.toFixed(0)} ${t('common.puntos')}.`);
			audioCorrecta.play(); // Reproduce el audio de respuesta correcta

			// guardar puntos en DB
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "puntos": puntos });
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "quizzesGanados": 1 })
			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "quizzesJugados": 1 })
		} else {
			setRespuesta(t('quiz.incorrecta'));
			audioIncorrecta.play(); // Reproduce el audio de respuesta incorrecta

			agregarPuntosEquipo(selectedPartida, selectedEquipo, { "quizzesJugados": 1 })
		}
	};

	async function botonArrancar(event) {
		event.preventDefault(); // Prevenir recarga del formulario

		console.debug(`botonArrancar`)

		// arrancar tiempo
		reiniciarTemporizador();

		console.debug(`preguntaLoad en boton arrancar es ${JSON.stringify(preguntaLoad)}`)

		// hacer visible a las preguntas
		setPregunta(preguntaLoad)

		// arrancar musica, especifico para resolver problema autoplay
		//temporizadorInterval
		audio.volume = 0.2
		audio.play()
		return (<audio src={audioFile} autoPlay loop />)

	}

	function botonGuardar(event) {
		event.preventDefault(); // Prevenir recarga del formulario
		//console.debug(`sigSprint en boton guardar es ${JSON.stringify(sigSprint)}`)

		if (yaSeGuardo === true) {
			return;
		}

		// Show a confirmation dialog to the user
		const userConfirmed = window.confirm(t('common.confirmar_guardar'));

		if (userConfirmed) {
			setYaSeGuardo(true);

			// Pause the audio
			audio.pause();

			if (sigSprint) {

				console.debug(`Agregando equipo ${selectedEquipo} al sprint ${sigSprint["nombre"]}`);
				agregarEquipoAlSprint(selectedPartida, selectedEquipo, sigSprint["numSprint"]);

				// adentro del if porque si no rompe, no se pq
				console.debug(`navegando a ${sigRuta}`);
				navigate(sigRuta);
			} else {
				console.debug(`No hay siguiente sprint!`);

				// adentro del if porque si no rompe, no se pq
				console.debug(`navegando a ${sigRuta}`);
				navigate(sigRuta);
			}
		}
	}


	return (
		<div className="content" style={{
			backgroundImage: `url(${img})`,
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			height: '100vh',
			width: '100vw',
		}}>
			<Row>
				<Col xs="12">
					<Card>
						<CardBody style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							{pregunta ? (null) : (
								<form onSubmit={botonArrancar} style={{ marginBottom: '10px' }}>
									<Button
										style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px', marginLeft: '20px' }}
										disabled={!preguntaLoad}
									>
										{t('quiz.iniciar')}
									</Button>
								</form>
							)}
							<span style={{ color: 'white', fontSize: '20px', textAlign: 'center' }}>
								{t('quiz.iniciar_desc')}
							</span>
							{yaSeGuardo ? (null) : (
								<form onSubmit={botonGuardar} style={{ marginRight: "30px" }}>
									<Button style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
										<img src={imgOk} alt="Terminar Quiz" style={{ width: '100px', height: '50px' }} />
									</Button>
								</form>
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col xs="12">
					<Card>
						<CardBody>

							{/* Version para una pregunta */}
							{!pregunta ? (
								<p>{t('common.apreta_arranque')}</p>
							) : (
								<>
									<div className="texto" style={{ color: 'white', fontSize: '3em' }}>
										<p style={{ color: 'white' }}>{pregunta.texto}</p>
									</div>
									<div className="opciones" style={{ color: 'white', fontSize: '1.1em' }}>
										{pregunta.opciones.map((opcion, index) => (
											<div key={index} style={{ color: 'white', fontSize: '1.1em', display: 'flex', alignItems: 'center' }}>
												<input type="radio" id={`opcion${index}`} name="opciones" value={index} style={{ transform: 'scale(1.5)', marginRight: '10px' }} />
												<label htmlFor={`opcion${index}`} style={{ fontSize: '1.1em', color: 'white' }}>{opcion}</label>
											</div>
										))}
										<button className="btn btn-info" onClick={() => verificarRespuesta(parseInt(document.querySelector('input[name="opciones"]:checked')?.value), pregunta.respuestaCorrecta)}>{t('quiz.responder')}</button>
									</div>
									<div style={{ color: 'white', fontSize: '2.5em' }}>{t('tiempo.restante')}: {tiempoRestante} {t('tiempo.segundos')}</div>
									{respuesta && <div style={{ color: 'white', fontSize: '1.5em' }}>{respuesta}</div>}

									{/* Reproductor de música de fondo */}
									{ }
								</>
							)}

						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
}

export default Quiz;