// routes de react 

// importar views
import Tablero from "views/Tablero.js";
import CriteriosCorrecion from "views/CriteriosCorreccion.js";
import EstadoResultados from "views/EstadoResultados.js";
import Metricas1 from "views/Metricas1";
import Metricas2 from "views/Metricas2";
import Final from "views/Final.js";

// crear rutas para cada archivo
// una vez aca, se actualizan solas en el sidebar
var routesJugadorPresencial = [
  {
    path: "/Tablero",
    name: 'rutas.tablero',
    icon: "tim-icons icon-chart-pie-36", // como podemos cambiar estas?
    component: <Tablero />,
    layout: "/JugadorPresencial",
  },
  {
    path: "/CriteriosCorreccion",
    name: 'rutas.glosario',
    icon: "tim-icons icon-single-02",
    component: <CriteriosCorrecion />,
    layout: "/JugadorPresencial",
  },
  {
    path: "/EstadoResultados",
    name: 'rutas.estado_res',
    icon: "tim-icons icon-single-02",
    component: <EstadoResultados />,
    layout: "/JugadorPresencial",
  },
  {
    path: "/Metricas1",
    name: 'rutas.metricas1',
    icon: "tim-icons icon-single-02",
    component: <Metricas1 />,
    layout: "/JugadorPresencial",
  },
  {
    path: "/Metricas2",
    name: 'rutas.metricas2',
    icon: "tim-icons icon-single-02",
    component: <Metricas2 />,
    layout: "/JugadorPresencial",
  },
  { // INVISIBLE
    path: "/Final",
    name: 'rutas.final',
    icon: "tim-icons icon-single-02",
    component: <Final />,
    layout: "/JugadorPresencial",
    isVisible: false
  },
];
export default routesJugadorPresencial;
