// puntos.js
// helpers para calcular puntajes, datos de cartas, etc.

import cartasHelpers from "./cartas.js"
import { host } from "./connection.js"
// ${host}

import jugadasHelpers from "./jugadas.js"
import sprintHeleprs from "./sprints.js"

const verJugadasEquipo = jugadasHelpers.verJugadasEquipo
const getSprintEnPartida = sprintHeleprs.getSprintEnPartida
const cartaPorID = cartasHelpers.cartaPorID;

///////////////
/* CONSTANTS */
///////////////

// hay tres formas de sumar puntos:
// el valor de las cartas, ~2000 puntos maximo por juego
// los quizzes, ~1000 puntos maximo por juego
// y el tiempo restante en el timer de cada ronda, otros ~2000 max
const maxPuntos = 8000
const maxPuntosSprint = 500
const maxPuntosQuiz = 150
const maxPuntosCartas = 200

const tiempoMaxQuiz = 60 // segundos

///////////////
/* TRAER DB  */
///////////////

// agarrar puntos del equipo de la db
async function fetchData(partidaId, equipoId) {
	const apiUrl = `https://${host}/juego/${partidaId}/${equipoId}/puntos`;

	try {
		const response = await fetch(apiUrl);

		if (!response.ok) {
			const errorMessage = `fetchData Network response was not ok. Status: ${response.status} ${response.statusText}`;
			throw new Error(errorMessage);
		}

		const data = await response.json();
		return data.data;
	} catch (error) {
		console.error('fetchData Error:', error);
		throw error;
	}
}

// app.get('/juego/:partida/:equipo/modifier'
async function fetchModifier(partidaId, equipoId) {
	const apiUrl = `https://${host}/juego/${partidaId}/${equipoId}/modifier`;

	try {
		const response = await fetch(apiUrl);

		if (!response.ok) {
			const errorMessage = `fetchData Network response was not ok. Status: ${response.status} ${response.statusText}`;
			throw new Error(errorMessage);
		}

		const data = await response.json();
		return { success: true, data: data.data };
	} catch (error) {
		console.error('fetchModifier Error:', error.message);
		return { success: false, error: error.message }
	}
}

///////////////
/*  CARTAS   */
///////////////

// dado una lista de cartas, aplica los puntos al equipo
function calculatePoints(team, cards, totalCardPoints) {
	let points = totalCardPoints;

	const mapping = {
		valor_propio: 'puntos',
		porter_buyers: 'porter_buyers_mod',
		porter_suppliers: 'porter_suppliers_mod',
		porter_rivalry: 'porter_rivalry_mod',
		porter_entrants: 'porter_entrants_mod',
		porter_substitute: 'porter_substitutes_mod',
		driver_novelty: 'driver_novelty_mod',
		driver_lockin: 'driver_lockin_mod',
		driver_complementary: 'driver_complementary_mod',
		driver_efficiency: 'driver_efficiency_mod',
	};

	for (const cardKey in cards) {
		if (mapping[cardKey]) {
			// Subtract Porter values from existing team points
			if (cardKey.startsWith('porter_')) {
				points -= (team[mapping[cardKey]] / 7) || 0;
			}
			// Add Puntos and Driver values
			else if (cardKey === 'puntos' || cardKey.startsWith('driver_')) {
				points += cards[cardKey] || 0;
			}
		}
	}

	return points;
}

// igual a la anterior pero para sprint 0 con "orig" en vez de "mod"
//app.put('/juego/:partida/:equipo/puntosOrig', async (req, res) => {

// trae los datos para cada una de una lista de cartas
async function fetchCardData(cardIds) {
	console.debug(`fetchCardData con: ${cardIds}`)
	const cardDataById = [];

	for (let i = 0; i < cardIds.length; i++) {
		var cardId = cardIds[i]
		console.debug(`trayendo carta: ${cardId}`)
		var carta = await cartaPorID(cardId)
		console.debug(`cartaPorID devuelve: ${JSON.stringify(carta)}`)
		cardDataById.push(carta)
	}
	console.debug(`array de obj de cartas es ${JSON.stringify(cardDataById)}`)
	return cardDataById;
}

// calcular los puntos de una carta
// cartas es array de objetos
async function puntosCartas(cartasArrayObj) {

	// array vacio de puntos
	var puntosBase = {
		"valor_propio": 0,
		//
		"porter_buyers": 0,
		"porter_suppliers": 0,
		"porter_rivalry": 0,
		"porter_entrants": 0,
		"porter_substitute": 0,
		//
		"driver_novelty": 0,
		"driver_lockin": 0,
		"driver_complementary": 0,
		"driver_efficiency": 0,
		//
		"valwheel_what": 0,
		"valwheel_how": 0,
		"valwheel_why": 0,
		"valwheel_who": 0,
	};

	var puntosNuevos = puntosBase

	// desempaca cada carta y lo suma al total
	// sobreescribe a puntos
	for (var i = 0; i < cartasArrayObj.length; i++) {
		//console.debug(`puntos antes de carta ${cartasArrayObj[i].id} son: ${puntosNuevos.valor_propio}`)
		var cambioPuntos = await sumarPuntos(puntosNuevos, cartasArrayObj[i])
		puntosNuevos = cambioPuntos
		//console.debug(`puntos despues de carta ${cartasArrayObj[i].id} son: ${puntosNuevos.valor_propio}`)
	}

	return puntosNuevos
}

// agarrar dos fuentes de puntos y sumarlos               <--------------
async function sumarPuntos(puntosViejos, puntosEntrantes) {
	const keys = Object.keys(puntosViejos)
	var puntosNuevos = {};
	for (let i = 0; i < keys.length; i++) {
		let key = keys[i]
		if (puntosEntrantes.hasOwnProperty(key)) {
			var valPuntosEntrantes = Number(puntosEntrantes[key]) || 0;

			// logica especial para porter va aca

			puntosNuevos[key] = puntosViejos[key] + valPuntosEntrantes;
			//console.debug(`key ${key}: ${puntosNuevos[key]} = ${puntosViejos[key]} + ${Number(puntosEntrantes[key])}`)
		}
	}
	//console.debug(`puntosNuevos son ${JSON.stringify(puntosNuevos)}`)
	return puntosNuevos;
}

// calcula la suma de puntos para un equipo
// de TODOS LOS MAZOS de cartas jugadas, sin contar tiempo extra en sprints ni quizzes
async function calcularPuntosTotalesCartas(partidaId, equipoId) {

	// traer lista de cartas
	const jugadas = await verJugadasEquipo(partidaId, equipoId);

	// conseguir los datos para cada carta
	const cartas = await fetchCardData(jugadas.cartas);

	// sumar los puntos de cada carta, en cada categoria por separado
	const puntos = await puntosCartas(cartas);

	return puntos;
}

///////////////
/*  SPRINTS  */
///////////////

// calcula los puntos de tiempo restante de un sprint
// mazo = Trends, etc
async function calcularPuntosSprint(partidaId, mazo, tiempoRestante) {
	const sprintData = await getSprintEnPartida(partidaId, mazo);

	// default a 300 seg si no hay duracion min en db
	const duracionMin = Number(sprintData.duracion_min) || 300;

	const duracionSec = duracionMin * 60;

	// calcular cuantos puntos otorgar
	var puntos = (tiempoRestante / duracionSec) * maxPuntosSprint;

	// redondeo
	puntos = Math.round(puntos);

	return puntos;
};

function calcularPuntosMaturity(partidaId, equipoId, mazo, puntos) {
	const fn = "VAR Puntos/maturity"
	try {
		if (!partidaId || !equipoId || !mazo || !puntos){throw new Error('faltan datos!')}
		console.debug(`${fn}: equipo ${equipoId}, mazo ${mazo}, puntos ${puntos}...`)

		// maturity toma un porcentual sobre los puntos
		const mod = Number(puntos)/100

		var maturity = {
			"datamaturity_estrategia_mod" : 0,
			"datamaturity_cultura_mod" : 0,
			"datamaturity_orgestructura_mod" : 0,
			"datamaturity_tecnologia_mod" : 0,
			"datamaturity_centralidad_mod" : 0,
		}

		// determinar a cuales aplicarles el puntaje
		switch (mazo) {
			case "Trends":
				maturity.datamaturity_estrategia_mod = mod;
				maturity.datamaturity_centralidad_mod = mod;
				break;
			case "Sustainability":
				maturity.datamaturity_cultura_mod = mod;
				break;
			case "Patterns":
				maturity.datamaturity_estrategia_mod = mod;
				break;
			case "BlueOcean":
				maturity.datamaturity_estrategia_mod = mod;
				maturity.datamaturity_centralidad_mod = mod;
				break;
			case "DigitalDrivers":
				maturity.datamaturity_tecnologia_mod = mod;
				maturity.datamaturity_orgestructura_mod = mod;
				break;
			case "Platform":
				break;
			case "DataDriven":
				maturity.datamaturity_orgestructura_mod = mod;
				maturity.datamaturity_tecnologia_mod = mod;
				break;
			case "Test":
				maturity.datamaturity_centralidad_mod = mod;
				break;
			case "Metrics":
				maturity.datamaturity_cultura_mod = mod;
				break;
			case "Roadmap":
				maturity.datamaturity_cultura_mod = mod;
				maturity.datamaturity_orgestructura_mod = mod;
				maturity.datamaturity_tecnologia_mod = mod;
				break;
			case "Ecosystem":
				maturity.datamaturity_estrategia_mod = mod;
				maturity.datamaturity_cultura_mod = mod;
				break;
			case "CustomerJourney":
				maturity.datamaturity_centralidad_mod = mod;
				break;
			default: throw new Error(`mazo ${mazo} no encontrado`);
		}

		console.debug(`${fn}: maturity para ${mazo} calculado a ${JSON.stringify(maturity)}`)
		
		// llamar a agregarPuntosEquipo
		agregarPuntosEquipo(partidaId, equipoId, {"datamaturity_estrategia_mod":maturity.datamaturity_estrategia_mod})
		agregarPuntosEquipo(partidaId, equipoId, {"datamaturity_cultura_mod":maturity.datamaturity_cultura_mod})
		agregarPuntosEquipo(partidaId, equipoId, {"datamaturity_orgestructura_mod":maturity.datamaturity_orgestructura_mod})
		agregarPuntosEquipo(partidaId, equipoId, {"datamaturity_tecnologia_mod":maturity.datamaturity_tecnologia_mod})
		agregarPuntosEquipo(partidaId, equipoId, {"datamaturity_centralidad_mod":maturity.datamaturity_centralidad_mod})

	} catch (error) {
		const msg = `${fn} error: ${error.message}`
		console.error(msg)
		return null
	}
}

///////////////
/*  QUIZZES  */
///////////////

// calcula los puntos de tiempo restante y respuesta correcta
// tiempo restante en segundos
// abstraido para poder aplicar calculos mas complejos si hace falta
function calcularPuntosQuiz(tiempoRestante, acertado) {
	var puntos = 0

	if (acertado) {
		puntos = tiempoRestante / tiempoMaxQuiz * maxPuntosQuiz
		puntos.toFixed(0) // asegura que sea int
	}

	console.debug(`Puntos de quiz son ${tiempoRestante} / ${tiempoMaxQuiz} * ${maxPuntosQuiz} = ${puntos}`)

	return puntos
}

///////////////
/* INSERT DB */
///////////////

// agrega puntos a un equipo en la db
// espera un key:value pair a la vez {"driver_novelty_mod":1234}
// puntos o _mod
async function agregarPuntosEquipo(partidaId, equipoId, puntos) {
	const apiUrl = `https://${host}/juego/${partidaId}/${equipoId}/puntos`;
	const body = puntos

	console.debug(`agregarPuntosEquipo para equipo ${equipoId}, puntos: ${JSON.stringify(body)}`)

	try {
		const response = await fetch(apiUrl, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) });
		if (!response.ok) {
			const errorMessage = `agregarPuntosEquipo Network response was not ok. Status: ${response.status} ${response.statusText}`;
			throw new Error(errorMessage);
		}
		const data = await response.json();
		return data.data;
	} catch (error) {
		console.error('agregarPuntosEquipo Fetch Data Error:', error);
		throw error;
	}
}

// espera un key:value pair a la vez {"driver_novelty_orig":1234}
// puntos o _orig
async function agregarPuntosEquipoSprint0(partidaId, equipoId, puntos) {
	const apiUrl = `https://${host}/juego/${partidaId}/${equipoId}/puntosOrig`;
	const body = puntos

	try {
		const response = await fetch(apiUrl, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) });
		if (!response.ok) {
			const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
			throw new Error(errorMessage);
		}
		const data = await response.json();
		return data.data;
	} catch (error) {
		console.error('Fetch Data Error:', error);
		throw error;
	}
}

// especial para ecosystem
async function agregarEcosystem(partidaId, equipoId, circulo, oferta, propuesta) {
	const apiUrl = `https://${host}/juego/${partidaId}/${equipoId}/ecosystem`;
	const body = {
		"circulo":circulo, 
		"oferta":oferta,
		"propuesta":propuesta
	}
	console.debug(`agregarEcosystem para equipo ${equipoId}, datos: ${JSON.stringify(body)}`)

	try {
		const response = await fetch(apiUrl, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) });
		if (!response.ok) {
			const errorMessage = `agregarPuntosEquipo Network response was not ok. Status: ${response.status} ${response.statusText}`;
			throw new Error(errorMessage);
		}

		const data = await response.json();
		return {success: true, data: data.data};
	} catch (error) {
		console.error('agregarPuntosEquipo Fetch Data Error:', error);
		return {success: false, error: error.message}
	}
}

///////////////
/*  EXPORTS  */
///////////////

export default {
	fetchData,
	fetchModifier,
	fetchCardData,
	calculatePoints,
	sumarPuntos,
	puntosCartas,
	calcularPuntosTotalesCartas,
	agregarPuntosEquipo,
	agregarPuntosEquipoSprint0,
	calcularPuntosSprint,
	calcularPuntosQuiz,
	maxPuntos,
	maxPuntosSprint,
	maxPuntosQuiz,
	tiempoMaxQuiz,
	calcularPuntosMaturity,
	agregarEcosystem
};

/*
	`puntos` BIGINT UNSIGNED NULL,

	`porter_buyers_orig` DECIMAL(8, 3) NULL,
	`porter_buyers_mod` DECIMAL(8, 3) NULL,
	`porter_suppliers_orig` DECIMAL(8, 3) NULL,
	`porter_suppliers_mod` DECIMAL(8, 3) NULL,
	`porter_rivalry_orig` DECIMAL(8, 3) NULL,
	`porter_rivalry_mod` DECIMAL(8, 3) NULL,
	`porter_entrants_orig` DECIMAL(8, 3) NULL,
	`porter_entrants_mod` DECIMAL(8, 3) NULL,
	`porter_substitutes_orig` DECIMAL(8, 3) NULL,
	`porter_substitutes_mod` DECIMAL(8, 3) NULL,

	`driver_novelty_orig` DECIMAL(8, 3) NULL,
	`driver_novelty_mod` DECIMAL(8, 3) NULL,
	`driver_lockin_orig` DECIMAL(8, 3) NULL,
	`driver_lockin_mod` DECIMAL(8, 3) NULL,
	`driver_complementary_orig` DECIMAL(8, 3) NULL,
	`driver_complementary_mod` DECIMAL(8, 3) NULL,
	`driver_efficiency_orig` DECIMAL(8, 3) NULL,
	`driver_efficiency_mod` DECIMAL(8, 3) NULL,

	`valwheel_what_orig` DECIMAL(8, 3) NULL,
	`valwheel_what_mod` DECIMAL(8, 3) NULL,
	`valwheel_how_orig` DECIMAL(8, 3) NULL,
	`valwheel_how_mod` DECIMAL(8, 3) NULL,
	`valwheel_why_orig` DECIMAL(8, 3) NULL,
	`valwheel_why_mod` DECIMAL(8, 3) NULL,
	`valwheel_who_orig` DECIMAL(8, 3) NULL,
	`valwheel_who_mod` DECIMAL(8, 3) NULL

    `datamaturity_estrategia_orig` DECIMAL(8, 3) NULL,
    `datamaturity_estrategia_mod` DECIMAL(8, 3) NULL,
    `datamaturity_cultura_orig` DECIMAL(8, 3) NULL,
    `datamaturity_cultura_mod` DECIMAL(8, 3) NULL,
    `datamaturity_orgestructura_orig` DECIMAL(8, 3) NULL,
    `datamaturity_orgestructura_mod` DECIMAL(8, 3) NULL,
    `datamaturity_tecnologia_orig` DECIMAL(8, 3) NULL,
    `datamaturity_tecnologia_mod` DECIMAL(8, 3) NULL,
    `datamaturity_centralidad_orig` DECIMAL(8, 3) NULL,
    `datamaturity_centralidad_mod` DECIMAL(8, 3) NULL,

    `ecosistema_circulo` VARCHAR(255) NULL,
    `ecosistema_dinero_orig` DECIMAL(8, 3) NULL,
    `ecosistema_dinero_mod` DECIMAL(8, 3) NULL,
    `ecosistema_oferta` VARCHAR(254) NULL,
    `ecosistema_propuesta_valor` VARCHAR(254) NULL,

    `sustentabilidad_economico` DECIMAL(8,3) NULL,
    `sustentabilidad_social` DECIMAL(8,3) NULL,
    `sustentabilidad_ecologico` DECIMAL(8,3) NULL
*/
