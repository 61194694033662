import { host } from "./connection.js"

// datos de la partida
async function datosPartida(partidaId) {
	const apiUrl = `https://${host}/api/partidas/${partidaId}`;
	console.debug(`Trayendo datos de la partida ${partidaId}...`)

	try {
		const response = await fetch(apiUrl);

		if (!response.ok) {
			const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
			throw new Error(errorMessage);
		}

		const data = await response.json();
		console.debug(`Datos de partida ${partidaId}: ${JSON.stringify(data.data[0])}`)
		return data.data[0];
	} catch (error) {
		console.error('Fallo de datosPartida en partida.js:', error.message);
		return null
	}
}

// traer array de partidas activas para el dia actual
async function partidasDelDia() {
	const fn = "TEST VAR partidas/delDia"
	try {
		// mandar query
		const url = `https://${host}/api/partidasActivasHoy`
		const response = await fetch(url);
		if (!response.ok) {
			const errorMessage = `Network response was not ok. Status: ${response.status} ${response.statusText}`;
			throw new Error(errorMessage);
		}
		const data = await response.json();
		const partidas = data.data
		console.debug(`${fn}: hay ${partidas.length} partidas activas hoy`)

		// devolver
		return {success: true, data: partidas}

	} catch (error) {
		const msg = `${fn} error: ${error.message}`
		console.error(msg)
		return { success: false, error: msg }
	}
}

export default {
	datosPartida,
	partidasDelDia
}