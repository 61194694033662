import React, { useState } from 'react';
import { DndContext, useDraggable, useDroppable, DragOverlay } from '@dnd-kit/core';

const DraggableSquare = ({ id, color, isDragOverlay = false }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
  });

  const overlayStyles = isDragOverlay ? {
    position: 'fixed',
    zIndex: 100,
    left: transform ? `${transform.x}px` : '0px',
    top: transform ? `${transform.y}px` : '0px',
    transition: 'transform 250ms ease',
  } : {};

  return (
    <div
      ref={!isDragOverlay ? setNodeRef : undefined}
      {...listeners}
      {...attributes}
      style={{
        width: '3rem', // 12 tailwind units
        height: '3rem', // 12 tailwind units
        margin: '0.25rem', // 1 tailwind unit
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'grab',
        backgroundColor: color, // Color es pasado como prop
        ...overlayStyles,
      }}
    >
      Drag
    </div>
  );
};

const DroppableSquare = ({ id }) => {
  const { setNodeRef } = useDroppable({ id });

  return (
    <div
      ref={setNodeRef}
      style={{
        width: '6rem', // 24 tailwind units
        height: '6rem', // 24 tailwind units
        margin: '0.25rem', // 1 tailwind unit
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'gray', // Base color
      }}
    >
      Drop
    </div>
  );
};

const getColor = (droppableId) => {
  switch (droppableId) {
    case 'droppable-1':
      return 'red';
    case 'droppable-2':
      return 'green';
    case 'droppable-3':
      return 'blue';
    case 'droppable-4':
      return 'orange';
    default:
      return 'skyblue'; // Default color
  }
};

const Grid = () => {
  const [squareColors, setSquareColors] = useState({});
  const [activeId, setActiveId] = useState(null);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    setActiveId(null);
    if (over) {
      console.log(`Dropped ${active.id} over ${over.id}`);
      setSquareColors({
        ...squareColors,
        [active.id]: getColor(over.id),
      });
    } else {
      console.log(`Dropped ${active.id} in an undefined area`);
    }
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  return (
    <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      {/* Cuadrícula de 5x5 para elementos arrastrables */}
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '5px', marginBottom: '5px', justifyContent: 'center' }}>
        {Array.from({ length: 25 }, (_, i) => `draggable-${i}`).map((id) => (
          <DraggableSquare key={id} id={id} color={squareColors[id] || 'skyblue'} />
        ))}
      </div>
      
      {/* Cuatro áreas droppables alineadas horizontalmente debajo de la cuadrícula */}
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        {['droppable-1', 'droppable-2', 'droppable-3', 'droppable-4'].map((id) => (
          <DroppableSquare key={id} id={id} />
        ))}
      </div>

      {/* Overlay para el elemento siendo arrastrado */}
      <DragOverlay>
        {activeId && (
          <DraggableSquare
            id={activeId}
            color={squareColors[activeId] || 'skyblue'}
            isDragOverlay={true}
          />
        )}
      </DragOverlay>
    </DndContext>
  );
};

export default Grid;