import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute";
import Login from "layouts/Login/Login.js";
import AdminLayout from "layouts/Admin/Admin.js";
import CapitanPresencialLayout from "layouts/Capitan/CapitanPresencial.js";
import CapitanVirtualLayout from "layouts/Capitan/CapitanVirtual.js";
import JugadorPresencialLayout from "layouts/Jugador/JugadorPresencial.js";
import JugadorVirtualLayout from "layouts/Jugador/JugadorVirtual.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";

// internacionalizacion
import './contexts/i18n'

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <BrowserRouter>
        <Routes>
          <Route path="/login/*" element={<Login />} />
          <Route path="/admin/*" element={<PrivateRoute><AdminLayout /></PrivateRoute>} />
          <Route path="/capitanPresencial/*" element={<PrivateRoute><CapitanPresencialLayout /></PrivateRoute>} /> {/* Aquí se añade la nueva ruta */}
          <Route path="/capitanVirtual/*" element={<PrivateRoute><CapitanVirtualLayout /></PrivateRoute>} /> {/* Aquí se añade la nueva ruta */}
          <Route path="/jugadorPresencial/*" element={<PrivateRoute><JugadorPresencialLayout /></PrivateRoute>} /> {/* Aquí se añade la nueva ruta */}
          <Route path="/jugadorVirtual/*" element={<PrivateRoute><JugadorVirtualLayout /></PrivateRoute>} /> {/* Aquí se añade la nueva ruta */}
          <Route path="*" element={<Navigate to="/login/Login" replace />} />
        </Routes>
      </BrowserRouter>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>
);