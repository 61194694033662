// resultados.js
// calcula los cambios en la empresa dado los puntos de un equipo

import { useState } from "react"
import { Trans } from 'react-i18next'

import ResultadosFijo from "classes/ResultadosFijo.js"
import puntosHelpers from "./puntos.js"

const resultadosFijo = new ResultadosFijo();
const fetchData = puntosHelpers.fetchData
const fetchCardData = puntosHelpers.fetchCardData
const calculatePoints = puntosHelpers.calculatePoints
const maxPuntos = puntosHelpers.maxPuntos

// hay tres formas de sumar puntos:
// el valor de las cartas, ~2000 puntos maximo por juego
// los quizzes, ~1000 puntos maximo por juego
// y el tiempo restante en el timer de cada ronda, otros ~2000 max

async function traerPuntosEquipo(partida, equipo) {
    console.debug(`Calculando puntos para equipo ${equipo} en partida ${partida}`)

    var puntos

    if (!partida || !equipo) {
        console.error("no se encontraron partida o equipo")
        // armar todas las tablas aunque no haya equipo
        // pq desp se selecciona
        puntos = 1
    } else {
        //console.debug("si se encontraron equipos")
        // traer datos del equipo
        try {
            const datosEquipoRes = await fetchData(partida, equipo);
            const datosEquipo = datosEquipoRes[0]

            console.debug(`datos del equipo son: ${JSON.stringify(datosEquipo)}, tipo ${typeof datosEquipo}`)
            puntos = datosEquipo.puntos
            console.debug(`En partida ${partida}, equipo ${equipo} tiene ${puntos} puntos`)
        } catch (error) {
            console.error('Error buscando puntos en equipo:', error);
            puntos = 1
        }
    }
    return puntos
}



// pide ID para la partida y ID para el equipo
async function tablaResultados(partida, equipo) {
    const fn = "resultados.js/tablaResultados"
    console.debug(`${fn}...`)
    try {

        var puntos = await traerPuntosEquipo(partida, equipo);

        // modificador porcentual para usar
        var mod = puntos / maxPuntos
        // usar como var*(1+mod) o var*(1-mod)
        // 1234 puntos -> cambio en 6.17%
        console.debug(`Puntos: ${puntos} / Maximo ${maxPuntos} = ${mod}`)

        // ARMAR TABLAS

        const datosAño1 = resultadosFijo.datosAñoX(1000000.00, 1000000.00)
        const datosAño2 = resultadosFijo.datosAñoX(1200000.00, 1200000.00)
        const datosAño3 = resultadosFijo.datosAñoX(1800000.00, 1800000.00)
        const datosAñoActual = resultadosFijo.datosAñoActualX(2150000, 2150000)

        const datosAño1E = resultadosFijo.datosAñoXE
            (60000, 20000, 50000, 15000, 0, 15000, 15000, 250000, 200000, 30000, 655000, 
                200000, 280000, 30000, 15000, 20000, 725000, 
                1380000, -380000, '-4.75%')
        const datosAño2E = resultadosFijo.datosAñoXE
            (75000, 10000, 18000, 15000, 5000, 10000, 5000, 125000, 75000, 15000, 385000, 
                220000, 310000, 250000, 40000, 25000, 845000, 
                1230000, -30000, '-5.13%')
        const datosAño3E = resultadosFijo.datosAñoXE
            (80000, 10000, 35000, 15000, 7000, 10000, 5000, 10000, 10000, 10000, 192000, 
                250000, 350000, 300000, 50000, 30000, 980000, 
                1172000, 628000, '+2.37%')
        const datosAñoActualE = resultadosFijo.datosAñoActualXE
            (80000, 10000, 15000, 15000, 10000, 10000, 5000, 5000, 10000, 10000, 170000, 
                300000, 375000, 400000, 150000, 50000, 1275000, 
                1445000, 705000, '2.73%')

        const tableData = resultadosFijo.tableData();

        // MODIFICADORES
        for (let i = 1; i < tableData.length - 1; i++) {
            tableData[i][1] = Math.round(tableData[i][1] * (1 + mod));  // Haciendo redondeo para asegurarse de que sea entero
            tableData[i][2] = Math.round(tableData[i][2]);  // Haciendo redondeo para asegurarse de que sea entero
            tableData[i][3] = (tableData[i][3] * (1 - mod)).toFixed(2);
            tableData[i][5] = (tableData[i][6] * (1 + mod)).toFixed(2);
        }

        // Actualizar las columnas "Ventas por artículos" y "Costo por artículos"
        for (let row = 1; row < tableData.length - 1; row++) {
            tableData[row][7] = tableData[row][1] * tableData[row][5];
            tableData[row][8] = tableData[row][1] * tableData[row][3];
        }

        // Recalcular las sumas para las columnas
        for (let col = 1; col < tableData[0].length; col++) {
            if (tableData[tableData.length - 1][col] === "") continue;

            let suma = 0;
            for (let row = 1; row < tableData.length - 1; row++) {
                suma += parseFloat(tableData[row][col]);
            }
            tableData[tableData.length - 1][col] = suma;
        }

        // Formatear todos los valores numéricos a moneda
        for (let row = 1; row < tableData.length; row++) {
            for (let col = 1; col < tableData[0].length; col++) {
                // Excluir las columnas "Número vendido" y "Número vendido inicial" del formateo de moneda
                if (col === 1 || col === 2) continue;

                const numValue = resultadosFijo.currencyToNumber(tableData[row][col]);
                if (!isNaN(numValue)) {
                    tableData[row][col] = resultadosFijo.formatCurrency(numValue);
                }
            }
        }

        // Actualizar datosAñoActual
        const totalVentasPorArticulos = tableData[tableData.length - 1][7];
        for (let dato of datosAñoActual) {
            if (dato.concepto === 'Ventas' || dato.concepto === 'Total ingresos') {
                dato.cantidad = totalVentasPorArticulos;
                dato.actual = totalVentasPorArticulos;
            }
        }

        // Actualizar "Costo por artículos" en datosAñoActualE
        for (let i = 0, row = 1; i < 5; i++, row++) {
            datosAñoActualE[13 + i].cantidad = tableData[row][8];
            datosAñoActualE[13 + i].actual = tableData[row][8];
        }

        // Suma de los gastos de los 5 artículos
        let totalGastosVariables = 0;

        for (let i = 13; i <= 17; i++) {
            totalGastosVariables += resultadosFijo.currencyToFloat(datosAñoActualE[i].cantidad);
        }

        // Asignar el total a "Total Gastos Variables"
        datosAñoActualE[18].cantidad = resultadosFijo.formatCurrency(totalGastosVariables);
        datosAñoActualE[18].actual = resultadosFijo.formatCurrency(totalGastosVariables);

        // El total de egresos es la suma de gastos fijos y variables
        const totalGastosFijos = resultadosFijo.currencyToFloat(datosAñoActualE[11].cantidad);
        const totalEgresos = totalGastosFijos + totalGastosVariables;
        datosAñoActualE[19].cantidad = resultadosFijo.formatCurrency(totalEgresos);
        datosAñoActualE[19].actual = resultadosFijo.formatCurrency(totalEgresos);

        // Finalmente, el RTADO NETO es Ingresos - Egresos
        const totalIngresos = resultadosFijo.currencyToFloat(datosAñoActual[1].cantidad); // Asumiendo que este es el índice correcto para Ventas
        const rtadoNeto = totalIngresos - totalEgresos;
        datosAñoActualE[20].cantidad = resultadosFijo.formatCurrency(rtadoNeto);
        datosAñoActualE[20].actual = resultadosFijo.formatCurrency(rtadoNeto);

        /*--------------------------------------------
        AQUI COMIENZAN LOS CALCULOS SOBRE LAS METRICAS
        --------------------------------------------*/

        const X = resultadosFijo.monetaryToNumber(tableData[tableData.length - 1][7]);
        const Y = resultadosFijo.monetaryToNumber(tableData[tableData.length - 1][8]);
        const margenBruto = X - Y;
        //console.debug("Margen Bruto:", margenBruto);

        console.debug(`${fn}: armando revenueGrowth con ${rtadoNeto}`);
        const valorActualE = rtadoNeto // antes: resultadosFijo.currencyToFloat(datosAñoActualE[datosAñoActualE.length - 1].actual);
        const valorInicialCorregido = 705000; // Convertirlo al mismo formato que valorActualE
        const revenueGrowth = valorActualE - valorInicialCorregido;

        const cantidadStr = datosAñoActualE[0].cantidad.replace(/[$,]/g, '').split('.').join('');
        //console.debug("Cantidad después de manipulación:", cantidadStr);
        //console.debug('Valor Actual E:', valorActualE);

        const ratioDeCambio = revenueGrowth / 705000;

        const totalVentasVieja = datosAño3[0].cantidad.replace(/[$,]/g, '').split('.').join(''); // cantidadStr
        //const totalNumeroVendido = // tableData[tableData.length - 1][1]
        const totalVentasActual = datosAñoActual[0].actual.replace(/[$,]/g, '').split('.').join(''); // totalVentasPorArticulos * totalNumeroVendido;

        console.debug(`${fn}: armando incrementoVentas con ${totalVentasActual} / ${totalVentasVieja}`);
        const incrementoVentas = (totalVentasActual / totalVentasVieja) - 1;

        // armar tabla
        // ya llega roto aca:  ResultadosFijo/TablaMetricas con -705000, 1441427.35, -1, NaN
        const TablaMetricas = resultadosFijo.tablaMetricas(revenueGrowth, margenBruto, ratioDeCambio, incrementoVentas);

        //console.debug('Mod:', mod);

        function modifyPercentageValue(index) {
            const currentValueStr = TablaMetricas[index][2];
            //console.debug('Índice:', index);
            //console.debug('Valor actual:', currentValueStr);

            if (typeof currentValueStr === 'string') {
                const currentValue = parseFloat(currentValueStr.replace('%', '')) / 100;
                //console.debug('Valor actual (como decimal):', currentValue);

                const increaseAmount = currentValue * mod;  // Incremento basado en el valor original
                //console.debug('Incremento:', increaseAmount);

                let newValue = currentValue + increaseAmount;
                if (newValue > 1) {  // Asegurarse de que nunca exceda 1 (100%)
                    newValue = 1;
                }
                //console.debug('Nuevo valor:', newValue);

                TablaMetricas[index][2] = (newValue * 100).toFixed(2) + "%";
                //console.debug('Nuevo valor (como porcentaje):', TablaMetricas[index][2]);
            } else {
                console.error(`El valor en TablaMetricas[${index}][2] no es una cadena:`, currentValueStr);
            }
        }

        function reduceLeadTimeValue(index) {
            const currentValueStr = TablaMetricas[index][2];
            //console.debug('Índice:', index);
            //console.debug('Valor actual:', currentValueStr);

            if (typeof currentValueStr === 'string') {
                const timeParts = currentValueStr.split(':');
                const totalHours = parseInt(timeParts[0]) + parseInt(timeParts[1]) / 60 + parseInt(timeParts[2]) / 3600;
                //console.debug('Valor actual (como horas):', totalHours);

                const decreaseAmount = totalHours * mod;  // Reducción basada en el valor original
                //console.debug('Decremento:', decreaseAmount);

                const newTotalHours = totalHours - decreaseAmount;
                //console.debug('Nuevo valor:', newTotalHours);

                const hours = Math.floor(newTotalHours);
                const minutes = Math.floor((newTotalHours - hours) * 60);
                const seconds = Math.floor(((newTotalHours - hours) * 60 - minutes) * 60);

                TablaMetricas[index][2] = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                //console.debug('Nuevo valor (como HH:MM:SS):', TablaMetricas[index][2]);
            } else {
                console.error(`El valor en TablaMetricas[${index}][2] no es una cadena:`, currentValueStr);
            }
        }

        function modifyIntValue(index, shouldDecrease = false) {
            const currentValue = TablaMetricas[index][2];
            //console.debug('Índice:', index);
            //console.debug('Valor actual:', currentValue);

            const adjustment = currentValue * mod;

            if (shouldDecrease) {
                TablaMetricas[index][2] = currentValue - adjustment;
            } else {
                TablaMetricas[index][2] = currentValue + adjustment;
            }

            //console.debug('Nuevo valor:', TablaMetricas[index][2]);
        }

        function modifyMoneyValue(index, shouldDecrease = false) {
            let currentValueStr = TablaMetricas[index][2];
            //console.debug('Índice:', index);
            //console.debug('Valor actual:', currentValueStr);

            // Remover símbolo de moneda y convertir a número
            const currentValue = parseFloat(currentValueStr.replace('$', '').replace(/\./g, '').replace(',', '.'));

            const adjustment = currentValue * mod;

            let newValue;
            if (shouldDecrease) {
                newValue = currentValue - adjustment;
            } else {
                newValue = currentValue + adjustment;
            }

            // Convertir de nuevo a formato de moneda
            const newValueStr = "$ " + newValue.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            TablaMetricas[index][2] = newValueStr;

            //console.debug('Nuevo valor:', newValueStr);
        }

        // Aplicar el cálculo a los valores deseados
        modifyPercentageValue(1);  // Delivery Reliability
        modifyPercentageValue(3);  // Customer Effort
        modifyPercentageValue(4);  // Customer Satisfaction
        modifyPercentageValue(5);  // Customer Retention
        modifyPercentageValue(9);  // Conversion Rate
        modifyPercentageValue(13); // ROI
        modifyPercentageValue(19); // Market Share

        reduceLeadTimeValue(2);    // Lead Time

        // Ajustar valores enteros
        modifyIntValue(6); // Cross Selling
        modifyIntValue(7, true); // Customer Adquisition Cost (reduce)
        modifyIntValue(8); // Unique Visitors
        modifyIntValue(10); // Repeat Visitor
        modifyIntValue(15); // NPS
        modifyIntValue(17); // Tráfico en sitio web

        // Aplicar los ajustes
        modifyMoneyValue(11); // Net Profit
        modifyMoneyValue(14, true); // Capital Expenditure (reduce)
        modifyMoneyValue(16); // Ticket Medio de Compra

        // para export
        const resultados = {
            datosAño1,
            datosAño1E,
            datosAño2,
            datosAño2E,
            datosAño3,
            datosAño3E,
            datosAñoActual,
            datosAñoActualE,
            TablaMetricas,
            tableData,
        };

        return resultados;

    } catch (error) {
        const msg = `${fn} error: ${error.message}`
        console.error(msg)
    }
};

export default { tablaResultados };