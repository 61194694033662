// Temporizador
// Maneja tiempo como componente externo para no estar reiniciando comps principales

import React, { useState, useEffect } from 'react';

export default function Temporizador (tiempoRestante, setTiempoRestante, temporizadorPausado, setTemporizadorPausado, temporizadorDetenido) {

	console.debug("tic...")

	const iniciarTemporizador = () => {
		if (!temporizadorPausado && !temporizadorDetenido) {
			const interval = setInterval(() => {
				if (tiempoRestante > 0) {
					setTiempoRestante(tiempoRestante - 1);
				} else {
					clearInterval(interval);
					setTemporizadorPausado(true);
				}
			}, 1000); // actualiza cada 1 segundo
			return interval;
		}
	};
	
	useEffect(() => {
		const interval = iniciarTemporizador();
		return () => {
			clearInterval(interval);
	
		};
	}, [temporizadorPausado, temporizadorDetenido, tiempoRestante]);

}