// helpers para manejar JWT

const Buffer = require('buffer');

/* no podemos usar JWT aca sin hacer un pilon de polyfills 
const jwt = require('jsonwebtoken'); 

async function decodeToken (token) {
    try {
        const decodedToken = jwt.decode(token);
        var claims = []
      
        // Access the claims (payload) of the JWT
        if (decodedToken) {
          console.debug('JWT Claims:');
          for (const claim in decodedToken) {
            if (decodedToken.hasOwnProperty(claim)) {
              console.debug(`${claim}: ${decodedToken[claim]}`);
              claims[claim] = decodeToken[claim]
            }
          }
          return claims
        } else {
          console.error('Failed to decode JWT.');
        }
      } catch (error) {
        console.error('Error decoding JWT:', error.message);
      }
}
*/

function base64UrlDecode(str) {
  // Convertir Base64URL a Base64 regular
  let base64 = str.replace('-', '+').replace('_', '/');
  
  // Añadir el relleno '=' necesario, si es necesario
  while (base64.length % 4) {
      base64 += '=';
  }
  
  // Decodificar la cadena Base64
  const decoded = atob(base64);
  return decoded;
}

function decodeToken(token) {

  // verificar que haya token
  if (!token){
    console.error(`no hay token!`)
    return
  }

  try {
      const parts = token.split('.');
      if (parts.length !== 3) {
          throw new Error('The JWT token is not correct. Expected three parts.');
      }

      const decodedPayload = base64UrlDecode(parts[1]);
      const payloadObj = JSON.parse(decodedPayload);
      const claims = {};

      //console.debug('JWT Claims:');
      for (const claim in payloadObj) {
          if (payloadObj.hasOwnProperty(claim)) {
              //console.debug(`${ claim }: ${ payloadObj[claim]}`);
              claims[claim] = payloadObj[claim];
          }
      }
      return claims;
  } catch (error) {
      console.error('Error decoding JWT:', error.message);
  }
}

export default {
  decodeToken
}