// Estado de Resultados

import React from "react";
import { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { useTranslation } from "react-i18next";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import img from "../assets/img/fondo3.png"; // Ruta IMAGEN

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import { host } from "../variables/connection.js";
import resultadosHelper from "../variables/resultados.js"
import tokensHelpers from "../variables/tokens.js"

const resultados = resultadosHelper.tablaResultados
const decodeToken = tokensHelpers.decodeToken

const tableContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
};

const tableContainerStyle2 = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
};

const tableStyle = {
  width: '30%', // Ancho ajustable
  borderCollapse: 'collapse',
  margin: '20px 0',
};

const thStyle = {
  backgroundColor: '#f2f2f2',
  border: '1px solid #ddd',
  padding: '8px',
  textAlign: 'center',
  color: 'black',
};

const tdStyle = {
  border: '1px solid #ddd',
  padding: '8px',
  textAlign: 'left',
  color: 'white',
};

function EstadoResultados(props) {

  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true); // Add loading state

  const [userType, setUserType] = useState([]);

  const [partidas, setPartidas] = useState([]);
  const [selectedPartida, setSelectedPartida] = useState();
  const [nombreEquipo, setNombreEquipo] = useState('');
  const [equipos, setEquipos] = useState([]);
  const [selectedEquipo, setSelectedEquipo] = useState();

  const [datosAño1, setdatosAño1] = useState([]);
  const [datosAño1E, setdatosAño1E] = useState([]);
  const [datosAño2, setdatosAño2] = useState([]);
  const [datosAño2E, setdatosAño2E] = useState([]);
  const [datosAño3, setdatosAño3] = useState([]);
  const [datosAño3E, setdatosAño3E] = useState([]);
  const [datosAñoActual, setdatosAñoActual] = useState([]);
  const [datosAñoActualE, setdatosAñoActualE] = useState([]);
  const [TablaMetricas, setTablaMetricas] = useState([]);
  const [tableData, settableData] = useState([]);

  useEffect(() => {
    setearConfDesdeToken(); // Para que se ejecute al montar el componente
  }, []); // Se ejecutará solo una vez al montar el componente gracias a la dependencia vacía []


  function setearConfDesdeToken() {
    // recibir token de local storage
    const token = localStorage.getItem('token')

    if (token) {
      console.debug('Token found:', token);
      const claims = decodeToken(token);
      console.debug('Token claims:', claims);
			
      // armar idioma
      if (claims.idioma) { i18n.changeLanguage(claims.idioma) }

      // configuracion de juego
      if (claims.role === "Player" || claims.role === "Captain") {
        setUserType("Player")
        setSelectedPartida(claims.partida)
        setSelectedEquipo(claims.equipo)

        //traerResultados(claims.partida, claims.equipo)

      } else if (claims.role === "Admin") {
        setUserType("Admin")
      } else {
        console.error("y vos quien sos ???")
      }
    }
  }

  const findPartidaName = () => {
    const partida = partidas.find(p => p.id === selectedPartida);
    return partida ? partida.nombre : "Selecciona una Partida";
  };

  const findEquipoName = () => {
    const equipo = equipos.find(e => e.id === selectedEquipo);
    return equipo ? equipo.nombre : "Selecciona un Equipo";
  };

  const fetchPartidas = async () => {
    try {
      const response = await fetch(`https://${host}/api/partidas`);
      if (!response.ok) { throw new Error('Respuesta del servidor no es OK'); }
      const data = await response.json();
      if (Array.isArray(data.data) && data.data.length) {
        setPartidas(data.data);
        setSelectedPartida(data.data[0].id); // Corregido para seleccionar el primer ID
      } else {
        console.error("Formato de datos no esperado:", data);
      }
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  const fetchEquiposPorPartida = async (partidaId) => {
    try {
      const response = await fetch(`https://${host}/juego/${partidaId}`);
      if (!response.ok) { throw new Error('Respuesta del servidor no es OK'); }
      const data = await response.json();
      if (Array.isArray(data.data)) {
        setEquipos(data.data);
      } else {
        setEquipos([]);
        console.warn(data.message);
      }
    } catch (error) {
      setEquipos([]); // En caso de un error, resetea los equipos.
      console.error("Error al obtener los equipos (o no los pediste todavia):", error);
    }
  };

  useEffect(() => {
    fetchPartidas();
  }, []);

  // Este useEffect se encargará de cargar los equipos cuando cambie la partida seleccionada
  useEffect(() => {
    if (selectedPartida) {
      fetchEquiposPorPartida(selectedPartida);
    }
  }, [selectedPartida]);

  const handlePartidaChange = (e) => {
    const partidaId = e.target.value;
    setSelectedPartida(partidaId);
    setEquipos([]);  // Resetea los equipos cuando cambias la partida
    setSelectedEquipo(null);  // Resetea el equipo seleccionado
  };

  const handleEquipoChange = (e) => {
    const equipoId = e.target.value;
    setSelectedEquipo(equipoId);
  };

  // Use useEffect to fetch resultados data and set loading state
  useEffect(() => {
    resultados(selectedPartida, selectedEquipo)
      .then((result) => {
        console.debug("Resultados traidos exitosamente");
        var datosResultados = result;
        console.debug(JSON.stringify(datosResultados))
        setdatosAño1(result.datosAño1);
        console.debug(`Año 1: ${JSON.stringify(datosAño1)}`)
        setdatosAño1E(result.datosAño1E);
        setdatosAño2(result.datosAño2);
        setdatosAño2E(result.datosAño2E);
        setdatosAño3(result.datosAño3);
        setdatosAño3E(result.datosAño3E);
        setdatosAñoActual(result.datosAñoActual);
        setdatosAñoActualE(result.datosAñoActualE);
        setTablaMetricas(result.TablaMetricas);
        settableData(result.tableData);
        setLoading(false); // Set loading to false once data is available
      })
      .catch((error) => {
        console.error("Error trayendo resultados:", error);
        setLoading(true); // Set loading to false in case of an error
      });
  }, [selectedPartida, selectedEquipo]);

  return (
    <>
      <div className="content"
        style={{
          backgroundImage: `url(${img})`, // Aplicar la imagen de fondo importada
          backgroundSize: 'cover', // Opcional, ajusta el tamaño de la imagen de fondo
          backgroundRepeat: 'no-repeat', // Opcional, controla la repetición de la imagen
        }}>
        {userType !== "Player" && userType !== "Captain" ? (
          <Row>
            <Col xs="12">
              <Card style={{ backgroundColor: '#003366' }}>
                <CardBody>
                  <h2 style={{ color: '#E8E8E8' }}>Seleccionar partida & equipo</h2>
                  <FormGroup style={{ marginBottom: '10px', maxWidth: '50%' }}>
                    <Label style={{ color: '#E8E8E8' }}>Seleccione una Partida</Label>
                    <Input
                      style={{
                        color: '#E8E8E8',
                        borderColor: '#2C83D5',
                        fontSize: '18px',
                        backgroundColor: 'transparent',
                        padding: '10px 15px',
                        height: 'auto'
                      }}
                      type="select"
                      value={selectedPartida || ''}
                      onChange={handlePartidaChange}
                    >
                      {partidas.map(partida => (
                        <option key={partida.id} value={partida.id}>
                          {partida.nombre}
                        </option>
                      ))}
                    </Input>
                    <Label style={{ color: '#E8E8E8' }}>Seleccione un Equipo</Label>
                    <Input
                      style={{
                        color: '#E8E8E8',
                        borderColor: '#2C83D5',
                        fontSize: '18px',
                        backgroundColor: 'transparent',
                        padding: '10px 15px',
                        height: 'auto'
                      }}
                      type="select"
                      value={selectedEquipo || ''}
                      onChange={handleEquipoChange}
                    >
                      {equipos.map(equipo => (
                        <option key={equipo.id} value={equipo.id}>
                          {equipo.nombre}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : null}


        {/* ESPERAR A QUE CARGUEN LOS DATOS ANTES DE RENDER */}
        {loading ? (
          <p>{t('common.cargandoResultados')}...</p>
        ) : (
          // Render your tables and charts when loading is false
          <>
            <Row>
              <Col xs="12">
                <Card className="card-chart">
                  <div style={tableContainerStyle}>
                    <table style={tableStyle}>
                      <thead>
                        <tr>
                          <th colSpan="2" style={thStyle}>{t('estadoRes.year')} 1</th>
                        </tr>
                        <tr>
                          <th colSpan="2" style={thStyle}>{t('estadoRes.ingresos')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datosAño1.map((item, index) => (
                          <tr key={index}>
                            <td style={tdStyle}>
                              {index === 1 ? (
                                <strong>{item.concepto}</strong>
                              ) : (
                                item.concepto
                              )}
                            </td>
                            <td style={tdStyle}>{item.cantidad}</td>
                          </tr>
                        ))}
                      </tbody>
                      <thead>
                        <tr>
                          <th colSpan="2" style={thStyle}>{t('estadoRes.egresos')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datosAño1E.map((item, index) => (
                          <tr key={index}>
                            <td style={tdStyle}>
                              {index === 0 || index === 12 || index === 18 || index === 19 || index === 20 || index === 21 ? (
                                <strong>{item.concepto}</strong>
                              ) : (
                                item.concepto
                              )}
                            </td>
                            <td style={tdStyle}>{item.cantidad}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <table style={tableStyle}>
                      <thead>
                        <tr>
                          <th colSpan="2" style={thStyle}>{t('estadoRes.year')} 2</th>
                        </tr>
                        <tr>
                          <th colSpan="2" style={thStyle}>{t('estadoRes.ingresos')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datosAño2.map((item, index) => (
                          <tr key={index}>
                            <td style={tdStyle}>
                              {index === 1 ? (
                                <strong>{item.concepto}</strong>
                              ) : (
                                item.concepto
                              )}
                            </td>
                            <td style={tdStyle}>{item.cantidad}</td>
                          </tr>
                        ))}
                      </tbody>
                      <thead>
                        <tr>
                          <th colSpan="2" style={thStyle}>{t('estadoRes.egresos')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datosAño2E.map((item, index) => (
                          <tr key={index}>
                            <td style={tdStyle}>
                              {index === 0 || index === 12 || index === 18 || index === 19 || index === 20 || index === 21 ? (
                                <strong>{item.concepto}</strong>
                              ) : (
                                item.concepto
                              )}
                            </td>
                            <td style={tdStyle}>{item.cantidad}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <table style={tableStyle}>
                      <thead>
                        <tr>
                          <th colSpan="2" style={thStyle}>{t('estadoRes.year')} 3</th>
                        </tr>
                        <tr>
                          <th colSpan="2" style={thStyle}>{t('estadoRes.ingresos')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datosAño3.map((item, index) => (
                          <tr key={index}>
                            <td style={tdStyle}>
                              {index === 1 ? (
                                <strong>{item.concepto}</strong>
                              ) : (
                                item.concepto
                              )}
                            </td>
                            <td style={tdStyle}>{item.cantidad}</td>
                          </tr>
                        ))}
                      </tbody>
                      <thead>
                        <tr>
                          <th colSpan="2" style={thStyle}>{t('estadoRes.egresos')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datosAño3E.map((item, index) => (
                          <tr key={index}>
                            <td style={tdStyle}>
                              {index === 0 || index === 12 || index === 18 || index === 19 || index === 20 || index === 21 ? (
                                <strong>{item.concepto}</strong>
                              ) : (
                                item.concepto
                              )}
                            </td>
                            <td style={tdStyle}>{item.cantidad}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Card className="card-chart">
                  <div style={tableContainerStyle2}>
                    <table style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}>
                      <thead>
                        <tr>
                          {tableData[0].map((cell, columnIndex) => (
                            <th key={columnIndex} style={thStyle}>{cell}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.slice(1).map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {row.map((cell, columnIndex) => (
                              <td key={columnIndex} style={tdStyle}>{cell}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                <Card className="card-chart">
                  <div style={tableContainerStyle}>

                    <table style={tableStyle}>
                      <thead>
                        <tr>
                          <th colSpan="2" style={thStyle}>{t('estadoRes.currentYear')}</th>
                        </tr>
                        <tr>
                          <th colSpan="2" style={thStyle}>{t('estadoRes.ingresos')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datosAñoActual.map((item, index) => (
                          <tr key={index}>
                            <td style={tdStyle}>
                              {index === 1 ? (
                                <strong>{item.concepto}</strong>
                              ) : (
                                item.concepto
                              )}
                            </td>
                            <td style={tdStyle}>{item.cantidad}</td>
                          </tr>
                        ))}
                      </tbody>
                      <thead>
                        <tr>
                          <th colSpan="2" style={thStyle}>{t('estadoRes.egresos')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datosAñoActualE.map((item, index) => (
                          <tr key={index}>
                            <td style={tdStyle}>
                              {index === 0 || index === 12 || index === 18 || index === 19 || index === 20 || index === 21 ? (
                                <strong>{item.concepto}</strong>
                              ) : (
                                item.concepto
                              )}
                            </td>
                            <td style={tdStyle}>{item.cantidad}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <table>
                      <thead>
                        <tr>
                          {TablaMetricas[0].map((cell, columnIndex) => (
                            <th key={columnIndex} style={thStyle}>{cell}</th>  // Aquí se aplica el estilo thStyle
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {TablaMetricas.slice(1).map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {row.map((cell, columnIndex) => (
                              <td key={columnIndex} style={tdStyle}>{cell}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
}

export default EstadoResultados;