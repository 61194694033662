import React from 'react';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import PDFBase from '../../assets/certificado/RoadMap Game certification V.3 sin nombre.pdf';
import { Trans } from 'react-i18next';

const DownloadCertificate = (nombre, institucion, fecha) => {
    const handleDownload = async () => {
        const url = PDFBase;

        try {
            const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            const page = pdfDoc.getPages()[0];
            const fontSize = 40;
            const font = await pdfDoc.embedFont(StandardFonts.Courier);
            const { width, height } = page.getSize();

            // Calcular posición para el nombre
            const textWidthNombre = font.widthOfTextAtSize(nombre, fontSize);
            const textXNombre = (width - textWidthNombre) / 2;
            const textYNombre = height / 2; // Ajustar esta posición según sea necesario

            // Reducir el tamaño de la fuente para la institución y la fecha
            const fontSizeInstitucionFecha = 20;

            // Concatenar institución y fecha
            const institucionFecha = institucion + ". " + fecha;
            const textWidthInstitucionFecha = font.widthOfTextAtSize(institucionFecha, fontSizeInstitucionFecha);
            const textXInstitucionFecha = (width - textWidthInstitucionFecha) / 2;
            const textYInstitucionFecha = textYNombre - 90; // Ajustar esta posición según sea necesario

            // Dibujar el nombre
            page.drawText(nombre, {
                x: textXNombre,
                y: textYNombre,
                size: fontSize,
                font: font,
                color: rgb(0, 0, 0),
            });

            // Dibujar la institución y la fecha juntas
            page.drawText(institucionFecha, {
                x: textXInstitucionFecha,
                y: textYInstitucionFecha,
                size: fontSizeInstitucionFecha,
                font: font,
                color: rgb(0, 0, 0),
            });

            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });

            const urlBlob = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = urlBlob;
            a.download = 'Certificado.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(urlBlob);
        } catch (error) {
            console.error('Error al generar el certificado:', error);
        }
    };

    return handleDownload();
};

export default DownloadCertificate;