import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import es from '../locales/es.json'
import en from '../locales/en.json'
import pt from '../locales/pt.json'

i18n

	// usar esta instancia para el plugin de react
	.use(initReactI18next)

	// configurar
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		debug: true,
		fallbackLng: 'es',
		interpolation: {
			escapeValue: false,
		},
		resources: {
			es: es,
			en: en,
			pt: pt
		}
	})

export default i18n;
